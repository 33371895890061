import * as fabric from "fabric"; // v6
import React, { useContext, useEffect, useRef, useState } from "react";
import { APainterContext } from "./APainterContext";
import { raiseCustomEvent, uuidv4 } from "./painter/util";
import { PAINTER_CONSTANTS } from "./painter/constants";
import { DrawMode, getUISystem } from "./painter/systems/ui";
import { getColorAtRelativePosition } from './canvasutil';
import './PainterCanvas.scss';

export const stopEvent = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
};

export class PainterCanvas extends fabric.Canvas {
    currentRaycastId: any;
    id: any;
    active: boolean;
    /**
     * Simulates a mouse event on the canvas.
     * @param {string} eventType - Type of the mouse event (`mousedown`, `mouseup`, etc.).
     * @param {number} x - The x-coordinate of the mouse pointer relative to the canvas.
     * @param {number} y - The y-coordinate of the mouse pointer relative to the canvas.
     */
    simulateMouseEvent(eventType, x, y, eventInfo) {
        this.enablePointerEvents = false;
        switch (eventType) {
            case PAINTER_CONSTANTS.MOUSE.MOUSEENTER:
            case PAINTER_CONSTANTS.MOUSE.MOUSEMOVE:
                if (!this.currentRaycastId) {
                    this.currentRaycastId = eventInfo.raycastid;
                }
                break;
            case PAINTER_CONSTANTS.MOUSE.MOUSEOUT:
                if (eventInfo.raycastid === this.currentRaycastId) {
                    this.currentRaycastId = null;
                }
                break;
        }
        if (eventInfo.raycastid !== this.currentRaycastId) {
            return;
        }
        const event = new MouseEvent(`${eventType}`, {
            buttons: 1,
            bubbles: true,
            cancelable: true,
            clientX: x,
            clientY: y,
            view: window,
            ...eventInfo
        });

        this[`upperCanvasEl`].dispatchEvent(event);
        // switch (eventType) {
        //     case PAINTER_CONSTANTS.MOUSE.MOUSEDOWN:
        //         this.active = true;
        //         console.log('active true;')
        //         break;
        //     case PAINTER_CONSTANTS.MOUSE.MOUSEUP:
        //         this.active = false;
        //         console.log('active false;')
        //         break;
        //     case PAINTER_CONSTANTS.MOUSE.MOUSEMOVE:
        //         if (this.active) {
        //             console.log('active true, raising painter canvas updated;')
        //             raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
        //                 id: this.id
        //             });
        //         }
        //         break;
        // }
        // raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
        //     id: (this as any).id
        // });
    }
}

function setBrush(canvas, brush) {
    canvas.isDrawingMode = false;
    canvas.freeDrawingBrush = brush;
}
var options = { e: { pointerId: 1 } };

function pointDrawer(points, brush, fireUp = false, onMove = undefined) {
    setBrush(brush.canvas, brush);
    if (points[0])
        brush.onMouseDown(points[0], options);
    for (var i = 1; i < points.length; i++) {
        points[i].x = parseFloat(points[i].x);
        points[i].y = parseFloat(points[i].y);
        brush.onMouseMove(points[i], options);
        onMove && onMove(points[i], i, points);
    }
    if (fireUp) {
        brush.onMouseUp(options);
    }
}

/**
 * Loads an image into a Fabric.js canvas.
 * @param {fabric.Canvas} canvas - The Fabric.js canvas instance.
 * @param {string} imageUrl - The URL of the image to load.
 * @param {Object} options - Optional settings for the image (e.g., left, top, scale, etc.).
 */
function loadImageIntoCanvas(canvas, imageUrl, options = {}) {
    (fabric.Image as any).fromURL(imageUrl, {
        crossOrigin: 'anonymous' // Use 'anonymous' for cross-origin images
    }, function (oImg) {
        // Apply additional options if provided
    }).then((oImg) => {
        oImg.set(options);

        // Add the image to the canvas
        canvas.add(oImg);
        canvas.renderAll();

    });
}

function freedrawing(canvas, eraser?) {
    // eslint-disable-next-line
    var brush = new fabric.PencilBrush(canvas);
    brush.color = eraser ? '#000000' : 'red';
    brush.width = 12;
    // Customization for the eraser behavior
    if (eraser) {
        // Override the onMouseDown method to fix the error
        const originalOnMouseDown = brush.onMouseDown;
        brush.onMouseDown = function (pointer, options) {
            originalOnMouseDown.call(this, pointer, options);

            // Change global composite operation for erasing
            canvas.contextTop.globalCompositeOperation = 'destination-out';
        };


        // Override the onMouseMove method
        brush.onMouseMove = function (pointer, eventOptions) {
            fabric.PencilBrush.prototype.onMouseMove.call(this, pointer, eventOptions);
            // Ensure the erasing operation continues as expected
            canvas.contextTop.globalCompositeOperation = 'destination-out';
        };
        // Reset globalCompositeOperation when mouse is up
        canvas.on('mouse:up', function () {
            canvas.contextTop.globalCompositeOperation = 'source-over';
        });
    }

    pointDrawer([], brush);
}
export const PainerFabricCanvas = ({ id, height, width }: { id: string, height: number, width: number }) => {
    const canvasEl = useRef<HTMLCanvasElement>(null);
    const canvasContext = useRef<any>(null);
    const drawing = useRef(null);
    const canvasContainer = useRef<HTMLDivElement>(null);
    const context = useContext(APainterContext);
    const painting = useRef(false);
    const [painterCanvas, setPainterCanvas] = useState(null)
    const [eraser, setEraser] = useState(false);
    const active = useRef(false);
    const [ready, setReady] = useState(0);

    useEffect(() => {
        const canvas: any = canvasEl.current;
        canvasContext.current = (canvas.getContext('2d'));
        // make the fabric.Canvas instance available to your app
        context.addCanvasContext({ id, canvas });
        canvas.id = id;
        // freedrawing(canvas, eraser);
        setPainterCanvas(canvas);
        setReady(ready + 1)
        // Reset globalCompositeOperation when mouse is up
        if (!(canvas as any).listeningToEvents) {
        }

        // Function to handle and simulate events
        const handleEvent = (eventType, evt) => {
            if (evt?.detail?.layerId !== id) return;
            if (context?.drawingDisabled?.current) { return; }
            const { clientX, clientY, raycastid, layerId, } = evt.detail;
            let ui = getUISystem();
            let x, y;
            let { height, width } = canvas;
            // For mouse events
            x = clientX * width; //- rect.left;
            y = clientY * height; //- rect.top;
            if (ui.getDrawingMode() === DrawMode.Mask) {
                let color = getColorAtRelativePosition(canvas, x / width, y / height);
                raiseCustomEvent(PAINTER_CONSTANTS.SELECT_MASKING_TARGET_COLOR, {
                    color,
                    x: Math.floor(x),
                    y: Math.floor(y),
                    eventType
                });
                return;
            }

            draw(eventType, canvasContext, x, y, id);

        };
        const handleMouseEvent = (eventType, evt) => {
            let ui = getUISystem();
            if (![DrawMode.Mask, DrawMode.Draw].includes(ui.getDrawingMode())) {
                return;
            }
            const { clientX, clientY, } = evt;
            let x, y;
            let { height, width } = canvas;
            let bb = evt.target.getBoundingClientRect()
            // For mouse events
            x = clientX - bb.left; //- rect.left;
            y = clientY - bb.top; //- rect.top;
            if (ui.getDrawingMode() === DrawMode.Mask && eventType === PAINTER_CONSTANTS.MOUSE.MOUSEUP) {
                let color = getColorAtRelativePosition(canvas, x / width, y / height);
                raiseCustomEvent(PAINTER_CONSTANTS.SELECT_MASKING_TARGET_COLOR, {
                    color,
                    x: Math.floor(x),
                    y: Math.floor(y),
                    eventType
                });
                return;
            }
            else if (ui.getDrawingMode() !== DrawMode.Draw) {
                return;
            }
            switch (eventType) {
                case PAINTER_CONSTANTS.MOUSE.MOUSEDOWN:
                    if (!painting.current) {
                        draw(PAINTER_CONSTANTS.MOUSE.START_DRAW, canvasContext, x, y, id);
                    }
                    painting.current = (true);
                    return;
                case PAINTER_CONSTANTS.MOUSE.MOUSEOUT:
                case PAINTER_CONSTANTS.MOUSE.MOUSEUP:
                    if (painting.current) {
                        draw(PAINTER_CONSTANTS.MOUSE.STOP_DRAW, canvasContext, x, y, id);
                    }
                    painting.current = (false);
                    break;
                case PAINTER_CONSTANTS.MOUSE.MOUSEMOVE:
                    if (painting.current) {
                        draw(PAINTER_CONSTANTS.MOUSE.DRAW, canvasContext, x, y, id);
                    }
                    break;
            }
        };
        // setTimeout(() => {
        //     let ui = getUISystem()
        //     if (ui) {
        //         drawOnCanvas(canvasContext.current, 100, 100, 10, ui.getCurrentColorState());
        //         raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
        //             id
        //         });
        //     }
        // }, 4000)
        const events = [
            'resize',
            'mousedown',
            'mouseenter',
            'mousemove',
            'mouseup',
            'mouseover',
            'draw',
            'mouseout',
            'click',
            'wheel',
            'contextmenu',
            'dblclick',
            'dragstart',
            'dragend',
            'dragover',
            'dragenter',
            'dragleave',
            'drop',
            // Adding touch events
            'touchstart', 'touchmove', 'touchend', 'touchcancel'];
        let all_handlers = events.map(eventType => {
            let handler = (event) => handleEvent(eventType, event);
            let handleMouse = (event) => handleMouseEvent(eventType, event);
            document.body.addEventListener(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${eventType}`, handler);
            canvasEl.current.addEventListener(`${eventType}`, handleMouse);
            return { eventType, handler, handleMouse };
        });
        // loadImageIntoCanvas(canvas, 'https://media.discordapp.net/attachments/993901691755831297/1188428872979587113/aporter_the_perfect_cell_e1ca3e64-8778-4210-bc98-eb2673c6d2f7.png?ex=659a7da3&is=658808a3&hm=412c668ca6346a3a071c96910873f3f4c41e6f9f3083176b4bacafbdf4cbab66&=&format=webp&quality=lossless&width=874&height=874')
        return () => {
            all_handlers.forEach(item => {
                const { eventType, handler, handleMouse } = item;
                document.body.removeEventListener(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${eventType}`, handler);
                if (canvasEl?.current?.removeEventListener) {
                    canvasEl.current.removeEventListener(`${eventType}`, handleMouse);
                }
            });
            setPainterCanvas(null);
            context.removeCanvasContext(id);
        }
    }, [height, width]);
    useEffect(() => {
        if (ready) {
            let canvasHtmls = canvasContainer.current.querySelectorAll('canvas');
            let canvases = []
            for (let i = 0; i < canvasHtmls.length; i++) {
                let canvas = canvasHtmls[i];
                canvas.setAttribute('composite', `${i}`);
                canvases.push(canvas);
            }
            console.log(`canvases: ${canvases.length}`)
            raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILT, {
                canvases,
                id
            });
            // raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
            //     canvas: painterCanvas['lowerCanvasEl'],
            //     id
            // });
        }
    }, [ready])
    useEffect(() => {
        if (context?.painterCanvas) {
            freedrawing(context.painterCanvas, eraser);
        }
    }, [eraser])

    return <div id={id} style={{ display: context.selectedLayer === id ? '' : 'none' }} className="painter-canvas" ref={canvasContainer}>
        <canvas width={width} height={height} ref={canvasEl} />
    </div>;

};

let hasDrawn = false;
function draw(eventType: any, canvasContext: React.MutableRefObject<any>, x: any, y: any, id: string) {
    switch (eventType) {
        case PAINTER_CONSTANTS.MOUSE.START_DRAW:
            canvasContext.current.beginPath();
            canvasContext.current.moveTo(x, y);
            canvasContext.current.lineCap = 'round';  // Set lineCap to round
            canvasContext.current.lineJoin = 'round'; // Set lineJoin to round
            break;
        case PAINTER_CONSTANTS.MOUSE.STOP_DRAW:
            canvasContext.current.closePath();
            break;
        case PAINTER_CONSTANTS.MOUSE.DRAW:
            let ui = getUISystem();
            if (ui) {
                let size = ui.getCursorSize();
                let color = ui.getCurrentColorState();
                let drawMode = ui.getDrawingMode();
                drawOnDotCanvas(canvasContext.current, x, y, 100 * easeInCubic(size), color, drawMode);
                raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
                    id
                });
                if (!hasDrawn) {
                    hasDrawn = true;
                    raiseCustomEvent(PAINTER_CONSTANTS.DRAWING, {
                        id
                    });
                }
            }
            break;
    }
}

function translateEventToAnotherElement(targetElement: HTMLElement): { offsetX: number, offsetY: number } {
    // Get the bounding rectangles of source and target elements
    const sourceRect = { left: 0, top: 0 };
    const targetRect = targetElement.getBoundingClientRect();

    // Calculate the offset
    const offsetX = sourceRect.left - targetRect.left;
    const offsetY = sourceRect.top - targetRect.top;

    // Create a new MouseEvent with adjusted clientX and clientY
    return {
        offsetX,
        offsetY,
    };

}
function easeInCubic(x: number): number {
    if (x < 0 || x > 1) {
        throw new Error("Input must be between 0 and 1");
    }
    return Math.pow(x, 1.3);
}
// let temp = (e) => {
//     if (isDrawing) {
//         if (isErasing) {
//             ctx.globalCompositeOperation = 'destination-out';
//             ctx.lineWidth = 10; // Eraser size
//         } else {
//             ctx.globalCompositeOperation = 'source-over';
//             ctx.lineWidth = 1; // Drawing line size
//         }
//         ctx.lineTo(e.offsetX, e.offsetY);
//         ctx.stroke();
//     }
// }
function drawOnCanvas(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number, color: string, drawMode: string) {
    // ctx.beginPath(); // Start a new path
    // ctx.arc(x, y, radius, 0, Math.PI * 2, true); // Create a circle

    if (drawMode === DrawMode.Draw) {
        ctx.globalCompositeOperation = 'source-over';
        ctx.strokeStyle = color;
        // ctx.fillStyle = color; // Set the fill color for drawing
        // ctx.fill(); // Fill the circle
    } else if (drawMode === DrawMode.Erase) {
        ctx.globalCompositeOperation = 'destination-out'; // Set the composite mode for erasing
        // ctx.fill(); // Erase the area
        // ctx.globalCompositeOperation = 'source-over'; // Reset the composite mode
    }
    ctx.lineWidth = radius;
    ctx.lineTo(x, y);
    ctx.stroke();

    // ctx.closePath(); // Close the path
}


function drawOnDotCanvas(ctx: CanvasRenderingContext2D, x: number, y: number, radius: number, color: string, drawMode: string) {
    ctx.beginPath(); // Start a new path
    ctx.arc(x, y, radius, 0, Math.PI * 2, true); // Create a circle

    if (drawMode === DrawMode.Draw) {
        ctx.fillStyle = color; // Set the fill color for drawing
        ctx.fill(); // Fill the circle
    } else if (drawMode === DrawMode.Erase) {
        ctx.globalCompositeOperation = 'destination-out'; // Set the composite mode for erasing
        ctx.fill(); // Erase the area
        ctx.globalCompositeOperation = 'source-over'; // Reset the composite mode
    }

    ctx.closePath(); // Close the path
}