// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, FullMetadata, list, StorageReference } from "firebase/storage";
import { getDatabase, get } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { listAll, getMetadata } from 'firebase/storage';

import { getAuth, signInAnonymously, onIdTokenChanged, signOut, browserLocalPersistence } from "firebase/auth";
import * as fdb from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAYQrAnTU9AwcjBUMBI9vIaSG5qvbeNMJc",
    authDomain: "apainter-945f5.firebaseapp.com",
    databaseURL: "https://apainter-945f5-default-rtdb.firebaseio.com",
    projectId: "apainter-945f5",
    storageBucket: "apainter-945f5.appspot.com",
    messagingSenderId: "689009854060",
    appId: "1:689009854060:web:3ee797fdd5661c58b47a49",
    measurementId: "G-8NFXZXKY81"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
auth.setPersistence(browserLocalPersistence)
const database = fdb.getDatabase(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export function getAuthInstance() {
    return auth;
}

export interface PainterUserCreds {
    uid: any, isAnonymous: boolean,
    token: string,
    claims: any;
    email: string;
}
export const signOutUser = async () => {
    try {
        await signOut(auth);
        console.log("User signed out successfully");
        return true;
        // Additional logic after successful sign out (e.g., updating UI, redirecting, etc.)
    } catch (error) {
        console.error("Error signing out: ", error);
        return false;
        // Handle any errors during sign out
    }
};
let userAuthUpdateFunc = null;
export function setUseAuthUpdateFunc(callback: any) {
    userAuthUpdateFunc = callback;
}
auth.onAuthStateChanged(async (user) => {
    if (user) {
        let { token, claims } = await getTokenAndClaims(user);
        // User is signed in, return existing user info
        if (userAuthUpdateFunc) {
            userAuthUpdateFunc({
                uid: user.uid,
                isAnonymous: user.isAnonymous,
                token,
                claims,
                email: user.email
                // Add other user properties as needed
            });
        }
    }
});
auth.onIdTokenChanged(async (user) => {
    if (user) {
        let { token, claims } = await getTokenAndClaims(user);
        // User is signed in, return existing user info
        if (userAuthUpdateFunc) {
            userAuthUpdateFunc({
                uid: user.uid,
                isAnonymous: user.isAnonymous,
                token,
                claims,
                email: user.email
                // Add other user properties as needed
            });
        }
    } else {
        // The user is signed out or the token expired and was not refreshed.
        // Handle the sign-out scenario or token expiration.
    }
});
export async function getUserInfo(callback?: any): Promise<PainterUserCreds> {
    return new Promise((resolve, reject) => {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                let { token, claims } = await getTokenAndClaims(user);
                // User is signed in, return existing user info
                if (userAuthUpdateFunc) {
                    userAuthUpdateFunc({
                        uid: user.uid,
                        isAnonymous: user.isAnonymous,
                        token,
                        claims,
                        email: user.email
                        // Add other user properties as needed
                    });

                }
                if (callback) {
                    callback({
                        uid: user.uid,
                        isAnonymous: user.isAnonymous,
                        token,
                        claims,
                        email: user.email
                        // Add other user properties as needed
                    });
                }
                resolve({
                    uid: user.uid,
                    isAnonymous: user.isAnonymous,
                    token,
                    claims,
                    email: user.email
                    // Add other user properties as needed
                });
            }
            else {
                resolve(null);
            }
        });
    });
}

async function getTokenAndClaims(user) {
    let token = await user.getIdToken(false);
    let claims = await user
        .getIdTokenResult()
        .then(async (idTokenResult: any) => {
            // Confirm the user is an Admin.
            if (!idTokenResult.claims?.user && auth?.currentUser) {
                token = await auth.currentUser.getIdToken(true);
                return await user
                    .getIdTokenResult()
                    .then(async (idTokenResult: any) => {
                        return idTokenResult.claims;
                    });
            }
            return idTokenResult.claims;
        })
        .catch((error: any) => {
            console.log(error);
        });
    return { token, claims };
}

export async function fetchAndUploadImage(imageUrl, firebasePath) {
    try {
        // Fetch the image
        const response = await fetch(imageUrl);
        if (!response.ok) throw new Error('Network response was not ok.');

        // Convert the image to a Blob
        const imageBlob = await response.blob();

        // Create a reference to the Firebase Storage path
        const storageRef = ref(storage, firebasePath);
        const imageRef = storageRef;

        // Upload the Blob to Firebase Storage
        const snapshot = await uploadBytes(imageRef, imageBlob);
        console.log('Uploaded a blob or file!', snapshot);

        // If needed, you can also retrieve and return the uploaded image URL
        const uploadedImageUrl = await getDownloadURL(imageRef);
        console.log('File available at', uploadedImageUrl);
        return uploadedImageUrl;
    } catch (error) {
        console.error("Error fetching and uploading image:", error);
    }
}

// Function to get metadata for all files in a specified folder
export async function getAllFilesMetadata(folderPath: string): Promise<FullMetadata[]> {
    const folderRef = ref(storage, folderPath);

    try {
        const listResponse = await listAll(folderRef);
        const metadataPromises = listResponse.items.map(itemRef => getMetadata(itemRef));
        const metadataList = await Promise.all(metadataPromises);

        // metadataList now contains metadata objects for all files in the folder
        console.log(metadataList);
        return metadataList;
    } catch (error) {
        console.error("Failed to get file metadata:", error);
        throw error; // Rethrow or handle error as needed
    }
}

// A simple in-memory cache
interface FileDetail {
    itemRef: any;
    metadata?: FullMetadata;
    url?: string;
    fullPath?: string;
}

let cache: Record<string, FileDetail[]> = {};
let cacheTokens: Record<string, string> = {};

export async function getFilesDetailsWithPagingAndCache(folderPath: string, skip: number, take: number, forceRefresh: boolean = false): Promise<FileDetail[]> {
    // Check if the data is already in the cache and if forceRefresh is not requested
    if (cache[folderPath] && !forceRefresh) {
        console.log('Returning cached data');
        // Sort before slicing to ensure we're returning the most recent files first
        const sortedCache = cache[folderPath].sort((a, b) => new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime());
        let slicedCache = sortedCache.slice(skip, skip + take);
        if (slicedCache.length) {
            return slicedCache;
        }
    }

    const folderRef = ref(storage, folderPath);

    try {
        cacheTokens[folderPath] = forceRefresh ? cacheTokens[folderPath] : null;
        const listResponse = await listAll(folderRef);
        cacheTokens[folderPath] = listResponse.nextPageToken;
        const filesDetailsPromises = listResponse.items.map(async itemRef => {
            if (cache[folderPath]) {
                let cachedItem = cache[folderPath].find(x => x.fullPath === itemRef.fullPath);
                if (cachedItem) {
                    return cachedItem;
                }
            };
            let fullPath = itemRef.fullPath.split('/');
            if (fullPath.length === 3) {
                let path = fullPath.slice(0, 2);
                let meta = await getMetadata(itemRef);
                let date = formatDate((meta.timeCreated));
                await moveFile(itemRef, [...path, date].join('/'))
            }

            return {
                itemRef,
                fullPath: itemRef.fullPath,
                metadata: await getMetadata(itemRef),
                url: await getDownloadURL(itemRef)
            };
        });

        let filesDetails = await Promise.all(filesDetailsPromises);

        // Sort the filesDetails array by the 'timeCreated' metadata property

        filesDetails = filesDetails.sort((a, b) => new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime());

        // Update the cache with the new sorted results
        cache[folderPath] = cache[folderPath] || [];
        cache[folderPath] = [
            ...cache[folderPath],
            ...filesDetails.filter(x => !cache[folderPath].find(v => v.fullPath === x.fullPath))
        ].sort((a, b) => new Date(b.metadata.timeCreated).getTime() - new Date(a.metadata.timeCreated).getTime());

        // Return the paginated slice from the sorted and cached results
        return filesDetails.slice(skip, skip + take);
    } catch (error) {
        console.error("Failed to get file details:", error);
        throw error; // Rethrow or handle error as needed
    }
}

/**
 * Lists "folders" within a specified path in Firebase Storage by identifying unique prefixes.
 * 
 * @param {string} path The path within Firebase Storage to list folders from.
 * @returns {Promise<string[]>} A promise that resolves with an array of unique "folder" names.
 */
export async function listFoldersAtPath(path) {
    const storage = getStorage();
    const listRef = ref(storage, path);

    try {
        // Perform a listAll operation to retrieve all items and prefixes under the path
        const { prefixes } = await listAll(listRef);

        // Map through the prefixes to extract folder names
        const folders = prefixes.map(prefix => {
            // Extract and return the name of the "folder" from each prefix
            const parts = prefix.fullPath.split('/');
            return parts[parts.length - 1]; // Return the last part as the folder name
        });

        console.log("Folders: ", folders);
        return folders;
    } catch (error) {
        console.error("Error listing folders: ", error);
        throw error;
    }
}

// Function to move files from one folder to another within Firebase Storage
export async function moveFile(itemRef: StorageReference, destFolderPath: string) {
    try {
        // Get the download URL
        const downloadURL = await getDownloadURL(itemRef);

        // Fetch the file data from the download URL
        const response = await fetch(downloadURL);
        const blob = await response.blob();

        // Create a reference for the new location
        const destRef = ref(storage, `${destFolderPath}/${itemRef.name}`);

        // Upload the file to the new location
        await uploadBytes(destRef, blob);
        console.log(`Moved ${itemRef.name} to ${destFolderPath}`);

        // Delete the original file
        await deleteObject(itemRef);
    } catch (error) {
        console.error("Error moving files: ", error);
    }
}

export function formatDate(timeCreated: number | string): string {
    const date = new Date(timeCreated);
    const year = date.getFullYear();
    // Pad the month and day with leading zeros if necessary
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
/**
 * Retrieves data from a specified path in Firebase Realtime Database.
 * 
 * @param {string} path - The path in the database from which to retrieve the data.
 * @returns {Promise<Object|null>} The data from the database or null if an error occurs or no data found.
 */
export async function readFromDatabase(path) {
    const dbRef = fdb.ref(database, path);

    try {
        const snapshot = await fdb.get(dbRef);
        if (snapshot.exists()) {
            console.log("Data retrieved successfully from", path);
            return snapshot.val();
        } else {
            console.log("No data available at", path);
            return null;
        }
    } catch (error) {
        console.error("Error reading data from", path, ":", error);
        return null;
    }
}
// Function to delete a file from Firebase Storage
export async function deleteFileFromFirebaseStorage(filePath: string): Promise<void> {
    // Debugging: Log the filePath to ensure it's correctly passed
    console.log(`Attempting to delete file at path: "${filePath}"`);

    // Ensure filePath is not undefined or root
    if (!filePath || filePath.trim() === '') {
        console.error('Invalid file path. Cannot delete root or undefined path.');
        return;
    }

    const fileRef = ref(storage, filePath);

    try {
        await deleteObject(fileRef);
        console.log(`File "${filePath}" deleted successfully.`);
    } catch (error) {
        console.error(`Failed to delete file "${filePath}":`, error);
    }
}

// Function to delete data at a specified path in Firebase RTDB
export async function deleteFromFirebaseRTDB(path: string): Promise<void> {
    const ref = fdb.ref(database, path);

    try {
        await fdb.remove(ref);
        console.log(`Data at path "${path}" has been successfully deleted.`);
    } catch (error) {
        console.error(`Failed to delete data at path "${path}":`, error);
    }
}

/**
 * Writes an object to a specified path in Firebase Realtime Database.
 * 
 * @param {string} path - The path in the database where the object should be written.
 * @param {Object} data - The object to write to the database.
 */
export async function writeToDatabase(path, data): Promise<boolean> {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
        console.error("No authenticated user. Cannot write data to:", path);
        return false;
    }

    const dbRef = fdb.ref(database, path);
    return fdb.set(dbRef, data)
        .then(() => {
            console.log("Data written successfully to", path);
            return true;
        })
        .catch((error) => {
            console.error("Error writing data:", error)
            return false;
        });
}

/**
 * Watches for updates on children of a specified path in Firebase Realtime Database.
 * 
 * @param {string} path - The path in the database to watch.
 * @param {function} onUpdate - Callback function to handle data updates.
 */
export function watchForChildUpdates(path, onUpdate) {
    const dbRef = fdb.ref(database, path);

    fdb.onChildAdded(dbRef, (snapshot) => {
        onUpdate('child_added', snapshot.key, snapshot.val());
    });

    fdb.onChildChanged(dbRef, (snapshot) => {
        onUpdate('child_changed', snapshot.key, snapshot.val());
    });

    fdb.onChildRemoved(dbRef, (snapshot) => {
        onUpdate('child_removed', snapshot.key, snapshot.val());
    });
}


// Helper function to extract the file path from the long URL
function extractFilePath(longUrl) {
    const decodedUrl = decodeURIComponent(longUrl);
    const matches = decodedUrl.match(/\/b\/[^/]+\/o\/([^?]+)/);
    return matches ? matches[1] : null;
}

// Function to get the download URL
export async function fetchDownloadUrl(longUrl) {
    // Extract the file path from the long URL
    const filePath = extractFilePath(longUrl);
    if (!filePath) {
        return longUrl;
    }

    // Initialize Firebase Storage
    const storage = getStorage();

    // Create a reference to the file using the extracted path
    const fileRef = ref(storage, filePath);

    // Get the download URL
    return getDownloadURL(fileRef)
        .then((url) => {
            return url;
        })
        .catch((error) => {
            // Handle any errors
            console.error("Error getting download URL: ", error);
            throw error;
        });
}


/**
 * Uploads an image to Firebase Storage and returns the download URL.
 * @param {File} imageFile - The image file to upload.
 * @param {string} path - The path in the storage where the file should be saved.
 * @returns {Promise<string>} - A promise that resolves with the download URL of the image.
 */
export async function uploadImageToFirebase(imageFile, path) {
    try {
        // Create a storage reference
        const storageRef = ref(storage, path);

        // Upload the file
        const snapshot = await uploadBytes(storageRef, imageFile);

        // Get the download URL
        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (error) {
        console.error("Error uploading the image: ", error);
        throw error;
    }
}

export async function uploadCanvas(canvas: any, location: string, fileName: string): Promise<string | null> {

    // Convert canvas content to data URL
    console.log('// Convert canvas content to data URL')
    console.log(`canvas: ${!!canvas}`)
    const dataUrl = canvas.toDataURL('image/png');

    // Convert data URL to Blob
    console.log('// Convert data URL to Blob')
    const response = await fetch(dataUrl);
    console.log('fetched')
    const blob = await response.blob();
    console.log('to blobbed')
    // Create a File object from the Blob
    const file = new File([blob], fileName, { type: 'image/png' });

    // Call the upload function
    console.log(`// Call the upload function`)
    return await uploadImageToFirebase(file, location)
        .then(url => {
            console.log('Uploaded. File available at:', url)
            return url;
        })
        .catch(error => {
            console.error('Error uploading file:', error)
            return null;
        });
}