
export const ClothingImages = {
    hoodie: {
        "front": {
            "left": 97.81946100000002, "scaleX": 0.16743435351562502, "scaleY": 0.16743435351562502, "top": 92.72776100000003, "scaleLeft": 0.09552681738281252, "scaleTop": 0.09055445410156253
        },
        //arml
        "arml": {
            "left": 94.77461399999999, "scaleX": 0.16730387890624998, "scaleY": 0.16730387890624998, "top": 293.406214, "scaleLeft": 0.09255333398437499, "scaleTop": 0.285311216796875
        },
        //insideHoodie
        "insideHoodie": {
            "left": 98.58850000000001, "scaleX": 0.1532685, "scaleY": 0.1532685, "top": 483.2201, "scaleLeft": 0.09627783203125001, "scaleTop": 0.47189462890625
        },
        //hoodieRight
        "hoodieRight": {
            "left": 292.62308600000006, "scaleX": 0.18445094921875008, "scaleY": 0.18445094921875008, "top": 296.254686, "scaleLeft": 0.28576473242187506, "scaleTop": 0.289311216796875
        },
        //armr
        "armr": {
            "left": 517.7331360000001, "scaleX": 0.17056413281250005, "scaleY": 0.17056413281250005, "top": 91.07546400000001, "scaleLeft": 0.5055987656250001, "scaleTop": 0.08894088281250001
        },
        //hoodieLeft
        "hoodieLeft": {
            "left": 492.43420000000003, "scaleX": 0.18310546875, "scaleY": 0.18310546875, "top": 296.06579999999997, "scaleLeft": 0.48089277343750003, "scaleTop": 0.28912675781249997
        },
        "back": {
            "left": 299.14961400000004, "scaleX": 0.16803630078124998, "scaleY": 0.16803630078124998, "top": 93.78121399999999, "scaleLeft": 0.29213829492187504, "scaleTop": 0.09158321679687499
        }
    }
}