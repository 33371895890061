import React, { useRef, useEffect, useContext } from 'react';
import useAScene from './painter/useAScene';
import { APainterContext, APainterContextValue } from './APainterContext';

export default function AEnterVRComponent() {
    const scene = useAScene();
    const context: APainterContextValue = useContext(APainterContext);
    const enterVRButtonRef = useRef(null);

    useEffect(() => {
        const button = enterVRButtonRef.current;
        if (button && scene) {
            const handleEnterVR = () => {
                try {
                    scene.enterVR();
                } catch (error) {
                    console.error('Error entering VR:', error);
                }
            };

            button.addEventListener('click', handleEnterVR);

            // Cleanup function to remove the event listener
            return () => {
                button.removeEventListener('click', handleEnterVR);
            };
        }
    }, [scene]); // Dependency array includes `scene` to re-attach the listener if `scene` changes
    let style: any = context.deviceType === 'VR' ? { height: 300, width: 300 } : {};
    return (
        <div className="a-enter-vr">
            <button
                style={style}
                ref={enterVRButtonRef}
                className="a-enter-vr-button"
                title="Enter VR mode">
            </button>
        </div>
    );
}
