import { useEffect, useState } from "react";
import { getListLoras, getListModels } from "./StableDiffModels";

export function useListModels(stable: boolean, types: {
    ['SD']: boolean,
    ['SD XL']: boolean,
}) {
    const [modelList, setModelList] = useState([]);
    useEffect(() => {
        setModelList(getListModels(stable, types))
    }, [stable, types])

    return modelList;
}


export function useListLora(stable: boolean) {
    const [loraList, setLoraList] = useState([]);

    useEffect(() => {
        setLoraList(getListLoras(stable));
    }, [stable])

    return loraList;
}