
export const ImageSpaces = {
    MyImages: 'MyImages',
    SearchImages: 'SearchImages',
    PixabayImages: 'PixabayImages',
    GoogleImages: 'GoogleImages',
    AllMyImages: 'AllMyImages'
}

export const PurchasableItemType = {
    Level0: 'Level0',
    Level1Subscription: "Level1Subscription",
    Level2Subscription: "Level2Subscription",
    Level3Subscription: "Level3Subscription",
    Level1Credits: "Level1Credits",
    Level2Credits: "Level2Credits",
    Level3Credits: "Level3Credits",
}