import { memo } from 'react';
import { ModelLoraSetup } from '../../APainterContext';
import { STABLE_DIFFUSION_MODELS } from '../../StableDiffModels';
import { createMenu, createMenuItem, onClick } from '../../util';
import { getBoundingBoxOfNonTransparentPixels } from '../canvasutil';
import { PAINTER_CONSTANTS } from '../constants';
import { AFRAME } from '../systems/brush';
import { INTERACTABLES } from '../systems/ui';
import { raiseCustomEvent, uuidv4 } from '../util';
import { framemixin } from 'a-frame-components';
const THREE: any = (window as any).THREE;

export default function () {
    AFRAME.registerComponent('styles-component', {
        schema: {
            ids: { type: 'string' }
        },
        init: function () {
            let ids = this.data.ids.split(';')
            let me = this;
            me.buttonMargin = .05;
            me.buttonSize = .65;
            let container = document.createElement('frame-container');
            container.setAttribute('direction', 'horizontal');
            container.setAttribute('margin', '.1 .1 .1 .1');
            ids.map((id, index) => {
                let component = document.createElement('a-style-component');
                component.setAttribute('id', id);
                component.setAttribute('button-size', me.buttonSize)
                container.appendChild(component);
            });
            me.el.appendChild(container);
        },
    });

    AFRAME.registerPrimitive('a-styles-component', {
        defaultComponents: {
            'styles-component': {}
        },
        mappings: {
            //gui item general
            'ids': 'styles-component.ids'
        }
    });


    AFRAME.registerComponent('style-component', {
        schema: {
            loraId: {
                type: 'string'
            },
            buttonSize: { type: 'number' },
            position: { type: 'string' }
        },
        init: function () {
            this.buttonSize = this.data.buttonSize || .45;
            this.width = 3;
            let loraContainerEl = this.createLoraContainer({ id: this.data.loraId })
            this.el.setAttribute('position', this.data.position);
            this.el.appendChild(loraContainerEl)
        },
        ...framemixin,
        getWidth: function () {
            return parseFloat(`${this?.configuration?.width || this?.guiItem?.width || 0}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.configuration?.height || this?.guiItem?.height || 0}`);
        },
        createLoraContainer: function ({ id }) {
            let me = this;
            let loraContainer = document.createElement('frame-container');
            loraContainer.setAttribute('direction', 'horizontal')
            loraContainer.setAttribute('justifyContent', 'flexStart')
            loraContainer.setAttribute('margin', '.1 .1 .1 .1');
            me.configuration = {};
            loraContainer.addEventListener('size-update', (evt: any) => {
                const { detail, srcElement } = evt;
                if (loraContainer === srcElement) {
                    let { width, height } = detail;
                    width = parseFloat(width);
                    height = parseFloat(height);
                    me.configuration.width = width;
                    me.configuration.height = height;
                    me.updateElementSize(me, me.el);
                }
            })
            let buttonContainer = document.createElement('frame-container');
            buttonContainer.setAttribute('direction', 'vertical');

            let buttonSubContainer = document.createElement('frame-container');
            buttonSubContainer.setAttribute('direction', 'horizontal');
            buttonSubContainer.setAttribute('margin', '0 .1 0 0');

            let imageEntity: any = document.createElement('frame-ar-image');
            let imageSize = (me.buttonSize - .02);
            imageEntity.setAttribute('maxwidth', imageSize || `.2`);
            imageEntity.setAttribute('maxheight', imageSize || `.2`);
            document.body.addEventListener(PAINTER_CONSTANTS.LORA_SELECTED, function (evt: any) {
                const { detail } = evt;
                const { url, lora, setup, strength } = detail;
                if (lora != id) { return; }
                // imageEntity.setAttribute('visible', `${!!url}`)
                // imageEntity.setAttribute('position', `0 0 .033`)
                imageEntity.setAttribute('url', url || '')
                let modelLoraSetup: ModelLoraSetup = setup;

                let model = STABLE_DIFFUSION_MODELS.find(x => x.screenshots === url)
                if (me.trainedWordsMenu) {
                    me.trainedWordsMenu.parentNode.removeChild(me.trainedWordsMenu);
                    me.trainedWordsMenu = null;
                    raiseCustomEvent(PAINTER_CONSTANTS.CLEAR_LORA_WORDS, {
                        id
                    });
                }
                if (modelLoraSetup?.layerPrompts?.[lora]?.strength || strength) {
                    strengthSlider.setAttribute('percent', `${modelLoraSetup?.layerPrompts?.[lora]?.strength || strength || 0}`);
                }
                if (modelLoraSetup) {

                    if (model?.trainedWords?.length) {
                        let menu = me.createTrainedWordMenu(model?.trainedWords, id, modelLoraSetup)
                        let { element } = menu.render();
                        me.trainedWordsMenu = element.element();
                        buttonContainer.appendChild(me.trainedWordsMenu);
                    }
                }
            })
            let loraStyle1 = me.createButton({
                onclick: `testButtonAction`,
                args: `${id}`,
                value: 'Style',
                position: '0 0 .01'
            })
            loraStyle1.addEventListener('click', function () {
                me.el.emit(PAINTER_CONSTANTS.OPEN_LORA_SELECTION, {
                    lora: id,
                })
            });
            let strengthSlider = document.createElement('frame-slider');
            strengthSlider.setAttribute('height', '.1');
            strengthSlider.setAttribute('title', 'Strength');
            strengthSlider.setAttribute('title-position', '-.5 0 0');
            strengthSlider.setAttribute('title-scale', '.3 .3 .3');
            strengthSlider.setAttribute('bar-length', '1');
            strengthSlider.setAttribute('value', '0')
            strengthSlider.addEventListener('change', (evt: any) => {
                let { value } = evt.detail;
                me.el.emit(PAINTER_CONSTANTS.LORA_STRENGTH_CHANGE, {
                    id,
                    value
                })
            })

            let clearStyle = me.createButton({
                onclick: 'testButtonAction',
                args: `${id}`,
                value: 'Clear',
                position: `${me.buttonSize / 2 + me.buttonSize / 3} 0.1 .01`,
                buttonSize: me.buttonSize / 3
            })
            clearStyle.addEventListener('click', function () {
                me.el.emit(PAINTER_CONSTANTS.CLEAR_LORA_STYLE, {
                    id,
                })
            })
            clearStyle.setAttribute('width', '.5')
            loraStyle1.setAttribute('width', '.5')
            loraStyle1.setAttribute('font-size', '.05')
            clearStyle.setAttribute('font-size', '.05')
            loraContainer.appendChild(buttonContainer);
            loraContainer.appendChild(imageEntity);
            buttonSubContainer.append(clearStyle);
            buttonSubContainer.append(loraStyle1)
            buttonContainer.append(buttonSubContainer)
            buttonContainer.appendChild(strengthSlider);
            return loraContainer;
        },
        createTrainedWordMenu: function (words, id, modelLoraSetup: ModelLoraSetup) {
            let me = this;
            me.triggerWords = {}
            me.triggerWordElements = {}
            let menu = createMenu({
                text: 'Trigger words',
                width: .75,
                forwardStep: .2,
                children: [
                    ...words.filter(x => x).map(word => {
                        return (createMenuItem({
                            width: .75,
                            type: 'radio',
                            text: word,
                            onRender: (el) => {
                                me.triggerWordElements[word] = el;
                                if (modelLoraSetup) {
                                    let checked = modelLoraSetup?.loraOptions?.[id]?.words?.[word] || false;
                                    me.triggerWords[word] = checked;
                                    el.setAttribute('checked', checked)
                                }
                                onClick(el, () => {
                                    me.triggerWords[word] = !me.triggerWords[word]
                                    el.setAttribute('checked', me.triggerWords[word])
                                    raiseCustomEvent(PAINTER_CONSTANTS.TOGGLE_LORA_WORD_VALUE, {
                                        id,
                                        word
                                    });
                                })
                            }
                        }))
                    })]
            })
            return menu;
        },
        createButton: function ({ type, onclick, args, value, position, buttonSize }) {
            let me = this;
            let button = document.createElement(type || 'frame-base-interactive');

            // button.setAttribute('width', buttonSize || me.buttonSize || '0.15')
            // button.setAttribute('height', buttonSize || me.buttonSize || '0.15')
            button.setAttribute('onclick', onclick)
            button.setAttribute('args', args)
            button.setAttribute('value', value || ``)
            button.setAttribute('font-color', '#ffffff')
            button.setAttribute('margin', '0 0.02 0.05 0')
            button.setAttribute('position', position || '.4 0 .1')
            return button;
        },
    });
    AFRAME.registerPrimitive('a-style-component', {
        defaultComponents: {
            'style-component': {}
        },
        mappings: {
            //gui item general
            'id': 'style-component.loraId',
            'button-size': 'style-component.buttonSize',
            'position': 'style-component.position'
        }
    });
}