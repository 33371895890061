import React, { useEffect, useRef } from 'react';
import { findGrayByPercentage, rgb2hsv } from './painter/util';
import { getUISystem } from './painter/systems/ui';
const THREE: any = (window as any).THREE;

export default function ColorBrightnessComponent() {
    const sliderRef = useRef(null);
    useEffect(() => {
        let handler: any = null;
        if (sliderRef?.current) {
            handler = (evt) => {
                let system = getUISystem();
                let { value } = evt.detail;
                if (system) {
                    system.setCurrentBrightnessState(Math.max(value, 0.000001))
                }
            };
            sliderRef?.current.addEventListener('change', handler)
        }
        return () => {
            if (sliderRef?.current && handler) {
                sliderRef?.current.removeEventListener('change', handler);
            }
        }
    }, [sliderRef?.current])
    return (
        <>

            <a-entity position=".5 .15 .15" scale=".5 .5 .5">
                <frame-troika-text anchor={'right'} value="Color Brightness " />
            </a-entity>

            <a-gui-slider
                ref={sliderRef}
                percent={1}
                height={.1}
                width={1.5}
            />
        </>
    )

}