export const PAINTER_CONSTANTS = {
    CIVITAI_RENDER_TARGETS: 'CIVITAI_RENDER_TARGETS',
    CIVITAI_DRAW_IMAGES: 'CIVITAI_DRAW_IMAGES',
    MODEL_LORA_UPDATE: 'MODEL_LORA_UPDATE',
    MASK_ALL_LAYERS: 'MASK_ALL_LAYERS',
    CLEAR_MASK: 'CLEAR_MASK',
    DELETE_MASKED_COLORS: 'DELETE_MASKED_COLORS',
    SHOW_ADD_FONT_INPUT: 'SHOW_ADD_FONT_INPUT',
    SELECT_MASKING_TARGET_COLOR: 'SELECT_MASKING_TARGET_COLOR',
    CHANGE_MASK_MODE: 'CHANGE_MASK_MODE',
    MASK_SENSITIVITY: 'MASK_SENSITIVITY',
    HOODIE_IMAGE_CHANGE: 'HOODIE_IMAGE_CHANGE',
    PAINTER_CANVAS_BUILT: 'painter-canvas-built',
    CANVAS_IMAGE_CHANGED: 'CANVAS_IMAGE_CHANGED',
    PAINTER_CANVAS_READY: 'painter-canvas-ready',
    ADD_FONT: 'ADD_FONT',
    PAINTER_CANVAS_BUILD: 'painter-build-canvas',
    LAYER_ADDED: 'layer-added',
    LAYER_REMOVED: 'LAYER_REMOVED',
    UPDATED_FROM_OTHER_MATCHINES: 'UPDATED_FROM_OTHER_MATCHINES',
    SELECT_LAYER: 'SELECT_LAYER',
    FOCUS_INPUT: 'FOCUS_INPUT',
    CLEAR_LAYER: 'CLEAR_LAYER',
    UPDATED_FONTS: 'UPDATED_FONTS',
    CREDITS_REMAINING_UPDATE: 'credits-remaining',
    LAYER_RESULT_UPDATED: 'layer-result-updated',
    CANVAS_IMAGE_UPDATED: 'CANVAS_IMAGE_UPDATED',
    TEXT_LAYER_UPDATES: 'TEXT_LAYER_UPDATES',
    LAYER_DISPLAY_READY: 'LAYER_DISPLAY_READY',
    LAYER_UPDATED: 'layer-updated',
    LAYER_UP: 'LAYER_UP',
    LAYER_DOWN: 'LAYER_DOWN',
    TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
    APPLICATION_MODE: 'APPLICATION_MODE',
    PROMPTS_UPDATED: 'PROMPTS_UPDATED',
    LAYER_DELETE: 'LAYER_DELETE',
    IMAGE_ADDED: 'IMAGE_ADDED',
    LORA_STRENGTH_CHANGE: 'LORA_STRENGTH_CHANGE',
    DRAWING_MODE_CHANGED: 'drawing-mode-changed',
    PAINTER_CANVAS_UPDATED: 'painter-canvas-updated',
    COMPOSITE_RESULT_CANVAS: 'COMPOSITE_RESULT_CANVAS',
    DRAWING: "DRAWING",
    LAYER_READY: 'LAYER_READY',
    TRANSFORMER_SCALE: 'tranformer-scale',
    TRANSFORMER_CHANGE: 'tranformer-change',
    TRANSFORMER_TRANSLATION: 'transformer-translation',
    UPDATE_RATIO: 'UPDATE_RATIO',
    UPDATE_SIZE: 'UPDATE_SIZE',
    TRANSFORMER_ROTATION: 'transformer-rotation',
    VR_CANVAS_PREFIX: 'painter-vr-canvas-',
    SCHEDULER_CHANGE: 'SCHEDULER_CHANGE',
    CLEAR_LORA_STYLE: 'clear-lora-style',
    OPEN_LORA_MENU: 'open-lora-menu',
    STRENGTH_CHANGE: 'STRENGTH_CHANGE',
    COLOR_SELECT: 'color-select',
    OPEN_LORA_SELECTION: 'open-lora-selection',
    CLOSE_LORA_MENU: 'close-lora-menu',
    CLOSE_MY_IMAGE_SELECTION: 'close-my-image-selection',
    COLOR_CHANGE: 'color-change',
    CLOSE_LORA_SELECTION: 'close-lora-selection',
    LORA_SELECTED: 'lora-selected',
    REMOVE_PROMPT: 'remove-prompt',
    TOGGLE_LORA_WORD_VALUE: 'SET_LORA_WORD_VALUE',
    CLEAR_LORA_WORDS: 'clear-lora-words',
    OPEN_MODEL_MENU: 'open-model-menu',
    MODEL_COMPONENT_LOADED: 'MODEL_COMPONENT_LOADED',
    CLOSE_MODEL_SELECTION: 'close-model-selection',
    MODEL_SELECTED: 'model-selected',
    MODEL_SELECTION: 'model-selection',
    MY_IMAGE_SELECTION: 'my-image-selection',

    MOUSE: {
        RESIZE: 'resize',
        MOUSEDOWN: 'mousedown',
        COLOR_CHANGE: 'color_change',
        MOUSEENTER: 'mouseenter',
        MOUSEMOVE: 'mousemove',
        MOUSEUP: 'mouseup',
        MOUSEOVER: 'mouseover',
        MOUSEOUT: 'mouseout',
        CLICK: 'click',
        DRAW: 'draw',
        START_DRAW: 'start_drawing',
        STOP_DRAW: 'stop_drawing',
        WHEEL: 'wheel',
        CONTEXTMENU: 'contextmenu',
        DBLCLICK: 'dblclick',
        DRAGSTART: 'dragstart',
        DRAGEND: 'dragend',
        DRAGOVER: 'dragover',
        DRAGENTER: 'dragenter',
        DRAGLEAVE: 'dragleave',
        DROP: 'drop',
        // Adding touch events
        TOUCHSTART: 'touchstart',
        TOUCHMOVE: 'touchmove',
        TOUCHEND: 'touchend',
        TOUCHCANCEL: 'touchcancel'
    }
}