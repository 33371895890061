import { getBoundingBoxOfNonTransparentPixels } from '../canvasutil';
import { PAINTER_CONSTANTS } from '../constants';
import { AFRAME } from '../systems/brush';
import { DrawMode, INTERACTABLES, MaskMode } from '../systems/ui';
import { raiseCustomEvent, uuidv4 } from '../util';
import styleComponent from './style-component';
import modelComponent from './model-component';
import promptListComponent from './prompt-list-component';
import { GrabAndDropEvents, customEventListener } from '../systems/grabanddrop';
import CanvasTransformations from './canvastransformations';
import { Transformation } from '../../gui/components/transformer';
import { OverlayText } from '../../APainterContext';
import { drawTextOnCanvas, rgbToHex } from '../../util';
import { MaskingData } from '../../interface';
import { applyTransparencyBasedOnSourceAlpha, magicSelectAndMask } from '../../canvasutil';
const THREE: any = (window as any).THREE;

/**
 * Draws an image (texture) onto a canvas.
 * 
 * @param canvasId The ID of the canvas element.
 * @param imageUrl The URL of the image to be drawn.
 */
export async function drawTextureOnCanvas(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D, imageUrl: string): Promise<void> {
    if (!canvas) {
        console.log('Canvas element not found')
        throw new Error('Canvas element not found');
    }

    if (!context) {
        console.log('Unable to get canvas context')
        throw new Error('Unable to get canvas context');
    }

    // Load the image asynchronously
    const image = new Image();
    image.crossOrigin = 'anonymous'
    image.src = imageUrl;
    await new Promise((resolve, reject) => {
        image.onload = () => {
            setTimeout(() => {
                resolve(true);
            }, 100)
        };
        image.onerror = reject;
    });
    // Draw the image onto the canvas
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
}

function drawCanvasInsideAnother(sourceCanvas: HTMLCanvasElement, targetCanvas: HTMLCanvasElement): void {
    const targetCtx = targetCanvas.getContext('2d');

    if (!targetCtx) {
        console.error('Failed to get the 2D context of the target canvas');
        return;
    }

    // Clear the target canvas
    targetCtx.clearRect(0, 0, targetCanvas.width, targetCanvas.height);

    // Calculate the scaling factor to fit the source canvas into the target canvas
    const scale = Math.min(targetCanvas.width / sourceCanvas.width, targetCanvas.height / sourceCanvas.height);

    // Calculate the size to draw the source canvas on the target canvas
    const drawWidth = sourceCanvas.width * scale;
    const drawHeight = sourceCanvas.height * scale;

    // Calculate the position to center the source canvas on the target canvas
    const offsetX = (targetCanvas.width - drawWidth) / 2;
    const offsetY = (targetCanvas.height - drawHeight) / 2;

    // Use drawImage to copy the source canvas onto the target canvas centered and scaled properly
    targetCtx.drawImage(sourceCanvas, offsetX, offsetY, drawWidth, drawHeight);
}


export default function () {
    styleComponent();
    modelComponent();
    promptListComponent();
    AFRAME.registerComponent('color-change-animation', {
        schema: { type: 'string' },
        update: function (oldData) {
            if (oldData !== this.data) {
                console.log(`this.data: ${this.data}`)
                console.log(`this.data: ${JSON.stringify(this.data)}`)
                this.el.setAttribute('animation', {
                    property: 'material.color',
                    from: oldData,
                    to: this.data,
                    dur: 1000,
                    easing: 'easeInOutQuad'
                });
            }
        }
    });

    AFRAME.registerComponent('color-array', {
        schema: { type: 'string' },
        init: function () {
            this.planes = []; // Array to store references to planes
            this.buttons = [];
        },

        update: function () {
            let me = this;
            const colors = this.data.split(',').map(s => s.trim());
            // Update existing planes or add new ones
            colors.forEach((color, idx) => {
                if (!this.planes[idx]) {
                    // Create new plane
                    let button: any = document.createElement('frame-gui-button');
                    // button.setAttribute('onclick', 'colorArrayClick')
                    button.addEventListener('click', () => {
                        me.el.emit(PAINTER_CONSTANTS.COLOR_SELECT, { color })
                    })
                    button.setAttribute('args', color)
                    let plane: any = document.createElement('a-entity');
                    plane.setAttribute('geometry', 'primitive: plane; height: 1; width: 1');
                    plane.setAttribute('material', 'color', color);
                    button.setAttribute('position', { x: -idx * 0.35, y: 0, z: 0 });
                    plane.setAttribute('scale', { x: .8, y: .8, z: .8 });
                    plane.setAttribute('position', { x: 0, y: 0, z: .03 });
                    button.appendChild(plane);
                    this.el.appendChild(button);
                    button.setAttribute('scale', { x: .3, y: .3, z: .3 });
                    this.buttons[idx] = button;
                    this.planes[idx] = plane;
                } else {
                    // Update existing plane color
                    console.log(`color : ${color}`)
                    this.planes[idx].setAttribute('color-change-animation', color);
                    this.buttons[idx].setAttribute('args', color)
                }
            });

            // Remove extra planes if the new array is shorter
            while (this.buttons.length > colors.length) {
                let extraPlane = this.buttons.pop();
                this.el.removeChild(extraPlane);
                this.planes.pop();
            }
        }
    });

    AFRAME.registerComponent('index-animator', {
        schema: {
            selectedIndex: { type: 'number', default: 0 },
            initial: { type: 'vec3', default: { x: 0, y: 0, z: 0 } },
            stepY: { type: 'number', default: 0 }
        },

        init: function () {
            // Initial setup if needed
        },

        update: function (oldData) {
            // This function is called whenever properties are updated
            var newIndex = this.data.selectedIndex;

            if (oldData.selectedIndex !== newIndex) {
                // Calculate new position based on newIndex
                var newPosition = {
                    x: this.data.initial.x,
                    z: this.data.initial.z,
                    y: this.data.initial.y + this.data.stepY * newIndex
                }; // Calculate new position

                // Update the position of the a-box
                this.el.setAttribute('animation', {
                    property: 'position',
                    to: newPosition.x + ' ' + newPosition.y + ' ' + newPosition.z,
                    dur: 1000,// Duration of the animation
                    easing: 'easeInOutCubic' // Easing function
                });
            }
        }
    });

    AFRAME.registerComponent('paint-canvas', {
        schema: {
            layerhand: { type: 'string', default: '' },
            height: { type: 'number', default: 512 },
            width: { type: 'number', default: 512 },
            initialLayers: { type: 'number', default: 1 },
            canvasColor: { type: 'string', default: '#ffffff' },
        },
        init: function () {
            this.maxBrushSize = 50
            this.layerUpdates = {};
            this.raycasters = {};

            this.raycastStates = {};
            this.layerLibrary = {};
            this.currentRayposition = {}
            this.layers = [];
            this.results = [];
            this.layerViewSize = .7;
            this.layerOffset = this.layerViewSize + .01; // Vertical offset between layers
            this.layerVerticalOffset = 1;
            this.buttonPadding = .2;
            this.buttonSize = .25;
            this.selectButtonSize = this.buttonSize;
            let loraCount = 3;
            this.layerProperties = {};
            this.loraCount = loraCount;
            this.clearButtonSize = this.buttonSize;
            this.layerViewWidth = this.buttonSize * this.loraCount + this.clearButtonSize + this.selectButtonSize;
            this.buttons = [{
                type: 'up',
                size: this.buttonSize,
                padding: this.buttonPadding
            }, {
                type: 'down',
                size: this.buttonSize,
                padding: this.buttonPadding
            }, {
                type: 'layerpreview',
                lora: 1,
                size: .5,
                padding: this.buttonPadding
            }, {
                type: 'select',
                size: this.buttonSize,
                padding: this.buttonPadding
            }, {
                type: 'clear',
                size: this.buttonSize,
                padding: this.buttonPadding
            }]
            this.buttonCount = this.loraCount + 1 + 1;
            var system = this.el.sceneEl.systems.ui; // Access by system name
            this.system = system;
            this.layerIndex = 0;
            this.onUpdateColor = this.onUpdateColor.bind(this);
            this.buildCompositeTexture = this.buildCompositeTexture.bind(this);
            this.handleRayInteractions = this.handleRayInteractions.bind(this);
            this.onUpdateSelectedLayer = this.onUpdateSelectedLayer.bind(this);
            this.updateCompositeTexture = this.updateCompositeTexture.bind(this);
            this.onUpdateSize = this.onUpdateSize.bind(this);
            let me = this;
            this.buildLayerDisplays();
            // this.buildResultDisplays();
            this.buildCompositeResultDisplay();
            this.addTransformHandlers();
            this.buildEasel();
            document.body.addEventListener(PAINTER_CONSTANTS.DRAWING_MODE_CHANGED, (evt: any) => {
                const { detail } = evt;
                const { mode } = detail;
                me.maskModeEnabled = mode === DrawMode.Mask;
            })
            document.body.addEventListener(PAINTER_CONSTANTS.UPDATE_SIZE, (evt: any) => {
                if (evt.detail?.height && evt.detail?.width) {
                    me.el.setAttribute('paint-canvas', 'height', evt.detail.height);
                    me.el.setAttribute('paint-canvas', 'width', evt.detail.width);
                    me.clearLayers();
                }
            })
            document.body.addEventListener(PAINTER_CONSTANTS.SELECT_LAYER, (evt: any) => {
                let nextLayer = me.layers.indexOf(evt.detail.id);
                if (nextLayer !== -1) {
                    me.selectedLayer = evt.detail.id;
                    me.updateLayerIndex(nextLayer);
                }
            })
            document.body.addEventListener(PAINTER_CONSTANTS.CLEAR_LAYER, (evt: any) => {
                let layer = me.getLayer(evt.detail.id);
                if (layer) {
                    me.clearLayer(layer)
                    me.updateCompositeTexture();
                }
            })
            document.body.addEventListener(PAINTER_CONSTANTS.LAYER_DELETE, (evt: any) => {
                let layer = me.getLayer(evt.detail.id);
                if (layer) {
                    me.deleteLayer(evt.detail.id)
                    me.updateCompositeTexture();
                }
            });
            document.body.addEventListener(PAINTER_CONSTANTS.TEXT_LAYER_UPDATES, async (evt: any) => {
                let { texts = null }: { texts: OverlayText[] } = evt.detail;
                if (texts) {
                    me.overlayTexts = me.overlayTexts || [];
                    me.overlayTextCanvas = me.overlayTextCanvas || {};
                    let drawn = false;
                    texts.filter((text: OverlayText) => {
                        let existing: OverlayText = me.overlayTexts.find(x => x.id === text.id);
                        if (!existing)
                            return true;

                        return !(
                            existing.value === text.value &&
                            existing.composite === text.composite &&
                            existing.hidden === text.hidden &&

                            existing.position.y === text.position.y &&
                            existing.position.x === text.position.x &&
                            existing.position.rotation === text.position.rotation &&
                            existing.font.color.r === text.font.color.r &&
                            existing.font.color.g === text.font.color.g &&
                            existing.font.color.b === text.font.color.b &&
                            existing.font.outlineColor.r === text.font.outlineColor.r &&
                            existing.font.outlineColor.g === text.font.outlineColor.g &&
                            existing.font.outlineColor.b === text.font.outlineColor.b &&
                            existing.font.font === text.font.font &&
                            existing.font.fontSize === text.font.fontSize &&
                            existing.font.outlineWidth === text.font.outlineWidth
                        )
                    }).forEach((text: OverlayText) => {
                        me.overlayTextCanvas[text.id] = me.overlayTextCanvas[text.id] || {};
                        if (!text.font.font || !text.font.fontSize || !text.value) {
                            if (me.overlayTextCanvas[text.id].canvas) {
                                me.overlayTextCanvas[text.id].canvas.parentNode.removeChild(me.overlayTextCanvas[text.id]?.canvas);
                                me.overlayTextCanvas[text.id].canvas = null;
                            }
                            return false;
                        }
                        else if (!me.overlayTextCanvas[text.id]?.canvas && text.value) {
                            me.overlayTextCanvas[text.id].canvas = document.createElement('canvas');
                            appendChild(me.overlayTextCanvas[text.id].canvas);
                        }
                        else if (!text.value && me.overlayTextCanvas[text.id]?.canvas) {
                            me.overlayTextCanvas[text.id].canvas.parentNode.removeChild(me.overlayTextCanvas[text.id]?.canvas);
                            me.overlayTextCanvas[text.id].canvas = null;
                            return;
                        }

                        me.overlayTextCanvas[text.id].text = text;
                        me.overlayTextCanvas[text.id].canvas.setAttribute('width', this.data.width);
                        me.overlayTextCanvas[text.id].canvas.setAttribute('height', this.data.height);
                        drawTextOnCanvas(me.overlayTextCanvas[text.id]?.canvas, text.value, {
                            fontFamily: `${text.font.font}`,
                            fontWeight: `400`,
                            fontStyle: '',
                            fontSize: `${text.font.fontSize * 200}px`,
                            color: rgbToHex(text.font.color) || '#ffffff',
                            outlineColor: text.font.outlineColor ? rgbToHex(text.font.outlineColor) || '#ffffff' : '',
                            outlineWidth: text.font.outlineWidth * 20,
                        }, {
                            x: text.position.x * me.data.width,
                            y: text.position.y * me.data.height,
                            rotation: text.position.rotation * Math.PI * 2
                        })
                        drawn = true;
                    });
                    if (!me.drawing) {
                        me.drawing = drawn;
                        raiseCustomEvent(PAINTER_CONSTANTS.DRAWING, {});
                    }
                    me.overlayTexts = JSON.parse(JSON.stringify(texts.sort((a, b) => parseFloat(`${a.layer || 0}`) - parseFloat(`${b.layer || 0}`)))) || [];
                    me.throttledUpdatedPainting = me.throttledUpdatedPainting || throttle(() => {
                        me.updatedPainting();
                    }, 1000);
                    me.updateCompositeTexture();
                    await me.updateCompositeResultTexture();
                }
            })

        },
        update: function (oldData) {
            let me = this;
            if (this.data.width && this.data.height) {
                if (oldData.width !== this.data.width || oldData.height !== this.data.height) {
                    me.buildEasel();
                    me.buildCompositeResultDisplay();
                    let maskingData: MaskingData = this.maskingData;
                    if (maskingData?.canvas) {
                        maskingData.canvas.height = this.data.height;
                        maskingData.canvas.width = this.data.width;
                    }
                }
            }
        },
        updateMaskCanvas: function () {
            let me = this;
            let maskingData: MaskingData = me.maskingData;
            if (maskingData) {
                let { canvas, colors, sensitivity } = maskingData;
                if (colors) {
                    let points = colors.map((colorObj) => {
                        let { x, y } = colorObj;
                        return {
                            startX: x,
                            startY: y
                        }
                    });
                    magicSelectAndMask(this.compositeCanvas, canvas, points, sensitivity)
                }
            }
        },
        addTransformHandlers: function () {
            let me = this;
            // redrawAndScaleCanvas
            let targetCanvas = null;
            let selectedCanvas = null;

            let canvasTransformations: CanvasTransformations = null;
            let bankedTransforms = {
                scale: { x: 0, y: 0 },
                translation: { x: 0, y: 0 },
                rotation: 0,
            }
            let size;
            let scale = { x: 1, y: 1 };
            let translation = { x: 0, y: 0 };
            let rotation = 0;
            setDefaultTransforms();
            if (me.layers.length) {
                selectedCanvas = me.getLayer(me.layers[0]);
            }
            customEventListener(PAINTER_CONSTANTS.SELECT_LAYER, (detail: any) => {
                console.log(detail);
                selectedCanvas = me.getLayer(detail.id);
                if (selectedCanvas) {
                    const { canvas } = selectedCanvas;
                    size = Math.max(canvas.width, canvas.height);
                }
                canvasTransformations = new CanvasTransformations();
                setDefaultTransforms();
            });

            customEventListener(PAINTER_CONSTANTS.TRANSFORMER_CHANGE, (detail: { transformer: Transformation }) => {
                let { transformer } = detail;
                if (transformer && selectedCanvas) {
                    const { canvas } = selectedCanvas;
                    if (!size) {
                        size = Math.max(canvas.width, canvas.height);
                    }
                    let change = transformer.previewChange({ transform: size / 5 });
                    transformCanvasImage(targetCanvas, canvas,
                        change.scale.x, change.scale.y, -change.rotate,
                        change.translation.x, -change.translation.y);
                    me.updateCompositeTexture();
                }
            })

            let maskCanvas = document.createElement('canvas');
            maskCanvas.height = this.data.height;
            maskCanvas.width = this.data.width;
            maskCanvas.classList.add('masking-canvas');
            let maskingData: MaskingData = {
                colors: [],
                canvas: maskCanvas,
                sensitivity: .1
            };
            me.maskingData = maskingData;
            customEventListener(PAINTER_CONSTANTS.DELETE_MASKED_COLORS, (evt: any) => {
                let maskingData: MaskingData = me.maskingData;
                if (me?.maskingData?.mode) {
                    for (let layerId of this.layers) {
                        if (this.layerProperties?.[layerId]?.hidden) {
                            continue;
                        }
                        let layer = this.getLayer(layerId);
                        for (let i = 0; i < layer.composite.canvases.length; i++) {
                            if (maskingData.maskAllLayers || me.selectedLayer === layerId) {
                                applyTransparencyBasedOnSourceAlpha(maskingData.canvas, layer.composite.canvases[i])
                            }
                        }
                    }
                    me.updateCompositeTexture();
                }
            });

            customEventListener(PAINTER_CONSTANTS.SELECT_MASKING_TARGET_COLOR, (evt: any) => {
                if (me.maskModeEnabled && PAINTER_CONSTANTS.MOUSE.MOUSEUP === evt.eventType) {
                    const { color, x, y, eventType } = evt;
                    let maskingData: MaskingData = me.maskingData;
                    if (me?.maskingData?.mode) {
                        switch (me.maskingData.mode) {
                            case MaskMode.Select:
                                maskingData.colors = [{ color, x, y }];
                                break;
                            case MaskMode.SelectAdditive:
                                maskingData.colors = [...maskingData.colors, { color, x, y }];
                                break;
                            case MaskMode.SelectSubtractive:
                                maskingData.colors = [...maskingData.colors, { color, x, y, subtractive: true }];
                                break;
                        }
                        me.updateMaskCanvas();
                        me.updateCompositeTexture();
                    }
                }
            });

            customEventListener(PAINTER_CONSTANTS.MASK_SENSITIVITY, (evt: any) => {
                maskingData.sensitivity = evt.value;
            })

            customEventListener(PAINTER_CONSTANTS.MASK_ALL_LAYERS, (evt: any) => {
                maskingData.maskAllLayers = evt.value;
            })

            customEventListener(PAINTER_CONSTANTS.CLEAR_MASK, (evt: any) => {
                me.maskingData.colors = [];
                me.updateMaskCanvas();
                me.updateCompositeTexture();
            })

            customEventListener(PAINTER_CONSTANTS.CHANGE_MASK_MODE, (evt: any) => {
                const { value } = evt;
                me.maskingData.mode = value;
            })

            customEventListener(PAINTER_CONSTANTS.TRANSFORMER_SCALE, (detail: any) => {
                if (detail.reset) {
                    bankedTransforms.scale = { ...scale };
                }
                if (detail.value) {
                    const { canvas } = selectedCanvas;
                    canvasTransformations.onScale(detail.value.x * 5, detail.value.y * 5);
                    transformCanvasImage(targetCanvas, canvas, scale.x + bankedTransforms.scale.x, scale.y + bankedTransforms.scale.y, -rotation, translation.x + bankedTransforms.translation.x, translation.y + bankedTransforms.translation.y)
                    me.updateCompositeTexture();
                }

            })
            customEventListener(PAINTER_CONSTANTS.TRANSFORMER_TRANSLATION, (detail: any) => {
                if (detail.reset) {
                    bankedTransforms.translation = { ...translation };
                }
                if (detail.value) {
                    const { canvas } = selectedCanvas;
                    let size = Math.max(canvas.width, canvas.height);
                    translation.x = (detail.value.x * size);
                    translation.y = -(detail.value.y * size);
                    transformCanvasImage(targetCanvas, canvas, scale.x + bankedTransforms.scale.x, scale.y + bankedTransforms.scale.y, -rotation, translation.x + bankedTransforms.translation.x, translation.y + bankedTransforms.translation.y)
                    me.updateCompositeTexture();
                }
            })
            customEventListener(PAINTER_CONSTANTS.TRANSFORMER_ROTATION, (detail: any) => {
                if (detail.reset) {
                    bankedTransforms.rotation = rotation;
                }
                if (detail.value) {
                    const { canvas } = selectedCanvas;
                    rotation = detail.value + bankedTransforms.rotation;
                    transformCanvasImage(targetCanvas, canvas, scale.x + bankedTransforms.scale.x, scale.y + bankedTransforms.scale.y, -rotation, translation.x + bankedTransforms.translation.x, translation.y + bankedTransforms.translation.y)
                    me.updateCompositeTexture();
                }
            })

            customEventListener(PAINTER_CONSTANTS.IMAGE_ADDED, (evt: any) => {
                let sourcecanvas = evt.canvas;
                const layer = me.getLayer(me.selectedLayer);
                if (layer) {
                    const { texture, canvas } = layer;
                    drawCanvasInsideAnother(sourcecanvas, canvas)
                    texture.needsUpdate = true;
                    me.updateCompositeTexture();
                    let layerIndex = me.layers.indexOf(me.selectedLayer);
                    if (layerIndex !== -1) {
                        me.el.emit(PAINTER_CONSTANTS.LAYER_UPDATED, {
                            canvas: me.compositeCanvas,
                            layerId: layerIndex
                        });
                    }
                }
            });
            customEventListener(PAINTER_CONSTANTS.DRAWING_MODE_CHANGED, (detail: any) => {
                console.log(detail);
                let { mode } = detail;
                switch (mode) {
                    case DrawMode.Transform:
                        const { canvas } = selectedCanvas;
                        const ctx = canvas.getContext('2d');
                        if (targetCanvas) {
                            ctx.restore();
                            resetCanvasTransformations(ctx);
                            targetCanvas.parentNode.removeChild(targetCanvas);
                            targetCanvas = null;
                        }
                        // Save the initial state
                        setDefaultTransforms();
                        targetCanvas = document.createElement('canvas');
                        appendChild(targetCanvas);
                        ctx.save();
                        redrawAndScaleCanvas(canvas, targetCanvas, 1);
                        break;
                    default:
                        {
                            const { canvas } = selectedCanvas;
                            const ctx = canvas.getContext('2d');
                            if (targetCanvas) {
                                ctx.restore();
                                resetCanvasTransformations(ctx);
                                targetCanvas.parentNode.removeChild(targetCanvas);
                                targetCanvas = null;
                            }
                        }
                        break;
                }
            })
            function setDefaultTransforms() {
                rotation = 0;
                scale = { x: 1, y: 1 };
                translation = { x: 0, y: 0 }
                bankedTransforms = {
                    scale: { x: 0, y: 0 },
                    translation: { x: 0, y: 0 },
                    rotation: 0,
                }
            }
        },
        buildEasel: function () {
            let me = this;
            if (me.compositeCanvas) {
                me.compositeCanvas.parentNode.removeChild(me.compositeCanvas);
            }
            if (me.easelEntity) {
                me.easelEntity.parentNode.removeChild(me.easelEntity);
            }
            me.compositeCanvas = document.createElement('canvas');
            document.body.appendChild(me.compositeCanvas)
            me.compositeCanvas.classList.add('paint-canvas');
            me.compositeCanvas.width = me.data.width;
            me.compositeCanvas.style.visibility = 'hidden';
            me.compositeCanvas.height = me.data.height;

            me.compositeContext = me.compositeCanvas.getContext('2d');


            // Set the canvas background to white
            var texture = new THREE.Texture(me.compositeCanvas);
            texture.needsUpdate = true; // Important to update the texture
            me.compositeContextTexture = texture;
            var material = new THREE.MeshBasicMaterial({ map: me.compositeContextTexture, side: THREE.DoubleSide });
            let size = 2.8125;
            let ratio = this.data.width / this.data.height;
            let height = size;
            let width = ratio * size;

            var mesh = new THREE.Mesh(
                new THREE.PlaneGeometry(width, height),
                material
            );
            mesh.position.set(0, height / 2 + .1, -2); // x, y, z

            // Attach the mesh to the entity this component is on
            let easelEntity: any = document.createElement('a-entity');

            easelEntity.setAttribute('gui-interactable', {});
            easelEntity.classList.add('raycastable');
            // Add the Three.js mesh to the A-Frame entity
            easelEntity.setObject3D('mesh', mesh);


            // Add the entity to the scene
            me.easelEntity = easelEntity;
            me.el.appendChild(easelEntity);

            me.setupRayListener(easelEntity, 'interactions', this.handleRayInteractions)
        },
        clearRaycastState: function (id) {
            delete this.raycastStates?.[id]
        },
        getRaycastState: function (id, key) {
            return this.raycastStates?.[id]?.[key]
        },
        setRaycastStates: function (id, key, value) {
            this.raycastStates[id] = this.raycastStates[id] || {};
            this.raycastStates[id][key] = value;
        },
        handleRayInteractions: function (args) {
            if (this.system && args) {
                const { x, y, raycaster } = args;
                let raycastid = raycaster?.components?.raycaster?.el?.id;
                let layerId = this.layers[this.layerIndex]
                if (raycastid) {
                    if (this.getRaycastState(raycastid, 'just-entered')) {
                        this.getRaycastState(raycastid, 'just-entered', false)
                        raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEENTER}`, {
                            clientX: x,
                            clientY: y,
                            raycastid,
                            layerId
                        })
                        if (this.getRaycastState(raycastid, 'drawing')) {
                            this.setRaycastStates(raycastid, 'drawing', false)
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.STOP_DRAW}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            })
                        }
                    }
                    raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEOVER}`, {
                        clientX: x,
                        clientY: y,
                        raycastid,
                        layerId
                    })
                    if (this.system.getButtonState(raycastid, 'trigger') === 'down') {
                        if (!this.getRaycastState(raycastid, 'pressed')) {
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEDOWN}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            })
                        }
                        this.setRaycastStates(raycastid, 'pressed', this.system.getButtonState(raycastid, 'trigger') === 'down')
                    }
                    else {
                        if (this.getRaycastState(raycastid, 'pressed')) {
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEUP}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            })
                            this.setRaycastStates(raycastid, 'pressed', this.system.getButtonState(raycastid, 'trigger') === 'down')
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.CLICK}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            })
                            // clicked
                        }
                    }
                    if (this.currentRayposition[raycastid]) {
                        if (calculateDistance(args, this.currentRayposition[raycastid]) > 0) {
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEMOVE}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            });
                            if (this.system.getButtonState(raycastid, 'trigger') === 'down') {
                                if (this.getRaycastState(raycastid, 'drawing')) {
                                    this.setRaycastStates(raycastid, 'drawing', true)
                                    raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.START_DRAW}`, {
                                        clientX: x,
                                        clientY: y,
                                        raycastid,
                                        layerId
                                    })

                                }
                                else {
                                    raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.DRAW}`, {
                                        clientX: x,
                                        clientY: y,
                                        raycastid,
                                        layerId
                                    })
                                }

                            }
                        }
                    }
                    if (this.getRaycastState(raycastid, 'just-exited')) {
                        this.getRaycastState(raycastid, 'just-exited', false)
                        raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEOUT}`, {
                            clientX: x,
                            clientY: y,
                            raycastid,
                            layerId
                        })

                        if (this.getRaycastState(raycastid, 'drawing')) {
                            this.setRaycastStates(raycastid, 'drawing', false)
                            raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.STOP_DRAW}`, {
                                clientX: x,
                                clientY: y,
                                raycastid,
                                layerId
                            })
                        }
                    }
                    this.currentRayposition = this.currentRayposition || {}
                    this.currentRayposition[raycastid] = {
                        x, y
                    }
                }
                else {
                    console.log('raycastid not found');
                }
            }
        },
        buildCompositeTexture: function (canvases) {
            // Set the canvas background to white
            var texture = new THREE.Texture(canvases[0]);
            texture.needsUpdate = true; // Important to update the texture
            return {
                canvas: canvases[0], texture, canvases, context: null//canvases[0].getContext('2d')
            };
        },
        clearLayer: function (layer) {
            const { texture, canvas, context } = layer;
            context.clearRect(0, 0, canvas.width, canvas.height);
            texture.needsUpdate = true;
        },
        redrawCompositeTexture: function (composite) {
            const { texture } = composite;
            texture.needsUpdate = true;
            console.log('redrawed composite')
        },
        buildCompositeResultDisplay: function () {
            if (this.compositeResultCanvas) {
                this.compositeResultCanvas.parentNode.removeChild(this.compositeResultCanvas)
            }
            if (this.composite_layerDisplayEntity) {
                this.composite_layerDisplayEntity.parentNode.removeChild(this.composite_layerDisplayEntity);
            }
            this.compositeResultCanvas = document.createElement('canvas');
            this.compositeResultCanvas.style.visibility = 'hidden';
            this.compositeResultCanvas.setAttribute('compsite', 'result');
            document.body.appendChild(this.compositeResultCanvas)
            this.compositeResultCanvas.width = this.data.width;
            this.compositeResultCanvas.height = this.data.height;
            // this.compositeResultCanvas.style.position = 'absolute';
            // this.compositeResultCanvas.style.top = `-${this.data.height}px`;
            this.compositeResultContext = this.compositeResultCanvas.getContext('2d');
            raiseCustomEvent(PAINTER_CONSTANTS.COMPOSITE_RESULT_CANVAS, { canvas: this.compositeResultCanvas, painterCanvas: this })
            // Set the canvas background to white
            var texture = new THREE.Texture(this.compositeResultCanvas);
            texture.needsUpdate = true; // Important to update the texture
            this.compositeResultCanvasTexture = texture;
            var material = new THREE.MeshBasicMaterial({ map: this.compositeResultCanvasTexture, side: THREE.DoubleSide });
            let size = 2.8125;
            let ratio = this.data.width / this.data.height;
            let width = ratio * size;
            let height = size;
            var mesh = new THREE.Mesh(
                new THREE.PlaneGeometry(width, height),
                material
            );

            // Create an A-Frame entity to wrap the Three.js mesh
            let layerDisplayEntity: any = document.createElement('a-entity');

            // Add the Three.js mesh to the A-Frame entity
            layerDisplayEntity.setObject3D('mesh', mesh);

            // Position the entity
            layerDisplayEntity.setAttribute('position', { x: 4, y: height / 2 + .1, z: 1 });
            layerDisplayEntity.setAttribute('rotation', { x: 0, y: -90, z: 0 });
            this.composite_layerDisplayEntity = layerDisplayEntity;
            // Add the entity to the scene
            this.el.appendChild(layerDisplayEntity);

        },
        updateCompositeResultTexture: async function (imageUrl = null) {
            let me = this;
            // Clear the composite canvas
            if (!imageUrl) {
                imageUrl = this.lastImageUrl;
            }
            this.lastImageUrl = imageUrl;
            this.compositeResultContext.clearRect(0, 0, this.compositeResultCanvas.width, this.compositeResultCanvas.height);
            if (imageUrl) {
                await drawTextureOnCanvas(this.compositeResultCanvas, this.compositeResultContext, imageUrl);
            }
            if (me.overlayTexts) {
                me.overlayTexts.map((text: OverlayText) => {
                    if (!(me.overlayTextCanvas[text.id]?.text as OverlayText)?.composite &&
                        !(me.overlayTextCanvas[text.id]?.text as OverlayText)?.hidden) {
                        if (me.overlayTextCanvas[text.id]?.canvas) {
                            this.compositeResultContext.drawImage(me.overlayTextCanvas[text.id]?.canvas, 0, 0);
                            if (!me.drawing) {
                                raiseCustomEvent(PAINTER_CONSTANTS.DRAWING, {});
                                me.drawing = true;
                            }
                        }
                    }
                })
            }


            this.compositeResultCanvasTexture.needsUpdate = true;
        },
        setupRayListener: function (entity, raycastKey, handler) {
            let me = this;
            me.raycasters = me.raycasters || {};
            let raycaster_id = null;
            entity.addEventListener('raycaster-intersected', evt => {
                if (evt?.detail?.el) {
                    evt.detail.el.$id = evt.detail.el.$id || uuidv4();
                    raycaster_id = evt.detail.el?.components?.raycaster?.el?.id;;
                    me.raycasters[raycastKey + evt.detail.el.$id] = {
                        raycaster: evt.detail.el,
                        entity: entity,
                        handler
                    };
                    let raycastid = evt.detail.el.$id;
                    me.setRaycastStates(raycastid, 'just-entered', true)
                }
            });
            entity.addEventListener('raycaster-intersected-cleared', evt => {
                if (raycaster_id) {
                    console.log(`raycaster-intersected-cleared :${raycaster_id}`)
                    let raycastid = raycaster_id;
                    console.log('layerId')
                    let layerId = me.layers[me.layerIndex]
                    console.log(layerId)
                    raiseCustomEvent(`${PAINTER_CONSTANTS.VR_CANVAS_PREFIX}${PAINTER_CONSTANTS.MOUSE.MOUSEOUT}`, {
                        clientX: me.currentRayposition?.[raycastid]?.x,
                        clientY: me.currentRayposition?.[raycastid]?.y,
                        raycastid,
                        layerId
                    })
                    delete me.currentRayposition[raycastid]
                    delete me.raycasters[raycastKey + raycastid];
                    me.clearRaycastState(evt?.detail?.el?.$id);
                }
                else {
                    console.log('no raycaster_id found')
                }
            });
        },
        updatedPainting: function () {
            let me = this;
            let layerIndex = me.layers.indexOf(me.selectedLayer);
            if (layerIndex !== -1) {
                me.el.emit(PAINTER_CONSTANTS.LAYER_UPDATED, {
                    canvas: me.compositeCanvas,
                    layerId: layerIndex
                });
            }
        },
        buildLayerDisplays: function () {
            let me = this;
            // Initialize array to hold the layer display meshes
            const layerVerticalOffset = this.layerVerticalOffset;
            const layerOffset = this.layerOffset; // Vertical offset between layers
            let layerDisplayParent: any = document.createElement('a-entity');
            layerDisplayParent.setAttribute('rotation', { x: 0, y: 50, z: 0 });
            layerDisplayParent.setAttribute('position', { x: -3.5, y: 1, z: 0 })
            me.el.appendChild(layerDisplayParent);
            let plane: any = document.createElement('a-entity');
            let planeWidth = 3.5;
            // Set the material, position, and rotation
            plane.setAttribute('material', { shader: 'flat', src: '#selectedicon', opacity: '1', side: 'double' });
            plane.setAttribute('geometry', 'primitive: box; width: .5; height: .5; depth: .01');
            plane.setAttribute('position', { x: planeWidth / 2 + .5, y: layerVerticalOffset, z: -.01 });
            plane.setAttribute('rotation', { x: 0, y: 0, z: 0 });
            plane.setAttribute('index-animator', {
                selectedIndex: this.layerIndex,
                initial: { x: 1.5, y: 0, z: -.01 },
                stepY: layerOffset
            })
            layerDisplayParent.appendChild(plane);
            this.selectedPanel = plane;
            document.body.addEventListener(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, (event: any) => {
                const { id } = event.detail;
                const layer = me.getLayer(id);
                if (layer) {
                    const { composite, texture } = layer;
                    if (composite) {
                        me.redrawCompositeTexture(composite);
                        me.updateCompositeTexture();
                    }
                    if (texture) {
                        texture.needsUpdate = true;
                    }
                    let layerIndex = me.layers.indexOf(id);
                    if (me.layerUpdates[layerIndex]) {
                        clearTimeout(me.layerUpdates[layerIndex])
                    }
                    me.layerUpdates[layerIndex] = setTimeout(() => {
                        me.el.emit(PAINTER_CONSTANTS.LAYER_UPDATED, {
                            canvas: me.compositeCanvas,
                            layerId: layerIndex,
                            layer: id,
                        });
                    }, 1000);
                }
                else {
                    console.log('no layer found');
                }
            });
            const change_handler = () => {
                me.updatedPainting();
            };
            document.body.addEventListener(PAINTER_CONSTANTS.MODEL_SELECTED, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.PROMPTS_UPDATED, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.LORA_SELECTED, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.LORA_STRENGTH_CHANGE, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.CLEAR_LORA_STYLE, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.TOGGLE_LORA_WORD_VALUE, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.SCHEDULER_CHANGE, change_handler);
            document.body.addEventListener(PAINTER_CONSTANTS.STRENGTH_CHANGE, change_handler);

            document.body.addEventListener(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILT, (event: any) => {
                const { canvases, id } = event.detail;
                if (canvases?.length) {
                    let container: any = document.createElement('a-entity');
                    let offset = 0;
                    container.setAttribute('position', {
                        // x: -3, y: layerOffset * me.layers.length + this.layerVerticalOffset, z: -.6
                        x: 0, y: layerOffset * me.layers.length, z: 0
                    });
                    container.setAttribute('rotation', { x: 0, y: 0, z: 0 });
                    layerDisplayParent.appendChild(container);
                    me.canvasContainers = me.canvasContainers || {}
                    me.canvasContainers[id] = container;
                    let canvasLayer = document.createElement('a-canvas-layer');
                    canvasLayer.setAttribute('layer-id', id);
                    me.buttons.forEach((btn) => {
                        switch (btn.type) {
                            case 'layerpreview':
                                {
                                    let composite = me.buildCompositeTexture(canvases)
                                    var texture = new THREE.Texture(canvases[0]);
                                    let material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
                                    let ratio = me.data.width / me.data.height;
                                    let width = ratio * btn.size;
                                    let height = btn.size;
                                    let mesh = new THREE.Mesh(
                                        new THREE.PlaneGeometry(width, height),
                                        material
                                    );
                                    // Create an A-Frame entity to wrap the Three.js mesh
                                    let layerDisplayEntity: any = document.createElement('a-entity');
                                    // layerDisplayEntity.setAttribute('layer', 'display')
                                    // // Add the Three.js mesh to the A-Frame entity
                                    layerDisplayEntity.setObject3D('mesh', mesh);
                                    layerDisplayEntity.setAttribute('draganddropzone', { width: .1, height: .1 })
                                    layerDisplayEntity.addEventListener(GrabAndDropEvents.DROPPED_GRABBED_DATA, async (evt) => {
                                        const { data, category } = evt.detail;
                                        switch (category) {
                                            case 'image-catalog':
                                                console.log(GrabAndDropEvents.DROPPED_GRABBED_DATA)
                                                console.log(data)
                                                if (data?.url && canvases?.[0]) {
                                                    await drawTextureOnCanvas(canvases[0], canvases[0].getContext('2d'), data?.url);
                                                    texture.needsUpdate = true;
                                                    me.updateCompositeTexture();
                                                    change_handler();
                                                }
                                                break;
                                        }
                                    })
                                    canvasLayer.addEventListener(PAINTER_CONSTANTS.LAYER_READY, (event: any) => {
                                        let details = event.detail;
                                        if (details?.id === id) {
                                            raiseCustomEvent(PAINTER_CONSTANTS.LAYER_DISPLAY_READY, { id, entity: layerDisplayEntity }, canvasLayer)
                                        }
                                    });
                                    canvasLayer.addEventListener(PAINTER_CONSTANTS.TOGGLE_VISIBILITY, (event: any) => {
                                        let details = event.detail;
                                        let { value, id } = details;
                                        if (id) {
                                            this.layerProperties[id] = {
                                                ...this.layerProperties[id],
                                                hidden: value
                                            };
                                            this.updateCompositeTexture();
                                            change_handler();
                                        }
                                    });
                                    canvasLayer.addEventListener(PAINTER_CONSTANTS.LAYER_DOWN, () => {
                                        let index = me.layers.indexOf(id);
                                        if (index !== 0) {
                                            swapElements(me.layers, index, index - 1)
                                            me.positionCanvasContainers();
                                            raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
                                                id
                                            });
                                        }
                                    });
                                    canvasLayer.addEventListener(PAINTER_CONSTANTS.LAYER_UP, () => {
                                        let index = me.layers.indexOf(id);
                                        if (index !== -1 && index < me.layers.length - 1) {
                                            swapElements(me.layers, index, index + 1)
                                            me.positionCanvasContainers();
                                            raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_UPDATED, {
                                                id
                                            });
                                        }
                                    });
                                    // // Position the entity
                                    console.log('built composites')
                                    me.addLayer(id, canvases[0], canvases[0].getContext('2d'), texture, null, null, null, composite, null, canvasLayer);
                                }
                                break;
                        }
                        offset -= btn.size + btn.padding;
                    })

                    container.appendChild(canvasLayer);
                    if (me.layers.length === 1) {
                        (window as any).selectLayer(null, me.layers[0])
                    }
                }
            });
            document.body.addEventListener(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, async (event: any) => {
                const { data } = event.detail; // Assuming layerIndex is provided in the event
                const { imageUrl, save } = data;

                await this.updateCompositeResultTexture(imageUrl);
                if (save) {
                    me.el.emit('on-save-image', {})
                }
            });
            raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILD, {});
        },
        positionCanvasContainers: function () {
            let me = this;
            me.layers.forEach((layer, layerIndex) => {
                me.canvasContainers[layer].setAttribute('animation',)
                me.canvasContainers[layer].setAttribute('animation', {
                    dur: 400,
                    easing: 'easeOutExpo',
                    property: 'position',
                    to: {
                        x: 0,
                        y: this.layerOffset * layerIndex,
                        z: 0
                    }
                });
            })
        },
        createLoraContainer: function ({ id, loraIndex, x }) {
            let me = this;
            let loraContainer = document.createElement('a-entity');
            let imageEntity: any = document.createElement('a-aspect-ratio-image');
            // imageEntity.setAttribute('visible', `false`)
            let imageSize = (me.buttonSize - .02);
            imageEntity.setAttribute('maxwidth', imageSize || `.2`);
            imageEntity.setAttribute('maxheight', imageSize || `.2`);
            document.body.addEventListener(PAINTER_CONSTANTS.LORA_SELECTED, function (evt: any) {
                const { detail } = evt;
                const { url, layer, lora } = detail;
                if (loraIndex !== lora || layer != id) { return; }
                // imageEntity.setAttribute('visible', `${!!url}`)
                imageEntity.setAttribute('position', `0 0 .033`)
                imageEntity.setAttribute('url', url)
            })
            let loraStyle1 = me.createButton({
                onclick: `testButtonAction`,
                args: `${id}`,
                value: 'Style',
                position: '0 0 .01'
            })
            loraStyle1.addEventListener('click', function () {
                me.el.emit(PAINTER_CONSTANTS.OPEN_LORA_MENU, {
                    lora: loraIndex,
                    layer: id
                })
            })
            loraStyle1.setAttribute('font-size', '.05')
            loraContainer.appendChild(loraStyle1);
            loraContainer.appendChild(imageEntity);
            loraContainer.setAttribute('position', x + ' 0 0')
            return loraContainer;
        },

        createLayerRenderedPanel: function ({ id, size, parentEl }) {
            let canvas = document.createElement('canvas');
            canvas.height = this.data.height;
            canvas.style.visibility = 'hidden';
            canvas.width = this.data.width;
            canvas.setAttribute('id', id);
            canvas.setAttribute('rendered', 'true');
            appendChild(canvas);
            var texture = new THREE.Texture(canvas);
            let material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
            let ratio = this.data.height / this.data.width;
            let height = ratio * size;
            let width = size;
            let mesh = new THREE.Mesh(
                new THREE.PlaneGeometry(width, height),
                material
            );
            // Create an A-Frame entity to wrap the Three.js mesh
            let layerDisplayEntity: any = document.createElement('a-entity');
            // layerDisplayEntity.setAttribute('layer', 'display')
            // // Add the Three.js mesh to the A-Frame entity
            layerDisplayEntity.setObject3D('mesh', mesh);

            // // Position the entity
            layerDisplayEntity.setAttribute('position', {
                x: -3, y: 0, z: -1.6
            });
            layerDisplayEntity.setAttribute('rotation', { x: 0, y: 50, z: 0 });
            parentEl.appendChild(layerDisplayEntity);
            return { canvas, context: canvas.getContext('2d'), texture, mesh, material, entity: layerDisplayEntity };
        },

        createButton: function ({ type, onclick, args, value, position, buttonSize }) {
            let me = this;
            let button = document.createElement(type || 'frame-gui-button');

            button.setAttribute('width', buttonSize || me.buttonSize || '0.15')
            button.setAttribute('height', buttonSize || me.buttonSize || '0.15')
            button.setAttribute('onclick', onclick)
            button.setAttribute('args', args)
            button.setAttribute('value', value || ``)
            button.setAttribute('font-color', '#ffffff')
            button.setAttribute('margin', '0 0.02 0.05 0')
            button.setAttribute('position', position || '.4 0 .1')
            return button;
        },
        addLayer: function (id, canvas, context, texture, material, mesh, entity, composite, renderedTarget, canvasLayer) {
            let me = this;
            me.layers.push(id)
            me.layerLibrary[id] = { canvas, context, texture, material, mesh, entity, composite, renderedTarget, canvasLayer };
            (window as any).setLayerCount(me.layers);
            raiseCustomEvent(PAINTER_CONSTANTS.LAYER_ADDED, { id, layers: me.layers })
        },
        deleteLayer: function (id) {
            let me = this;
            if (me.layers.length > 1) {
                me.layers = me.layers.filter(x => x !== id)
                const { canvasLayer } = me.layerLibrary[id];
                canvasLayer.parentNode.removeChild(canvasLayer);
                delete me.layerLibrary[id];
                (window as any).setLayerCount(me.layers);
                raiseCustomEvent(PAINTER_CONSTANTS.LAYER_REMOVED, { id, layers: me.layers })
                me.positionCanvasContainers();
                if (me.selectedLayer === id) {
                    raiseCustomEvent(PAINTER_CONSTANTS.SELECT_LAYER, { id: me.layers[0] });
                }
                else {
                    raiseCustomEvent(PAINTER_CONSTANTS.SELECT_LAYER, { id: me.selectedLayer });
                }
            }
        },
        clearLayers: function () {
            let me = this;
            me.layers.map(id => {
                if (me.layerLibrary[id]) {
                    const { canvasLayer } = me.layerLibrary[id];
                    canvasLayer.parentNode.removeChild(canvasLayer);
                    delete me.layerLibrary[id];
                }
            });
            (window as any).setLayerCount([]);
            raiseCustomEvent(PAINTER_CONSTANTS.SELECT_LAYER, { id: null });
            me.layers.length = 0;
        },
        getLayer: function (id) {
            return this.layerLibrary[id] || null
        },
        tick: function () {
            if (!this.system) { return; } // Exit if not pressed or not intersecting

            for (let raycastKey in this.raycasters) {
                if (this.raycasters[raycastKey]) {
                    const { raycaster, entity, handler } = this.raycasters[raycastKey];
                    if (raycaster?.components?.raycaster && entity) {
                        let intersection = raycaster.components.raycaster.getIntersection(entity);
                        if (intersection?.uv) {
                            var uv = intersection.uv;
                            var x = uv.x;
                            var y = (1 - uv.y); // Invert y as canvas coordinates are from top to bottom
                            // Emit a custom event with x and y coordinates
                            handler({ uv, x, y, raycaster, entity });
                            // You can now use x and y to draw on the canvas or perform other actions
                        }
                    }
                }
            }
        },
        updateCompositeTexture: function () {
            let me = this;
            // Clear the composite canvas
            this.compositeContext.clearRect(0, 0, this.compositeCanvas.width, this.compositeCanvas.height);

            // Draw each layer onto the composite canvas
            for (let layerId of this.layers) {
                if (this.layerProperties?.[layerId]?.hidden) {
                    continue;
                }
                let layer = this.getLayer(layerId);
                for (let i = 0; i < layer.composite.canvases.length; i++) {
                    this.compositeContext.drawImage(layer.composite.canvases[i], 0, 0);
                }
            }

            if (me.overlayTexts) {
                me.overlayTexts.map((text: OverlayText) => {
                    if ((me.overlayTextCanvas[text.id]?.text as OverlayText)?.composite &&
                        !(me.overlayTextCanvas[text.id]?.text as OverlayText)?.hidden) {
                        if (me.overlayTextCanvas[text.id]?.canvas) {
                            this.compositeContext.drawImage(me.overlayTextCanvas[text.id]?.canvas, 0, 0);
                            me.throttledUpdatedPainting();
                        }
                    }
                })
            }

            if (me.maskModeEnabled) {
                let maskingData: MaskingData = me.maskingData;
                if (maskingData) {
                    const { canvas } = maskingData;
                    let maskResultCanvas = maskingData.maskResultCanvas || document.createElement('canvas');
                    if (!maskingData.maskResultCanvas) {
                        maskingData.maskResultCanvas = maskResultCanvas;
                    }
                    maskResultCanvas.width = me.data.width;
                    maskResultCanvas.height = me.data.height;
                    let maskResultContext = maskingData.maskResultContext || maskResultCanvas.getContext('2d');
                    if (!maskingData.maskResultContext) {
                        maskingData.maskResultContext = maskResultContext;
                    }
                    maskResultContext.clearRect(0, 0, maskResultCanvas.width, maskResultCanvas.height);
                    maskResultContext.drawImage(this.compositeCanvas, 0, 0);
                    maskResultContext.drawImage(canvas, 0, 0);
                    me.compositeContextTexture.image = maskResultCanvas;
                    me.compositeContextTexture.needsUpdate = true;
                }
            }
            else {
                // Update the texture with the composite canvas
                this.compositeContextTexture.image = this.compositeCanvas;
                this.compositeContextTexture.needsUpdate = true;
            }
        },
        onUpdateSelectedLayer: function (evt) {
            if (evt.detail.direction) {
                this.updateLayerIndex(evt.detail.direction + this.layerIndex);
                if (this.layerIndex < 0) {
                    this.updateLayerIndex(this.layers.length - 1);
                }
                else if (this.layerIndex >= this.layers.length) {
                    this.updateLayerIndex(0);
                }
            }
        },
        updateLayerIndex: function (value) {
            this.layerIndex = value;
            if (this.selectedPanel) {
                this.selectedPanel.setAttribute('index-animator', 'selectedIndex', value);
            }
        },
        onUpdateColor: function (evt) {
            console.log('on update color called')
            console.log(`evt.detail.color: ${evt.detail.color}`);
            this.currentColor = evt.detail.color;
        },
        onUpdateSize: function (evt) {
            console.log('on update size called')
            console.log(`evt.detail.size: ${evt.detail.size}`);
            this.currentSize = this.maxBrushSize * evt.detail.size;
        }
    })


}

function calculateDistance(pos1, pos2) {
    if (!pos1 || !pos2) {
        console.error('Invalid raycast IDs provided.');
        return null;
    }

    const deltaX = pos2.x - pos1.x;
    const deltaY = pos2.y - pos1.y;

    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
}
function appendChild(child) {
    let bucket = document.body;
    child.style.display = 'none';
    bucket.appendChild(child);
}
function swapElements<T>(array: T[], index1: number, index2: number): T[] {
    // Check if indices are within the bounds of the array
    if (index1 < 0 || index1 >= array.length || index2 < 0 || index2 >= array.length) {
        throw new Error("Index out of bounds");
    }

    // Swapping the elements
    const temp = array[index1];
    array[index1] = array[index2];
    array[index2] = temp;

    return array;
}

function redrawAndScaleCanvas(sourceCanvas: HTMLCanvasElement, targetCanvas: HTMLCanvasElement, scaleFactor: number): void {
    // Get the 2D contexts of the source and target canvases
    const sourceContext = sourceCanvas.getContext('2d');
    const targetContext = targetCanvas.getContext('2d');

    if (!sourceContext || !targetContext) {
        throw new Error('Canvas 2D context not supported or canvas elements not provided correctly.');
    }

    // Calculate the new dimensions
    const scaledWidth = sourceCanvas.width * scaleFactor;
    const scaledHeight = sourceCanvas.height * scaleFactor;

    // Adjust the target canvas size
    targetCanvas.width = scaledWidth;
    targetCanvas.height = scaledHeight;

    // Clear the target canvas
    targetContext.clearRect(0, 0, scaledWidth, scaledHeight);

    // Draw the source canvas to the target canvas, scaling it in the process
    targetContext.drawImage(sourceCanvas, 0, 0, scaledWidth, scaledHeight);
}
function transformCanvasImage(
    sourceCanvas: HTMLCanvasElement,
    targetCanvas: HTMLCanvasElement,
    scaleFactorX: number,
    scaleFactorY: number,
    rotationRadians: number,
    translateX: number,
    translateY: number
): void {
    const targetContext = targetCanvas.getContext('2d');

    if (!targetContext) {
        throw new Error('Canvas 2D context not supported or canvas elements not provided correctly.');
    }

    console.log(`scale:${scaleFactorX},${scaleFactorY}, rotation: ${rotationRadians}, translation: ${translateX}, ${translateY}`)
    // Clear the target canvas
    targetContext.clearRect(0, 0, targetCanvas.width, targetCanvas.height);

    // Save the current state of the target canvas
    targetContext.save();

    // Move to the center of the image, apply translation here
    targetContext.translate(targetCanvas.width / 2 + translateX, targetCanvas.height / 2 + translateY);

    // Rotate the canvas around the image center
    targetContext.rotate(rotationRadians);

    // Apply scaling
    targetContext.scale(scaleFactorX, scaleFactorY);

    // Draw the source canvas to the target canvas, centered on the transformed canvas
    targetContext.drawImage(sourceCanvas, -sourceCanvas.width / 2, -sourceCanvas.height / 2);

    // Undo the translation to move the image back to its original position
    targetContext.translate(-translateX, -translateY);

    // Restore to the saved state
    targetContext.restore();
}


function transformCanvasImage2(
    sourceCanvas: HTMLCanvasElement,
    targetCanvas: HTMLCanvasElement,
    scaleFactorX: number,
    scaleFactorY: number,
    rotationRadians: number,
    translateX: number,
    translateY: number
): void {
    const sourceContext = sourceCanvas.getContext('2d');
    const targetContext = targetCanvas.getContext('2d');

    if (!sourceContext || !targetContext) {
        throw new Error('Canvas 2D context not supported or canvas elements not provided correctly.');
    }

    // Clear the target canvas and adjust its size
    targetCanvas.width = sourceCanvas.width * scaleFactorX + Math.abs(translateX) * 2;
    targetCanvas.height = sourceCanvas.height * scaleFactorY + Math.abs(translateY) * 2;

    // Translate to center of the target canvas for rotation
    targetContext.translate(targetCanvas.width / 2, targetCanvas.height / 2);

    // Rotate the canvas
    targetContext.rotate(rotationRadians);

    // Scale the canvas
    targetContext.scale(scaleFactorX, scaleFactorY);

    // Translate the canvas as specified (after scaling, to keep the translation relative to the scaled size)
    targetContext.translate(translateX / scaleFactorX, translateY / scaleFactorY);

    // Draw the source canvas to the target canvas, adjusting for the initial translation
    // The image is drawn at (-sourceCanvas.width / 2, -sourceCanvas.height / 2) to center it based on its own dimensions
    targetContext.drawImage(sourceCanvas, -sourceCanvas.width / 2, -sourceCanvas.height / 2);
}
function resetCanvasTransformations(context) {
    // Resets the current transform to the identity matrix
    context.setTransform(1, 0, 0, 1, 0, 0);
}
function throttle<T extends (...args: any[]) => void>(callback: T, limit: number): (...args: Parameters<T>) => void {
    let waiting = false; // Initially, not waiting
    let lastArgs: Parameters<T> | null = null;

    // The function returned by throttle
    return function (...args: Parameters<T>) {
        lastArgs = args;
        if (!waiting) { // If not currently waiting
            callback.apply(this, lastArgs); // Execute the callback with the current arguments
            waiting = true; // Prevent further execution
            setTimeout(() => {
                waiting = false; // After the limit, allow execution again
                if (lastArgs) {
                    callback.apply(this, lastArgs); // Execute the callback with the last arguments received
                    lastArgs = null; // Reset lastArgs
                }
            }, limit);
        }
    };
}
