import React from 'react';
import './APainterComponent.scss';
export default function APainterComponent() {
    return (
        <>
            <a href="https://aframe.io/a-painter/"><div id="apainter-logo" className="hidden"></div><div id="apainter-author" style={{ display: "none" }} className="hidden">authorname</div></a>
            <div id="apainter-ui">
                <div className="progress hide">
                    Saving painting...
                    <div className="bar"></div>
                </div>
                <div className="share hide">
                    <div className="help">
                        <h1>Painting saved!</h1>
                        <p>Your painting was saved in the following URL.<br />You can use it to continue your work later or to share your masterpiece in social media.</p>
                    </div>
                    <div className="form">
                        <input id="apainter-share-url" type="text" value="" readOnly />
                        <div id="copy-toclipboard" data-clipboard-target="#apainter-share-url" className="button copy">COPY URL</div>
                    </div>
                </div>
            </div>
        </>
    )
}