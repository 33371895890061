import React from 'react';
import './SelectInput.scss'; // Import the CSS file for styles

interface SelectInputProps {
    label?: string;
    options: string[];
    value: string;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({ label, options, value, onChange }) => {
    return (
        <div className="select-input-container">
            {label && <label className="select-label">{label}</label>}
            <div className="select-container">
                <select className="select-input" value={value} onChange={onChange}>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default SelectInput;
