import { createClient } from 'pexels';
import { PexelApiResponse, PexelPhoto, PixabayResponse } from './interface';
import { searchImages } from './service';
import { checkIfImageExists } from './useStableDiffusionPipeline';
import { GoogleFont } from './APainterContext';

const PEXEL_KEY = "QIr3RRQyquktbSj75XvnmgnyQEO4v7mD7523MYADjzeqiZpozmVbjO1m";
const clients: any = {
    pexel: null
}
export interface Container {
    height: number;
    width: number;
}

export interface Item {
    height: number;
    width: number;
}

export interface Margin {
    left: number;
    right: number;
    top: number;
    bottom: number;
}

export interface Position {
    x: number;
    y: number;
}

export interface Result {
    positions: Position[];
    containerSize: Container;
}

export function getGoogleFonts(): GoogleFont[] {
    return [{
        name: 'Bad Script',
        fontFamily: `"Bad Script", cursive;`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Bad+Script'
    }, {
        name: 'Geostar',
        fontFamily: `"Geostar", serif;`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Geostar'
    }, {
        name: 'Diplomata',
        fontFamily: `"Diplomata", serif;`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Geostar'
    }, {
        name: 'Paytone One',
        fontFamily: `"Paytone One", sans-serif`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Paytone+One'
    }, {
        name: 'Truculenta',
        fontFamily: `"Truculenta", sans-serif`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Truculenta'
    }, {
        name: 'Madimi One',
        fontFamily: `"Madimi One", sans-serif;`,
        fontWeight: 400,
        fontStyle: 'normal',
        family: 'family=Madimi+One'
    }]
}

export function convertCssToObject(css: string): Record<string, any> {
    // Split the CSS string into lines and filter out empty lines
    const lines = css.split('\n').filter(line => line.trim() !== '');

    // Define the output object structure
    let output = {
        name: '',
        fontFamily: '',
        fontWeight: 0,
        fontStyle: '',
        family: ''
    };

    // Process each line to fill the output object
    lines.forEach(line => {
        // Extract property name and value
        const [property, value] = line.split(':').map(part => part.trim().replace(';', ''));

        switch (property) {
            case 'font-family':
                // Extract the font name and set it as both 'name' and 'fontFamily'
                const fontName = value.split(',')[0].replace(/"/g, '');
                output.name = fontName;
                output.fontFamily = value;
                // Assuming 'Madimi One' is a typo and meant to relate to the 'fontName', adjust accordingly
                output.family = `family=${fontName.replace(/\s+/g, '+')}`;
                break;
            case 'font-weight':
                output.fontWeight = parseInt(value, 10);
                break;
            case 'font-style':
                output.fontStyle = value;
                break;
            // Additional cases for other properties if necessary
        }
    });

    return output;
}

//<link href="https://fonts.googleapis.com/css2?family=Bad+Script&family=Madimi+One&family=Paytone+One&display=swap" rel="stylesheet">


export function generateUniqueId(prefix: string = 'component'): string {
    // Current timestamp
    const timestamp = Date.now();

    // Random number to reduce the chance of collision
    const randomNumber = Math.floor(Math.random() * 10000);

    // Combine prefix, timestamp, and random number
    return `${prefix}-${timestamp}-${randomNumber}`;
}

export function addGoogleFont(family: string) {
    loadGoogleFont(`https://fonts.googleapis.com/css2?${family}&display=swap`);
}

export function loadGoogleFont(url: string): void {
    // Check if a link element with the same href already exists
    const existingLinks = document.querySelectorAll('link');
    const isFontLoaded = Array.from(existingLinks).some(link => link.href === url);

    // If the font is not already loaded, add it to the head
    if (!isFontLoaded) {
        const linkElement = document.createElement('link');
        linkElement.href = url;
        linkElement.rel = 'stylesheet';
        document.head.appendChild(linkElement);
        console.log(`Google font loaded: ${url}`);
    } else {
        console.log(`Google font already loaded: ${url}`);
    }
}

export function drawTextOnCanvas(
    canvas: HTMLCanvasElement,
    text: string,
    style: {
        fontFamily: string,
        fontWeight: string,
        fontStyle: string,
        fontSize: string,
        color: string,
        outlineColor?: string, // Optional outline color
        outlineWidth?: number  // Optional outline width
    },
    position: { x: number, y: number, rotation: number } // rotation in radians
): void {
    if (!canvas) {
        console.error('Canvas not found');
        return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
        console.error('Unable to get canvas context');
        return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.font = `${style.fontStyle} ${style.fontWeight} ${style.fontSize} '${style.fontFamily}', sans-serif`;

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = style.color;

    const lines = text.split('\\n');
    const lineHeight = parseInt(style.fontSize) * 1.2; // Adjust line height as needed

    ctx.translate(position.x, position.y);
    ctx.rotate(position.rotation);

    for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const yOffset = (i - (lines.length - 1) / 2) * lineHeight;

        if (style.outlineColor && style.outlineWidth) {
            ctx.strokeStyle = style.outlineColor;
            ctx.lineWidth = style.outlineWidth;
            ctx.strokeText(line, 0, yOffset);
        }

        ctx.fillText(line, 0, yOffset);
    }

    ctx.setTransform(1, 0, 0, 1, 0, 0);
}



// // Usage example
// drawTextOnCanvas('myCanvas', 'Hello, world!',
//     { fontFamily: 'Paytone One', fontWeight: '400', fontStyle: 'normal' },
//     { x: 10, y: 20 });


export async function fetchImagesFromPexel(query: string, bearerToken: string): Promise<PexelPhoto[]> {
    clients.pexel = clients.pexel || createClient(PEXEL_KEY);
    if (window.location.href.indexOf('localhost') === -1) {
        let results = await searchImages({
            bearerToken,
            params: { pexel: { query } }
        })
        let images = results.data
        return images;
    }
    return clients.pexel.photos.search({
        query, per_page: 50
    }).then((photos: PexelApiResponse) => {
        return photos.photos;
    });
}
export async function fetchImagesFromPixabay(query: string, bearerToken: string): Promise<PixabayResponse> {
    let results: any = await searchImages({
        bearerToken,
        params: { pixabay: { query } }
    })
    let images = results.data
    return images;
}

export async function fetchImagesFromGoogle(query: string, bearerToken: string): Promise<{ url: string, title: string }[]> {
    let results: any = await searchImages({
        bearerToken,
        params: { google: { query } }
    })
    let imageResults = [];
    results.data.map((temp) => {
        temp.items.map(item => {
            imageResults.push({
                url: item.link,
                title: item.link,
            })
        })
    })

    return Promise.resolve().then(async () => {
        return Promise.all(imageResults.map(async (item) => {
            let res = await checkIfImageExists(item.url)
            if (res) {
                return item;
            }
        })).then(res => {
            return res.filter(x => x);
        })
    });
}

/**
 * Maps a number from one range to another.
 * 
 * @param value The number to map.
 * @param fromLow The lower bound of the value's current range.
 * @param fromHigh The upper bound of the value's current range.
 * @param toLow The lower bound of the value's target range.
 * @param toHigh The upper bound of the value's target range.
 * @returns The number mapped to the new range.
 */
export function mapRange(
    value: number,
    toLow: number,
    toHigh: number,
    fromLow: number = 0,
    fromHigh: number = 1,
): number {
    // First, find the ratio of the position of 'value' relative to its current range
    const ratio = (value - fromLow) / (fromHigh - fromLow);
    // Then, scale this ratio to the target range
    const mappedValue = ratio * (toHigh - toLow) + toLow;
    return mappedValue;
}



export function createAndApplyAttributes(elementType, attributesStr) {
    // Create the element
    const element = document.createElement(elementType);

    // Split the attributes string by spaces, considering quotes to correctly handle spaces within attribute values
    const attributes = attributesStr.match(/([^\s="']+="[^"]*"|[^ ]+)/g);

    attributes.forEach(attr => {
        // Split each attribute by the first occurrence of "=" to separate the key and value
        let [key, value] = attr.split(/=(.+)/);

        // Remove quotes from around the value, if present
        value = value.replace(/^['"]|['"]$/g, '');

        // Special handling for JSON-like syntax, convert it to valid JSON by replacing single quotes with double quotes
        if (value.startsWith("{") && value.endsWith("}")) {
            value = value.replace(/'/g, '"');
        }

        // Try to parse JSON values (for objects or arrays), otherwise keep the value as is
        try {
            value = JSON.parse(value);
        } catch (e) {
            // Value is not JSON, do nothing
        }

        // Set the attribute on the element
        element.setAttribute(key, value);
    });

    return element;
}

export function createElement(elementType, attributesDic: { [str: string]: any }) {
    let element = null;
    return function (moreAttr?: { [str: string]: any }) {
        // Create the element
        element = element || document.createElement(elementType);

        // Split the attributes string by spaces, considering quotes to correctly handle spaces within attribute values

        Object.entries(attributesDic).forEach(attr => {
            // Split each attribute by the first occurrence of "=" to separate the key and value
            let [key, value] = attr;

            // Set the attribute on the element
            element.setAttribute(key, value);
        });
        if (moreAttr) {
            Object.entries(moreAttr).forEach(attr => {
                // Split each attribute by the first occurrence of "=" to separate the key and value
                let [key, value] = attr;

                // Set the attribute on the element
                element.setAttribute(key, value);
            })
        }
        return element;
    }
}

export class Element implements Item {
    width: number;
    height: number;
    element: any;
    children: Element[];
    alignment: Alignment;
    direction: Direction;
    onRender: any;
    margin: Margin
    constructor({
        element,
        width,
        height,
        margin,
        alignment = 'flexStart',
        direction = 'horizontal',
        onRender
    }: {
        element: any,
        width: number,
        height: number,
        margin?: Margin,
        alignment?: Alignment,
        direction?: Direction,
        onRender?: any
    }) {
        this.element = element;
        this.alignment = alignment;
        this.direction = direction;
        this.width = width;
        this.height = height;
        this.children = [];
        this.onRender = onRender;
        this.margin = {
            bottom: 0,
            left: 0,
            right: 0,
            top: 0,
            ...(margin || {})
        }
    }
    appendChild(...elements: Element[]) {
        this.children.push(...elements);
    }
    render(depth = 0) {
        let me = this;
        if (!this?.children?.length) {
            if (this.onRender) {
                this.onRender(this.element({}))
            }

            return {
                containerSize: {
                    width: this.width,
                    height: this.height
                },
                positions: [],
                element: this
            }
        }
        let items = this.children.map((element) => {
            return element.render(depth + 1);
        });
        const {
            containerSize,
            positions
        } = calculatePositionsAndContainerSize(items.map(t => t.containerSize), this.alignment, this.direction, this.margin);
        let els = this.children.map((element, index) => {
            let pos = positions[index];
            return position(element, `${pos.x} ${pos.y} ${.02}`)
        })

        if (this.children.length) {
            this.width = containerSize.width;
            this.height = containerSize.height;
            let myEl = me.element({ width: me.width, height: me.height });
            els.forEach(el => {
                myEl.appendChild(el);
            });
        }
        if (this.onRender) {
            this.onRender(this.element({}))
        }

        return {
            containerSize,
            positions,
            element: this
        }
    }
}
export function position(element: Element, position: string) {
    let container = createElement('a-entity', { position: `${position}` });
    let el = new Element({
        element: container,
        height: element.height,
        width: element.width
    });
    let temp = el.element();
    temp.appendChild(element.element());
    return temp;
}
function defaultMargin() {
    return {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
    }
}
export function createContainer(type = 'a-entity', attributes, margin?: Margin) {
    let element = createElement(type, attributes);
    return new Element({
        element,
        height: 0,
        width: 0,
        margin: { ...(defaultMargin()), ...(margin || {}) }
    });
}
export function createComponent(type = 'a-entity',
    attributes,
    height,
    width,
    margin?: Margin,
    onRender?: (el: any) => void) {
    let element = createElement(type, attributes);
    return new Element({
        element,
        height,
        width,
        margin: { ...(defaultMargin()), ...(margin || {}) },
        onRender
    });
}

export function createBaseInteractive({
    value,
    height = .4,
    width = .8,
    type = "button",
    fontSize = .17
}) {

    let element = createElement('frame-base-interactive', {
        width,
        height,
        value,
        ['interactive-type']: type,
        ['font-size']: fontSize
    })
    return new Element({
        element,
        height,
        width
    });
}
export function createBaseIconInteractive({
    value = '',
    height = .4,
    width = .8,
    icon = 'f103',
    fontSize = .17,
    type = 'icon-button',
}) {
    let element = createElement('frame-base-interactive', {
        width,
        height,
        value: value || icon,
        ['interactive-type']: type,
        ['font-size']: fontSize,
        margin: "0 0 0.05 0"
    })
    return new Element({
        element,
        height,
        width
    });
    //  return createAndApplyAttributes('frame-base-interactive', `width="${width || .4}" height="${height || 0.4}" interactive-type="icon-button" value="${icon}" margin="0 0 0.05 0" font-size=".17"`)
}

export function onClick(el: any, func: any) {
    let handler = () => {
        if (func) {
            func();
        }
    };
    el.addEventListener('click', handler)
    return handler;
}
export function createMenuItem({ type, width = 1, text, onRender }: {
    width?: number,
    text: string,
    type?: string,
    onRender?: (el: any) => void
}) {
    let button = createElement("frame-base-interactive",
        {
            width: width,
            ['interactive-type']: type || 'button',
            height: .2,
            value: text,
            ['font-size']: .07
        }
        //`width="1" interactive-type="button" height="0.2" value="${text}" margin="0 0 0.05 0" font-size=".07"`
    );
    return new Element({
        element: button,
        width: 1,
        height: .2,
        onRender
    });
}
export function createInteractiveButton({
    interactiveType,
    width,
    text,
    onRender
}: { interactiveType?: string, width?: any, text: string, onRender?: (el: any) => void }) {
    let button = createElement("frame-base-interactive",
        {
            width: width || '1',
            ['interactive-type']: interactiveType || 'button',
            height: .2,
            value: text,
            ['font-size']: .07
        }
        //`width="1" interactive-type="button" height="0.2" value="${text}" margin="0 0 0.05 0" font-size=".07"`
    );
    return new Element({
        element: button,
        width: width || 1,
        height: .2,
        onRender
    });
}

export function createMenu({
    text = 'Menu',
    height = .2,
    width = 1,
    forwardStep = .01,
    children = []
}) {
    let menu = createElement('frame-menu-container',
        {
            [`menu-direction`]: "up",
            [`flex-direction`]: "column",
            [`justify-content`]: "center",
            [`align-items`]: "center",
            [`component-padding`]: "0.01",
            ['forward-step']: forwardStep,
            width,
            [`text-value`]: text,
            [`menu-item-height`]: height,
            [`menu-item-width`]: width
        }
    )({});
    menu.addEventListener('open-changed', (evt) => {
        const { detail } = evt;
        menu.setAttribute('open', !detail.open);
    })
    menu.addEventListener('close-menu', (evt) => {
        const { detail } = evt;
        menu.setAttribute('open', false);
    })
    children.map((c) => {
        menu.appendChild(c.element({}));
        if (c.onRender) {
            c.onRender(c.element({}));
        }
    })

    return new Element({
        element: function () { return menu },
        height,
        width
    });
}
export type Alignment = 'flexStart' | 'flexEnd' | 'center';
export type Direction = 'vertical' | 'horizontal';

export function calculatePositionsAndContainerSize(
    items: Item[],
    alignment: Alignment,
    direction: Direction,
    margin: Margin
): Result {
    let maxContainerWidth = 0;
    let maxContainerHeight = 0;
    let currentPosition = 0;

    // First Pass: Calculate total container size
    items.forEach((item, index) => {
        if (direction === 'vertical') {
            const effectiveWidth = item.width + margin.left + margin.right;
            maxContainerWidth = Math.max(maxContainerWidth, effectiveWidth);
            currentPosition += item.height + (index === 0 ? margin.top : 0) + margin.bottom;
        } else { // 'horizontal'
            const effectiveHeight = item.height + margin.top + margin.bottom;
            maxContainerHeight = Math.max(maxContainerHeight, effectiveHeight);
            currentPosition += item.width + (index === 0 ? margin.left : 0) + margin.right;
        }
    });

    if (direction === 'vertical') {
        maxContainerHeight = currentPosition + margin.bottom; // Include bottom margin at the end
    } else {
        maxContainerWidth = currentPosition + margin.right; // Include right margin at the end
    }

    let positions: Position[] = [];
    currentPosition = 0;

    // Second Pass: Adjust positions based on the total calculated container size
    items.forEach((item, index) => {
        let x: number;
        let y: number;

        if (direction === 'vertical') {
            switch (alignment) {
                case 'flexStart':
                    x = margin.left + item.width / 2;
                    break;
                case 'flexEnd':
                    x = maxContainerWidth - margin.right - item.width / 2;
                    break;
                case 'center':
                    x = maxContainerWidth / 2;
                    break;
            }
            y = currentPosition + margin.top + item.height / 2;
            currentPosition += item.height + (index === 0 ? margin.top : 0) + margin.bottom;
        } else {
            switch (alignment) {
                case 'flexStart':
                    y = margin.top + item.height / 2;
                    break;
                case 'flexEnd':
                    y = maxContainerHeight - margin.bottom - item.height / 2;
                    break;
                case 'center':
                    y = maxContainerHeight / 2;
                    break;
            }
            x = currentPosition + margin.left + item.width / 2;
            currentPosition += item.width + (index === 0 ? margin.left : 0) + margin.right;
        }

        positions.push({ x: x - (maxContainerWidth / 2), y: y - (maxContainerHeight / 2) });
    });

    return {
        positions: positions,
        containerSize: {
            width: maxContainerWidth,
            height: maxContainerHeight
        }
    };
}
export function createText(text, { color, fontFamily }) {
    var textEntity = document.createElement("a-entity");
    textEntity.setAttribute('troika-text', `value: ${text}; 
                                        align:left; 
                                        anchor:left; 
                                        baseline:center;
                                        letterSpacing:0;
                                        color:${color};
                                        font:${fontFamily || "assets/fonts/Plaster-Regular.ttf"};
                                        fontSize:${'14px'};
                                        depthOffset:1;
                                        maxWidth:611;
                                        `);
    textEntity.setAttribute('position', `0 0 0.07`);
    textEntity.setAttribute('scale', `0.005 0.005 0.005`);
    return textEntity;
}

export function calculatePositionsAndContainerSize2(
    items: Item[],
    alignment: Alignment,
    direction: Direction,
    margin: Margin
): Result {
    let positions: Position[] = [];
    let maxContainerWidth = 0;
    let maxContainerHeight = 0;
    let currentPosition = 0;

    items.forEach((item, index) => {
        let x: number;
        let y: number;

        if (direction === 'vertical') {
            const effectiveWidth = item.width + margin.left + margin.right;
            switch (alignment) {
                case 'flexStart':
                    x = margin.left + item.width / 2;
                    break;
                case 'flexEnd':
                    x = effectiveWidth - margin.right - item.width / 2;
                    break;
                case 'center':
                    x = effectiveWidth / 2;
                    break;
            }
            y = currentPosition + margin.top + item.height / 2;
            currentPosition += item.height + (index === 0 ? margin.top : 0) + margin.bottom;
            maxContainerWidth = Math.max(maxContainerWidth, effectiveWidth);
            maxContainerHeight = currentPosition;
        } else { // 'horizontal'
            const effectiveHeight = item.height + margin.top + margin.bottom;
            switch (alignment) {
                case 'flexStart':
                    y = margin.top + item.height / 2;
                    break;
                case 'flexEnd':
                    y = effectiveHeight - margin.bottom - item.height / 2;
                    break;
                case 'center':
                    y = effectiveHeight / 2;
                    break;
            }
            x = currentPosition + margin.left + item.width / 2;
            currentPosition += item.width + (index === 0 ? margin.left : 0) + margin.right;
            maxContainerHeight = Math.max(maxContainerHeight, effectiveHeight);
            maxContainerWidth = currentPosition;
        }

        positions.push({ x, y });
    });

    // Adjust final container size to include the margin on the far side
    if (direction === 'vertical') {
        maxContainerHeight += margin.bottom; // Add bottom margin only once at the end
    } else { // 'horizontal'
        maxContainerWidth += margin.right; // Add right margin only once at the end
    }

    return {
        positions: positions,
        containerSize: {
            width: maxContainerWidth,
            height: maxContainerHeight
        }
    };
}

export const containerStlye: any = {
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    maxWidth: 400,
    zIndex: 1000, // Ensure it's above other content
}
export const btnstyle: any = {
    display: 'inline-block',
    padding: '10px 15px',
    backgroundColor: '#007bff', // Bootstrap primary button color
    color: 'white',
    textAlign: 'center',
    textDecoration: 'none',
    border: '1px solid transparent',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
};

export function rgbToHex(color: { r: number; g: number; b: number }): string {
    // Helper function to convert a single component
    const toHex = (component: number): string => {
        // Scale the 0-1 range to 0-255, round it to get a whole number
        const value = Math.round(component * 255);
        // Convert to hexadecimal and pad with zero if needed
        return value.toString(16).padStart(2, '0');
    };

    // Convert each component using the helper function
    const rHex = toHex(color.r);
    const gHex = toHex(color.g);
    const bHex = toHex(color.b);

    // Concatenate results with a '#' prefix to form the full color code
    return `#${rHex}${gHex}${bHex}`;
}

export function makeUnique<T>(items: T[]): T[] {
    return Array.from(new Set(items));
}
