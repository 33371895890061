import React from 'react';
import './CheckboxInput.scss'; // Import the CSS file for styles

interface CheckboxInputProps {
    label?: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({ label, checked, onChange }) => {
    return (
        <label className="checkbox-container">
            {label}
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span className="checkmark"></span>
        </label>
    );
};

export default CheckboxInput;
