import { useState } from 'react';
import React from 'react';
import './App.css';
import AFrameComponent from './AFrameComponent';
import { APainterProvider } from './APainterProvider';
import APainterComponent from './APainterComponent';
import AEnterVRComponent from './AEnterVRComponent';
import { uuidv4 } from './painter/util';
import PromptInput from './PromptInput';
import APainterAuth from './APainterAuth';
function App() {
  let stylezz = {
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000, // Ensure it's above other content
  };
  return (
    <APainterProvider>
      <div style={{
        backgroundColor: '#ffffff55',
        borderRadius: 10,
        position: 'absolute',
        top: 10,
        left: 10,
        zIndex: 10000,
        padding: 10,
        maxHeight: `calc(100vh - 60px)`,
        overflow: 'auto',
        maxWidth: '100vw',
        ...stylezz
      }}>
        <PromptInput />
      </div>
      <AFrameComponent />
      <APainterComponent />
      <AEnterVRComponent />
    </APainterProvider >
  );
}

export default App;
