import { framemixin, watchAttribute } from 'a-frame-components';
import { CitivaiSetup, CivitaiLora, CivitaiModel } from '../APainterContext';
import { PAINTER_CONSTANTS } from '../painter/constants';
import { customEventListener } from '../painter/systems/grabanddrop';
import civitaiItem from './civitai-item';
import { mapRange } from '../util';
export default function () {
    const AFRAME: any = (window as any).AFRAME || {}
    if (typeof AFRAME === 'undefined') {
        throw new Error('Component attempted to register before AFRAME was available.');
    }


    AFRAME.registerComponent('civitai-lora-item', {
        schema: {
            value: { type: 'string', default: '' },
            options: { type: 'string', default: '' },
        },

        init: function () {
            let me = this;
            var el = this.el;
            customEventListener(PAINTER_CONSTANTS.PROMPTS_UPDATED, () => {
                if (me.promptListComponent) {
                    let {
                        config
                    }: {
                        id: any;
                        config: CitivaiSetup
                    } = me.civitOptions;  // Component data

                    me.promptListComponent.setAttribute('prompts', JSON.stringify(config.prompts || []));
                }
            });
            watchAttribute(el, {
                'options': (name, value, el) => {
                    me.civitOptions = value;
                    me.render();
                }
            })
        },
        ...framemixin,
        getWidth: function () {
            return parseFloat(`${this?.container?.getAttribute('width') || 0}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.container?.getAttribute('item-height') || 0}`);
        },
        render: function () {
            let el = this.el;
            let me = this;
            try {
                let {
                    id,
                    config
                }: {
                    id: any;
                    config: CitivaiSetup
                } = this.civitOptions;  // Component data

                if (config) {
                    const container = me.container || document.createElement('frame-container');
                    container.setAttribute('direction', 'horizontal');
                    container.setAttribute('alignment', 'flexStart');
                    container.setAttribute('margin', '.05 .05 .05 .05');
                    // a-prompt-list-component
                    me.container = container;
                    if (!me.configMenu) {
                        let configMenu = document.createElement('frame-drop-down')
                        configMenu.setAttribute('value', 'Menu');

                        configMenu.addEventListener('change', (evt: any) => {
                            if (evt.detail.value === 'add-lora') {
                                me.el.emit('add-lora', { id: config.id });
                            }
                            else if (evt.detail.value === 'set-model') {
                                me.el.emit('set-model', { id: config.id });
                            }
                            else if (evt.detail.value === 'remove-setup') {
                                me.el.emit('remove-setup', { id: config.id });
                            }
                            else {
                                me.el.emit(evt.detail.value, { id: config.id });
                            }
                        })
                        configMenu.setAttribute('options', JSON.stringify([
                            {
                                id: config.id,
                                name: 'Add Lora',
                                value: 'add-lora'
                            }, {
                                id: config.id,
                                name: 'Add Prompt',
                                value: 'add-prompt'
                            }, {
                                id: config.id,
                                name: 'Add Negative Prompt',
                                value: 'add-negative-prompt'
                            }, {
                                id: config.id,
                                name: 'Remove Setup',
                                value: 'remove-setup'
                            }
                        ]))
                        me.configMenu = configMenu;
                        me.enabledCheckbox = document.createElement('frame-checkbox');
                        me.enabledCheckbox.setAttribute('value', config.enabled);
                        me.enabledCheckbox.setAttribute('label', 'Enabled');
                        me.enabledCheckbox.addEventListener('click', () => {
                            me.el.emit('config-enabled', {
                                value: !config.enabled,
                                id: config.id
                            })
                        });
                        const stepsSlider = me.stepsSlider || document.createElement('frame-slider');
                        stepsSlider.setAttribute('orientation', 'horizontal')
                        stepsSlider.setAttribute('percent', `${config.steps ? config.steps / 10 : .7}`);
                        stepsSlider.setAttribute('bar-thickness', `.1`);
                        stepsSlider.setAttribute('bar-length', '1');
                        stepsSlider.setAttribute('height', '.3');
                        stepsSlider.setAttribute('min', '1');
                        stepsSlider.setAttribute('max', '10');
                        stepsSlider.setAttribute('step', '1');
                        stepsSlider.setAttribute('targetbarsize', '.3');
                        stepsSlider.setAttribute('title', 'Steps');
                        stepsSlider.setAttribute('title-scale', '.3 .3');
                        stepsSlider.setAttribute('title-position', '-.5 0 0');
                        if (!me.stepsSlider) {
                            stepsSlider.addEventListener('change', (evt: any) => {
                                me.el.emit('steps-change', {
                                    value: Math.round(mapRange(evt.detail.value, 1, 10)),
                                    id: config.id
                                })
                            });
                            me.stepsSlider = stepsSlider;
                        }

                        const denoise = me.denoiseSlider || document.createElement('frame-slider');
                        denoise.setAttribute('orientation', 'horizontal')
                        denoise.setAttribute('percent', `${config.denoise || .7}`);
                        denoise.setAttribute('bar-thickness', `.1`);
                        denoise.setAttribute('bar-length', '1');
                        denoise.setAttribute('height', '.3');
                        denoise.setAttribute('targetbarsize', '.3');
                        denoise.setAttribute('title', 'Denoise');
                        denoise.setAttribute('title-scale', '.3 .3');
                        denoise.setAttribute('title-position', '-.5 0 0');
                        if (!me.denoiseSlider) {
                            denoise.addEventListener('change', (evt: any) => {
                                me.el.emit('denoise-change', {
                                    value: evt.detail.value,
                                    id: config.id
                                })
                            });
                            me.denoiseSlider = denoise;
                        }


                        // <frame-checkbox {...allLayerCheckbox} label='All Layers' value={false} />
                        let subcontainer = document.createElement('frame-container');
                        subcontainer.setAttribute('direction', 'vertical');
                        subcontainer.setAttribute('margin', '.05 .05 .05 .05');
                        subcontainer.appendChild(configMenu);
                        subcontainer.appendChild(me.enabledCheckbox);
                        subcontainer.appendChild(stepsSlider);
                        subcontainer.appendChild(me.denoiseSlider);
                        container.appendChild(subcontainer);
                    }
                    else {
                        me.enabledCheckbox.setAttribute('value', !!config.enabled);
                    }


                    let modelComponent = me.createModelComponent(config);
                    if (modelComponent) {
                        container.appendChild(modelComponent);
                    }
                    config.loras.forEach((font) => {
                        me.renderLora(font, container, config)
                    });

                    let promptListComponent = document.createElement('a-prompt-list-component');
                    if (!me.promptListComponent) {
                        me.promptListComponent = promptListComponent;
                        me.promptListComponent.addEventListener(PAINTER_CONSTANTS.REMOVE_PROMPT, function (evt) {
                            evt.stopPropagation();
                            me.el.emit('remove-setup-prompt', {
                                promptId: evt.detail.id,
                                id: config.id
                            });
                        })
                        container.appendChild(promptListComponent)
                    }
                    promptListComponent.setAttribute('prompts', JSON.stringify(config.prompts || []));
                    let offsetContainer = document.createElement('a-entity');
                    offsetContainer.appendChild(container);
                    el.appendChild(offsetContainer);
                    me.offsetContainer = offsetContainer;
                    container.addEventListener('size-update', () => {
                        let width = me.getWidth();
                        offsetContainer.setAttribute('position', `${width / 2 - .5} 0 0`);
                    })

                    me.updateElementSize(me, me.el);
                }
            } catch (e) {
                console.error(e);
            }
        },
        showTrainedWords: function () {
            let me = this;
            if (!me.trainedWordsContainer) {
                me.trainedWordsContainer = document.createElement('a-entity');
                me.trainedWordsContainer.setAttribute('position', '0 0 -.1');

                let subcontainer = document.createElement('frame-container');
                subcontainer.setAttribute('direction', 'vertical');
                subcontainer.setAttribute('margin', '.05 .05 .05 .05');
                let {
                    id,
                    config
                }: { id: any; config: CitivaiSetup } = this.civitOptions;  // Component data

                me.trainedWordsContainer.appendChild(subcontainer);
                me.offsetContainer.appendChild(me.trainedWordsContainer);
            }
        },
        renderLora: function (font: CivitaiLora, container: any, config: CitivaiSetup) {
            let me = this;
            me.loraComponents = me.loraComponents || {};
            me.loraComponents[font.id] = me.loraComponents[font.id] || {};
            const menuItem = me.loraComponents[font.id]?.menuItem || document.createElement('frame-ar-image'); // Assuming 'frame-base-interactive' is a custom component or styled div
            menuItem.setAttribute('value', font.url);
            menuItem.setAttribute('url', font.url || 'https://cdn.midjourney.com/7acac49c-096e-41db-9ddb-9e592e0dd31b/0_2.webp');
            menuItem.setAttribute('maxwidth', '.5');
            menuItem.setAttribute('maxheight', '.5');

            const menuItemContainer = me.loraComponents[font.id]?.menuItemContainer || document.createElement('frame-container');
            menuItemContainer.setAttribute('direction', 'vertical');
            menuItemContainer.setAttribute('alignment', 'center');
            menuItemContainer.setAttribute('margin', '.05 .05 .05 .05');

            const text = me.loraComponents[font.id]?.text || document.createElement('frame-troika-text');
            text.setAttribute('value', 'Lora');
            text.setAttribute('font-size', '.1')
            if (!me.loraComponents[font.id]?.text) {
                menuItemContainer.appendChild(text);
                me.loraComponents[font.id].text = text;
            }

            let menuItemMenu = me.loraComponents[font.id].menuItemContainer?.menuItemMenu || document.createElement('frame-drop-down')
            menuItemMenu.setAttribute('value', font.name);
            if (!me.loraComponents[font.id]?.menuItemMenu) {
                menuItemMenu.addEventListener('change', (evt: any) => {
                    me.el.emit('lora-action', {
                        id: config.id,
                        loraId: font.id,
                        value: evt.detail.value
                    });
                })
            }
            menuItemMenu.setAttribute('options', JSON.stringify([
                {
                    id: config.id,
                    name: 'Set Lora',
                    value: 'set_lora'
                },
                {
                    id: config.id,
                    name: 'Remove',
                    value: 'remove_lora'
                }, font?.trainedWords?.length ? {
                    id: config.id,
                    name: 'Trained Words',
                    value: 'trained-words'
                } : null
            ].filter(x => x)))

            if (!me.loraComponents[font.id].menuItem) {
                menuItemContainer.appendChild(menuItem);
                me.loraComponents[font.id].menuItem = menuItem;
            }
            if (font.url) {
                const slider = me.loraComponents[font.id].slider || document.createElement('frame-slider');
                slider.setAttribute('orientation', 'horizontal')
                slider.setAttribute('percent', `${font.strength || 0}`);
                slider.setAttribute('bar-thickness', `.1`);
                slider.setAttribute('bar-length', '1');
                slider.setAttribute('height', '.3');
                slider.setAttribute('targetbarsize', '.3');
                slider.setAttribute('title', 'Strength');
                slider.setAttribute('title-scale', '.3 .3');
                slider.setAttribute('title-position', '-.5 0 0');
                if (!me.loraComponents[font.id].slider) {
                    slider.addEventListener('change', (evt: any) => {
                        font.strength = evt.detail.value
                        me.el.emit('lora-strength-change', {
                            value: evt.detail.value,
                            loraId: font.id,
                            setupId: config.id
                        })
                    })
                }
                const clip = me.loraComponents[font.id].clip || document.createElement('frame-slider');
                clip.setAttribute('orientation', 'horizontal')
                clip.setAttribute('percent', `${font.clip || 0}`);
                clip.setAttribute('bar-thickness', `.1`);
                clip.setAttribute('bar-length', '1');
                clip.setAttribute('height', '.3');
                clip.setAttribute('targetbarsize', '.3');
                clip.setAttribute('title', 'Clip');
                clip.setAttribute('title-scale', '.3 .3');
                clip.setAttribute('title-position', '-.5 0 0');
                if (!me.loraComponents[font.id].clip) {
                    clip.addEventListener('change', (evt: any) => {
                        font.clip = evt.detail.value
                        me.el.emit('lora-clip-change', {
                            value: evt.detail.value,
                            loraId: font.id,
                            setupId: config.id
                        })
                    })
                }
                menuItemContainer.appendChild(slider);
                menuItemContainer.appendChild(clip);
                me.loraComponents[font.id].slider = slider
                me.loraComponents[font.id].clip = clip
            }
            if (!me.loraComponents[font.id].menuItemMenu) {
                menuItemContainer.appendChild(menuItemMenu);
                me.loraComponents[font.id].menuItemMenu = menuItemMenu
            }
            if (!me.loraComponents[font.id].menuItemContainer) {
                me.loraComponents[font.id].menuItemContainer = menuItemContainer;
                container.appendChild(menuItemContainer);
            }
        },
        createModelComponent: function (config: CitivaiSetup) {
            let me = this;
            let returnit = false;
            const { model } = config;
            const menuItem = me?.modelComponent?.menuItem || document.createElement('frame-ar-image'); // Assuming 'frame-base-interactive' is a custom component or styled div

            menuItem.setAttribute('value', model?.url);
            menuItem.setAttribute('url', model?.url || 'https://cdn.midjourney.com/7acac49c-096e-41db-9ddb-9e592e0dd31b/0_2.webp');
            menuItem.setAttribute('maxwidth', '.5');
            menuItem.setAttribute('maxheight', '.5');

            const menuItemContainer = me?.modelComponent?.menuItemContainer || document.createElement('frame-container');
            menuItemContainer.setAttribute('direction', 'vertical');
            menuItemContainer.setAttribute('alignment', 'center');
            menuItemContainer.setAttribute('margin', '.05 .05 .05 .05');

            const text = me?.modelComponent?.text || document.createElement('frame-troika-text');
            if (!me?.modelComponent?.text) {
                text.setAttribute('value', 'Model');
                text.setAttribute('font-size', '.1')
                menuItemContainer.appendChild(text);
            }


            let menuItemMenu = me?.modelComponent?.menuItemMenu || document.createElement('frame-drop-down')
            menuItemMenu.setAttribute('value', model?.name || '');
            if (!me?.modelComponent?.menuItemMenu) {
                menuItemMenu.addEventListener('change', (evt: any) => {
                    me.el.emit('model-action', {
                        id: config.id,
                        value: evt.detail.value
                    });
                })
            }
            menuItemMenu.setAttribute('options', JSON.stringify([
                {
                    id: config.id,
                    name: 'Set Model',
                    value: 'set_model'
                }
            ]))
            if (!me?.modelComponent?.menuItem) {
                menuItemContainer.appendChild(menuItem);
            }
            if (!me?.modelComponent?.menuItemMenu) {
                menuItemContainer.appendChild(menuItemMenu);
            }
            if (!me.modelComponent) {
                returnit = true;
                me.modelComponent = {
                    menuItemContainer,
                    menuItem,
                    text,
                    menuItemMenu
                };
            }
            if (model?.url) {
                if (!me?.modelComponent?.slider) {
                    const slider = me?.modelComponent?.slider || document.createElement('frame-slider');
                    slider.setAttribute('percent', `${model?.strength || 0}`);
                    slider.setAttribute('orientation', 'horizontal')
                    slider.setAttribute('bar-thickness', `.1`);
                    slider.setAttribute('bar-length', '1');
                    slider.setAttribute('height', '.3');
                    slider.setAttribute('targetbarsize', '.3');
                    slider.setAttribute('title', 'Strength');
                    slider.setAttribute('title-scale', '.3 .3');
                    slider.setAttribute('title-position', '-.5 0 0');
                    slider.addEventListener('change', (evt: any) => {
                        model.strength = evt.detail.value
                        me.el.emit('model-strength-change', {
                            value: evt.detail.value,
                            setupId: config.id
                        })
                    })
                    const clip = me?.modelComponent?.clip || document.createElement('frame-slider');
                    clip.setAttribute('percent', `${model?.clip || 0}`);
                    clip.setAttribute('orientation', 'horizontal')
                    clip.setAttribute('bar-thickness', `.1`);
                    clip.setAttribute('bar-length', '1');
                    clip.setAttribute('height', '.3');
                    clip.setAttribute('targetbarsize', '.3');
                    clip.setAttribute('title', 'Clip');
                    clip.setAttribute('title-scale', '.3 .3');
                    clip.setAttribute('title-position', '-.5 0 0');
                    clip.addEventListener('change', (evt: any) => {
                        model.clip = evt.detail.value
                        me.el.emit('model-clip-change', {
                            value: evt.detail.value,
                            setupId: config.id
                        })
                    })
                    menuItemContainer.appendChild(slider);
                    menuItemContainer.appendChild(clip);
                    me.modelComponent.slider = slider;
                    me.modelComponent.clip = clip;
                }
            }
            if (returnit) {
                return menuItemContainer;
            }
            return null;
        },
        /**
         * Called when component is attached and when component data changes.
         * Generally modifies the entity based on the data.
         */
        update: function (oldData) {
            if (oldData.options !== this.data.options) {
                // this.civitOptions = JSON.parse(this.data.options);
                // this.render();
            }

        },

        remove: function () { },

        pause: function () { },

        play: function () { }
    });

    AFRAME.registerPrimitive('a-civitai-lora-item', {
        defaultComponents: {
            'civitai-lora-item': {}
        },
        mappings: {
            //gui item general
            'options': 'civitai-lora-item.options',
            'value': 'civitai-lora-item.value'
        }
    });
    civitaiItem();
}