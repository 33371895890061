export const SUPPORTED_LORAS = [
    {
        "name": "xl_more_art-full / xl_real / Enhancer",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b8aa7a96-222b-4405-b5fc-de062bf5c8c8/width=450/1949769.jpeg",
        "trainedWords": [],
        "file_name": "xl_more_art-full-beta1.safetensors"
    },
    {
        "name": "【Character / Art Style】Fashion Girl (SDXL UPDATE)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ed6152a8-0c14-457f-8e8f-aab27914983b/width=450/1788050.jpeg",
        "trainedWords": [],
        "file_name": "fashigirl-v6-sdxl-5ep-resize.safetensors"
    },
    {
        "name": "❄️SDXL Frosted❄️",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/67b2a064-9041-4e0c-83b1-225d6566d15c/width=450/4124894.jpeg",
        "trainedWords": [
            "FrostedStyle"
        ],
        "file_name": "SDXLFrosted.safetensors"
    },
    {
        "name": "Detail Tweaker XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/356743dd-df6f-4c7c-b858-506e31b45efb/width=450/1867664.jpeg",
        "trainedWords": [],
        "file_name": "tweak-detail-xl.safetensors"
    },
    {
        "name": "DreamART Style LORA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f2b0c777-f0cb-45f2-a88d-4ec5bd90b8a6/width=450/1943292.jpeg",
        "trainedWords": [],
        "file_name": "DreamARTSDXL.safetensors"
    },
    {
        "name": "Glass Sculptures",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/452155c6-1865-491a-8fa8-40ecda8c1d20/width=450/2837950.jpeg",
        "trainedWords": [
            "translucent",
            "transparent"
        ],
        "file_name": "sdxl_glass.safetensors"
    },
    {
        "name": "Lava Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d046dd11-3430-4b45-ad25-cb2a08383904/width=450/3691174.jpeg",
        "trainedWords": [
            "ral-lava"
        ],
        "file_name": "ral-lava-sdxl.safetensors"
    },
    {
        "name": "Made_of_pieces_broken_glass",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/22926f1c-7ff4-475b-bb8f-bbb5daf3328c/width=450/4377004.jpeg",
        "trainedWords": [
            "shards",
            "glass",
            "brocken glass",
            "transparent glass",
            "pieces of glass",
            "Made_of_pieces_broken_glass"
        ],
        "file_name": "Made_of_pieces_broken_glass-000001.safetensors"
    },
    {
        "name": "Juggernaut Cinematic XL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ea4f8019-749e-486f-adc2-c05454d22a8b/width=450/1828361.jpeg",
        "trainedWords": [
            "Movie Still",
            "Film Still",
            "Cinematic",
            "Cinematic Shot",
            "Cinematic Lighting"
        ],
        "file_name": "JuggernautCineXL.safetensors"
    },
    {
        "name": "Crystals [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cd5889e6-0a7c-4cd3-890e-7e8094c9c51c/width=450/3228916.jpeg",
        "trainedWords": [
            "crystalz"
        ],
        "file_name": "crystalz-sdxl.safetensors"
    },
    {
        "name": "Smol Animals [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d0dc5676-b555-4bb4-90b1-f497ca57fcd2/width=450/3339625.jpeg",
        "trainedWords": [
            "zhibi"
        ],
        "file_name": "zhibi-sdxl.safetensors"
    },
    {
        "name": "SDXL MS Paint Portraits",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3906a552-5dca-4b74-b4d5-7e6bd048a241/width=450/3294816.jpeg",
        "trainedWords": [
            "MSPaint portrait",
            "MSPaint drawing"
        ],
        "file_name": "SDXL_MSPaint_Portrait.safetensors"
    },
    {
        "name": "LeLo - LEGO LoRA for XL & SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cf8ba6aa-ae51-4493-8afe-19b35b6801ba/width=450/2404036.jpeg",
        "trainedWords": [
            "LEGO BrickHeadz",
            "LEGO MiniFig",
            "LEGO Creator"
        ],
        "file_name": "lego_v2.0_XL_32.safetensors"
    },
    {
        "name": "Liquid Flow Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dcbfc109-30c1-47b1-843a-ee161cded26d/width=450/3620711.jpeg",
        "trainedWords": [
            "watce"
        ],
        "file_name": "watce-sdxl.safetensors"
    },
    {
        "name": "ClassipeintXL (oil paint / oil painting style)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2fc839ab-313a-45f3-83b0-130ab7cc76af/width=450/1989641.jpeg",
        "trainedWords": [
            "ClassipeintXL"
        ],
        "file_name": "ClassipeintXL.safetensors"
    },
    {
        "name": "Pixel Art XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1e624f01-e7f4-4cbd-bf84-0393dc441058/width=450/1814892.jpeg",
        "trainedWords": [],
        "file_name": "pixel-art-xl.safetensors"
    },
    {
        "name": "SDXL YAMER'S STYLE 😍 Princeps Omnia LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1583da13-9f18-4c00-9f31-d11f6f486ac1/width=450/2315834.jpeg",
        "trainedWords": [
            "yamer style"
        ],
        "file_name": "XL_Yamer_Style-Alpha.safetensors"
    },
    {
        "name": "InkPunk XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a6975ca9-8ee8-4483-8b34-75a40fc25d5e/width=450/2221657.jpeg",
        "trainedWords": [
            "Inkpunk"
        ],
        "file_name": "InkPunk XL - Alpha.safetensors"
    },
    {
        "name": "Opal Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/af2a8872-1a9e-473f-adb8-c932179132df/width=450/4281726.jpeg",
        "trainedWords": [
            "ral-opal"
        ],
        "file_name": "ral-opal-sdxl.safetensors"
    },
    {
        "name": "Glowing Skull [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2809c445-a536-4570-b326-8ad73156f888/width=450/3185456.jpeg",
        "trainedWords": [
            "epoxy_skull"
        ],
        "file_name": "epoxy_skull-sdxl.safetensors"
    },
    {
        "name": "WizardCoreAI (SDXL + SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f2cf28ca-4739-4178-a83c-e35158f51e6f/width=450/3032272.jpeg",
        "trainedWords": [
            "wizardcoreai"
        ],
        "file_name": "WizardCoreAIp.safetensors"
    },
    {
        "name": "zuowangdao",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b4b0f8f9-4098-4b22-8bad-f6daa216d26e/width=450/2163209.jpeg",
        "trainedWords": [
            "zwd"
        ],
        "file_name": "zwd_v2_SDXL.safetensors"
    },
    {
        "name": "Bismuth Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e65fa0ae-3834-459b-8424-d281e5dde337/width=450/4319263.jpeg",
        "trainedWords": [
            "ral-bismut"
        ],
        "file_name": "ral-bismut-sdxl.safetensors"
    },
    {
        "name": "XL Weapon Dual Pistols - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/491f2e95-71cd-4307-a769-d7ba6e9a8a78/width=450/4986909.jpeg",
        "trainedWords": [
            "dual pistols"
        ],
        "file_name": "XL_Weapon_Dual_Pistols_-_By_HailoKnight.safetensors"
    },
    {
        "name": "XL Weapon Assault Rifle - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/62fa9f88-7b09-4be3-81ca-bda9c01584d6/width=450/5254502.jpeg",
        "trainedWords": [
            "Assault Rifle"
        ],
        "file_name": "XL_Weapon_Assault_Rifle_-_By_HailoKnight.safetensors"
    },
    {
        "name": "AI Overlord Santas - The Green Team LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e6332f92-506f-4595-a8b1-a23ef78efa64/width=450/4227748.jpeg",
        "trainedWords": [],
        "file_name": "AI_Overlord_Santas_-_The_Green_Team_LoRA.safetensors"
    },
    {
        "name": "Envy Ink Swirl XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1327b493-f7cb-4de8-ae27-376c2bb18a5b/width=450/4676570.jpeg",
        "trainedWords": [
            "swirling ink"
        ],
        "file_name": "EnvyInkSwirlXL01.safetensors"
    },
    {
        "name": "Storm Cloud Style SDXL + SD 1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/648e9129-63d8-4e23-b7aa-edc1279a27d0/width=450/4833916.jpeg",
        "trainedWords": [
            "martius_storm"
        ],
        "file_name": "Storm_Cloud_Style_SDXL.safetensors"
    },
    {
        "name": "Semiconductor Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4c97e350-3464-483b-a07e-b7bb83897165/width=450/4583321.jpeg",
        "trainedWords": [
            "ral-semiconductor"
        ],
        "file_name": "ral-semiconductor-sdxl.safetensors"
    },
    {
        "name": "Christmas Critters [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f8216f68-4400-42de-9b7a-97bdab481cbf/width=450/3779835.jpeg",
        "trainedWords": [
            "ral-chrcrts"
        ],
        "file_name": "ral-chrcrts-sdxl.safetensors"
    },
    {
        "name": "Polygon Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3d387584-4a39-4294-8311-826f89c2e3a3/width=450/4625428.jpeg",
        "trainedWords": [
            "ral-polygon"
        ],
        "file_name": "ral-polygon-sdxl.safetensors"
    },
    {
        "name": "Color Swirl Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7a76efd3-fe7a-4311-bd69-5a4ba62e167b/width=450/5133257.jpeg",
        "trainedWords": [
            "ral-colorswirl"
        ],
        "file_name": "ral-colorswirl-sdxl.safetensors"
    },
    {
        "name": "Oil Spill Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dc1ea323-0ada-488c-b212-807d9a6317c1/width=450/4809731.jpeg",
        "trainedWords": [
            "ral-oilspill"
        ],
        "file_name": "ral-oilspill-sdxl.safetensors"
    },
    {
        "name": "Long Exposure Style [SDXL LoRA]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8e6b0b22-b8de-4f92-8c5b-119d34221c78/width=450/3891116.jpeg",
        "trainedWords": [
            "ral-exposure"
        ],
        "file_name": "ral-exposure-sdxl.safetensors"
    },
    {
        "name": "Ikea Instructions - LoRA - SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d93b841d-89db-4a23-ace2-bd86547fba96/width=450/2709980.jpeg",
        "trainedWords": [],
        "file_name": "ikea_instructions_xl_v1_5.safetensors"
    },
    {
        "name": "Kurokawa Akane | Oshi no Ko | (LoRA)  | 1.5 & XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7aa5c41e-c8b6-4574-b69c-8c0f9b4d38b3/width=450/1849653.jpeg",
        "trainedWords": [
            "blue hair",
            "green eyes",
            "medium hair",
            "gradient hair"
        ],
        "file_name": "akane.safetensors"
    },
    {
        "name": "Photorealistic Slider SDXL - LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b539431a-498d-42f9-a143-8c4f0758e472/width=450/1739247.jpeg",
        "trainedWords": [],
        "file_name": "sdxl_photorealistic_slider_v1-0.safetensors"
    },
    {
        "name": "Fire and Ice Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/80b75be2-d5db-49cf-acd9-2f14c5a9e40c/width=450/3645354.jpeg",
        "trainedWords": [
            "faize"
        ],
        "file_name": "faize-sdxl.safetensors"
    },
    {
        "name": "ChaosMarineStyleAI (SDXL + SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/37a55f29-61d3-4e3a-a68c-583465384fc8/width=450/3725414.jpeg",
        "trainedWords": [
            "ChaosMarineStyleAI"
        ],
        "file_name": "ChaosMarineStyleAIp.safetensors"
    },
    {
        "name": "LCM&TurboMix LoRA (Only 12MB, 8-step sampling Effect is superior to using LCM or Turbo alone!)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/335b0e27-e01d-4ac3-9802-37c6942c4c6d/width=450/4028435.jpeg",
        "trainedWords": [],
        "file_name": "LCM&TurboMix.safetensors"
    },
    {
        "name": "Super Cereal - SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8bf43198-0e29-451f-933e-a4d557f55a58/width=450/2879380.jpeg",
        "trainedWords": [],
        "file_name": "cereal_box_sdxl_v1.safetensors"
    },
    {
        "name": "ParchartXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/684fef69-def7-4e1b-997d-777b3a3c00e8/width=450/5162784.jpeg",
        "trainedWords": [
            "parchment"
        ],
        "file_name": "parchment.safetensors"
    },
    {
        "name": "Transparent Glass Body [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e22fe0cb-6622-4461-817d-a316337d80d1/width=450/3527699.jpeg",
        "trainedWords": [
            "tranzp"
        ],
        "file_name": "tranzp-sdxl.safetensors"
    },
    {
        "name": "Acid Slime Bubbles [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/741bc975-cda8-4263-a71e-bc89af17312a/width=450/3204089.jpeg",
        "trainedWords": [
            "acidzlime"
        ],
        "file_name": "acidzlime-sdxl.safetensors"
    },
    {
        "name": "Mythical Creatures [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cdc307ec-29e9-4cad-a439-5c561ec7fda6/width=450/4088352.jpeg",
        "trainedWords": [
            "ral-mythcr"
        ],
        "file_name": "ral-mythcr-sdxl.safetensors"
    },
    {
        "name": "Fractal Vines",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/78d429a1-d399-41cd-b9ff-834be51dd78e/width=450/4883733.jpeg",
        "trainedWords": [
            "fractalvines",
            "made from fractal vines",
            "covered in fractal vines"
        ],
        "file_name": "Fractal_Vines.safetensors"
    },
    {
        "name": "Crayon Style - SDXL & SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/df443ce0-c1fd-481e-8a4e-0f916ca18f99/width=450/1833180.jpeg",
        "trainedWords": [],
        "file_name": "crayons_v1_sdxl.safetensors"
    },
    {
        "name": "Amber Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8d00aaad-bc7b-4061-a97f-2277d53cc2e0/width=450/4820881.jpeg",
        "trainedWords": [
            "ral-amber"
        ],
        "file_name": "ral-amber-sdxl.safetensors"
    },
    {
        "name": "Wood Figure Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f7092304-f4c2-4ba9-990c-114d876531f5/width=450/3308568.jpeg",
        "trainedWords": [
            "woodfigurez"
        ],
        "file_name": "woodfigurez-sdxl.safetensors"
    },
    {
        "name": "Overgrowth Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4554131a-b21c-4518-89d4-031a5f0bd925/width=450/3666928.jpeg",
        "trainedWords": [
            "ovrgrwth"
        ],
        "file_name": "ovrgrwth-sdxl.safetensors"
    },
    {
        "name": "Fluffy Style [SDXL LoRA]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ea755b26-546f-4c9d-b89e-f26d7dd3057f/width=450/3884021.jpeg",
        "trainedWords": [
            "ral-fluff"
        ],
        "file_name": "ral-fluff-sdxl.safetensors"
    },
    {
        "name": "Chrome Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d6397a92-6e39-46ef-860d-991d6aa25cb9/width=450/3798122.jpeg",
        "trainedWords": [
            "ral-chrome"
        ],
        "file_name": "ral-chrome-sdxl.safetensors"
    },
    {
        "name": "The Wizard's Vintage Romance Novel",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bb7e8827-efc9-4b69-9b51-4c4ecedc9393/width=450/5034108.jpeg",
        "trainedWords": [
            "Book cover of a vintage romance novel",
            "Cover of a Harlequin romance novel"
        ],
        "file_name": "wizards_vintage_romance.safetensors"
    },
    {
        "name": "Perfect Eyes XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bf6111c7-5f3f-437a-9d4a-9d68c3d5e504/width=450/1772427.jpeg",
        "trainedWords": [
            "green eyes",
            "blue eyes",
            "brown eyes",
            "perfecteyes"
        ],
        "file_name": "PerfectEyesXL.safetensors"
    },
    {
        "name": "Black eye demon",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dfab3c21-f482-48d1-8729-fd58171d2c28/width=450/3168344.jpeg",
        "trainedWords": [],
        "file_name": "Wb1-000008.safetensors"
    },
    {
        "name": "Colorful Fuzzy Style SD1.5+SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3d521206-5a46-4922-a924-cc0bd50951e5/width=450/4772215.jpeg",
        "trainedWords": [
            "martius_fuzz"
        ],
        "file_name": "Colorful_Fuzzy_Style_SDXL.safetensors"
    },
    {
        "name": "FF Style: Edvard Munch, (SDXL)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c9d2845e-4787-4d21-b202-8792f36d5a61/width=450/3250605.jpeg",
        "trainedWords": [
            "style of Edvard Munch",
            "Edvard Munch style",
            " Edvard Munch art",
            "Edvard Munch"
        ],
        "file_name": "FF-Style-Edvard-Munch-32.safetensors"
    },
    {
        "name": "Better Picture, More Details LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/427c6574-e42b-44fa-ae92-ee605eb17a60/width=450/1966128.jpeg",
        "trainedWords": [],
        "file_name": "SDXLHighDetail.safetensors"
    },
    {
        "name": "Genshin_XL｜原神甘雨 Ganyu",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/027857e1-93e0-4f57-a77c-8afde282cb7f/width=450/2678448.jpeg",
        "trainedWords": [
            "(\\gan yu\\)"
        ],
        "file_name": "ganyu_xl.safetensors"
    },
    {
        "name": "ScrapBuiltAI (SDXL, 83MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/50ce8fcb-32e0-41f2-a3e8-4041d6b03e78/width=450/2571841.jpeg",
        "trainedWords": [
            "scrapbuiltai"
        ],
        "file_name": "ScrapBuiltAIp.safetensors"
    },
    {
        "name": "Horror Skeletons [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7b2649c5-918a-4944-98e6-7378a23deceb/width=450/3296788.jpeg",
        "trainedWords": [
            "zkeleton"
        ],
        "file_name": "zkeleton-sdxl.safetensors"
    },
    {
        "name": "Blue Resin Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b18a690b-0385-4066-9292-0e134694e768/width=450/5248848.jpeg",
        "trainedWords": [
            "ral-blueresin"
        ],
        "file_name": "ral-blueresin-sdxl.safetensors"
    },
    {
        "name": "DonM - Elementals - [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a680e2c9-10b4-4eb7-8129-dba0bbc73366/width=450/4602767.jpeg",
        "trainedWords": [
            "DonM3l3m3nt4lXL"
        ],
        "file_name": "DonM3l3m3nt4lXL-000010.safetensors"
    },
    {
        "name": "3D Cube Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7c75aaf5-2ce6-4da7-9359-ce2acb7b95c7/width=450/4871092.jpeg",
        "trainedWords": [
            "ral-3dcubes"
        ],
        "file_name": "ral-3dcubes-sdxl.safetensors"
    },
    {
        "name": "Epoch Elves Overflow [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/13c16246-3fe6-4d1e-a397-768be77fb184/width=450/4436871.jpeg",
        "trainedWords": [
            "ral-epochelves"
        ],
        "file_name": "ral-epochelves-sdxl.safetensors"
    },
    {
        "name": "Fantasy toga",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b129bf81-cef9-42cb-b774-11cd978734a0/width=450/2503469.jpeg",
        "trainedWords": [],
        "file_name": "fantasy_toga_sdxl_v2.3.safetensors"
    },
    {
        "name": "fat animal",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e9447531-94db-467c-a51a-c3ef24a382e7/width=450/2463208.jpeg",
        "trainedWords": [
            "fat"
        ],
        "file_name": "[XL]fat.safetensors"
    },
    {
        "name": "Essenz - Better Photography (Style LoRa for SDXL 1.0)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bd5adf86-574d-468c-b5f4-55f5a1f2d1ae/width=450/3582545.jpeg",
        "trainedWords": [
            "photo in phst artstyle"
        ],
        "file_name": "SDXL1.0_Essenz-series-by-AI_Characters_Style_HighQualityPhotography-v1.0.safetensors"
    },
    {
        "name": "Genshin_XL｜原神刻晴 Keqing",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/342cd59c-6353-4304-801f-52b928b2aa18/width=450/2679126.jpeg",
        "trainedWords": [
            "(\\ke qing\\),"
        ],
        "file_name": "Keqing2_xl.safetensors"
    },
    {
        "name": "{scifi/old}EgyptPunkAI (SDXL, 37MB, +SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d8bfd8d0-d3c3-4fac-87e0-8f982cb599c7/width=450/2817385.jpeg",
        "trainedWords": [
            "egyptpunkai",
            "old",
            "scifi"
        ],
        "file_name": "EgyptPunkAIp.safetensors"
    },
    {
        "name": "Envy Better Hires Fix XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6d4f518f-54ba-4ab1-9e04-94ed2f890a22/width=450/4449199.jpeg",
        "trainedWords": [],
        "file_name": "EnvyBetterHiresFixXL01.safetensors"
    },
    {
        "name": "(SDXL) TwoFace Blade - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/98546aa0-4390-4f5b-9f8e-3bdedab6a1a5/width=450/5417134.jpeg",
        "trainedWords": [
            "TwoFace Blade"
        ],
        "file_name": "TwoFace_Blade_-_By_HailoKnight.safetensors"
    },
    {
        "name": "Action SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3f841dea-1767-4dfb-99b6-095d6ec3b49d/width=450/1876531.jpeg",
        "trainedWords": [
            "zdyna_pose"
        ],
        "file_name": "!action-sdxl-V0.5.safetensors"
    },
    {
        "name": "Ahegao (Tongue Out, Cross-eyed) SDXL 1k",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9a11ced4-3f40-46fb-8924-47941498c741/width=450/1831192.jpeg",
        "trainedWords": [
            "tongue out",
            "ahegao",
            "drool"
        ],
        "file_name": "ahegao_sdxl_v4.safetensors"
    },
    {
        "name": "XL Weapon Sword & Shield - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b0264eba-50c0-43e0-ad0c-0c10c8c8b002/width=450/4427047.jpeg",
        "trainedWords": [
            "Sword and shield"
        ],
        "file_name": "XL_Weapon_Sword__Shield_-_By_HailoKnight.safetensors"
    },
    {
        "name": "Beer Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/489056d2-df2b-4890-bf31-476166574726/width=450/5484042.jpeg",
        "trainedWords": [
            "ral-beer"
        ],
        "file_name": "ral-beer-sdxl.safetensors"
    },
    {
        "name": "[Lah] China Goddess Fashion (敦煌风汉服新风格)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3c4f8edb-2bea-402e-b029-3a709ad023f7/width=450/2125330.jpeg",
        "trainedWords": [
            "china goddess"
        ],
        "file_name": "sdxl_chinagoddess.safetensors"
    },
    {
        "name": "[1.5/XL 1.0] Makima | Chainsaw Man",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c1dc5e09-cb03-4f89-99bd-e34cafa5f652/width=450/1801925.jpeg",
        "trainedWords": [
            "makima",
            "braided ponytail",
            "ringed eyes",
            "collared shirt",
            "necktie"
        ],
        "file_name": "makima_loraxl_v5.safetensors"
    },
    {
        "name": "BiopunkAI 2 (SDXL, SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7f603ebf-759a-401f-b31a-4373bdb4ab33/width=450/2847132.jpeg",
        "trainedWords": [
            "biopunkai"
        ],
        "file_name": "BioPunk2AIp.safetensors"
    },
    {
        "name": "Alchemy",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1cac48cc-e592-4f61-9331-792c977f2c63/width=450/2164137.jpeg",
        "trainedWords": [
            "alchemy"
        ],
        "file_name": "Alchemy_sdxl.safetensors"
    },
    {
        "name": "LaxpeintXL (digital paint style LoRA)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/418508a7-9023-4425-b794-7382eac45e9a/width=450/2303408.jpeg",
        "trainedWords": [
            "LaxpeintXL"
        ],
        "file_name": "LaxpeintXL.safetensors"
    },
    {
        "name": "Dry Earth Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/67e4ac10-aefd-4502-86a4-86730b091103/width=450/3571307.jpeg",
        "trainedWords": [
            "drath"
        ],
        "file_name": "dryearth-sdxl.safetensors"
    },
    {
        "name": "DonM - Steel Magic [SDXL,SD1.5]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ae7e1cec-ef4c-42ee-a15d-41e45c3dcf15/width=450/5123584.jpeg",
        "trainedWords": [
            "DonMSt33lM4g1cXL"
        ],
        "file_name": "DonMSt33lM4g1cXL-v1.1-000008.safetensors"
    },
    {
        "name": "Copper Wire Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b368e432-6b0c-488e-9238-b7aae2fa91cb/width=450/5154785.jpeg",
        "trainedWords": [
            "ral-copperwire"
        ],
        "file_name": "ral-copperwire-sdxl.safetensors"
    },
    {
        "name": "RPGBarbarian",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/94ad1ec0-3806-4100-ae27-d1bd9050b427/width=450/1901331.jpeg",
        "trainedWords": [
            "barbarian"
        ],
        "file_name": "RPGBarbariansXL.safetensors"
    },
    {
        "name": "Detail Enhancer (SDXL)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f29bc24d-24df-4fb4-87e3-1e1c7c41dedf/width=450/2973044.jpeg",
        "trainedWords": [],
        "file_name": "XDetail_heavy.safetensors"
    },
    {
        "name": "DisplayCaseXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d5e668a4-1cf7-4252-b9ff-e4c352393176/width=450/4921073.jpeg",
        "trainedWords": [
            "display case"
        ],
        "file_name": "DisplayCaseXL.safetensors"
    },
    {
        "name": "Extremely detailed - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/28902248-7baa-4594-97af-aadd5d7956dc/width=450/4396544.jpeg",
        "trainedWords": [
            "extremely detailed"
        ],
        "file_name": "extremely_detailed.safetensors"
    },
    {
        "name": "(Win 2250 Buzz!) XL Weapon Sniper Rifle - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e8219314-8cd3-4bb5-8b90-22d7f16376b1/width=450/5376339.jpeg",
        "trainedWords": [
            "Sniper Rifle"
        ],
        "file_name": "XL_Weapon_Sniper_Rifle_-_By_HailoKnight.safetensors"
    },
    {
        "name": "Concept: Perfect Eyes",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a4a980c5-5f12-4a9a-8ad8-5244ba5f6829/width=450/1797814.jpeg",
        "trainedWords": [
            "perfecteyes"
        ],
        "file_name": "lora-sdxl-perfect-eyes.safetensors"
    },
    {
        "name": "Stylized Setting (Isometric) SDXL & SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cb4e4c54-bed7-4adf-9721-f09bae4f2e61/width=450/5179128.jpeg",
        "trainedWords": [
            "Isometric_Setting"
        ],
        "file_name": "Stylized_Setting_SDXL.safetensors"
    },
    {
        "name": "Cyber Graphic",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5233a2fc-ccd5-409e-bf65-9978362e52ac/width=450/1991906.jpeg",
        "trainedWords": [],
        "file_name": "cybergraphic_sdxl.safetensors"
    },
    {
        "name": "Microverse Creator ",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a9a93436-c656-4b4a-96c9-1654176ceb78/width=450/2013037.jpeg",
        "trainedWords": [],
        "file_name": "Microverse_Creator_sdxl.safetensors"
    },
    {
        "name": "Anime Sketch (Style) SDXL & SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a1f89e58-b94a-4cb8-b67f-cb7fcfa96d23/width=450/4382788.jpeg",
        "trainedWords": [
            " (Pencil_Sketch:1.2, messy lines, greyscale, traditional media, sketch),",
            "unfinished, hatching (texture)"
        ],
        "file_name": "Anime_Sketch_SDXL.safetensors"
    },
    {
        "name": "XL Fantasy warriors - by HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/67113a33-cf57-4faa-bcd7-4f08ee7b5703/width=450/1978985.jpeg",
        "trainedWords": [
            "holding sword",
            "armor",
            "holding weapon"
        ],
        "file_name": "XLKnightV1.safetensors"
    },
    {
        "name": "Bloodstained - XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c5310dd2-eb21-4293-9597-ef4d6deabaa5/width=450/1784587.jpeg",
        "trainedWords": [
            "Bloodstainedai"
        ],
        "file_name": "Bloodstained-XL-V1.safetensors"
    },
    {
        "name": "Crowd of people - sliders / ntcai.xy",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/50eef4e5-de04-498d-8069-9ddf0d204314/width=450/4423096.jpeg",
        "trainedWords": [
            "crowd of people"
        ],
        "file_name": "crowd_of_people.safetensors"
    },
    {
        "name": "SDXL Angelic/Demonic",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/151720b2-155e-439c-9bb8-6bb5d1be45db/width=450/1917349.jpeg",
        "trainedWords": [
            "AngelicStyle"
        ],
        "file_name": "SDXLAngelicStyle.safetensors"
    },
    {
        "name": "2077 Style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6b835fb8-768a-418f-8c6a-681f4848d8e2/width=450/2296221.jpeg",
        "trainedWords": [
            " 2077_Style"
        ],
        "file_name": "2077_Style.safetensors"
    },
    {
        "name": "Kafka from Honkai: Star Rail LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/55fa8b83-a772-407f-bd75-9734e75a5143/width=450/1847186.jpeg",
        "trainedWords": [
            "kafka",
            "eyewear on head",
            "jacket",
            "white shirt",
            "pantyhose",
            "boots",
            "purple gloves"
        ],
        "file_name": "kafka_loraxl.safetensors"
    },
    {
        "name": "Maniacal Laughter - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/43ffed6b-fa97-4480-b258-900cac6876e3/width=450/4339489.jpeg",
        "trainedWords": [
            "maniacal laughter"
        ],
        "file_name": "maniacal_laughter.safetensors"
    },
    {
        "name": "Wojak SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/daa9697a-e8f9-47c2-94a3-c5fd6522d78d/width=450/2016261.jpeg",
        "trainedWords": [
            "wojak",
            "Crying Wojak",
            "Trad Wife",
            "Doomer"
        ],
        "file_name": "wojak_SDXL.safetensors"
    },
    {
        "name": "BeksinskiXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/89e4e944-bcfd-44cc-8701-260f8f41ba8c/width=450/3298119.jpeg",
        "trainedWords": [
            "digital artwork by Beksinski"
        ],
        "file_name": "sdxl_lora_beksinski-000010.safetensors"
    },
    {
        "name": "SteamPunkNoireAI (SDXL) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6150e9d6-2828-4e0f-9e80-c4137e3f65a4/width=450/3093772.jpeg",
        "trainedWords": [
            "steampunknoireai"
        ],
        "file_name": "SteamPunkNoireAIp.safetensors"
    },
    {
        "name": "Ink Art XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c4519323-fffb-48aa-b20f-93d9ec37a054/width=450/4949074.jpeg",
        "trainedWords": [
            "Ink art"
        ],
        "file_name": "InkedArtXL.safetensors"
    },
    {
        "name": "Do Don't Style ",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8c756ec5-d920-419d-aa47-c6dc4af60987/width=450/2531468.jpeg",
        "trainedWords": [],
        "file_name": "Do_Dont.safetensors"
    },
    {
        "name": "Melting Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/79899bea-ab06-4283-9603-650961c429f4/width=450/4950113.jpeg",
        "trainedWords": [
            "ral-melting"
        ],
        "file_name": "ral-melting-sdxl.safetensors"
    },
    {
        "name": "Samus Aran/サムス・アラン (Metroid) SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/08971ac7-1766-468b-bc90-1db7dc1d4e0b/width=450/5491265.jpeg",
        "trainedWords": [
            "defSamus, blonde hair, bodysuit, blue clothes, blue pants, high heels",
            "altSamus, blonde hair, blue crop top, blue shorts, bare shoulders, midriff",
            "altSamus, blonde hair, orange crop top, orange shorts, bare shoulders, midriff"
        ],
        "file_name": "samus-xl-nvwls-v1.safetensors"
    },
    {
        "name": "Jeans Denim Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f77049ea-e6e4-40c6-b73d-304d27b422c5/width=450/4141626.jpeg",
        "trainedWords": [
            "ral-jeans"
        ],
        "file_name": "ral-jeans-sdxl.safetensors"
    },
    {
        "name": "Aluminium Foil Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/53a4dbcc-b7fc-4feb-b71b-04d3a622cfe4/width=450/4211575.jpeg",
        "trainedWords": [
            "ral-alufoil"
        ],
        "file_name": "ral-alufoil-sdxl.safetensors"
    },
    {
        "name": "Old Witch Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d1ea70d8-de34-42bc-80eb-bc7f87898883/width=450/5422555.jpeg",
        "trainedWords": [
            "ral-wtchz"
        ],
        "file_name": "ral-wtchz-sdxl.safetensors"
    },
    {
        "name": "AetherPunkAI (SDXL) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ae5af229-b982-4792-96eb-e32c74d241a6/width=450/2070652.jpeg",
        "trainedWords": [
            "aetherpunkAI"
        ],
        "file_name": "AetherPunkAI.safetensors"
    },
    {
        "name": "Dark Fantasy Art",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2b6db4b5-2e6e-4ec0-ac9b-e30abbade11d/width=450/4348874.jpeg",
        "trainedWords": [
            "dark fantasy"
        ],
        "file_name": "dark_fantasy_art.safetensors"
    },
    {
        "name": "OilPaint SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4914075d-1692-46a9-9784-553ff96853c4/width=450/2288954.jpeg",
        "trainedWords": [
            "style by nty"
        ],
        "file_name": "model_SDXL.safetensors"
    },
    {
        "name": "SDXL Offset Example Lora",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/38a638ad-e35f-4d2c-afd7-222117646ac8/width=450/2276659.jpeg",
        "trainedWords": [],
        "file_name": "sdxl_offset_example_v10.safetensors"
    },
    {
        "name": "Cortana [Halo] LoRA SD1.5 & SDXL1.0",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a91144e4-1b11-4a2e-855e-ea8a028a8046/width=450/1966572.jpeg",
        "trainedWords": [
            "Cortana",
            "hologram"
        ],
        "file_name": "cortana_v0.0.1.safetensors"
    },
    {
        "name": "Face Helper - SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/eaeb8140-7580-4475-afc7-9b1425a79539/width=450/2513178.jpeg",
        "trainedWords": [],
        "file_name": "face_xl_v0_1.safetensors"
    },
    {
        "name": "Barbwire Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6e533688-c3b9-469c-8da4-cb57b5d30281/width=450/4820161.jpeg",
        "trainedWords": [
            "ral-barbwire"
        ],
        "file_name": "ral-barbwire-sdxl.safetensors"
    },
    {
        "name": "RPGAngels",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ae17cd0d-edf6-482d-94eb-5a031d5ff5d4/width=450/1898892.jpeg",
        "trainedWords": [
            "angel"
        ],
        "file_name": "RPGAngelsXL.safetensors"
    },
    {
        "name": "DonM - Spectral Style [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4844edeb-8958-47a3-9be3-7a997ca2ff7a/width=450/4658288.jpeg",
        "trainedWords": [
            "DonMSp3ctr4lXL"
        ],
        "file_name": "DonMSp3ctr4lXL-000008.safetensors"
    },
    {
        "name": "AI_uehara",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/12508d9d-73ea-45f8-8f7a-3cbde5433e3d/width=450/3986844.jpeg",
        "trainedWords": [
            "aiuehara"
        ],
        "file_name": "こたじろう1-aiUehara-LoRA.safetensors"
    },
    {
        "name": "Hevok Nendoroid Chibi [Art Style Capture] LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9402be8a-aaf7-402e-8df7-723d86bf85b4/width=450/1968313.jpeg",
        "trainedWords": [],
        "file_name": "nendoroid_v0.0.1.safetensors"
    },
    {
        "name": "{Tesla/Edison}punkAI (SDXL, workflows, 48MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5482184e-854d-4bd4-bab0-ee8d98cee923/width=450/2485540.jpeg",
        "trainedWords": [
            "teslapunkai"
        ],
        "file_name": "TeslaPunkAIXLv2p.safetensors"
    },
    {
        "name": "Male feet pose / barefoot",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/09c53b78-522c-4047-a50a-81af3754d39b/width=450/3646282.jpeg",
        "trainedWords": [
            "footpose, barefoot, male feet, sitting, soles, toes, foot focus, solo"
        ],
        "file_name": "footpose-11v_sdxl_lora-000028.safetensors"
    },
    {
        "name": "カラオケ karaokeroom",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/93b598a9-6eee-45a4-b256-4f9576cc234f/width=450/2065197.jpeg",
        "trainedWords": [
            "karaokeroom",
            "joypad",
            "denmoku",
            "AT-CLM7000TX, microphone",
            "WM-610, microphone"
        ],
        "file_name": "karaokeroom_SDXL_V1.safetensors"
    },
    {
        "name": "[Lah] Cyberpunk | SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/92e7774d-7b4e-47c9-8962-c8f4f70c7882/width=450/2176150.jpeg",
        "trainedWords": [
            "cyberpunk style",
            "cyberpunk"
        ],
        "file_name": "sdxl_cyberpunk.safetensors"
    },
    {
        "name": "Western Dragon Collection XL - Classic style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/14a6d099-ee94-4631-a6ba-045192cd7d6e/width=450/2703298.jpeg",
        "trainedWords": [
            "dragon",
            "classic dragon",
            "exotic dragon",
            "magma dragon",
            "bone dragon",
            "undead dragon",
            "thunder dragon",
            "forest dragon",
            "golden dragon"
        ],
        "file_name": "Classic Western Dragons XL.safetensors"
    },
    {
        "name": "Adriana Chechik SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b349d0ad-b92b-4201-ada2-ddf3357e71e5/width=450/1934172.jpeg",
        "trainedWords": [
            "Adriana Chechik"
        ],
        "file_name": "adrianachechik_SDXL.safetensors"
    },
    {
        "name": "Alien Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/447f7bfc-00c7-40b7-9c9b-b2c61b8b29e0/width=450/3266064.jpeg",
        "trainedWords": [
            "alienzkin"
        ],
        "file_name": "alienzkin-sdxl.safetensors"
    },
    {
        "name": "Boosette (Mario) SDXL & SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/704b124b-4f2e-476c-a150-f72465f70bfb/width=450/4034319.jpeg",
        "trainedWords": [
            "Boosette_Mario,  1girl,  long hair,  white hair,  long white dress,  ballroom dress,  white gloves,"
        ],
        "file_name": "Boosette_Mario_SDXL.safetensors"
    },
    {
        "name": "Science DNA Style - Discover the building blocks of everything!",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/04883a60-e125-4a96-98d3-77a43bbd0fc4/width=450/4118535.jpeg",
        "trainedWords": [
            "ScienceDNAStyle"
        ],
        "file_name": "ScienceDNAStyleXL.safetensors"
    },
    {
        "name": "Christmas",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9dec685b-02bb-4beb-80bf-579a68887f8f/width=450/4221772.jpeg",
        "trainedWords": [
            "christmas"
        ],
        "file_name": "christmas.safetensors"
    },
    {
        "name": "Star Rail_XL 星穹铁道 三月七 Marth7",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e0bb5d13-6e15-427e-92f5-04913789f9f8/width=450/3179859.jpeg",
        "trainedWords": [
            "sanyueqi",
            "1girl,pink hair,long hair,purple eyes, earrings,jewelry,choker, long sleeves, white shirt,blue skirt,"
        ],
        "file_name": "Marth7x_xl.safetensors"
    },
    {
        "name": "DonM - Rust World Style [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/88000793-6fb2-48d3-82b2-9727db330b9f/width=450/4399482.jpeg",
        "trainedWords": [
            "DonM0ccul7Ru57XL"
        ],
        "file_name": "DonM0ccul7Ru57XL-000006.safetensors"
    },
    {
        "name": "AshleyWoodArtAI [Hellspawn] (SDXL) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7840bccd-9ecb-4228-84be-0948bd975c27/width=450/3305351.jpeg",
        "trainedWords": [
            "AshleyWoodArtAI"
        ],
        "file_name": "AshleyWoodArtAILccp.safetensors"
    },
    {
        "name": "Glacial Ice Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7d7d0149-9c81-4481-849d-e72bb91e3a4c/width=450/4455375.jpeg",
        "trainedWords": [
            "ral-glacial"
        ],
        "file_name": "ral-glacial-sdxl.safetensors"
    },
    {
        "name": "Santa Claus Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/923b17f2-ff2f-420c-86be-46f104a897cb/width=450/4111392.jpeg",
        "trainedWords": [
            "ral-santa"
        ],
        "file_name": "ral-santa-sdxl.safetensors"
    },
    {
        "name": "Mycelium Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/29537988-1fcf-4369-b4b8-3dc603901488/width=450/4922001.jpeg",
        "trainedWords": [
            "ral-mycelium"
        ],
        "file_name": "ral-mycelium-sdxl.safetensors"
    },
    {
        "name": "Camouflage Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ca8fb2ef-97e1-4161-87fc-cbee1af06f1c/width=450/4217528.jpeg",
        "trainedWords": [
            "ral-camo"
        ],
        "file_name": "ral-camo-sdxl.safetensors"
    },
    {
        "name": "Duct Tape Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0d0b7976-d820-4973-adfb-dc638fa1e44e/width=450/4374660.jpeg",
        "trainedWords": [
            "ral-ducttape"
        ],
        "file_name": "ral-ducttape-sdxl.safetensors"
    },
    {
        "name": "Aerith Gainsborough/エアリス・ゲインズブール (Final Fantasy VII) SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cc453aec-d6cc-478a-93b4-b95f7cfe7412/width=450/5433961.jpeg",
        "trainedWords": [
            "defaerith, braided ponytail, hair bow, pink bow, choker, necklace, cropped jacket, red jacket, short sleeves, pink dress, long dress",
            "altaerith, very long hair, hair ribbons, drill hair, red ribbons, star necklace, red ballgown, bare shoulders, strapless, plunging neckline"
        ],
        "file_name": "aerith-xl-nvwls-v1.safetensors"
    },
    {
        "name": "Mold Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8916763e-c81f-4ff1-a42c-b3e4c844d6b6/width=450/4320661.jpeg",
        "trainedWords": [
            "ral-mold"
        ],
        "file_name": "ral-mold-sdxl.safetensors"
    },
    {
        "name": "🎉 Paper Streamer Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1db20928-10eb-40b5-92a1-b08ca9d0f960/width=450/4959494.jpeg",
        "trainedWords": [
            "ral-paperstreamer"
        ],
        "file_name": "ral-paperstreamer-sdxl.safetensors"
    },
    {
        "name": "Bubble Wrap Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8358506b-8eda-4412-8697-1f0501370cad/width=450/4260787.jpeg",
        "trainedWords": [
            "ral-bubblewrap"
        ],
        "file_name": "ral-bubblewrap-sdxl.safetensors"
    },
    {
        "name": "Cerestia of Life (Last Origin) SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1a40a2c0-79fb-4616-8baa-9a7458a1d8f7/width=450/5500981.jpeg",
        "trainedWords": [
            "cerestiadef, circlet, forehead jewel, revealing clothes, breast curtains, pelvic curtain, bridal gauntlets, elbow gloves, single thighhigh",
            "cerestiacow, cow print, circlet, forehead jewel, thighhighs, elbow gloves, cowbell, collar, bikini",
            "cerestiacow, cow print, circlet, forehead jewel, thighhighs, elbow gloves, cowbell, collar, bodypaint, (nude:0.7)",
            "wide hips, huge breasts, sagging breasts"
        ],
        "file_name": "cerestia-lo-richy-v1_xl.safetensors"
    },
    {
        "name": "Varnish Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0872df85-eeb3-4215-8dd6-4afd337af5a9/width=450/5289270.jpeg",
        "trainedWords": [
            "ral-varnish"
        ],
        "file_name": "ral-varnish-sdxl.safetensors"
    },
    {
        "name": "Spikes Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/109f1d18-dbfb-49f5-9fda-368dcca41d07/width=450/5102198.jpeg",
        "trainedWords": [
            "ral-spikes"
        ],
        "file_name": "ral-spikes-sdxl.safetensors"
    },
    {
        "name": "RPGBandit",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c5edcc55-ec64-46f5-9dd6-fbbb261826b4/width=450/1900832.jpeg",
        "trainedWords": [
            "bandit"
        ],
        "file_name": "RPGBanditXL.safetensors"
    },
    {
        "name": "DonM - Demon Veins Style [SDXL, SD1.5]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bb69ee2a-5fd9-434d-9aea-2c94506fbbab/width=450/3426535.jpeg",
        "trainedWords": [
            "DonMD3m0nV31nsXL",
            "demonic",
            "veins",
            "bio",
            "organic"
        ],
        "file_name": "DonMD3m0nV31nsXL_v1.1-000009.safetensors"
    },
    {
        "name": "BetterText.Redmond - Improving Text on SDXL Concept LORA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/03d059cd-51e6-4338-8948-6cec1ed72fb1/width=450/2529345.jpeg",
        "trainedWords": [],
        "file_name": "BetterTextRedmond.safetensors"
    },
    {
        "name": "XL Weapon Pistol - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/65b7b4f1-a4b5-47bc-b12c-7721259acdbd/width=450/3621819.jpeg",
        "trainedWords": [
            "pointing pistol"
        ],
        "file_name": "XL_Weapon_pistol.safetensors"
    },
    {
        "name": "Psychedelic Noir",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8f2c30d9-e4ad-4b36-acd3-a688f3351502/width=450/2017243.jpeg",
        "trainedWords": [],
        "file_name": "Psychedelic_Noir__sdxl.safetensors"
    },
    {
        "name": "Mistletoe Mercenaries - sliders - yellow team image contest helper",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b36fa0a1-67fe-4953-99db-03bbdcd62c3c/width=450/4299298.jpeg",
        "trainedWords": [
            "mistletoe mercenaries"
        ],
        "file_name": "mistletoe_mercenaries.safetensors"
    },
    {
        "name": "Sci-fi Environments",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/088f6aea-02ef-41a8-8542-679a48d41d85/width=450/2028144.jpeg",
        "trainedWords": [],
        "file_name": "Sci-fi_Environments_sdxl.safetensors"
    },
    {
        "name": "Watercolor Style - SDXL & 1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f1552fcc-f9d2-4a65-825f-bfbd610991ff/width=450/1849205.jpeg",
        "trainedWords": [],
        "file_name": "watercolor_v1_sdxl.safetensors"
    },
    {
        "name": "Guild Wars [Art Style Capture] Fantasy LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a4a32f70-a22c-420f-8e18-fd6f8c36ec15/width=450/2230849.jpeg",
        "trainedWords": [],
        "file_name": "guild_wars_2_v0.0.1.safetensors"
    },
    {
        "name": "PixelArtRedmond - Pixel Art Loras for SD XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/39c126ae-7d96-463d-8943-1b06a21dfef3/width=450/2479501.jpeg",
        "trainedWords": [
            "Pixel Art",
            "PixArFK"
        ],
        "file_name": "PixelArtRedmond-Lite64.safetensors"
    },
    {
        "name": "Neon Environments",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ca4aea8c-98ed-47af-9be2-03eea46ace7e/width=450/2248303.jpeg",
        "trainedWords": [],
        "file_name": "Neon_Environments.safetensors"
    },
    {
        "name": "Tanya Degurechaff  [The Saga of Tanya the Evil | Youjo Senki] LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a61a3292-8e9b-4c21-aa38-280cff183748/width=450/1984493.jpeg",
        "trainedWords": [
            "Youjo Senki]",
            "Tanya Degurechaff"
        ],
        "file_name": "youjo_senki_v0.0.1.safetensors"
    },
    {
        "name": "FF Style: James Gilleard - Modern Illustration Art",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/fe89172f-11c5-4f86-b970-b88dd3d28165/width=450/3256334.jpeg",
        "trainedWords": [
            "James Gilleard",
            "james gilleard artwork",
            "in the style of james gilleard",
            "james gilleard illustration"
        ],
        "file_name": "FF-Style-James_Gilleard.safetensors"
    },
    {
        "name": "Essenz - Darkest Dungeon [Chris Bourassa] (Style LoRa for SDXL 1.0)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/03a60720-e9ea-4995-b237-4d880e2783f2/width=450/3374355.jpeg",
        "trainedWords": [
            "artwork in dgst artstyle"
        ],
        "file_name": "SDXL1.0_Essenz-series-by-AI_Characters_Style_DarkestDungeon-v1.0.safetensors"
    },
    {
        "name": "Greg Rutkowski [Art Syle Capture] LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5127774c-ce1e-419d-bf5a-f306547cb52b/width=450/3345340.jpeg",
        "trainedWords": [
            "Greg Rutkowski"
        ],
        "file_name": "greg_rutkowski_xl_v1.safetensors"
    },
    {
        "name": "Cosmic Nebula Style SDXL + SD1.5",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e0d1881f-f959-42f7-a2e8-7d5ed82d8114/width=450/4795700.jpeg",
        "trainedWords": [
            "martius_nebula"
        ],
        "file_name": "Cosmic_Nebula_Style.safetensors"
    },
    {
        "name": "Mushrooms [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/41001722-640e-4c78-85a4-9bb24e26fd8c/width=450/3241625.jpeg",
        "trainedWords": [
            "mushroomz"
        ],
        "file_name": "mushroomz-sdxl.safetensors"
    },
    {
        "name": "Marionettes - Wooden carved puppets on strings",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/66a905c7-5a62-4898-83d6-9aba1bfb37de/width=450/2627481.jpeg",
        "trainedWords": [
            "marionette"
        ],
        "file_name": "marionette_lora_sdxl.safetensors"
    },
    {
        "name": "Wool Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5bb4c11b-e2bb-4192-9e68-6b7169fae3ec/width=450/3365446.jpeg",
        "trainedWords": [
            "zwuul"
        ],
        "file_name": "zwuul-sdxl.safetensors"
    },
    {
        "name": "Genshin_XL｜原神八重神子 Yae Mik",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3646dec8-76a2-4052-a57c-3c55241adbe6/width=450/2677277.jpeg",
        "trainedWords": [
            "(\\ba chong shen zi\\)"
        ],
        "file_name": "Yae_Miko_xl.safetensors"
    },
    {
        "name": "Doctor Diffusion's Claymation Style LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/85c8a1a2-74ee-4935-9140-d62bc0e2d90e/width=450/3304896.jpeg",
        "trainedWords": [
            "made-of-clay"
        ],
        "file_name": "DD-made-of-clay-XL-v2.safetensors"
    },
    {
        "name": "Ice Style XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/325a5fe3-6de0-4b97-8d0e-5028804fb581/width=450/3863776.jpeg",
        "trainedWords": [
            "ice"
        ],
        "file_name": "Ice.safetensors"
    },
    {
        "name": "MechanicusStyleAI (SDXL} - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a498b427-2d6a-4ae0-8ff6-66f4d684a68c/width=450/3197746.jpeg",
        "trainedWords": [
            "MechanicusStyleAI"
        ],
        "file_name": "MechanicusStyleAIp.safetensors"
    },
    {
        "name": "DonM - The Colours of Magic LoRA - Every Magic you want! [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0147398d-0e9d-48c8-95f7-68bb690b512f/width=450/5065490.jpeg",
        "trainedWords": [
            "DonMM4g1cXL "
        ],
        "file_name": "DonMM4g1cXL_v1.2-000008.safetensors"
    },
    {
        "name": "SovietPunkAI (SDXL) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f265aa41-0ada-420f-93cc-d22d2868649c/width=450/3014430.jpeg",
        "trainedWords": [
            "sovietpunkai"
        ],
        "file_name": "SovietPunkAIp.safetensors"
    },
    {
        "name": "Noodles Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/973d5e3d-1680-4ea2-8c56-e2519ff95c0d/width=450/3248725.jpeg",
        "trainedWords": [
            "noodlez"
        ],
        "file_name": "noodlez-sdxl.safetensors"
    },
    {
        "name": "Stardust-XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/53267269-2305-4a49-98c6-7fc6c51c110f/width=450/3042539.jpeg",
        "trainedWords": [],
        "file_name": "Stardust-XL.safetensors"
    },
    {
        "name": "DonM - Death Magic Attire [SDXL, SD1.5]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c3ae1196-b8f5-43c2-8cfa-e8676f531633/width=450/3478994.jpeg",
        "trainedWords": [
            "DonMD34thM4g1c4tt1r3XL"
        ],
        "file_name": "DonMD34thM4g1c4tt1r3XL-000008.safetensors"
    },
    {
        "name": "SteampunkXL_V1",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/206ebc02-8b76-48c8-bdfd-f6b70693ec02/width=450/3414564.jpeg",
        "trainedWords": [
            "steampunk"
        ],
        "file_name": "SteampunkXL_V1.safetensors"
    },
    {
        "name": "Embroidered Patch Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/df0a8674-2931-4066-b2c9-cba3fe4db313/width=450/4709372.jpeg",
        "trainedWords": [
            "ral-embroideredpatch"
        ],
        "file_name": "ral-embroideredpatch-sdxl.safetensors"
    },
    {
        "name": "pale demon",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ce7ae7f5-fb62-4703-a4f5-d677c029cab4/width=450/3024957.jpeg",
        "trainedWords": [],
        "file_name": "pale_demon.safetensors"
    },
    {
        "name": "Trichom Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/eed082f5-c905-4585-a657-46de241ae648/width=450/4897213.jpeg",
        "trainedWords": [
            "ral-trichom"
        ],
        "file_name": "ral-trichome-sdxl.safetensors"
    },
    {
        "name": "Toothpaste Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c71307bd-8186-4434-be5d-6f339fde6488/width=450/4150191.jpeg",
        "trainedWords": [
            "ral-toothp"
        ],
        "file_name": "ral-toothp-sdxl.safetensors"
    },
    {
        "name": "[XL] Andira アンチラ (5 outfits) || Granblue Fantasy (グランブルーファンタジー)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/981cfd8c-d784-46f6-b147-badebbc3e78f/width=450/5491629.jpeg",
        "trainedWords": [
            "andiradef, hair ribbon, two side up, monkey tail, detached sleeves, thighhighs, white leotard, sash, choker, shawl, hagoromo, cleavage cutout",
            "andirasu, monkey tail, highleg swimsuit, see-through sleeves, see-through, thigh strap",
            "andiraff, hairband, ahoge, midriff, monkey tail, tail bow, tail bell, blue ribbon, pom pom (clothes), jacket, blue shorts, striped thighhighs, long sleeves, fur trim, cleavage cutout, belt",
            "andiraaa, monkey tail, school uniform, glasses, collared shirt, blue skirt, green bowtie, red ribbon",
            "andiraev, monkey tail, white scarf, white leotard,  white thighhighs,  fur trim"
        ],
        "file_name": "spgbfAndiraXL-09.safetensors"
    },
    {
        "name": "Avocado Skin",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/33b02142-09c5-4ead-821b-a7c27e502b79/width=450/4130773.jpeg",
        "trainedWords": [
            "avocado skin"
        ],
        "file_name": "avocado_skin.safetensors"
    },
    {
        "name": "Cloud_AI_SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1454fbbe-6449-43b7-b43f-7eea14301fca/width=450/2618709.jpeg",
        "trainedWords": [
            "CloudAI"
        ],
        "file_name": "cloudAI-000033.safetensors"
    },
    {
        "name": "Eldritch Comics (comic book style illustration)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/949aef35-51f8-4f56-bce1-55877228b864/width=450/5421218.jpeg",
        "trainedWords": [
            "comic book"
        ],
        "file_name": "EldritchComicsXL.safetensors"
    },
    {
        "name": "DedMoroz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7e517e67-895d-40ef-a181-0e04de3949e2/width=450/4361022.jpeg",
        "trainedWords": [
            "DedMoroz"
        ],
        "file_name": "DedMoroz.safetensors"
    },
    {
        "name": "Bolsonaro - SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/00df6b6a-bb83-4c9d-a147-cdc5dcbf277a/width=450/3130595.jpeg",
        "trainedWords": [
            "bolsonaro",
            "bolsonaro man"
        ],
        "file_name": "BolsonaroV1.safetensors"
    },
    {
        "name": "Stickers.Redmond - Stickers Lora for SD XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/90b38e12-162d-4b8f-a388-184fd6470083/width=450/2464262.jpeg",
        "trainedWords": [
            "Stickers",
            "Sticker"
        ],
        "file_name": "StickersRedmond.safetensors"
    },
    {
        "name": "White Background",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b0932288-996e-410a-bc0d-e00b61a07971/width=450/1797478.jpeg",
        "trainedWords": [
            "with a white background"
        ],
        "file_name": "white_1_0.safetensors"
    },
    {
        "name": "Futuristic XL - by EDG",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1c9b39c3-4208-4797-85bc-93320468ad99/width=450/1764215.jpeg",
        "trainedWords": [
            "edgFut_clothing",
            "wearing edgFut_clothing",
            "neons",
            "electric circuits"
        ],
        "file_name": "edgFuturisticXL.safetensors"
    },
    {
        "name": "Genshin_XL｜原神纳西妲 Nahida",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6acddb83-ca7e-4ed8-8cd7-3f3ba3a8bfe9/width=450/2916861.jpeg",
        "trainedWords": [
            "(\\na xi tan\\)"
        ],
        "file_name": "Nahida_xl.safetensors"
    },
    {
        "name": "Genshin_XL｜Furina & Focalors | 芙宁娜 & 芙卡洛斯",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5f4fe8bf-2717-4600-b8eb-a28d064539de/width=450/2779840.jpeg",
        "trainedWords": [
            "(\\fu ning na\\)"
        ],
        "file_name": "Furinax_xl.safetensors"
    },
    {
        "name": "SDXL Inkdrawing Directors Cut",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5180874f-f410-43e0-8ff2-f78844daf0db/width=450/2779441.jpeg",
        "trainedWords": [
            "Inkdrawing",
            "by Kr355e"
        ],
        "file_name": "SDXL_Inkdrawing_Directors_Cut_E.safetensors"
    },
    {
        "name": "Flesh Monstrosity XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/561c219b-a97f-4dc5-917f-a0ca3e179b98/width=450/3067579.jpeg",
        "trainedWords": [
            "flesh",
            "mutant"
        ],
        "file_name": "FleshMonstrosityXL-000001.safetensors"
    },
    {
        "name": "Happy - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4302c839-37ce-4a61-a49b-e062e8f2ab20/width=450/4095746.jpeg",
        "trainedWords": [
            "happy",
            "sad"
        ],
        "file_name": "happy.safetensors"
    },
    {
        "name": "Scibu - Scifi Buildings",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/44449f3a-50d0-4d18-89df-9d279c50a15d/width=450/2051272.jpeg",
        "trainedWords": [
            "scibu",
            "science fiction",
            "building",
            "architecture"
        ],
        "file_name": "scibu.safetensors"
    },
    {
        "name": "Riju SDXL (The Legend Of Zelda: Tears of the Kingdom) (TotK)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5dbe3fab-e582-4a2e-b387-48a2e62d1efa/width=450/4084572.jpeg",
        "trainedWords": [
            "Riju_Zelda,  midriff,  red hair",
            "black dress"
        ],
        "file_name": "Riju_Zelda_TotK_Anime_SDXL.safetensors"
    },
    {
        "name": "Genshin_XL｜原神可莉 Klee",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dfde2c25-8746-4e4a-8181-da9efa7fd761/width=450/2792261.jpeg",
        "trainedWords": [
            "(\\ke li\\)"
        ],
        "file_name": "Kleex_xl.safetensors"
    },
    {
        "name": "PE Epic Land [Style] [Landscapes]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a0f96b1e-8870-4beb-aefa-5c45fc99a0e3/width=450/2202378.jpeg",
        "trainedWords": [
            "PEEpicLand",
            "illustration"
        ],
        "file_name": "PE_EpicLand.safetensors"
    },
    {
        "name": "Female Orcs XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5cb17731-e18d-4e00-9590-bb584bb64c7d/width=450/2318511.jpeg",
        "trainedWords": [
            "orc",
            "female orc",
            "fangs",
            "green skin"
        ],
        "file_name": "femaleorcv1.safetensors"
    },
    {
        "name": "Blake Lively SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f458d5ab-ec62-47d5-b9f3-e6b1b20be8a1/width=450/2113693.jpeg",
        "trainedWords": [
            "blake lively",
            "ohwx woman"
        ],
        "file_name": "blakelively_SDXL.safetensors"
    },
    {
        "name": "[Lah] Arcana | SD1.5 & SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1542be2a-9ba2-4ecb-819a-04a869f0af9b/width=450/3506976.jpeg",
        "trainedWords": [
            "arcana"
        ],
        "file_name": "Arcana_XL.safetensors"
    },
    {
        "name": "Armor from HaDeS XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7623df02-b0d5-4d0a-a22e-fb9091bb8d20/width=450/3629585.jpeg",
        "trainedWords": [
            "hdsrmr"
        ],
        "file_name": "hadesarmorXL.safetensors"
    },
    {
        "name": "Cyclops [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a34cb3ff-9ad5-49bd-a3f2-7afa3f91f5c2/width=450/3221278.jpeg",
        "trainedWords": [
            "cyclops"
        ],
        "file_name": "cyclops-sdxl.safetensors"
    },
    {
        "name": "Envy Radial XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b3333b2c-364c-455d-8b02-0b913d321f14/width=450/4696455.jpeg",
        "trainedWords": [
            "radial"
        ],
        "file_name": "EnvyRadialXL01.safetensors"
    },
    {
        "name": "Fur Pirates [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cc4a3680-7f04-4117-87d3-48cb3bddb98d/width=450/3408312.jpeg",
        "trainedWords": [
            "fur pirates"
        ],
        "file_name": "furpirate-sdxl.safetensors"
    },
    {
        "name": "Fantasy Animals",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/eff377c7-917c-4cfb-a049-9905ff7bff38/width=450/3820646.jpeg",
        "trainedWords": [
            "fantasy animal "
        ],
        "file_name": "fantasy animal.safetensors"
    },
    {
        "name": "Envy Troncore XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/863a2945-47fd-4323-a177-0e694a85fe51/width=450/4232068.jpeg",
        "trainedWords": [
            "troncore"
        ],
        "file_name": "EnvyTroncoreXL01.safetensors"
    },
    {
        "name": "XL Weapon Orbstaff - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b2c00481-9eaf-4dd1-b1d6-50f3fc134941/width=450/4185270.jpeg",
        "trainedWords": [
            "orbstaff"
        ],
        "file_name": "XL_Weapon_Orbstaff_-_By_HailoKnight.safetensors"
    },
    {
        "name": "Fairy Lights - Add Fairy Lights to Your SDXL Images",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c7057538-74e5-4b30-b9c1-257214250e45/width=450/4212524.jpeg",
        "trainedWords": [
            "fairy lights"
        ],
        "file_name": "fairy_lights.safetensors"
    },
    {
        "name": "Symmetrical Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3bf4aef9-659e-4f3f-8f04-7b6d72ae6965/width=450/5171717.jpeg",
        "trainedWords": [
            "ral-symetrical"
        ],
        "file_name": "ral-symetrical-sdxl.safetensors"
    },
    {
        "name": "DonM - Neon Christmas Ghost Style [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6ae217a4-b1b2-482c-99fd-43daab92e01f/width=450/4125683.jpeg",
        "trainedWords": [
            "DonMN30nChr1stGh0stsXL"
        ],
        "file_name": "DonMN30nChr1stGh0stsXL-000009.safetensors"
    },
    {
        "name": "Raw Meat Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d0063b44-a238-4bee-93f6-96336d44cc67/width=450/4380205.jpeg",
        "trainedWords": [
            "ral-meat"
        ],
        "file_name": "ral-rawmeat-sdxl.safetensors"
    },
    {
        "name": "Fitting Foam Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/69f22907-2270-4777-8d04-083a8d4bb62a/width=450/4950316.jpeg",
        "trainedWords": [
            "ral-fittingfoam"
        ],
        "file_name": "ral-fittingfoam-sdxl.safetensors"
    },
    {
        "name": "[XL 1.0] Chisato Nishikigi | Lycoris Recoil",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/78321588-d8f0-4b29-8803-8992390e3f1b/width=450/5420978.jpeg",
        "trainedWords": [
            "nishikigi chisato, bob cut, hair ribbon",
            "lycoris uniform, two-tone dress, red dress, grey dress, neck ribbon, long sleeves"
        ],
        "file_name": "lycorisrecoil_nishikigi_xl-000004.safetensors"
    },
    {
        "name": "Belle Delphine XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bb0a1b07-42e3-4736-ad67-5fc3cff296fd/width=450/1787162.jpeg",
        "trainedWords": [
            "Belle Delphine"
        ],
        "file_name": "DI_belle_delphine_sdxl_v1.safetensors"
    },
    {
        "name": "Elsa Jean SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dc580de3-7d8c-4900-8d1d-e2707230a293/width=450/2361599.jpeg",
        "trainedWords": [
            "3lsaj3an",
            "blonde woman"
        ],
        "file_name": "elsajean_SDXL.safetensors"
    },
    {
        "name": "PE Neon Sign [Style]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/565ba6a2-4b33-492f-a0e8-b2503a906025/width=450/2266240.jpeg",
        "trainedWords": [
            "PENeonSign",
            "neon sign",
            "glowing",
            "colorful"
        ],
        "file_name": "PE_NeonSignStyle.safetensors"
    },
    {
        "name": "Cloudify",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/113a5ee7-602c-4173-90c3-a9a46e8b2835/width=450/1852585.jpeg",
        "trainedWords": [
            "cloudify",
            "sky",
            "clouds",
            "rainbow"
        ],
        "file_name": "Cloudify-SDXL.safetensors"
    },
    {
        "name": "Concept Art Style - Syd Mead",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1e4d57a5-f0d5-4504-84aa-beee5e91de56/width=450/3381611.jpeg",
        "trainedWords": [
            "concept art by syd mead",
            "retro futuristic",
            "flat colors"
        ],
        "file_name": "concept_art_syd_mead.safetensors"
    },
    {
        "name": "Attractive - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/15e6007e-5f2c-4eb6-bf73-208a14eb90ad/width=450/4181973.jpeg",
        "trainedWords": [
            "attractive",
            "ugly"
        ],
        "file_name": "attractive.safetensors"
    },
    {
        "name": "DonM - GloomyNightmare [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0da02050-c3eb-4a32-93b2-b6551c261227/width=450/2207764.jpeg",
        "trainedWords": [
            "DonMn1ghtm4reXL"
        ],
        "file_name": "DonMn1ghtm4reXL.safetensors"
    },
    {
        "name": "Centaur X",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/49cc35ac-6483-44c1-85e5-ff25530d8c1f/width=450/4788411.jpeg",
        "trainedWords": [
            "male/female centaur",
            "traditional //attribute// costume",
            "Rococo dress",
            "Pantaloons"
        ],
        "file_name": "centaurx1.safetensors"
    },
    {
        "name": "Envy Fantasy Architectural Flourishes XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/529a812c-7395-4a83-bd48-72e061dd3d32/width=450/3969266.jpeg",
        "trainedWords": [],
        "file_name": "EnvyFlourishesXL01.safetensors"
    },
    {
        "name": "RPGRatFolkXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/21345775-7c10-415d-b363-50df7a705f08/width=450/2197191.jpeg",
        "trainedWords": [
            "rat folk"
        ],
        "file_name": "RPGRatFolkXL.safetensors"
    },
    {
        "name": "Envy Oil Pastel XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6b61df61-46b4-4696-9448-2a79f2cafc75/width=450/3630366.jpeg",
        "trainedWords": [
            "oil pastel"
        ],
        "file_name": "EnvyOilPastelXL01.safetensors"
    },
    {
        "name": "Santa Sleigh Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5d74612a-7f01-4f11-8f25-8440aaac691f/width=450/4281518.jpeg",
        "trainedWords": [
            "ral-santasleigh"
        ],
        "file_name": "ral-santasleigh-sdxl.safetensors"
    },
    {
        "name": "CyberPunkAI (SDXL, 53MB + SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5835f74d-a20d-42da-9926-be1715eb90e1/width=450/2780822.jpeg",
        "trainedWords": [
            "cyberpukai"
        ],
        "file_name": "CyberPunkAIp.safetensors"
    },
    {
        "name": "Empire Style XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e097edf2-1246-4d3e-863e-9212ccb65248/width=450/2386276.jpeg",
        "trainedWords": [
            "Victorian",
            "London",
            "Empire"
        ],
        "file_name": "Empire_SDXL_OnSite.safetensors"
    },
    {
        "name": "DonM - Tech World Style [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/02657670-a492-427b-9034-a4f6bcd94d3c/width=450/4341700.jpeg",
        "trainedWords": [
            "DonMT3chW0rldXL"
        ],
        "file_name": "DonMT3chW0rldXL-000009.safetensors"
    },
    {
        "name": "EasyFix [Negative LoRA] SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ceecf0c3-76cd-4a51-810a-5f1c3c491570/width=450/2613574.jpeg",
        "trainedWords": [
            "overfit style",
            "(overfit style:2.0)",
            "(overfit style:4.0)",
            "<PUT OVERFIT STYLE IN NEGATIVE PROMPT>"
        ],
        "file_name": "EasyFix.safetensors"
    },
    {
        "name": "Dungeons & Dragons [Art Style Capture] Fantasy LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3a206a7c-3aff-4005-afde-39b1f1fdbd35/width=450/2195483.jpeg",
        "trainedWords": [
            "Dungeons and Dragons "
        ],
        "file_name": "dungeons_and_dragons_v0.0.1.safetensors"
    },
    {
        "name": "SimplePositive XL v1.0 (slightly enahnce image quality and hand correction with minimal style changes) -by AutoRunMech",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c1d60615-4fdf-4523-91ce-c8383fbe550e/width=450/2451134.jpeg",
        "trainedWords": [],
        "file_name": "SimplePositive_XL_v1_AutoRunMech.safetensors"
    },
    {
        "name": "Illustration style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8e828fb5-3af5-45c5-a0df-08e0cdb9f7d2/width=450/2882815.jpeg",
        "trainedWords": [
            "ch"
        ],
        "file_name": "chahua.safetensors"
    },
    {
        "name": "Waist Slider XL (Microwaist XL)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4506d549-6856-427b-9bf9-98200e94d911/width=450/2034065.jpeg",
        "trainedWords": [],
        "file_name": "Microwaist_XL_v01.safetensors"
    },
    {
        "name": "Pixar Style (SDXL)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/815de45b-eba5-41f3-9e02-79b6c99c39be/width=450/3416819.jpeg",
        "trainedWords": [
            "pixar style"
        ],
        "file_name": "PixarXL.safetensors"
    },
    {
        "name": "AnimeLoRA SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/35b0c1ea-cdf1-496d-8aa3-af7e2f5a4dbd/width=450/1979740.jpeg",
        "trainedWords": [
            "anime"
        ],
        "file_name": "anime_sdxl_v1-e07.safetensors"
    },
    {
        "name": "Werewolf [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3d29b5d9-9c4b-405c-9c00-1c7d80413203/width=450/3379451.jpeg",
        "trainedWords": [
            "werewolf"
        ],
        "file_name": "werewolf-sdxl.safetensors"
    },
    {
        "name": "lala_3dlive_(pripara)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/12ca021c-d623-450a-8e43-62b3a8073f45/width=450/1714454.jpeg",
        "trainedWords": [],
        "file_name": "test_lala.safetensors"
    },
    {
        "name": "XL All-in-one Clothing Bundle",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b06103b3-90ad-4726-ab85-adbf1082c277/width=450/2630911.jpeg",
        "trainedWords": [
            "cute maid dress",
            "flower dress",
            "idol dress",
            "princess dress",
            "ruffled lingerie",
            "sailor senshi uniform",
            "victorian dress",
            "victorian maid dress"
        ],
        "file_name": "xl_n15g_aio_clothing-1.0.safetensors"
    },
    {
        "name": "Alexandra Daddario SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e9a48495-a0f1-40b6-b8b3-05474a1b0c1e/width=450/2065932.jpeg",
        "trainedWords": [
            "Alexandra Daddario",
            "ohwx woman"
        ],
        "file_name": "alexandradaddario_SDXL.safetensors"
    },
    {
        "name": "Looking at viewer - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3c5b4aa2-8c6b-41d8-afcd-c440573f40d8/width=450/4417199.jpeg",
        "trainedWords": [
            "looking at viewer"
        ],
        "file_name": "looking_at_viewer.safetensors"
    },
    {
        "name": "XL Victorian Dress",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d2d4c21b-58af-4cd5-b148-5bf47a0165f0/width=450/2365551.jpeg",
        "trainedWords": [
            "victorian dress"
        ],
        "file_name": "xl_victorian_dress-1.0.safetensors"
    },
    {
        "name": "Essenz - Makoto Shinkai Anime Screencap [Your Name - Weathering With You - Suzume] (Style LoRa for SDXL 1.0)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3cfee95c-5dc9-40ce-b07e-047be40d8c17/width=450/3172233.jpeg",
        "trainedWords": [
            "anime screencap in mnst artstyle"
        ],
        "file_name": "SDXL1.0_Essenz-series-by-AI_Characters_Style_MakotoShinkai-v1.0.safetensors"
    },
    {
        "name": "Benedikta Harman - Final Fantasy XVI",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8d48faa6-7f45-497a-990d-6a771ec52865/width=450/2385842.jpeg",
        "trainedWords": [
            "benedikta_harman",
            "bene_outfit"
        ],
        "file_name": "benedikta_sdxl_V1_01.safetensors"
    },
    {
        "name": "Steve McCurry - Photography SDXL LoRa",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/26078147-b187-4649-beee-6eb0707c90b2/width=450/3949201.jpeg",
        "trainedWords": [
            "Photography",
            "stvmccrr style",
            "stvmccrr"
        ],
        "file_name": "stvmccrr.safetensors"
    },
    {
        "name": "A Scanner Darkly Style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c40929ba-2e84-4368-9c07-c20b3df6dac7/width=450/2500466.jpeg",
        "trainedWords": [],
        "file_name": "A_Darkly_Scanner.safetensors"
    },
    {
        "name": "Genshin_XL｜原神胡桃 Hutao",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dbe6b0e0-d477-4587-acdb-30c78ce06905/width=450/2980953.jpeg",
        "trainedWords": [
            "(\\hu tao\\)"
        ],
        "file_name": "hutaox_xl.safetensors"
    },
    {
        "name": "Tropical Buildings SDXL & SD1.5 (Delfino Plaza) (Super Mario Sunshine)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6f07e1af-3214-4e78-9da5-5d1d71b36e0a/width=450/4098162.jpeg",
        "trainedWords": [
            " Delfino_Plaza,  small fruit stand,  pineapples,  coconuts,  palm tree,  small house"
        ],
        "file_name": "Delfino_Plaza_XL-000009.safetensors"
    },
    {
        "name": "Breakcore Style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/168c3844-a4c6-4ff0-9eab-530f17144de3/width=450/2122723.jpeg",
        "trainedWords": [
            "breakcore "
        ],
        "file_name": "breakcore_sdxl.safetensors"
    },
    {
        "name": "Minimalist tattoo designs (SD1.5 & NAI available)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2bb7fc38-2817-4f2d-b166-39716e353c9f/width=450/4069738.jpeg",
        "trainedWords": [
            "drawing of a minimalist tattoo"
        ],
        "file_name": "MinTattooXL-000034.safetensors"
    },
    {
        "name": "Genshin_XL｜原神神里凌华  Kamisato Ayaka",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/37496870-f962-473d-9dd3-44b88e316323/width=450/2931123.jpeg",
        "trainedWords": [
            "(\\shen li ling hua\\)"
        ],
        "file_name": "Kamisato Ayakax_xl.safetensors"
    },
    {
        "name": "RPG Portrait - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7a76699a-ad44-4d16-b201-fd6131fbd21a/width=450/4120125.jpeg",
        "trainedWords": [
            "rpg portrait",
            "photograph"
        ],
        "file_name": "rpg_portrait.safetensors"
    },
    {
        "name": "XL Futuristic Alien - by HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/02241316-f56a-4b9a-8bd5-d37dbd5f3e13/width=450/2274133.jpeg",
        "trainedWords": [
            "futuristic alien",
            "science fiction"
        ],
        "file_name": "XLFuturisticAlienV1E11.safetensors"
    },
    {
        "name": "Balloons [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/67a69d15-6a20-43a1-9f60-c2c394dcfb99/width=450/3331638.jpeg",
        "trainedWords": [
            "balloonz"
        ],
        "file_name": "balloonz-sdxl.safetensors"
    },
    {
        "name": "HorrorArt",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c6b44cf2-ff6c-43f9-8cd6-de113c8ed21b/width=450/3059323.jpeg",
        "trainedWords": [],
        "file_name": "takma2.safetensors"
    },
    {
        "name": "XL Stormtrooper - by HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5992832b-a5dc-4d2c-942b-6b294cbed62d/width=450/2013790.jpeg",
        "trainedWords": [
            "stormtrooper"
        ],
        "file_name": "StormtrooperXLV1.safetensors"
    },
    {
        "name": "DonM - Demon Characters [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e4e7a45f-88be-48ba-bacc-c18bf8e997a4/width=450/3062808.jpeg",
        "trainedWords": [
            "DonMD3m0nXL "
        ],
        "file_name": "DonMD3m0nXL-000010.safetensors"
    },
    {
        "name": "BloodPunkAI (SDXL, 43MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/48c87b76-5a34-4486-8aaf-dd40d7dfe118/width=450/2705473.jpeg",
        "trainedWords": [
            "bloodpunkai"
        ],
        "file_name": "BloodPunkAIp.safetensors"
    },
    {
        "name": "RPGDrowXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/99dc43b8-23be-4ba4-941d-dee8e8403834/width=450/2144133.jpeg",
        "trainedWords": [
            "drow"
        ],
        "file_name": "RPGDrowXL.safetensors"
    },
    {
        "name": "Chrome Tech - XL World Morph",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9ddcbf76-e825-4f0e-9a96-475c29f9954b/width=450/3307055.jpeg",
        "trainedWords": [
            "chrometech"
        ],
        "file_name": "Chrome_Tech_XL.safetensors"
    },
    {
        "name": "Wizard's Vintage Board Games",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0a3e3811-a2f2-418e-9b72-a2cea8bb34cd/width=450/4437757.jpeg",
        "trainedWords": [
            "Vintage board game box",
            "vintage board game box called \"your-title\""
        ],
        "file_name": "Wizards_Vintage_Board_Game.safetensors"
    },
    {
        "name": "RPGDisplacerBeastXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/62aa43b8-007e-427f-a802-781d5f3359d0/width=450/2468504.jpeg",
        "trainedWords": [
            "displacer beast"
        ],
        "file_name": "RPGDisplacerBeastXL.safetensors"
    },
    {
        "name": "XL Weapon Battleaxe - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b2e1249c-beb8-4833-89c4-e7d07d3d4319/width=450/4056664.jpeg",
        "trainedWords": [
            "wielding a battleaxe"
        ],
        "file_name": "XL_Weapon_Battleaxe_-_By_HailoKnight.safetensors"
    },
    {
        "name": "DonM - Math Magic Style [SD1.5, SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0afe22f2-5411-415c-85e5-e427dc097d00/width=450/4452681.jpeg",
        "trainedWords": [
            "DonMM4hM4g1cXL"
        ],
        "file_name": "DonMM4hM4g1cXL-000008.safetensors"
    },
    {
        "name": "CrackedMagma",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/203e76dc-a86a-48c8-a8e6-62c87ebcf568/width=450/4964058.jpeg",
        "trainedWords": [
            "crackedmagma",
            "made from cracked magma"
        ],
        "file_name": "CrackedMagma.safetensors"
    },
    {
        "name": "SpaghettifyXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3fdbb654-c577-49dc-8656-0daba2ec8f4b/width=450/3839722.jpeg",
        "trainedWords": [
            "spaghettify"
        ],
        "file_name": "Spaghettify.safetensors"
    },
    {
        "name": "XL Weapon Battlehammer - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/725f72dc-04d1-4204-8cf5-67f809f38490/width=450/4252536.jpeg",
        "trainedWords": [
            "battlehammer"
        ],
        "file_name": "XL_Weapon_Battlehammer.safetensors"
    },
    {
        "name": "Sticky Rice Style [SD1.5 & SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/39102ca1-1034-4991-9493-3fce2a6806da/width=450/4383088.jpeg",
        "trainedWords": [
            "styr"
        ],
        "file_name": "styr2.safetensors"
    },
    {
        "name": "Glitter Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a05de9c9-d75b-46ad-af88-2b059e6324d6/width=450/4953192.jpeg",
        "trainedWords": [
            "ral-glitter"
        ],
        "file_name": "ral-glitter-sdxl.safetensors"
    },
    {
        "name": "Guilty (NIKKE) SDXL LoRA",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/655d6269-9a1d-4b93-a061-b43cb5248c14/width=450/5435764.jpeg",
        "trainedWords": [
            "guilty, white bodysuit, collar, straitjacket"
        ],
        "file_name": "guilty-nikke-richy-v1_xl.safetensors"
    },
    {
        "name": "SDXL - Epic Beard & Mustache",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/81d13db1-52cf-4dcf-b479-3ce31fd290b1/width=450/5392637.jpeg",
        "trainedWords": [
            "brdanmu",
            "beard",
            "mustache"
        ],
        "file_name": "- SDXL - brdanmu_ beard_mustache_V1.0.safetensors"
    },
    {
        "name": "Motor Oil Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/541a234f-90e1-4b73-ae45-d52d8e3e8f7a/width=450/5352316.jpeg",
        "trainedWords": [
            "ral-motoroil"
        ],
        "file_name": "ral-motoroil-sdxl.safetensors"
    },
    {
        "name": "Blacklight Slime Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ab244268-5c40-4389-8189-6f289a9fc99c/width=450/5419311.jpeg",
        "trainedWords": [
            "ral-blacklight"
        ],
        "file_name": "ral-blacklight-sdxl.safetensors"
    },
    {
        "name": "Cyber Background",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9b74d64f-7100-4380-878c-5c4c6f06fc88/width=450/2044775.jpeg",
        "trainedWords": [],
        "file_name": "Cyber_Background_sdxl.safetensors"
    },
    {
        "name": "Torn Clothes - SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ed9df854-9f62-4022-b98d-383edc1dba16/width=450/4308968.jpeg",
        "trainedWords": [
            "torn",
            "fly down",
            "torn at _______"
        ],
        "file_name": "tornclothesv6-8.safetensors"
    },
    {
        "name": "OrkStyleAI (SDXL + SD1.5) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/72595791-3fea-42ae-ad9e-6587bca259b5/width=450/3832415.jpeg",
        "trainedWords": [
            "OrkStyleAI"
        ],
        "file_name": "OrkStyleAIp.safetensors"
    },
    {
        "name": "Low poly count - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0dd6a572-123f-4a2b-9d09-2a9574bba2b5/width=450/4364032.jpeg",
        "trainedWords": [
            "low poly count"
        ],
        "file_name": "low_poly_count.safetensors"
    },
    {
        "name": "FF Style: Rafal Olbinski | Surrealist",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ef7e5b1e-794a-4c5d-9744-0e39953b88e1/width=450/3281202.jpeg",
        "trainedWords": [],
        "file_name": "FF-Style-Rafal-Olbinski.LORA.safetensors"
    },
    {
        "name": "XL Palette knife painting",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/48cb9a3d-f9d8-4cd3-917c-e7a2ddea95f7/width=450/2997309.jpeg",
        "trainedWords": [],
        "file_name": "xl_MaxRos.safetensors"
    },
    {
        "name": "EnvyFantasticXL01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d50c2e0f-5276-488f-ac74-9373747455d1/width=450/3659280.jpeg",
        "trainedWords": [
            "fantastic"
        ],
        "file_name": "EnvyFantasticXL01.safetensors"
    },
    {
        "name": "XL Weapon Fist Gauntlets - By HailoKnight",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3591a1c4-0431-4fa7-ab6e-71d0f6b5006b/width=450/4636483.jpeg",
        "trainedWords": [
            "fist gauntlets"
        ],
        "file_name": "XL_Weapon_Fist_Gauntlets_-_By_HailoKnight.safetensors"
    },
    {
        "name": "Onion Style XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d3354a2b-4645-4461-aa6c-4242fa2c5d71/width=450/4847782.jpeg",
        "trainedWords": [
            "onionstyle"
        ],
        "file_name": "onionstyle.safetensors"
    },
    {
        "name": "InfraBlackholeTechAI (SXDL, tribute to Navimixu, 36MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e409d1b6-ee73-43d6-8367-a5a983b0470f/width=450/2686418.jpeg",
        "trainedWords": [
            "inblackholetechAI "
        ],
        "file_name": "InfraBlackholeTechAIp.safetensors"
    },
    {
        "name": "RPGJesterXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/90e3827a-693b-40f8-8290-d34a0af76f38/width=450/2297633.jpeg",
        "trainedWords": [
            "jester"
        ],
        "file_name": "RPGJesterXL.safetensors"
    },
    {
        "name": "Medieval Illustration Style - SDXL 1.0",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c7b29a91-f9e8-4a73-ac45-d9b18092ed4f/width=450/1843905.jpeg",
        "trainedWords": [
            "vintageillustration"
        ],
        "file_name": "vintage_illust.safetensors"
    },
    {
        "name": "JR East E235 series / train interior",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3f343226-0d7c-4b6f-a2e7-6ed2dcf0ee0e/width=450/2064701.jpeg",
        "trainedWords": [
            "e235, train interior, "
        ],
        "file_name": "E235l_SDXL_V1.safetensors"
    },
    {
        "name": "Hankyu 3000 series / train interior",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/39945d92-527e-4bbc-a192-35629a950f69/width=450/2066211.jpeg",
        "trainedWords": [
            "hankyu3000"
        ],
        "file_name": "Hankyu3000_SDXL_V1.safetensors"
    },
    {
        "name": "国画 | 水墨 | 山水画 SDXL(CHINESE INK PAINTING)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2b7ed337-3686-40e5-95e4-e133645d1f16/width=450/2228121.jpeg",
        "trainedWords": [
            "QIEMANCN"
        ],
        "file_name": "landscape-painting-sdxl_v2.safetensors"
    },
    {
        "name": "Cyber Room",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b82bbb53-799f-400a-9051-62b4077dc642/width=450/1998147.jpeg",
        "trainedWords": [],
        "file_name": "cyber_room_sdxl.safetensors"
    },
    {
        "name": "[LoRA] Senko-san | Multiple outfits",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/60bb6dae-7883-4ff5-891c-470f4f31a7a9/width=450/4290875.jpeg",
        "trainedWords": [
            "senko-san miko",
            "senko-san maid",
            "senko-san swimsuit",
            "senko-san sleepwear"
        ],
        "file_name": "MultiSenko v1.0b.safetensors"
    },
    {
        "name": "RPGGhostXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/39f54cc0-cc21-4f91-ab3d-b2196884ee13/width=450/2156190.jpeg",
        "trainedWords": [
            "ghost"
        ],
        "file_name": "RPGGhostXL.safetensors"
    },
    {
        "name": "Stillsuit SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a3fe0f8f-0d3b-4818-a3e5-24028a4a17e0/width=450/2826908.jpeg",
        "trainedWords": [],
        "file_name": "Stillsuit.safetensors"
    },
    {
        "name": "Envy Kawaii XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4bcf5e73-8da9-442d-a72f-7127a41c04f9/width=450/2272503.jpeg",
        "trainedWords": [],
        "file_name": "EnvyKawaiiXL01.safetensors"
    },
    {
        "name": "Mind Warp",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b8faa542-7221-41eb-883d-2ab527bdf411/width=450/2308309.jpeg",
        "trainedWords": [],
        "file_name": "MindWarp.safetensors"
    },
    {
        "name": "XL All-in-one Hairstyle Bundle",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a24da122-934b-4493-ae15-890cc7b44b6e/width=450/2646455.jpeg",
        "trainedWords": [
            "big hair",
            "double bun",
            "floofy bob",
            "mega ponytail",
            "mega side ponytail",
            "mega twin drills",
            "mega twintails",
            "ojou curls"
        ],
        "file_name": "xl_n15g_aio_hairstyles-1.0.safetensors"
    },
    {
        "name": "Chromatic Taleweaver",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7d476246-5194-4067-bc7e-635c0b86b79f/width=450/2649778.jpeg",
        "trainedWords": [],
        "file_name": "Chromatic_Taleweaver.safetensors"
    },
    {
        "name": "[1.5/XL 1.0] Yae Miko | Genshin Impact",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/fd2436e2-ea9c-4035-982f-154643e49fd4/width=450/1815009.jpeg",
        "trainedWords": [
            "yae miko",
            "hair ornament",
            "japanese clothes",
            "sideboob",
            "detached sleeves",
            "wide sleeves",
            "jewelry",
            "earrings",
            "bare shoulders"
        ],
        "file_name": "yaemiko_loraxl.safetensors"
    },
    {
        "name": "Shocked - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8754a54c-62a5-4140-83bc-bff81b9f4dc7/width=450/4237522.jpeg",
        "trainedWords": [
            "shocked",
            "nonplussed"
        ],
        "file_name": "shocked.safetensors"
    },
    {
        "name": "Envy Zoom Slider XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e71327e1-cc67-4261-9107-cfcf2490b6ed/width=450/3949708.jpeg",
        "trainedWords": [],
        "file_name": "EnvyZoomSliderXL01.safetensors"
    },
    {
        "name": "Saki Fuwa from Tower of Fantasy",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/91f016cb-328e-4998-b296-c5f13a1304de/width=450/2324747.jpeg",
        "trainedWords": [],
        "file_name": "sakifuwa_v12_XL-30-000030.safetensors"
    },
    {
        "name": "XL  Outline /  轮廓线",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/052eea84-97ec-4b97-959d-abe27f0dc82b/width=450/3041463.jpeg",
        "trainedWords": [],
        "file_name": "outline_xl_end.safetensors"
    },
    {
        "name": "RPGCentaurXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/938e9318-39d9-4fea-b10b-860f84dcea6d/width=450/2323037.jpeg",
        "trainedWords": [
            "centaur"
        ],
        "file_name": "RPGCentaurXL.safetensors"
    },
    {
        "name": "XL Mega Ponytail",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/28232a37-8062-4121-9980-368da11c5743/width=450/2002482.jpeg",
        "trainedWords": [
            "mega ponytail",
            "big hair"
        ],
        "file_name": "xl_mega_ponytail-1.0.safetensors"
    },
    {
        "name": "Studio Ghibli [Art Style Capture] LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6585bdd8-4248-427f-82c5-8a26a6795f22/width=450/3408331.jpeg",
        "trainedWords": [
            "Studio Ghibli"
        ],
        "file_name": "studio_ghibli_xl_v1.safetensors"
    },
    {
        "name": "Wallpaper X",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/287c705a-55f0-4e1d-8f8b-236cf089d50c/width=450/2297144.jpeg",
        "trainedWords": [],
        "file_name": "wallpaper_X.safetensors"
    },
    {
        "name": "RPGOrcXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f9225a8d-8359-4e30-9de0-102d843230bd/width=450/2196820.jpeg",
        "trainedWords": [
            "orc"
        ],
        "file_name": "RPGOrcXL.safetensors"
    },
    {
        "name": "[XL]Felt_Craft",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ad18cfc0-e047-4e7e-92d1-7341bec69d48/width=450/2390292.jpeg",
        "trainedWords": [],
        "file_name": "[XL]Felt_Craft.safetensors"
    },
    {
        "name": "Charlize Theron SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c709c435-93da-4450-b40b-1c280d29247e/width=450/2171004.jpeg",
        "trainedWords": [
            "charlize theron",
            "ohwx woman"
        ],
        "file_name": "charlizetheron_SDXL.safetensors"
    },
    {
        "name": "Porcelain Style [SDXL LoRA]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/668626e7-753a-4f5b-802e-3e85ba5b3255/width=450/3858412.jpeg",
        "trainedWords": [
            "ral-porcelain"
        ],
        "file_name": "ral-porcelain-sdxl.safetensors"
    },
    {
        "name": "Dark Magician Girl [Yu-Gi-Oh!] LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/762f6a37-f265-4b0d-8392-7d6755914f28/width=450/2090759.jpeg",
        "trainedWords": [
            "dark magician girl",
            "yu-gi-oh!"
        ],
        "file_name": "dark_magician_girl_v0.0.1.safetensors"
    },
    {
        "name": "Mila Kunis SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ecd69c9e-5a22-42dc-a84b-1414658c6ec6/width=450/2067670.jpeg",
        "trainedWords": [
            "Mila Kunis",
            "ohwx woman"
        ],
        "file_name": "milakunis_SDXL.safetensors"
    },
    {
        "name": "HRGigerAI (SDXL, 28MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b594633c-f7f4-46f7-835f-55f96465783d/width=450/2628836.jpeg",
        "trainedWords": [
            "HRGigerAI"
        ],
        "file_name": "HRGigerAIp.safetensors"
    },
    {
        "name": "Angry - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ba62fc06-fd1a-47f1-bfaf-60809607c6cc/width=450/4133657.jpeg",
        "trainedWords": [
            "angry",
            "calm"
        ],
        "file_name": "angry.safetensors"
    },
    {
        "name": "Dance Art",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/23fd8d70-470b-4217-bedd-ca372ac52f0f/width=450/2365287.jpeg",
        "trainedWords": [],
        "file_name": "Dance_Art.safetensors"
    },
    {
        "name": "Fog. SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/886a90e3-01ba-4602-83c9-3fee3770aa73/width=450/2102898.jpeg",
        "trainedWords": [
            "fog",
            "in the fog"
        ],
        "file_name": "SDXL_Fog_Sa_May_V2.safetensors"
    },
    {
        "name": "Tom Whalen Artwork XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a33dd1b1-bbd3-4c0f-82a6-a8a0036d551e/width=450/2508155.jpeg",
        "trainedWords": [],
        "file_name": "Tom_Whalen.safetensors"
    },
    {
        "name": "Synaptic Shadows",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/708f1323-7c89-4ebd-b334-97a7bade5fe4/width=450/2498052.jpeg",
        "trainedWords": [],
        "file_name": "Synaptic_Shadows.safetensors"
    },
    {
        "name": "Absolutely Terrified - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bbd26390-19f7-4a49-8030-a0788aae3025/width=450/4423471.jpeg",
        "trainedWords": [
            "absolutely terrifying"
        ],
        "file_name": "absolutely_terrified.safetensors"
    },
    {
        "name": "Essenz - Jean Giraud/Moebius [Voyage d'Hermès] (Style LoRa for SDXL 1.0)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1bfe8ad4-52c7-45fa-bc96-60c49c505e5d/width=450/3376345.jpeg",
        "trainedWords": [
            "artwork in jmst artstyle"
        ],
        "file_name": "SDXL1.0_Essenz-series-by-AI_Characters_Style_MoebiusJeanGiraud-v1.0.safetensors"
    },
    {
        "name": "Genshin_XL｜原神妮露 Nilou",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/51a92f33-3fdd-4a37-92ea-47fd1e035d46/width=450/2881805.jpeg",
        "trainedWords": [
            "(\\ni lu\\)"
        ],
        "file_name": "Niloux_xl.safetensors"
    },
    {
        "name": "Volodymyr Zelenskyy SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/620d0edc-7305-4100-8e65-264dfaa3601c/width=450/1986168.jpeg",
        "trainedWords": [
            "zelenskyy",
            "ohwx man"
        ],
        "file_name": "volodymyrzelenskyy_SDXL.safetensors"
    },
    {
        "name": "PastelartXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f1621e21-37e1-4aa6-a3f3-003a2391bd01/width=450/2594439.jpeg",
        "trainedWords": [
            "Drawing in pastels"
        ],
        "file_name": "PasteldrawnXL0.8.safetensors"
    },
    {
        "name": "Braces SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/deec4702-1bfc-4df7-8174-43081b09305b/width=450/5199081.jpeg",
        "trainedWords": [
            "braces",
            "teeth"
        ],
        "file_name": "braces-sdxl.safetensors"
    },
    {
        "name": "Paper Cutout style (SDXL Dreambooth LoRA)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/fb97a6de-a23b-42c8-bd0c-2d1ff952af33/width=450/3724100.jpeg",
        "trainedWords": [
            "PaperCutout style"
        ],
        "file_name": "sdxl-PaperCutouts-Dreambooth.safetensors"
    },
    {
        "name": "Envy Greebles XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4b2f5ce9-0978-45fb-aee3-2e2bca4ccd6e/width=450/3875591.jpeg",
        "trainedWords": [],
        "file_name": "EnvyGreeblesXL01.safetensors"
    },
    {
        "name": "Beyond the black rainbow Style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4b8967ac-ecd3-4146-ad42-d9d5d89b3708/width=450/2086540.jpeg",
        "trainedWords": [
            "beyond_the_black_rainbow"
        ],
        "file_name": "beyond_the_black_rainbow-sdxl.safetensors"
    },
    {
        "name": "CulturePunkAI 3/3 (SDXL, bundle, 104MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d5d5d91f-0a8c-4db3-8cbc-05c577de4427/width=450/2257067.jpeg",
        "trainedWords": [
            "CattlePunkAI",
            "MusketPunkAI",
            "PiratePunkAI",
            "SamuraiPunkAI",
            "vikingpunkai"
        ],
        "file_name": "CulturePunkAI_3.safetensors"
    },
    {
        "name": "Space Girl",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/103e9a83-2dfe-4d29-b453-c60723a9591c/width=450/2218331.jpeg",
        "trainedWords": [],
        "file_name": "space_girl.safetensors"
    },
    {
        "name": "Blue Archives_XL 妃咲 碧蓝档案",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3e1dab73-3d7d-4766-8714-b44e83279018/width=450/3050101.jpeg",
        "trainedWords": [
            "feixiao",
            "dress,china dress,hair ornament,chinese clothes,butterfly hair ornament,halo,"
        ],
        "file_name": "feixiaox_xl.safetensors"
    },
    {
        "name": "Blue Archives_XL 飞鸟马时 Asuma Toki 碧蓝档案",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/aa11e0e4-4f4e-4fcd-9882-3d7229c2c9bf/width=450/3049681.jpeg",
        "trainedWords": [
            "feiniao",
            "feiniao,1girl,playboy bunny,halo,rabbit ears,bow,tail,"
        ],
        "file_name": "feiniaox_xl.safetensors"
    },
    {
        "name": "Silhouette - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a1e22667-85e6-49f4-9a05-3e27ef7d429b/width=450/4587247.jpeg",
        "trainedWords": [
            "silhouette"
        ],
        "file_name": "silhouette.safetensors"
    },
    {
        "name": "Sketchbook Style  XL 草稿本画风",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/06e9fcd8-afb7-46e8-aed3-cc8a71ad887a/width=450/3746127.jpeg",
        "trainedWords": [
            "sketch"
        ],
        "file_name": "caogao.safetensors"
    },
    {
        "name": "Deepth Of Mind-SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b53bdfaa-64ed-4374-81a3-766f5184c8b7/width=450/2731269.jpeg",
        "trainedWords": [
            "silhouette"
        ],
        "file_name": "DeepthOfMind-SDXL-Version1.safetensors"
    },
    {
        "name": "Orcs WoW Style [SDXL LoRA]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/ba069a09-187d-44d3-ad3c-9fd8ae81690d/width=450/3507096.jpeg",
        "trainedWords": [
            "wowrcs"
        ],
        "file_name": "wowrcs-sdxl.safetensors"
    },
    {
        "name": "Envy Magical XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/7b93d6f7-1b0d-4316-982e-fc1b15c405ad/width=450/3552132.jpeg",
        "trainedWords": [
            "magical"
        ],
        "file_name": "EnvyMagicalXL01.safetensors"
    },
    {
        "name": "DonM - Fairy Wings - art style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/46092afc-4550-489c-8aa7-ced92eb22615/width=450/2932524.jpeg",
        "trainedWords": [
            "DonMF41ryW1ng5XL"
        ],
        "file_name": "DonMF41ryW1ng5XL.safetensors"
    },
    {
        "name": "Echoes",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/8d23a5f7-392b-4c33-a622-cdea3bc21b6a/width=450/2112999.jpeg",
        "trainedWords": [
            "Echoes"
        ],
        "file_name": "Echoes_sdxl.safetensors"
    },
    {
        "name": "Pastry [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/aa111b96-5bdc-4580-8d61-817b68fc5fba/width=450/3403727.jpeg",
        "trainedWords": [
            "pastry"
        ],
        "file_name": "pastry-sdxl.safetensors"
    },
    {
        "name": "Porcellana(XL)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f721c19c-c7d8-4e6e-880c-1c2917920343/width=450/3372306.jpeg",
        "trainedWords": [
            "'Porcellana style'",
            "'Art Painting'",
            "'at night'",
            "'by the window'"
        ],
        "file_name": "PorcellanaXL_V3e10.safetensors"
    },
    {
        "name": "黑白·神魔 (Black and White · Gods and Demons)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/dbee1370-6114-4bf4-adf2-53db0c313d35/width=450/5086074.jpeg",
        "trainedWords": [
            "wabstyle"
        ],
        "file_name": "wabstyleSDXL.safetensors"
    },
    {
        "name": "Envy Haze / Clarity Slider XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4dfb277e-82b3-4d1b-919f-8631b2ac682d/width=450/3606692.jpeg",
        "trainedWords": [
            "haze"
        ],
        "file_name": "EnvyHazeSliderXL01.safetensors"
    },
    {
        "name": "TumorPunkAI (SDXL) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/526b9382-c65d-4d97-beb7-c9471029eb33/width=450/2945502.jpeg",
        "trainedWords": [
            "tumorpunkai"
        ],
        "file_name": "TumorPunkAIp.safetensors"
    },
    {
        "name": "PE Grimace [Character]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/57072d5c-1a8c-4ccc-9fcc-56f9b41b6823/width=450/2155804.jpeg",
        "trainedWords": [
            "Grimace"
        ],
        "file_name": "PE_Grimace.safetensors"
    },
    {
        "name": "KK | Long Exposure SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a03169de-2cc5-4a31-9e8e-c620b7350aad/width=450/3547314.jpeg",
        "trainedWords": [
            "long exposure",
            "expo"
        ],
        "file_name": "Long_Exposure_XL-KK.safetensors"
    },
    {
        "name": "Genshin_XL｜原神达达利亚 Tartaglia  (genshin impact)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0f5ef03c-fe37-40e6-840f-4dc23513abd0/width=450/3526320.jpeg",
        "trainedWords": [
            "dadaliya, tartaglia (genshin impact),  1boy, blue eyes, gloves,orange hair,  mask on head, solo, bangs, jacket, hair between eyes, earrings,short hair,"
        ],
        "file_name": "dadaliya_xl.safetensors"
    },
    {
        "name": "Diamond XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/961ec646-5181-4d16-b274-8af2a88f92c7/width=450/4001682.jpeg",
        "trainedWords": [
            "diam0nd"
        ],
        "file_name": "diam0nd.safetensors"
    },
    {
        "name": "DonM - Nightdemon",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e88d5cf9-ac27-4f0c-ad11-5cbeb476aa93/width=450/5099843.jpeg",
        "trainedWords": [
            "DonMN1gh7D3m0nXL"
        ],
        "file_name": "DonMN1gh7D3m0nXL-v1.1-000008.safetensors"
    },
    {
        "name": "Rubber Patch Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/eb58f267-ebed-4141-a3f9-3f69be55b217/width=450/4733653.jpeg",
        "trainedWords": [
            "ral-embroideredpatch"
        ],
        "file_name": "ral-rubberpatch-sdxl.safetensors"
    },
    {
        "name": "Unicorn LoRA XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/fb455f96-22ae-41e7-80c5-4e33af4a9931/width=450/4809486.jpeg",
        "trainedWords": [
            "unicorn"
        ],
        "file_name": "unicorn_xl_v1.safetensors"
    },
    {
        "name": "BugCraftXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/47d17ab3-d344-4d5e-8054-fc336ac4743d/width=450/5385219.jpeg",
        "trainedWords": [
            "BugCraft"
        ],
        "file_name": "BugCraftXL.safetensors"
    },
    {
        "name": "Strawberry Jam Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5f483e65-931c-4ac2-bfec-1ecc14e6e044/width=450/4376592.jpeg",
        "trainedWords": [
            "ral-strawberryjam"
        ],
        "file_name": "ral-strawberryjam-sdxl.safetensors"
    },
    {
        "name": "Mistletoe Mercs - SDXL LoRa",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/db607b68-d38d-44b9-81f5-52e70f7b007a/width=450/4641681.jpeg",
        "trainedWords": [
            "mstltmrc"
        ],
        "file_name": "mstltmrc.safetensors"
    },
    {
        "name": "Electricity Style [LoRA 1.5+SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/415d481f-8e70-4f6d-b9e0-82c84cafc01e/width=450/5557704.jpeg",
        "trainedWords": [
            "ral-elctryzt"
        ],
        "file_name": "ral-elctryzt-sdxl.safetensors"
    },
    {
        "name": "Bebop SDXL (Cowboy Bebop)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/790d66d1-1e11-4fc7-bfaf-f27e09c0ee41/width=450/4372701.jpeg",
        "trainedWords": [
            "cbbebop",
            "cbbebop spaceship"
        ],
        "file_name": "Bebop_SDXL_Cowboy_Bebop.safetensors"
    },
    {
        "name": "Guillotine",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6e09d3ac-deaf-4f98-9488-2c390d18e2b5/width=450/4361738.jpeg",
        "trainedWords": [
            "guillotine"
        ],
        "file_name": "guillotine.safetensors"
    },
    {
        "name": "Toilet Paper Style [SDXL]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/11ae27d9-5a52-4c98-b980-ed64578335b7/width=450/5526901.jpeg",
        "trainedWords": [
            "ral-toiletpaper"
        ],
        "file_name": "ral-toiletpaper-sdxl.safetensors"
    },
    {
        "name": "Illegal Immagration In America",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/cfb3819c-e9e9-4062-a905-00095a2dbf9a/width=450/4975186.jpeg",
        "trainedWords": [],
        "file_name": "Illegal_Immagration_In_America-000015.safetensors"
    },
    {
        "name": "TESLA TOY CARS - By DICE",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/efe65abf-63eb-4bc1-bef2-e96c25005832/width=450/5248479.jpeg",
        "trainedWords": [
            "Hot Wheels toy car company have made a toy car version of a Tesla Model 3, The toy telsa model 3 car comes in a mini display unit/box. The car is exploding into fire and flames, white smoke fills the top of the display box, (realistic explosion from car), (realistic Flames bellow from the car windows inside the display unit),  (standing next to the car is a little palstic toy man, he holds both arms in the air, "
        ],
        "file_name": "TESLA_TOY_CARS.safetensors"
    },
    {
        "name": "Undressing LoRA [SD1.5 & SDXL1.0]",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/5c5cf52f-9095-4297-b0fb-47f100294002/width=450/4835443.jpeg",
        "trainedWords": [
            "undressing"
        ],
        "file_name": "undressing_xl_v0.safetensors"
    },
    {
        "name": "[XL&1.5] 2B (‎YoRHa No.2 Type B) Nier:Automata",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/35373e29-0379-4c8c-93bc-c62413837b33/width=450/3132222.jpeg",
        "trainedWords": [
            "yorha2b",
            "2b_outfit"
        ],
        "file_name": "yorha2b_sdxl_V10-000008.safetensors"
    },
    {
        "name": "Latex.ID - LoRa Latex Generator",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/208c5c1b-1e31-4bb9-b5cb-59f8f2612660/width=450/3148021.jpeg",
        "trainedWords": [],
        "file_name": "FF-Latex.ID-XL-011.bf16.safetensors"
    },
    {
        "name": "Street Tones",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/69312d52-4e7f-4b85-b40b-8e15c3059665/width=450/2207676.jpeg",
        "trainedWords": [],
        "file_name": "street_tones.safetensors"
    },
    {
        "name": "Starfield stype food : chunks",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/bafe6db5-71ba-423a-bf47-0a6f609d694a/width=450/2472201.jpeg",
        "trainedWords": [
            "chunks"
        ],
        "file_name": "chunks3.safetensors"
    },
    {
        "name": "おまかせにぎり盛り合わせ sushi SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b418e6a6-57d7-4c20-8675-6e509cfa7756/width=450/2241917.jpeg",
        "trainedWords": [
            "nigiriL, sushi, food focus, food, still life, vegetable, plate, realistic, "
        ],
        "file_name": "sushi_SDXL_V2.safetensors"
    },
    {
        "name": "Millennium Falcon SDXL (Star Wars)",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1ee3cbaa-a1a9-4956-8801-e0111db813ca/width=450/4076121.jpeg",
        "trainedWords": [
            "mfalcon"
        ],
        "file_name": "Millennium_Falcon_SDXL_005Beta.safetensors"
    },
    {
        "name": "Japanese Girl - SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/1e3a8fd3-805f-4855-97bf-c1ec50532f98/width=450/1868757.jpeg",
        "trainedWords": [
            "jpn-girl"
        ],
        "file_name": "jpn-girl_v1.safetensors"
    },
    {
        "name": "UraniumTech (SDXL, tribute to Navimixu, 68MB) - konyconi",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/95619430-8641-45bc-8b34-ecbd14dc03e8/width=450/2468446.jpeg",
        "trainedWords": [
            "uraniumtech"
        ],
        "file_name": "UraniumTechAIp.safetensors"
    },
    {
        "name": "Envy Junkworld XL 01",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/144f4a89-d1d4-4d15-8c8f-168dca612efc/width=450/4606574.jpeg",
        "trainedWords": [
            "junkworld"
        ],
        "file_name": "EnvyJunkworldXL01.safetensors"
    },
    {
        "name": "destruction_XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/d761a5b5-6004-43ac-91b8-5b2bee648147/width=450/5001488.jpeg",
        "trainedWords": [
            "destruction",
            "ruined",
            "collapsed",
            "flooded"
        ],
        "file_name": "destruction_XL-000010.safetensors"
    },
    {
        "name": "CyborgsXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f5b05521-8e71-4d40-b8c9-88ae053085d8/width=450/5250873.jpeg",
        "trainedWords": [
            "cyborg",
            "science fiction",
            "cyberpunk"
        ],
        "file_name": "CyborgsXL.safetensors"
    },
    {
        "name": "Sauron LORA SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/53998b89-47aa-46f9-a593-fe68a45fc17c/width=450/1882772.jpeg",
        "trainedWords": [
            "sauron",
            "dark lord sauron"
        ],
        "file_name": "sauronXL.safetensors"
    },
    {
        "name": "Lowbrow Art Style SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/18912945-d2a5-4230-8b99-591a67b244c1/width=450/4934496.jpeg",
        "trainedWords": [
            "lowbrow art style",
            "surreal"
        ],
        "file_name": "LowBrowXL.safetensors"
    },
    {
        "name": "Multiple XL Sliders - Age, weight, Hands, etc",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/530fa684-c376-48f8-ba64-c0e1a762517e/width=450/4738288.jpeg",
        "trainedWords": [],
        "file_name": "professional.safetensors"
    },
    {
        "name": "Slimwoman XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/179e6988-f6a1-4c7a-b063-77be4a62ee06/width=450/3974965.jpeg",
        "trainedWords": [
            "slimwoman",
            "naked",
            "very small breast"
        ],
        "file_name": "slimwoman.safetensors"
    },
    {
        "name": "RPGAlchemist",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/c400acad-36fd-4571-93c2-b660f2292f0e/width=450/1898980.jpeg",
        "trainedWords": [
            "alchemist"
        ],
        "file_name": "RPGAlchemistsXL.safetensors"
    },
    {
        "name": "Pencil Art B&W (Jasmine style) XL 1.0",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/4a4da853-9a9b-4bc3-8108-b69df2bb930e/width=450/1805876.jpeg",
        "trainedWords": [
            "jsbw style"
        ],
        "file_name": "JasmineBWXLDoguMini.safetensors"
    },
    {
        "name": "Coloring book - LineArt",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/794b6632-dcac-4584-8bf4-95d419c983f1/width=450/3125383.jpeg",
        "trainedWords": [],
        "file_name": "Coloring_book_-_LineArt.safetensors"
    },
    {
        "name": "Uberfit - Muscular Women",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e69a1be1-b151-458a-8367-1728c1f93d1c/width=450/2355668.jpeg",
        "trainedWords": [
            "uberfit",
            "muscular",
            "athletic"
        ],
        "file_name": "uberfit_lora_sdxl.safetensors"
    },
    {
        "name": "Futureistic Fantasy Anime SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/3782bcc1-cb00-4fe4-b277-fcd4ea6860fb/width=450/1883673.jpeg",
        "trainedWords": [
            "futuristic-fantasy-anime"
        ],
        "file_name": "futureistic_fantasy_anime_sdxl_v1.safetensors"
    },
    {
        "name": "RPGBanshee",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/38578c7d-497c-401b-82fe-69af5875d36a/width=450/1901089.jpeg",
        "trainedWords": [
            "banshee"
        ],
        "file_name": "RPGBansheeXL.safetensors"
    },
    {
        "name": "ChibiStyleXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e9d54175-3866-468f-ba78-94e7ef7e349f/width=450/1899621.jpeg",
        "trainedWords": [
            "chibi"
        ],
        "file_name": "chibistylexl-v1.safetensors"
    },
    {
        "name": "Embroidery Style - SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/f50612cc-7db1-4c22-82bf-080f208c36df/width=450/1866196.jpeg",
        "trainedWords": [],
        "file_name": "embroidered_style_v1_sdxl.safetensors"
    },
    {
        "name": "Lana Rhoades SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/6a672869-de02-4f3e-9017-bb0a9f93c88d/width=450/2285141.jpeg",
        "trainedWords": [
            "lana rhoades",
            "ohwx woman"
        ],
        "file_name": "lanarhoades_SDXL.safetensors"
    },
    {
        "name": "Korean Photo Studio Style XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/50f1b076-52ef-44b4-a1cd-0f3ac13d2d53/width=450/1945461.jpeg",
        "trainedWords": [],
        "file_name": "Korean_Photo_Studio_Style_XL.safetensors"
    },
    {
        "name": "Genshin_XL｜原神夜兰 Yelan",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/91f0eee6-4a94-4434-88c4-b9dfa13f3906/width=450/2706725.jpeg",
        "trainedWords": [
            "(\\ye lan\\)"
        ],
        "file_name": "Yelan.safetensors"
    },
    {
        "name": "RPGDwarfXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b2114c6c-fa13-43e4-b4e6-84bb771d0201/width=450/2144210.jpeg",
        "trainedWords": [
            "dwarf"
        ],
        "file_name": "RPGDwarfXL.safetensors"
    },
    {
        "name": "Nina Dobrev SDXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/a53be8af-aef5-4335-aba4-be3f1d1749c4/width=450/1952567.jpeg",
        "trainedWords": [
            "Nina Dobrev",
            "ohwx woman"
        ],
        "file_name": "ninadobrev_SDXL.safetensors"
    },
    {
        "name": "Genshin_XL｜原神申鹤  Shenhe",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/af8326cd-b84a-453a-8e4f-582b54d8d4c3/width=450/2732067.jpeg",
        "trainedWords": [
            " (\\shen he\\)"
        ],
        "file_name": "Shenhe_xl.safetensors"
    },
    {
        "name": "Eye catching - sliders / ntcai.xyz",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/2eb20513-0bf1-4146-97d5-168e3301136f/width=450/4291919.jpeg",
        "trainedWords": [
            "eye catching",
            "drab"
        ],
        "file_name": "eye_catching.safetensors"
    },
    {
        "name": "[Aberration]Sumi-e",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/aa41b5bc-545f-4618-95e2-e1770b7f594a/width=450/2880829.jpeg",
        "trainedWords": [
            "sumi-e"
        ],
        "file_name": "[XL]Sumi-e.safetensors"
    },
    {
        "name": "RPGWeaponsmithXL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/b91a8340-0106-4dce-9475-bb7e2b796a66/width=450/2250230.jpeg",
        "trainedWords": [
            "weaponsmith"
        ],
        "file_name": "RPGWeaponsmithXL.safetensors"
    },
    {
        "name": "By Night Portraits",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e9f73c3d-0852-48fc-b035-93bfdc53347e/width=450/2287855.jpeg",
        "trainedWords": [],
        "file_name": "bynightPortraits-XL.safetensors"
    },
    {
        "name": "Hair Style",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/35ba7a19-2840-4f7f-ab11-612ccc6a32fa/width=450/2193821.jpeg",
        "trainedWords": [],
        "file_name": "hair_style.safetensors"
    },
    {
        "name": "【SDXL】March_7th  |  Star Rail",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/0ce41bf1-8105-4d6d-b312-360040404ba8/width=450/3205267.jpeg",
        "trainedWords": [
            "march 7th"
        ],
        "file_name": "march_7th.safetensors"
    },
    {
        "name": "CocoNady_XL",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/e843afcd-5023-4904-8740-605a99c8823e/width=450/2395736.jpeg",
        "trainedWords": [
            "ckn"
        ],
        "file_name": "model_SDXL.safetensors"
    },
    {
        "name": "ganyu_xl",
        "image": "https://image.civitai.com/xG1nkqKTMzGDvpLrqFT7WA/9690d419-a4ab-48e3-9441-49f8185b45e6/width=450/4735854.jpeg",
        "trainedWords": [],
        "file_name": "ganyu_xl.safetensors"
    }
]