import React, { useContext, useState } from 'react';
import { APainterContext, APainterContextValue } from "./APainterContext";
import { UserProperties } from './interface';
import BuyCredits from './BuyCredits';
import CreditsIcons from './CreditsIcon';
import { btnstyle, containerStlye } from './util';
//@types/react/index.d.ts
export default function Credits() {
    const [showBuy, setShowBuy] = useState(false);
    const context: APainterContextValue = useContext(APainterContext);

    if (context?.user?.[UserProperties.Credits] && !showBuy) {
        return <div style={containerStlye}>
            <div style={{
                display: 'flex', flexDirection: 'row'
            }}>
                <CreditsIcons />
                <span style={{
                    fontSize: 28,
                    paddingLeft: 10
                }}>{context.user[UserProperties.Credits]}</span>

            </div>
            <div style={{ marginBottom: 5 }}>
                <a style={btnstyle} onClick={() => {
                    setShowBuy(true);
                }}>Buy more</a>
            </div>
        </div>
    }
    return <>
        <BuyCredits onClose={() => {
            setShowBuy(false);
        }} />
    </>
}