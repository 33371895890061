
function getInstance() {
    console.log('Create an instance of SpeechRecognition')
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
        console.log("SpeechRecognition API is not supported in this browser.");
    }
    if (!SpeechRecognition) {
        throw new Error("SpeechRecognition API is not supported.");
    }
    // Create an instance of SpeechRecognition
    const recognition = new SpeechRecognition();

    // Set properties if needed (e.g., continuous, language, etc.)
    recognition.interimResults = true; // Interim results
    recognition.continuous = true; // Change to true if continuous recognition is needed
    recognition.lang = 'en-US'; // Set the language
    recognition.onaudioend = (event) => {
        console.log('audio end')
    }
    recognition.onaudiostart = (event) => {
        console.log('audio start')
    }
    recognition.onnomatch = (event) => {
        console.log('on no match')
    }
    recognition.onsoundend = (event) => {
        console.log('sound end')
    }
    recognition.onsoundstart = (event) => {
        console.log('sound start')
    }
    recognition.onspeechstart = (event) => {
        console.log('speech start')
    }
    recognition.onspeechend = (event) => {
        console.log('speech end')
    }
    recognition.onstart = (event) => {
        console.log('on start')
    }
    return recognition;
}
let recognition;
// Function to initialize speech recognition
export function startSpeechRecognition(callback: (text: string, isFinal: boolean) => void): any {
    console.log('start speech recognition')
    // Ensure the SpeechRecognition API is available in the browser
    const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
    if (!SpeechRecognition) {
        console.log("SpeechRecognition API is not supported in this browser.");
        console.error("SpeechRecognition API is not supported in this browser.");
    }

    if (!SpeechRecognition) {
        throw new Error("SpeechRecognition API is not supported.");
    }

    let shouldRestart = true;
    // Create an instance of SpeechRecognition
    recognition = recognition || getInstance();

    console.log('recognition')
    console.log(recognition)
    // Set properties if needed (e.g., continuous, language, etc.)
    recognition.continuous = true; // Change to true if continuous recognition is needed
    recognition.lang = 'en-US'; // Set the language

    // Handler for the result event
    recognition.onresult = (event) => {
        let interimTranscript = '';
        let finalTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
            if (event.results[i].isFinal) {
                finalTranscript += event.results[i][0].transcript;
                callback(event.results[i][0].transcript, true);
            } else {
                interimTranscript += event.results[i][0].transcript;
                callback(event.results[i][0].transcript, false);
            }
            console.log('on result');
            console.log(event.results[i][0].transcript)
        }

        console.log("Final Transcript:", finalTranscript);
        console.log("Interim Transcript:", interimTranscript);
    };
    // Handler for end event
    recognition.onend = () => {
        console.log('Speech recognition service disconnected');
        if (shouldRestart) {
            recognition.start();
        }
    };

    // Error handling
    recognition.onerror = (event) => {
        console.error("SpeechRecognition error", event.error);
    };

    // Start speech recognition
    console.log('Start speech recognition')
    recognition.start();

    return () => {
        shouldRestart = false;
        recognition.stop();
        console.log("Recognition stopped by the user.");
    };
}

// // Usage example
// const stopListening = startSpeechRecognition((text) => {
//     console.log("Recognized text:", text);
//     // Perform actions based on the recognized text
// });

// // To stop listening
// // stopListening();
