import { useContext, useEffect, useRef, useState } from "react";
import { APainterContext } from "./APainterContext";
import useEventListener from "./painter/useEventListener";
import { PAINTER_CONSTANTS } from "./painter/constants";

export default function LoraMenuPanel({ targetLora }: any) {
    const context = useContext(APainterContext);
    const [lora, setLora] = useState(null);
    const panelRef = useRef(null);
    const [trainedWords, setTrainedWords] = useState(null);
    const selectStyleRef = useRef(null);
    const closeBtnRef = useRef(null);
    useEffect(() => {
        (window as any).loraUpdate = function () {

        };

        (window as any).loraSliderUpdate = function () {

        };
    }, [])

    useEffect(() => {
        if (panelRef?.current) {
            panelRef.current.setAttribute('animation__position1', `property: position; to:0 ${(trainedWords?.length || 0) * .2 + .02} 0; dur:200; easing:easeInOutCubic;`)
        }
    }, [panelRef, trainedWords?.length]);

    useEventListener(selectStyleRef, 'click', (evt) => {
        selectStyleRef.current.emit(PAINTER_CONSTANTS.OPEN_LORA_SELECTION, {});
    });

    useEventListener(closeBtnRef, 'click', (evt) => {
        closeBtnRef.current.emit(PAINTER_CONSTANTS.CLOSE_LORA_MENU, {});
    });

    const margin = .3;
    let buttonSize = 0.125;
    let height = 2;
    let width = 2;
    const item_height = .3;
    let floor_offset = height / 2;
    let imageSize = .5;
    let position = `0 0 0.15`;
    let items_position = `0 0 0.05`;
    useEffect(() => {
        if (targetLora) {
            let lora = context.getLoraUrl(targetLora.layer, targetLora.lora);
            setLora(lora || null);
            if (lora) {
                setTrainedWords(lora.trainedWords)
            }
        }
    }, [targetLora, context.layerPrompts])

    return (
        <>
            <a-entity ref={panelRef} >
                <frame-gui-flex-container
                    flex-direction="column"
                    component-padding="0.1"
                    opacity="0.25"
                    width={width}
                    height={height}
                    panel-color="#000"
                    panel-rounded="0.1"
                    position={position} rotation="0 0 0"
                >
                    <a-entity position={` ${width / 2 + -imageSize} ${width / 2 + (-imageSize / 2)} .03`}>
                        {lora?.image ? <a-aspect-ratio-image
                            maxwidth={imageSize}
                            maxheight={imageSize}
                            url={lora?.image}></a-aspect-ratio-image> : null}
                    </a-entity>
                    <frame-gui-icon-label-button
                        ref={closeBtnRef}
                        width={buttonSize}
                        height={buttonSize}
                        icon-font="assets/fonts/ionicons.ttf"
                        icon="f128"
                        icon-font-size="0.1"
                        value=""
                        margin="0 0 0.05 0.02"
                        position={`${-width / 2 + -buttonSize} ${height / 2} 0`}></frame-gui-icon-label-button>
                    <frame-gui-button
                        ref={selectStyleRef}
                        width="1.25" height="0.2"
                        onclick="testButtonAction"
                        value="Select Style"
                        font-color="#ffffff"
                        position={`-1.5 .5 0`}
                        margin="0 0.02 0.05 0"
                    >
                    </frame-gui-button>
                    <a-entity
                        flex-direction="column"
                        component-padding="0.1"
                        opacity="0.25"
                        width={width}
                        height={height}
                        panel-color="#000"
                        panel-rounded="0.1"
                        position={items_position}
                        rotation="0 0 0"
                    >
                        {trainedWords && targetLora && lora ? trainedWords.map((word, index) => {
                            return (
                                <>
                                    <LoraMenuRadio
                                        key={`${index}`}
                                        position={`0 ${.4 * -index} 0`}
                                        visible={true}
                                        width={width}
                                        value={context?.layerPrompts?.[context?.layerIds?.indexOf(targetLora?.layer)]?.loraConfigs?.[`lora${targetLora.lora}`]?.[lora.name]?.[word]?.enabled}
                                        margin={margin}
                                        word={word}
                                        onChange={(val) => {
                                            context.setLoraEnabled(targetLora.layer, targetLora.lora, lora.name, word, val)
                                        }} />
                                </>
                            )
                        }) : null}
                    </a-entity>
                </frame-gui-flex-container>
            </a-entity>
        </>
    )
}

export function LoraMenuRadio({ height, visible, position, value, width, margin, word, onChange }: any) {
    const radioRef = useRef(null);

    useEventListener(radioRef, "clicked", (evt) => {
        console.log(`evt?.detail?.data?.checked - ${evt?.detail?.data?.checked}`)
        onChange(evt?.detail?.data?.checked);
    })
    useEffect(() => {
        if (radioRef?.current) {
            radioRef?.current.setAttribute('checked', !!value)
            radioRef?.current.setAttribute('active', !!value)
        }
    }, [radioRef, value]);
    useEffect(() => {
        if (radioRef?.current) {
            radioRef?.current.setAttribute('show', !!visible);
            radioRef?.current.setAttribute('word', word);
            if (position) {
                radioRef?.current.setAttribute('position', position);
            }

        }
    }, [radioRef, visible, word])
    return (<>
        <frame-base-interactive
            ref={radioRef}
            width={width || 2}
            height={height || "0.4"}
            value={word}
            margin="0 0 0.05 0"
            font-size=".07"
        />
    </>
    );
}
