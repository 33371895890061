// LoginRegister.tsx
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import React, { useContext, useEffect, useState } from 'react';
import { getAuthInstance, getUserInfo } from './firebase-app';
import Spinner from './Spinner';
import { APainterContext } from './APainterContext';
import './LoginRegister.scss'

const LoginRegister: React.FC = () => {
    const context = useContext(APainterContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [checkingLogin, setCheckingLogin] = useState(false);

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const auth = getAuthInstance();
            await signInWithEmailAndPassword(auth, email, password);
            await getUserInfo().then((res) => {
                setCheckingLogin(false);
                if (res) {
                    context.setUserInfo(res);
                }
            });
            // Handle successful login
        } catch (error) {
            if (error) {
                // Handle different error types
                switch (error.code) {
                    case 'auth/user-not-found':
                        setError('No user found with this email. Please register.');
                        break;
                    case 'auth/wrong-password':
                        setError('Incorrect password. Please try again.');
                        break;
                    case 'auth/user-disabled':
                        setError('This user account has been disabled.');
                        break;
                    default:
                        setError('An error occurred during login. Please try again.');
                }
            } else {
                // Handle non-Firebase errors
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const auth = getAuthInstance();
            await createUserWithEmailAndPassword(auth, email, password);
            await getUserInfo().then((res) => {
                setCheckingLogin(false);
                if (res) {
                    context.setUserInfo(res);
                }
            });
            // Handle successful registration
        } catch (error) {
            if (error) {
                // Handle different error types
                switch (error.code) {
                    case 'auth/email-already-in-use':
                        setError('Email already in use. Please login or use a different email.');
                        break;
                    case 'auth/invalid-email':
                        setError('Invalid email. Please check your email format.');
                        break;
                    case 'auth/weak-password':
                        setError('Password is too weak. Please use a stronger password.');
                        break;
                    default:
                        setError('An error occurred during registration. Please try again.');
                }
            } else {
                // Handle non-Firebase errors
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    useEffect(() => {
        setCheckingLogin(true);
        getUserInfo().then((res) => {
            setCheckingLogin(false);
            if (res) {
                context.setUserInfo(res);
            }
        });
    }, []);

    if (checkingLogin) {
        return <Spinner />
    }

    return (
        <>
            <div className="login-register-container">
                <form onSubmit={isLogin ? handleLogin : handleRegister}>
                    <div style={{ alignContent: 'center', display: 'flex', justifyContent: 'center' }}>
                        <img src="img/pettypaint.png" style={{ maxHeight: 100 }} />
                    </div>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                    />
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                    />
                    {error && <p className="error">{error}</p>}
                    <button type="submit">{isLogin ? 'Login' : 'Register'}</button>
                    <button type="button" onClick={() => setIsLogin(!isLogin)}>
                        {isLogin ? 'Need an account? Register' : 'Have an account? Login'}
                    </button>
                </form>
            </div>
        </>
    );
};

export default LoginRegister;
