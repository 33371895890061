import React, { useState, DragEvent, useContext } from 'react';
import { useCustomEventListener } from './painter/useCustomEventListener';
import { PAINTER_CONSTANTS } from './painter/constants';
import { raiseCustomEvent } from './painter/util';
import { APainterContext } from './APainterContext';

const FontDrop: React.FC = () => {
    const [fontName, setFontName] = useState<string>('');
    const [show, setShow] = useState(false);
    const context = useContext(APainterContext);

    const handleDragOver = (e: DragEvent) => {
        e.preventDefault(); // Necessary to allow for the drop event to fire.
    };

    const handleDrop = (e: DragEvent) => {
        e.preventDefault();
        setShow(false);
        const files = e.dataTransfer.files;
        if (files.length) {
            const file = files[0];

            // Check if the file is a font file
            if (/font\/|application\/(x-font-)|(font-woff)/.test(file.type) || /\.(ttf|otf|woff|woff2)$/i.test(file.name)) {
                let fontName = file.name.split('.')[0];
                setFontName(fontName); // Assuming font name is the file name without extension

                const reader = new FileReader();
                reader.onload = (loadEvent) => {
                    const fontFace = `
                        @font-face {
                            font-family: '${fontName}';
                            src: url('${loadEvent.target?.result}') format('${getFontFormat(file.name)}');
                        }
                    `;
                    injectFontStyle(fontFace);
                    let record = {
                        name: fontName,
                        fontFamily: fontName,
                        fontWeight: 100,
                        fontStyle: 'normal',
                        family: fontName
                    };
                    raiseCustomEvent(PAINTER_CONSTANTS.ADD_FONT, { record })
                    context.addFont(record);
                };
                reader.readAsDataURL(file);
            } else {
                alert('Please drop a valid font file.');
            }
        }
    };

    // Helper function to determine font format
    const getFontFormat = (fileName: string): string => {
        if (fileName.endsWith('.ttf')) return 'truetype';
        if (fileName.endsWith('.otf')) return 'opentype';
        if (fileName.endsWith('.woff')) return 'woff';
        if (fileName.endsWith('.woff2')) return 'woff2';
        return '';
    };

    // Injects the font style into the document
    const injectFontStyle = (fontFace: string) => {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = fontFace;
        document.head.appendChild(style);
    };

    useCustomEventListener(PAINTER_CONSTANTS.SHOW_ADD_FONT_INPUT, () => {
        setShow(true);
    })
    if (!show) {
        return <></>
    }
    return (
        <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            style={{
                border: '2px dashed gray',
                padding: '20px',
                width: '400px',
                height: '200px',
                textAlign: 'center',
                lineHeight: '180px',
            }}
        >
            {fontName || 'Drag and drop a font file here'}
        </div>
    );
};

export default FontDrop;
