import { generateUniqueId } from "../../../util";
import { PAINTER_CONSTANTS } from "../../constants";
import { customEventListener } from "../../systems/grabanddrop";
import SweatshirtMaterials from "./SweatshirtMaterials";
import { ClothingImages } from "./clothing";
import { presets } from "./presets";
export default function () {
    const THREE = (window as any).THREE;
    const AFRAME = (window as any).AFRAME || {};
    const Contrado3D = {
        MaterialType: {
            Basic: 1,
            Phong: 2,
            Standard: 3,
            Physical: 4
        }
    }

    AFRAME.registerComponent('hoodie', {
        schema: {
            clothingTextures: { type: 'string', default: '' },
            width: { type: 'number', default: 1024 },
            height: { type: 'number', default: 1024 },
        },
        init: function () {
            let me = this;
            this.model = null;
            this.el.setAttribute('gltf-model', '#hoodie_obj');
            this.el.addEventListener('model-loaded', this.setModel.bind(this));
            this.hoodieCanvas = document.createElement('canvas');
            this.canvasId = generateUniqueId();
            this.hoodieCanvas.setAttribute('id', this.canvasId)
            this.hoodieCanvas.style.visibility = 'hidden';
            this.hoodieCanvas.setAttribute('height', this.data.height);
            this.hoodieCanvas.setAttribute('width', this.data.width);
            document.body.appendChild(this.hoodieCanvas);
            this.el.addEventListener(PAINTER_CONSTANTS.HOODIE_IMAGE_CHANGE, (evt: any) => {
                let clothingTextures = evt.detail;
                if (clothingTextures?.hoodie) {
                    let keys = Object.keys(ClothingImages.hoodie);
                    let updateTexture = false;
                    keys.forEach((key) => {
                        if (clothingTextures.hoodie?.[key]) {
                            let hoodiePartCanvas: any = clothingTextures.hoodie?.[key]?.canvas;
                            if (hoodiePartCanvas) {
                                updateTexture = true;
                                const textureCanvasSize = 1000;
                                const originalCanvasSize = 600;
                                let relativeSizeChange = textureCanvasSize / originalCanvasSize;
                                const assumed_size = me.data.width;
                                var width = hoodiePartCanvas.width;
                                var height = hoodiePartCanvas.height;
                                let scaleX = ClothingImages.hoodie[key].scaleX * relativeSizeChange * (assumed_size / width)
                                let scaleY = ClothingImages.hoodie[key].scaleY * relativeSizeChange * (assumed_size / height)
                                let left = ClothingImages.hoodie[key].scaleLeft * width * relativeSizeChange * (assumed_size / width)
                                let top = ClothingImages.hoodie[key].scaleTop * height * relativeSizeChange * (assumed_size / height)
                                drawCanvasWithScaleAndCenteredTranslation(me.hoodieCanvas, hoodiePartCanvas, scaleX, scaleY, left, top);
                            }
                        }
                    });
                    if (me.material && updateTexture && me.hoodieCanvas) {
                        let selectedMaterial = SweatshirtMaterials.MaterialInfo.find(x => x.MeshName === 'template');
                        if (selectedMaterial) {
                            let texture = new THREE.Texture(me.hoodieCanvas);
                            applyTextureTile(texture, selectedMaterial.MaterialConfiguration.DiffuseMapTile)
                            setTextureConfig(texture);
                            me.material.map = texture;
                            me.material.map.needsUpdate = true
                            me.material.needsUpdate = true;
                        }
                    }
                }
            })
        },
        setModel: function (evt) {
            this.model = evt.detail.model;
            let diffuseMap, texture, normalMap, specularMap, metalnessMap, roughnessMap, envMap, clearcoatMap, transmissionMap;
            let applyTexture = true;
            const loader = new THREE.TextureLoader();
            let matConfig: any = {};
            let selectedMaterial = SweatshirtMaterials.MaterialInfo.find(x => x.MeshName === 'template');
            let aoMap = loader.load(selectedMaterial.AoMapPath);
            applyTextureTile(aoMap, selectedMaterial.MaterialConfiguration.AoMapTile);
            setTextureConfig(aoMap)
            if (selectedMaterial.DiffuseMapPath) {
                diffuseMap = loader.load(selectedMaterial.DiffuseMapPath);
                applyTextureTile(diffuseMap, selectedMaterial.MaterialConfiguration.DiffuseMapTile);
                setTextureConfig(diffuseMap)
            }
            else {
                if (typeof texture != "undefined" && texture != null && applyTexture) {
                    applyTextureTile(texture, selectedMaterial.MaterialConfiguration.DiffuseMapTile);
                    setTextureConfig(texture)
                };
            }
            if (selectedMaterial.NormalMapPath) {
                normalMap = loader.load(selectedMaterial.NormalMapPath);
                applyTextureTile(normalMap, selectedMaterial.MaterialConfiguration.DiffuseMapTile);
                setTextureConfig(normalMap);
            };
            matConfig.reflectivity = selectedMaterial.MaterialConfiguration.Reflectivity != null ? selectedMaterial.MaterialConfiguration.Reflectivity : 1;
            matConfig.refractionRatio = selectedMaterial.MaterialConfiguration.RefractionRatio != null ? selectedMaterial.MaterialConfiguration.RefractionRatio : .98;
            matConfig.aoMapIntensity = selectedMaterial.MaterialConfiguration.AoMapIntensity != null ? selectedMaterial.MaterialConfiguration.AoMapIntensity : 1;
            matConfig.transparent = selectedMaterial.MaterialConfiguration.Transparent;
            matConfig.opacity = matConfig.transparent ? selectedMaterial.MaterialConfiguration.Opacity : 1;
            matConfig.depthTest = selectedMaterial.MaterialConfiguration.DepthTest;
            matConfig.depthWrite = selectedMaterial.MaterialConfiguration.DepthWrite;
            matConfig.vertexColors = selectedMaterial.MaterialConfiguration.VertexColors


            if (selectedMaterial.MaterialType >= Contrado3D.MaterialType.Phong) {
                if (selectedMaterial.SpecularMapPath && selectedMaterial.MaterialType == Contrado3D.MaterialType.Phong) {
                    specularMap = loader.load(selectedMaterial.SpecularMapPath);
                    specularMap.encoding = THREE.sRGBEncoding;
                    applyTextureTile(specularMap, selectedMaterial.MaterialConfiguration.DiffuseMapTile);
                    setTextureConfig(specularMap);
                }
            }
            matConfig.specular = new THREE.Color(selectedMaterial.MaterialConfiguration.SpecularColor);
            matConfig.shininess = selectedMaterial.MaterialConfiguration.Shininess;
            matConfig.normalScale = new THREE.Vector2(selectedMaterial.MaterialConfiguration.NormalScaleX, selectedMaterial.MaterialConfiguration.NormalScaleY);

            if (selectedMaterial.MaterialType >= Contrado3D.MaterialType.Standard && selectedMaterial.MetalnessMapPath) {
                metalnessMap = loader.load(selectedMaterial.MetalnessMapPath);
                metalnessMap.encoding = THREE.sRGBEncoding;
                matConfig.metalnessMap = metalnessMap;
                setTextureConfig(metalnessMap);
            }
            if (selectedMaterial.RoughnessMapPath) {
                roughnessMap = loader.load(selectedMaterial.RoughnessMapPath);
                matConfig.roughnessMap = roughnessMap;
                setTextureConfig(roughnessMap);
            }
            if (selectedMaterial.EnvironmentMapPath) {
                envMap = loader.load(selectedMaterial.EnvironmentMapPath);
                envMap.mapping = THREE.EquirectangularReflectionMapping;
                envMap.encoding = THREE.sRGBEncoding;
                matConfig.envMap = envMap;
                setTextureConfig(envMap);
                matConfig.envMapIntensity = selectedMaterial.MaterialConfiguration.EnvMapIntensity;
                matConfig.emissive = new THREE.Color(selectedMaterial.MaterialConfiguration.EmissiveColor);
                matConfig.emissiveIntensity = selectedMaterial.MaterialConfiguration.EmissiveIntensity;
                matConfig.roughness = selectedMaterial.MaterialConfiguration.Roughness;
                matConfig.metalness = selectedMaterial.MaterialConfiguration.Metalness;
            };

            if (selectedMaterial.MaterialType >= Contrado3D.MaterialType.Physical && selectedMaterial.ClearCoatMapPath) {
                clearcoatMap = loader.load(selectedMaterial.ClearCoatMapPath);
                clearcoatMap.encoding = THREE.sRGBEncoding;
                matConfig.clearcoatMap = clearcoatMap;
                setTextureConfig(clearcoatMap);
            }
            if (selectedMaterial.TransmissionMapPath) {
                transmissionMap = loader.load(selectedMaterial.TransmissionMapPath);
                matConfig.transmissionMap = transmissionMap;
                setTextureConfig(transmissionMap);
                matConfig.transmission = selectedMaterial.MaterialConfiguration.Transmission;
                matConfig.clearcoat = selectedMaterial.MaterialConfiguration.ClearCoat
            }

            matConfig.side = selectedMaterial.MaterialConfiguration.MaterialSide != null ? selectedMaterial.MaterialConfiguration.MaterialSide : this.produtConfigurationModel.SecneConfiguration.Object3D.MaterialSide

            const material = new THREE.MeshStandardMaterial({
                map: (texture && applyTexture && texture) || diffuseMap, // Diffuse texture
                normalMap: normalMap, // Normal map
                aoMap: aoMap, // AO map
                specularMap,
                ...matConfig
                // ...other textures
            });
            this.material = material;
            this.model.traverse((child) => {
                if (child.isMesh) {
                    // Apply the material to the child.
                    child.material = material;
                }
            });
        },
        update: function (oldData: any) {
            if (this.data.clothingTextures) {
                if (this.data.clothingTextures !== oldData.clothingTextures) {
                    let clothingTextures = JSON.parse(this.data.clothingTextures);
                    if (clothingTextures?.hoodie) {
                        let keys = Object.keys(ClothingImages.hoodie);
                        let updateTexture = false;
                        keys.forEach((key) => {
                            if (clothingTextures.hoodie?.[key]) {
                                let hoodiePartCanvas: any = document.querySelector(`${clothingTextures.hoodie?.[key]}`);
                                if (hoodiePartCanvas) {
                                    updateTexture = true;
                                    const textureCanvasSize = 1000;
                                    const originalCanvasSize = 600;
                                    let relativeSizeChange = textureCanvasSize / originalCanvasSize;
                                    const assumed_size = this.data.width;
                                    var width = hoodiePartCanvas.width;
                                    var height = hoodiePartCanvas.height;
                                    let scaleX = ClothingImages.hoodie[key].scaleX * relativeSizeChange * (assumed_size / width)
                                    let scaleY = ClothingImages.hoodie[key].scaleY * relativeSizeChange * (assumed_size / height)
                                    let left = ClothingImages.hoodie[key].scaleLeft * width * relativeSizeChange * (assumed_size / width)
                                    let top = ClothingImages.hoodie[key].scaleTop * height * relativeSizeChange * (assumed_size / height)
                                    drawCanvasWithScaleAndCenteredTranslation(this.hoodieCanvas, hoodiePartCanvas, scaleX, scaleY, left, top);
                                }
                            }
                        })
                        if (this.material && updateTexture && this.hoodieCanvas) {
                            let selectedMaterial = SweatshirtMaterials.MaterialInfo.find(x => x.MeshName === 'template');
                            if (selectedMaterial) {
                                let texture = new THREE.Texture(this.hoodieCanvas);
                                applyTextureTile(texture, selectedMaterial.MaterialConfiguration.DiffuseMapTile)
                                setTextureConfig(texture);
                                this.material.map = texture;
                                this.material.needsUpdate = true;
                            }
                        }
                    }
                }
            }
        }
    });
    /**
    * Draws one canvas onto another canvas with scaling and translation,
    * using the center of the source canvas as the origin for transformations.
    *
    * @param {HTMLCanvasElement} destCanvas - The destination canvas element.
    * @param {HTMLCanvasElement} sourceCanvas - The source canvas element to draw.
    * @param {number} scaleX - The scaling factor in the X direction.
    * @param {number} scaleY - The scaling factor in the Y direction.
    * @param {number} x - The X coordinate for translation, relative to the center of the sourceCanvas.
    * @param {number} y - The Y coordinate for translation, relative to the center of the sourceCanvas.
    */
    function drawCanvasWithScaleAndTranslation(destCanvas, sourceCanvas, scaleX, scaleY, x, y) {
        const ctx = destCanvas.getContext('2d');
        const destCenterX = destCanvas.width / 2;
        const destCenterY = destCanvas.height / 2;
        const sourceCenterX = sourceCanvas.width / 2;
        const sourceCenterY = sourceCanvas.height / 2;

        // Save the current context state (to restore it later)
        ctx.save();

        // Translate to the center of the destination canvas
        ctx.translate(destCenterX, destCenterY);

        // Apply scaling
        ctx.scale(scaleX, scaleY);

        // Calculate the translated position of the source canvas center in the destination canvas
        // and move to that position. This ensures the source canvas is centered on the destination canvas.
        const translatedSourceCenterX = (x + sourceCenterX) - sourceCenterX * scaleX;
        const translatedSourceCenterY = (y + sourceCenterY) - sourceCenterY * scaleY;

        ctx.translate(translatedSourceCenterX, translatedSourceCenterY);

        // Draw the source canvas onto the destination canvas.
        // Here, you need to position the source canvas so that its center aligns with the translated origin.
        ctx.drawImage(sourceCanvas, -sourceCenterX, -sourceCenterY);

        // Restore the context to its original state
        ctx.restore();
    }
    /**
     * Draws one canvas onto another canvas with scaling and translation. The scaling changes the size of the
     * sourceCanvas, and the sourceCanvas is positioned so that its center is at (x, y) on the destCanvas.
     * This function does not clear the destCanvas, allowing multiple drawings to be overlayed.
     *
     * @param {HTMLCanvasElement} destCanvas - The destination canvas element.
     * @param {HTMLCanvasElement} sourceCanvas - The source canvas element to draw.
     * @param {number} scaleX - The scaling factor in the X direction.
     * @param {number} scaleY - The scaling factor in the Y direction.
     * @param {number} x - The X coordinate on the destCanvas where the center of the sourceCanvas should be placed.
     * @param {number} y - The Y coordinate on the destCanvas where the center of the sourceCanvas should be placed.
     */
    function drawCanvasWithScaleAndCenteredTranslation(destCanvas, sourceCanvas, scaleX, scaleY, x, y) {
        const ctx = destCanvas.getContext('2d');

        // Save the current context state (to restore it later)
        ctx.save();

        // Move to the point where the center of the sourceCanvas should be placed
        ctx.translate(x, y);

        // Apply scaling
        ctx.scale(scaleX, scaleY);

        // Draw the source canvas onto the destination canvas, adjusted so its center is at the origin of the translation
        // This involves shifting the sourceCanvas by half its width and height, but scaled
        ctx.drawImage(sourceCanvas, -sourceCanvas.width / 2, -sourceCanvas.height / 2);

        // Restore the context to its original state
        ctx.restore();
    }

    function applyTextureTile(texture, tile) {
        return texture && tile && (tile.WrapS && (texture.wrapS = tile.WrapS),
            tile.WrapT && (texture.wrapT = tile.WrapT),
            tile.RepeatS && tile.RepeatT && texture.repeat.set(tile.RepeatS, tile.RepeatT))
    }
    function setTextureConfig(texture) {
        texture.flipY = presets.isGLTF ? !1 : !0;
        texture.encoding = THREE.sRGBEncoding
    }

    AFRAME.registerPrimitive('a-hoodie', {
        defaultComponents: {
            'hoodie': {}
        },
        mappings: {
            'clothing-textures': 'hoodie.clothingTextures',
            'width': 'hoodie.width',
            'height': 'hoodie.height'
        }
    });
}