import React, { useContext, useEffect, useRef, useState } from 'react';
import { APainterContext } from './APainterContext';
import { getAuthInstance, getUserInfo } from './firebase-app';
import { signInWithCredential, EmailAuthProvider } from "firebase/auth";
import * as firebaseui from 'firebaseui';
import CenteredContent from './CenteredContent';
import LoginRegister from './LoginRegister';

export default function APainterAuth({ }: any) {
    const context = useContext(APainterContext);
    const uiRef = useRef(null);
    const uiStarted = useRef(null);
    useEffect(() => {
        if (!uiRef.current) {
            // Initialize the FirebaseUI Widget using Firebase.
            var ui = new firebaseui.auth.AuthUI(getAuthInstance());
            uiRef.current = (ui);
        }
    }, [uiRef?.current]);
    useEffect(() => {
        if (uiRef.current && !uiStarted.current) {
            uiStarted.current = true;
            getUserInfo((user) => {
                context.setUserInfo(user)
            }).then((res) => {
                if (!res) {
                    if (uiRef?.current?.start)
                        uiRef.current.start('#firebaseui-auth-container', {
                            autoUpgradeAnonymousUsers: false,
                            callbacks: {
                                signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
                                    // User successfully signed in.
                                    // Return type determines whether we continue the redirect automatically
                                    // or whether we leave that to developer to handle.
                                    let userInfo = await getUserInfo(null)
                                    context.setUserInfo(userInfo);
                                    uiRef.current = null;
                                    if (context.layerIds.length === 0) {
                                        (window as any).addLayer();
                                    }
                                    return true;
                                },
                                uiShown: function () {
                                    console.log('ui shown')
                                    // The widget is rendered.
                                }
                            },
                            // signInFailure callback must be provided to handle merge conflicts which
                            // occur when an existing credential is linked to an anonymous user.
                            signInFailure: function (error) {
                                // Check for the specific error code for anonymous user upgrade merge conflicts
                                if (error.code === 'firebaseui/anonymous-upgrade-merge-conflict') {
                                    var cred = error.credential;

                                    // Get the reference to the current anonymous user
                                    var anonymousUser = getAuthInstance().currentUser;

                                    // TODO: Transfer data from the anonymous user to the permanent user
                                    // This part of the code depends on how your app's data is structured
                                    // For example, you might need to update user-related data in your database
                                    // to associate it with the new user ID.

                                    // After transferring data, complete the sign-in with the new credentials
                                    return signInWithCredential(getAuthInstance(), cred)
                                        .then(function (userCredential) {
                                            // If needed, handle the signed-in user information in userCredential
                                            // You might want to set some state or redirect the user

                                            // Delete the anonymous user after successful transfer and sign-in
                                            return anonymousUser.delete().then(function () {
                                                // Return the userCredential to complete the sign-in flow
                                                return userCredential;
                                            });
                                        })
                                        .catch(function (error) {
                                            // Handle errors in signing in with the new credential or deleting the anonymous user
                                            console.error("Error in signInWithCredential:", error);
                                        });
                                } else {
                                    // Handle other errors
                                    return Promise.resolve();
                                }
                            },
                            signInOptions: [
                                {
                                    signInMethod: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
                                    provider: EmailAuthProvider.PROVIDER_ID
                                }
                            ],
                            // Other config options...
                        });
                }
                else {
                    context.setUserInfo(res);
                    if (context.layerIds.length === 0) {
                        (window as any).addLayer();
                    }
                    uiRef.current = null;
                }
            })
        }
    }, [!!uiRef.current])

    return <div ref={uiRef}>
        <div id="firebaseui-auth-container">
            <LoginRegister />
        </div>
    </div>
}