import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { APainterContext, APainterContextValue, CatalogImage, CitivaiSetup, GoogleFont, LayerPrompts, ModelLoraSetup, OverlayText, RenderTarget, createDefaultLayerPrompt, createDefaultLoraConfig, createOverlayText } from './APainterContext';
import { useAPainter } from './painter/useAPainter';
import { useSceneLoaded } from './painter/useSceneLoaded';
import { useDragAndDrop } from './painter/useDragAndDrop';
import { detectDevice, useVrAnalytics } from './painter/useVrAnalytics';
import painter from './painter';
import { raiseCustomEvent, uuidv4 } from './painter/util';
import { PainterUserCreds, fetchAndUploadImage, formatDate, setUseAuthUpdateFunc, signOutUser, writeToDatabase } from './firebase-app';
import { SUPPORTED_LORAS } from './Lora';
import { PAINTER_CONSTANTS } from './painter/constants';
import { ModelTypes, SUPPORTED_MODELS } from './StableDiffModels';
import { useListLora, useListModels } from './useListModels';
import { checkAccount, createCheckoutLinksAsync } from './service';
import { CheckoutSessionLinks, User, UserProperties } from './interface';
import { load } from 'a-frame-components';
import useCustomEventListenerAsync, { useCustomEventListener } from './painter/useCustomEventListener';
import { addGoogleFont, fetchImagesFromGoogle, fetchImagesFromPexel, fetchImagesFromPixabay, getGoogleFonts } from './util';
import useUniqueIdentifier from './uniqueIdentifier';
interface APainterProviderProps {
  children: ReactNode;
}

export const APainterProvider: FC<APainterProviderProps> = ({ children }) => {
  // Call the hooks
  const layerIds = useRef([])
  const [painterLoaded, setPainterLoaded] = useState(false);
  const machineIdentifier = useUniqueIdentifier();
  const selectedModelTypes = useRef({
    ['SD']: true,
    ['SD XL']: true,
  });
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [user, setUser] = useState<User | null>(null);
  const [focusedElement, setFocusedElement] = useState('');
  const [maskingTargetColor, setMaskingTargetColor] = useState(null);
  const drawingDisabled = useRef(false);
  const [searchImages, setSearchImages] = useState<CatalogImage[]>();
  const [pixalogImages, setPixalogImages] = useState<CatalogImage[]>();
  const [googleImages, setGoogleImages] = useState<CatalogImage[]>();
  const [selectedLayerIndex, setSelectedLayerIndex] = useState(null);
  const [modelLoraSetups, setModelLoraSetups] = useState<ModelLoraSetup[]>([]);
  const [currentModelLoraSetup, setCurrentModelLoraSetup] = useState(null);
  const [scheduler, setScheduler] = useState('UniPCMultistepScheduler');

  let overlaytext = createOverlayText();
  const [texts, setTexts] = useState<OverlayText[]>([
    overlaytext,
    createOverlayText(),
    createOverlayText(),
    createOverlayText(),
    createOverlayText(),
    createOverlayText()
  ]);
  const [imageSpace, setImageSpace] = useState('');
  const [strength, setStrength] = useState(0);
  let renderUrl = window.localStorage.getItem('render-url');
  const [localRenderUrl, setLocalRenderUrl] = useState(renderUrl);
  const [userInfo, setUserInfo] = useState<PainterUserCreds>(null);
  const [canvas, setCanvas] = useState(null);
  const [painterCanvas, setPainterCanvas] = useState(null);
  const [googleFonts, setGoogleFonts] = useState<GoogleFont[]>(getGoogleFonts());
  const [currentSetup, setCurrentSetup] = useState<string>(null)
  const [useStabileDiffusion, setUseStableDiffusion] = useState(true);
  const [ratio, setRatio] = useState(9 / 16);
  const maxDimension = 1080;
  let initial = window.localStorage.getItem('civitai-setups');
  const citivaiSetups = useRef(JSON.parse(initial || '[]'));
  const [selectedOverlayText, setSelectedOverlayText] = useState(overlaytext?.id || null);
  const [shouldUpdate, setUpdate] = useState(Date.now());
  const [renderTarget, setRenderTarget] = useState<RenderTarget>(RenderTarget.StableDiffusionApi)
  const [canvasSize, setCanvasSize] = useState({
    height: Math.round(maxDimension * ratio), width: Math.round(maxDimension)
  })
  const [paintSessionId, setPaintSessionId] = useState(uuidv4())
  const [layerPrompts, setLayerPrompts] = useState<LayerPrompts>({});
  const [painterCanvases, setPainterCanvases] = useState([]);
  const [loraOptions, setLoraOptions] = useState<{
    [id: string]: {
      strength: number,
      words: { [word: string]: boolean }
    }
  }>({})
  const [deviceType, setDeviceType] = useState(null);
  const [checkoutLinks, setCheckoutLinks] = useState<CheckoutSessionLinks[]>([])
  const supportedLoras = useListLora(true);
  const supportedModels = useListModels(true, selectedModelTypes.current);
  useAPainter();
  useEffect(() => {
    if (currentModelLoraSetup) {
      let setup = modelLoraSetups.find(x => x.id === currentModelLoraSetup);
      let updatedSetup: ModelLoraSetup = JSON.parse(JSON.stringify({ id: currentModelLoraSetup, layerPrompts, loraOptions, strength }));
      if (JSON.stringify(updatedSetup) !== JSON.stringify(setup)) {
        let updatedSetups = modelLoraSetups.map((x) => {
          if (x.id === currentModelLoraSetup) {
            return updatedSetup;
          }
          if (updatedSetup?.layerPrompts?.prompts) {
            x.layerPrompts.prompts = JSON.parse(JSON.stringify(updatedSetup.layerPrompts.prompts));
          }
          return x;
        })
        raiseCustomEvent(PAINTER_CONSTANTS.MODEL_LORA_UPDATE, {
          loraOptions, layerPrompts,
          id: currentModelLoraSetup,
          modelLoraSetups: updatedSetups
        });
        setModelLoraSetups(updatedSetups);
      }
    }
    else {
      let updatedSetup: ModelLoraSetup = JSON.parse(JSON.stringify({
        id: uuidv4(),
        loraOptions,
        layerPrompts,
        strength
      }));
      let updateModels = [...modelLoraSetups, updatedSetup];
      setModelLoraSetups(updateModels);
      setCurrentModelLoraSetup(updatedSetup.id);
      raiseCustomEvent(PAINTER_CONSTANTS.MODEL_LORA_UPDATE, {
        loraOptions,
        layerPrompts,
        id: updatedSetup.id,
        modelLoraSetups: updateModels
      })
    }
  }, [loraOptions, layerPrompts, strength]);

  useSceneLoaded(() => {
    load().catch(console.log).then(() => {
      return painter().then(() => {
        setPainterLoaded(true);
      });
    });
  });
  useEffect(() => {
    if (ratio) {
      if (ratio < 1) {
        let size = {
          height: Math.round(maxDimension * ratio / 8) * 8,
          width: Math.round(maxDimension / 8) * 8
        };
        setCanvasSize(size)
        raiseCustomEvent(PAINTER_CONSTANTS.UPDATE_SIZE, size)
      }
      else {
        let size = {
          height: Math.round(maxDimension / 8) * 8,
          width: Math.round(maxDimension / ratio / 8) * 8
        }
        setCanvasSize(size)
        raiseCustomEvent(PAINTER_CONSTANTS.UPDATE_SIZE, size)
      }
    }
  }, [ratio])

  useDragAndDrop();
  useVrAnalytics();
  useCustomEventListener(PAINTER_CONSTANTS.COMPOSITE_RESULT_CANVAS, (evt: any) => {
    let { canvas, painterCanvas } = evt;
    setPainterCanvas(painterCanvas);
    setCanvas(canvas);
  })
  useCustomEventListener(PAINTER_CONSTANTS.CIVITAI_DRAW_IMAGES, (evt: any) => {
    if (localRenderUrl) {
      fetch(`${localRenderUrl}/draw_images`, {
        body: JSON.stringify(evt),
        method: 'POST'
      }).catch((e) => {
        console.log(e);
      })
    }
  })

  useCustomEventListener(PAINTER_CONSTANTS.CIVITAI_RENDER_TARGETS, (evt: any) => {
    if (localRenderUrl) {
      fetch(`${localRenderUrl}/render_targets`, {
        body: JSON.stringify(evt),
        method: 'POST'
      }).catch((e) => {
        console.log(e);
      })
    }
  })
  useEffect(() => {
    let interval = null;
    if (renderTarget === RenderTarget.LocalCivitai) {
      if (localRenderUrl) {
        setInterval(async () => {
          await fetch(`${localRenderUrl}/latest`).then(async (res) => {
            if (res) {
              let image = await res.json();
              if (image) {
                let imageUrl = `${localRenderUrl}/serve_image/${image}`
                let fileName = `local_${image}.png`;
                let filePath = `stable_renders/${userInfo?.uid}/${formatDate(Date.now())}/${fileName}`;
                let url = await fetchAndUploadImage(imageUrl, filePath);
                await fetch(`${localRenderUrl}/delete_image/${image}`, {});

                if (url) {
                  raiseCustomEvent(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, {
                    data: { imageUrl: url, save: true },
                  });

                  await writeToDatabase(`civitai/${userInfo?.uid}/latest`, {
                    url
                  })
                }
              }
            }
          }).catch((e) => {
            console.log(e);
          });
          await fetch(`${localRenderUrl}/images`, {
          }).catch((e) => {
            console.log(e);
          }).then(async (res) => {
            console.log(res)
            if (res) {
              let list = await res.json();
              console.log(list);
              for (let i = 0; i < list.length; i++) {
                if (list[i] && list[i] !== 'null') {
                  let imageUrl = `${localRenderUrl}/serve_image/${list[i]}`
                  let fileName = `layer_stable_${list[i]}.png`;
                  let filePath = `stable_renders/${userInfo?.uid}/${formatDate(Date.now())}/${fileName}`;
                  let url = await fetchAndUploadImage(imageUrl, filePath);

                  await fetch(`${localRenderUrl}/delete_image/${list[i]}`, {})
                }
              }
            }
          })
        }, 10000);
      }
    }
    return () => {
      if (interval !== null)
        clearInterval(interval);
    }
  }, [localRenderUrl, renderTarget])
  useCustomEventListener(PAINTER_CONSTANTS.OPEN_LORA_SELECTION, (evt: any) => {
    drawingDisabled.current = (true)
  })
  const { detail } = useCustomEventListener(PAINTER_CONSTANTS.FOCUS_INPUT, (evt: any) => {
    setFocusedElement(evt.element)
  })
  useCustomEventListener(PAINTER_CONSTANTS.OPEN_MODEL_MENU, (evt: any) => {
    drawingDisabled.current = (true)
  })

  useCustomEventListener(PAINTER_CONSTANTS.CLOSE_MODEL_SELECTION, (evt: any) => {
    drawingDisabled.current = (false)
  })
  useCustomEventListener(PAINTER_CONSTANTS.CLOSE_LORA_SELECTION, (evt: any) => {
    drawingDisabled.current = (false)
  })

  useCustomEventListener(PAINTER_CONSTANTS.LORA_STRENGTH_CHANGE, (evt: any) => {
    let update = {
      ...layerPrompts,
      [evt.id]: {
        ...layerPrompts[evt.id],
        strength: evt.value
      }
    };
    setLayerPrompts(update);
    setLoraOptions({
      ...loraOptions,
      [evt.id]: {
        ...(loraOptions?.[evt.id] || {}),
        strength: evt.value
      }
    })
  });

  useEffect(() => {
    setUseAuthUpdateFunc((userInfo: any) => {
      setUserInfo(userInfo);
      setUseStableDiffusion(!!userInfo);
    })
    // Replace this with your VR device check and mobile device check logic
    detectDevice().then((device) => {
      setDeviceType(device)
    });
  }, [])
  useEffect(() => {
    if (userInfo?.token) {
      checkAccount({ bearerToken: userInfo?.token }).then((user: User) => {
        setUser(user);
      })
      createCheckoutLinksAsync({ bearerToken: userInfo?.token }).then((res: {
        status: string,
        message: string,
        data: CheckoutSessionLinks[]
      }) => {
        if (res?.data) {
          setCheckoutLinks(res.data)
        }
      })
    }
  }, [userInfo]);

  useCustomEventListener(PAINTER_CONSTANTS.UPDATE_RATIO, (evt: any) => {
    if (evt.ratio) {
      setRatio(evt.ratio)
    }
  });

  useCustomEventListener(PAINTER_CONSTANTS.CLEAR_LORA_WORDS, (evt: any) => {
    setLoraOptions({
      ...loraOptions,
      [evt.id]: {
        ...(loraOptions?.[evt.id] || {}),
        words: {}
      }
    });
  });

  useCustomEventListener(PAINTER_CONSTANTS.TOGGLE_LORA_WORD_VALUE, (evt: any) => {
    setLoraOptions({
      ...loraOptions,
      [evt.id]: {
        ...(loraOptions?.[evt.id] || {}),
        words: {
          ...(loraOptions?.[evt.id]?.words || {}),
          [evt.word]: !(loraOptions?.[evt.id]?.words || {})?.[evt.word]
        }
      }
    });
  });

  useCustomEventListener(PAINTER_CONSTANTS.CREDITS_REMAINING_UPDATE, (detail: any) => {
    setUser({
      ...user,
      [UserProperties.Credits]: detail.credits
    });
  })
  function setNestedValue(obj: any, path: string, value: any): void {
    const keys = path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];

      // If the key does not exist or it's not an object, create an empty object for it
      if (current[key] === undefined || typeof current[key] !== 'object') {
        current[key] = {};
      }

      current = current[key];
    }

    // Set the value to the last key
    current[keys[keys.length - 1]] = value;
  }
  function getNestedValue(obj: any, path: string): void {
    const keys = path.split('.');
    let current = obj;

    for (let i = 0; i < keys.length - 1; i++) {
      const key = keys[i];

      // If the key does not exist or it's not an object, create an empty object for it
      if (current[key] === undefined || typeof current[key] !== 'object') {
        current[key] = {};
      }

      current = current[key];
    }

    // Set the value to the last key
    return current[keys[keys.length - 1]];
  }
  // Prepare the value for the context
  const contextValue: APainterContextValue = {
    localRenderUrl,
    machineIdentifier,
    setLocalRenderUrl: (url) => {
      window.localStorage.setItem('render-url', url);
      setLocalRenderUrl(url);
    },
    currentSetup,
    setCurrentSetup,
    painterLoaded,
    citivaiSetups: citivaiSetups.current,
    setCivitaiSetups: (setups: CitivaiSetup[]) => {
      window.localStorage.setItem('civitai-setups', JSON.stringify(setups));
      citivaiSetups.current = setups;
    },
    addFont: (font: GoogleFont) => {
      if (!googleFonts.find(x => x.name === font.name)) {
        let update = [...googleFonts, font].sort((a, b) => { return `${a?.name}`.localeCompare(`${b?.name}`) })
        setGoogleFonts(update);
        raiseCustomEvent(PAINTER_CONSTANTS.UPDATED_FONTS, { fonts: update })
        addGoogleFont(font.family);
      }
    },
    paintSessionId,
    painterCanvasComponent: painterCanvas,
    checkoutLinks,
    searchImages,
    pixalogImages,
    focusedElement,
    googleImages,
    aspectRatio: ratio,
    strength,
    googleFonts,
    supportedModels,
    selectedOverlayText,
    compositeCanvas: canvas,
    copyOverylayTexts(to_id, from_id, properties) {
      if (to_id === from_id) return;
      let to_text = texts.find(v => v.id === to_id);
      let from_text = texts.find(v => v.id === from_id);
      if (!to_text || !from_text) return;
      let updates = texts.map((text) => {
        properties.map((property, i) => {
          let value = getNestedValue(from_text, property)
          if (text.id === to_id) {
            setNestedValue(text, property, value)
          }
        });

        return text;
      });
      setTexts(updates);
      raiseCustomEvent(PAINTER_CONSTANTS.TEXT_LAYER_UPDATES, {
        texts: updates
      })
    },
    updateOverlayTexts: (id: string, properties: string[], values: any[]) => {
      let found = texts.find(v => v.id === id)
      if (found) {
        let updated = false;
        let updates = texts.map((text) => {
          properties.map((property, i) => {
            let value = values[i]
            if (getNestedValue(found, property) !== value) {
              if (text.id === id) {
                updated = true;
                setNestedValue(text, property, value)
              }
            }
          });

          return text;
        });
        if (updated) {
          setTexts(updates);
          raiseCustomEvent(PAINTER_CONSTANTS.TEXT_LAYER_UPDATES, {
            texts: updates
          })
        }
      }
    },
    setSelectedText: function (id: string) {
      setSelectedOverlayText(id);
    },
    addText: () => {
      setTexts([...texts, createOverlayText()])
    },
    removeText: (id: string) => {
      setTexts([...texts.filter(x => x.id !== id)]);
    },
    texts,
    selectedModelTypes: selectedModelTypes.current,
    setSelectedModelTypes: (key, value) => {
      selectedModelTypes.current = {
        ...selectedModelTypes.current,
        [key]: value,
      }
      setUpdate(Date.now());
    },
    setStrength: function (strength: number) {
      setStrength(strength);
      setModelLoraSetups([...modelLoraSetups.map((v) => {
        if (v.id === currentModelLoraSetup) {
          v.strength = strength;
        }
        return v;
      })])
      raiseCustomEvent(PAINTER_CONSTANTS.STRENGTH_CHANGE, {})
    },
    scheduler,
    setScheduler: (scheduler: string) => {
      setScheduler(scheduler);
      raiseCustomEvent(PAINTER_CONSTANTS.SCHEDULER_CHANGE, {});
    },
    setImageSpace: (space: string) => {
      setImageSpace(space);
    },
    modelLoraSetups,
    currentModelLoraSetup,
    upsertModelLoraSetup: (arg: ModelLoraSetup) => {
      if (modelLoraSetups?.length < 6) {
        setModelLoraSetups([...modelLoraSetups, arg]);
        setCurrentModelLoraSetup(arg.id);
      }
    },
    renderTarget,
    setRenderTarget(renderTarget: RenderTarget) {
      setRenderTarget(renderTarget);
    },
    deleteModelLoraSetup: (id: string) => {
      if (modelLoraSetups.length > 1) {
        setModelLoraSetups([...modelLoraSetups.filter(x => x.id !== id)]);
        if (id === currentModelLoraSetup) {
          let newcurrent = modelLoraSetups.find(x => x)
          if (newcurrent) {
            setCurrentModelLoraSetup(newcurrent?.id)
          }
        }
      }
    },
    setCurrentModelLoraSetup: (id: string) => {
      let setup: ModelLoraSetup | undefined = modelLoraSetups.find(v => v.id === id);
      if (setup) {
        setCurrentModelLoraSetup(id);
        setLayerPrompts(setup.layerPrompts);
        raiseCustomEvent(PAINTER_CONSTANTS.MODEL_SELECTED, {
          model: setup.layerPrompts.model,
          url: setup.layerPrompts?.supportedModel?.imageUrl || ''
        });

        ['lora1', 'lora2', 'lora3', 'lora4'].map((lora) => {
          raiseCustomEvent(PAINTER_CONSTANTS.LORA_SELECTED, {
            name: setup?.layerPrompts?.[lora]?.name || '',
            lora: lora,
            url: setup?.layerPrompts?.[lora]?.url || '',
            setup
          })
        })
      }
    },
    imageSpace,
    userInfo,
    user,
    layerPrompts,
    loraOptions,
    setLayerPrompts,
    drawingDisabled,
    webhook: 'https://apainter-945f5.web.app',
    webhooks: ['https://apainter-945f5.web.app', 'https://pettypaint.com'],
    selectedLayer,
    useStabileDiffusion,
    deviceType,
    selectedLayerIndex,
    search: async ({ query, source }) => {
      try {
        switch (source) {
          case 'google':
            {
              let results = await fetchImagesFromGoogle(query, userInfo?.token);
              console.log(results);
              setGoogleImages(results.map((res, index) => {
                return {
                  id: index,
                  value: index,
                  text: '',
                  url: res.url
                }
              }))
            }
            break;
          case 'pixabay':
            {
              let results = await fetchImagesFromPixabay(query, userInfo?.token);
              if (results?.hits) {
                console.log(results);
                let res = results.hits.map((photo, index) => {
                  return {
                    id: index,
                    value: index,
                    text: photo.tags,
                    url: photo.largeImageURL
                  }
                })
                setPixalogImages(res)
              }
            }
            break;
          default:
            {
              let results = await fetchImagesFromPexel(query, userInfo?.token);
              console.log(results);
              let res = results.map((photo, index) => {
                return {
                  id: index,
                  value: index,
                  text: photo.alt,
                  url: photo.src.landscape || photo.src.large
                }
              })
              setSearchImages(res)
            }
            break;
        }
      } catch (e) {
        setSearchImages([])
      }
    },
    setUserInfo: (userInfo: any) => {
      setUserInfo(userInfo);
      setUseStableDiffusion(!!userInfo);
    },
    layerIds: layerIds.current,
    canvasSize,
    shouldUpdate,
    signOutUser: async () => {
      await signOutUser()
      setUserInfo(null);
    },
    removePrompt: (id: string) => {
      let lp = {
        ...layerPrompts
      }
      lp.prompts = lp.prompts || [];
      lp.prompts = lp.prompts.filter(x => x.id !== id)
      setLayerPrompts(lp)
    },
    addPrompt: (arg0: { prompt: string; positive: boolean; }) => {
      let lp = {
        ...layerPrompts
      }
      lp.prompts = lp.prompts || [];
      lp.prompts.push({ ...arg0, id: uuidv4() })

      setLayerPrompts(lp);
      raiseCustomEvent(PAINTER_CONSTANTS.PROMPTS_UPDATED, {
        prompts: lp.prompts
      })
    },
    setLayerIds: (ids) => {
      layerIds.current = (ids)
    },
    setLayerLorasStyle: (argss: { lora: string, loraUrl: string, }[], model: string) => {
      console.log(argss);
      let lp: LayerPrompts = {
        ...layerPrompts,
      }

      for (let i = 0; i < argss.length; i++) {
        let args = argss[i];
        let lora_obj = supportedLoras.find(d => d.image === args.loraUrl);
        let lora_name = lora_obj?.name;
        let lora_url = lora_obj?.image;

        lp = {
          ...lp,
          [args.lora]: {
            name: lora_name || '',
            url: lora_url,
            strength: lp?.[args.lora]?.strength || Math.random(),
            words: []
          }
        };
        raiseCustomEvent(PAINTER_CONSTANTS.LORA_SELECTED, {
          name: lora_name,
          lora: args.lora,
          url: lora_url || '',
          strength: lp?.[args.lora]?.strength
        })
      }

      lp = {
        ...lp,
        model
      };

      let supportedModel = supportedModels.find(x => x.fileName === model)
      lp.supportedModel = supportedModel;
      setLayerPrompts(lp);
      raiseCustomEvent(PAINTER_CONSTANTS.MODEL_SELECTED, {
        model: model,
        url: supportedModel?.imageUrl || ''
      })


    },
    setLayerLoraStyle: (args: { lora: any, loraUrl: string, }) => {
      console.log(args);
      let lora_obj = supportedLoras.find(d => d.image === args.loraUrl);
      let lora_name = lora_obj?.name;
      let lora_url = lora_obj?.image;

      let lp: any = {
        ...layerPrompts,
        [args.lora]: {
          ...(layerPrompts[args.lora] || {}),
          name: lora_name || '',
          url: lora_url,
          words: []
        }
      };

      setLayerPrompts(lp)
      raiseCustomEvent(PAINTER_CONSTANTS.LORA_SELECTED, {
        name: lora_name,
        lora: args.lora,
        url: lora_url || ''
      })
    },
    setLoraEnabled: (layer, lora, name, word, val) => {
      if (!layerIds?.current) return;

      const index = layerIds.current.indexOf(layer);
      if (index < 0) return;

      const loraObj = findLoraByName(name, supportedLoras);
      if (!loraObj) return;

      setLayerPrompts(updateLoraConfig(index, lora, name, word, val, layerPrompts));

    },
    getLoraUrl: (layer, index) => {
      if (layerIds?.current) {
        let layerindex = layerIds.current.indexOf(layer);
        if (layerindex >= 0) {
          if (layerPrompts?.[layerindex]?.[`lora${index}`]) {
            let loraName = layerPrompts[layerindex][`lora${index}`]
            let lora = supportedLoras.find(d => d.name === loraName)
            if (lora) {
              return lora;
            }
          }
        }
      }
      return null;
    },
    setModel: (model) => {
      let lp = {
        ...layerPrompts,
        model
      };

      let supportedModel = supportedModels.find(x => x.fileName === model)
      lp.supportedModel = supportedModel;
      setLayerPrompts(lp)
      raiseCustomEvent(PAINTER_CONSTANTS.MODEL_SELECTED, {
        model: model,
        url: supportedModel?.imageUrl || ''
      })
    },
    setSelectedLayer: (id) => {
      setSelectedLayerIndex(layerIds.current.indexOf(id));
      setSelectedLayer(id);
    },
    clearLoraStyle: (lora) => {
      let lp = {
        ...layerPrompts,
      };
      delete lp[lora]
      setLayerPrompts(lp)
      raiseCustomEvent(PAINTER_CONSTANTS.LORA_SELECTED, {
        lora: lora,
      })

    },
    addCanvasContext: (painterCanvas) => {
      let update = [...painterCanvases, painterCanvas];
      layerIds.current = update.map(t => t.id)
      setPainterCanvases(() => update)
      setUpdate(Date.now());
      if (layerIds.current.length === 1) {
        (window as any).selectLayer(null, layerIds.current[0])
      }
    },
    removeCanvasContext: (id) => {
      let update = [...painterCanvases.filter(x => x.id !== id)]
      setPainterCanvases(() => update)
    },
    painterCanvas: painterCanvases
    // Add any values you want to provide through the context
  };

  return (
    <APainterContext.Provider value={contextValue as any}>
      {children}
    </APainterContext.Provider>
  );
};
const findLoraByName = (name, supportedLoras) => supportedLoras.find(d => d.name === name);

const updateLoraConfig = (index, lora, name, word, val, currentPrompts) => {
  const loraKey = `lora${lora}`;
  const existingConfig = currentPrompts[index]?.loraConfigs?.[loraKey] || {};
  const existingWordConfig = currentPrompts[index]?.loraConfigs?.[loraKey]?.[name] || {};

  return {
    ...currentPrompts,
    [index]: {
      ...createDefaultLayerPrompt(),
      ...currentPrompts[index],
      loraConfigs: {
        ...(currentPrompts[index]?.loraConfigs || {}),
        [loraKey]: {
          ...existingConfig,
          [name]: {
            ...existingWordConfig,
            [word]: {
              enabled: val, strength: 1
            }
          }
        }
      }
    }
  };
};
