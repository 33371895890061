
import mixin from './mixin';

export default function () {
    const THREE: any = (window as any).THREE;
    const AFRAME: any = (window as any).AFRAME || {}

    /*
    <frame-menu-container
        forward-step="0.05"
        text-value="---Select---"
        menu-direction={'down'}
        flex-direction="column"
        justify-content="flexStart"
        align-items="flexStart"
        component-padding="0.01"
        menu-item-height={`.2`}
        menu-item-width={`1.0`}>
        {
            googleFonts.map((text, index) => {
                return (
                    <frame-base-interactive
                        font-size=".07"
                        value={text.name}
                        title={text.name}
                        interactive-type={'button'}
                        width={1}
                        height={"0.2"}
                        margin="0 0 0.05 0"
                    />
                )
            })
        }
    </frame-menu-container> 
    */
    if (typeof AFRAME === 'undefined') {
        throw new Error('Component attempted to register before AFRAME was available.');
    }


    AFRAME.registerComponent('google-fonts', {
        schema: {
            value: { type: 'string', default: '' },
            fonts: { type: 'string', default: '' },
        },

        init: function () {
            var el = this.el;
            var data = this.data;
            this.render();
        },
        ...mixin,
        getWidth: function () {
            return parseFloat(`${this?.container?.getAttribute('menu-item-width')}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.container?.getAttribute('menu-item-height')}`);
        },
        render: function () {
            let el = this.el;
            let me = this;
            try {
                let fonts = JSON.parse(this.data.fonts);
                if (fonts?.length) {
                    if (me.container) {
                        me.container.parentNode.removeChild(me.container);
                    }

                    const container = document.createElement('frame-menu-container');
                    container.setAttribute('forward-step', '0.05');
                    container.setAttribute('text-value', this.data.value || '-- - Select-- - ');
                    container.setAttribute('menu-direction', 'down');
                    container.setAttribute('flex-direction', 'column');
                    container.setAttribute('justify-content', 'flex-start');
                    container.setAttribute('align-items', 'flex-start')
                    container.setAttribute('padding', '0.01')
                    container.setAttribute('menu-item-height', `.2`)
                    container.setAttribute('menu-item-width', `1.0`);
                    this.container = container;

                    fonts.forEach((font) => {
                        const menuItem = document.createElement('frame-base-interactive'); // Assuming 'frame-base-interactive' is a custom component or styled div
                        menuItem.setAttribute('font-size', '.07');
                        menuItem.setAttribute('value', font.name);
                        menuItem.setAttribute('title', font.name);
                        menuItem.setAttribute('interactive-type', 'button');
                        menuItem.setAttribute('width', '1');
                        menuItem.setAttribute('height', '.2');
                        menuItem.setAttribute('margin', '0 0 0.05 0');
                        container.appendChild(menuItem);
                    })
                    el.appendChild(container);

                    me.updateElementSize(me, me.el);
                }
            } catch (e) {
                console.error(e);
            }
        },

        /**
         * Called when component is attached and when component data changes.
         * Generally modifies the entity based on the data.
         */
        update: function (oldData) {
            if (oldData.fonts !== this.data.fonts) {
                this.render();
            }
            if (this.data.value !== oldData.value) {
                this.container.setAttribute('text-value', this.data.value || '-- - Select-- - ');
            }
        },

        /**
         * Called when a component is removed (e.g., via removeAttribute).
         * Generally undoes all modifications to the entity.
         */
        remove: function () { },

        /**
         * Called on each scene tick.
         */
        // tick: function (t) { },

        /**
         * Called when entity pauses.
         * Use to stop or remove any dynamic or background behavior such as events.
         */
        pause: function () { },

        /**
         * Called when entity resumes.
         * Use to continue or add any dynamic or background behavior such as events.
         */
        play: function () { }
    });

    AFRAME.registerPrimitive('a-google-fonts', {
        defaultComponents: {
            'google-fonts': {}
        },
        mappings: {
            //gui item general
            'fonts': 'google-fonts.fonts',
            'value': 'google-fonts.value'
        }
    });
}