import React, { useState } from 'react';
import { btnstyle } from './util';

const DesktopUsageInstructions: React.FC = () => {
    const [hide, setHide] = useState(false);

    if (hide) {
        return (
            <a style={{ ...btnstyle, fontSize: 30 }} onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = '#0056b3'; // Darken color on hover
            }}
                onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#007bff'; // Back to original color
                }} onClick={() => {
                    setHide(false);
                }}>Instructions</a>
        )
    }
    return (
        <div style={{
            padding: '20px',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            zIndex: 1000, // Ensure it's above other content
        }}>
            <h4>Controls</h4>
            <p><strong>Turn Camera:</strong> Use the mouse to drag view</p>
            <p>
                <strong>Move:</strong>
                Use "<strong>WASD</strong>" or <strong>arrow keys</strong>
                <div style={{ borderRadius: 10, }}>
                    <img style={{ maxHeight: 150, maxWidth: 150 }} src="/img/arrow_directions.png" />
                </div>
            </p>
            <p><strong>Paint:</strong> Press <strong style={{ fontSize: 30 }}>CTRL or COMMAND</strong> button to paint.</p>
            <a style={btnstyle}
                onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = '#0056b3'; // Darken color on hover
                }}
                onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = '#007bff'; // Back to original color
                }} href="javascript:void(0);" onClick={() => {
                    setHide(true);
                }}>Hide</a>
        </div>
    );
};

export default DesktopUsageInstructions;
