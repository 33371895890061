import { Prompt } from '../../APainterContext';
import { getBoundingBoxOfNonTransparentPixels } from '../canvasutil';
import { PAINTER_CONSTANTS } from '../constants';
import { AFRAME } from '../systems/brush';
import { GetNegativeColor, GetPositiveColor, INTERACTABLES } from '../systems/ui';
import { raiseCustomEvent, uuidv4 } from '../util';
import { framemixin } from 'a-frame-components';
const THREE: any = (window as any).THREE;

export default function () {
    AFRAME.registerComponent('prompt-list-component', {
        schema: {
            prompts: { type: 'string' },
            centered: { type: 'boolean', default: false },
            itemHeight: { type: 'number', default: .25 }
        },
        ...framemixin,
        getWidth: function () {
            return parseFloat(`${this?.container?.getAttribute('width') || 0}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.container?.getAttribute('height') || 0}`);
        },
        init: function () {
            this.existingPrompts = []
            try {
                let prompts = JSON.parse(this.data.prompts);
                this.currentPrompts = prompts;
            } catch (e) {
                this.currentPrompts = [];
            }
            const backgroundWidth = 4;
            this.backgroundWidth = backgroundWidth;
            this.backBoardMargin = .3
            this.bucket = document.createElement('a-entity');
            this.backboard = document.createElement('a-plane');
            this.backboard.setAttribute('material', 'color', `#ffffff`);
            this.backboard.setAttribute('opacity', '.2');
            this.backboard.setAttribute('width', this.backgroundWidth)
            this.backboard.setAttribute('panel-color', '#000')
            this.backboard.setAttribute('panel-rounded', '0.5')
            this.backboardContainer = document.createElement('a-entity');
            this.backboardContainer.setAttribute('position', `${(this.backgroundWidth / 2) - .25} 0 -.001'`);
            this.el.appendChild(this.bucket)
            this.backboardContainer.appendChild(this.backboard)
            this.promptContainer = document.createElement('a-entity');
            this.bucket.appendChild(this.promptContainer)
            this.bucket.appendChild(this.backboardContainer)
            this.renderCurrentPrompts();
        },
        renderCurrentPrompts: function () {
            let me = this;
            let to_create = this.currentPrompts.map((prompt: Prompt) => {
                return this.existingPrompts.find(t => t.id === prompt.id) ? null : prompt;
            }).filter(x => x);
            let to_remove = this.existingPrompts.filter(x => !this.currentPrompts.find(v => v.id === x.id));

            if (to_remove?.length) {
                to_remove.forEach((item) => {
                    item.entity.parentNode.removeChild(item.entity)
                })
                this.existingPrompts = this.existingPrompts.filter(x => !to_remove.find(y => y.id === x.id))
            }

            let container = this.container || document.createElement('frame-container');
            container.setAttribute('direction', 'vertical');
            if (!this.container) {
                me.promptContainer.appendChild(container);
                this.container = container;
                this.container.addEventListener('size-update', () => {
                    let width = parseFloat(this.container.getAttribute('width') || '0');
                    me.backboard.setAttribute('width', width)
                    if (me.data.centered) {
                        me.promptContainer.setAttribute('position', `${width / 2} 0 0`)
                        me.backboardContainer.setAttribute('position', `${(width / 2) - .25} 0 -.001'`);
                    }
                    else {
                        me.backboardContainer.setAttribute('position', `${0} 0 -.001'`);
                    }
                    if (me.container) {
                        framemixin.updateElementSize(me, me.el);
                    }
                })
            }
            if (to_create?.length) {
                let new_prompts = to_create.map((prompt: Prompt, index) => {
                    let component = document.createElement('a-prompt-item-component');
                    component.setAttribute('prompt', JSON.stringify(prompt));
                    container.appendChild(component);
                    return {
                        id: prompt.id,
                        prompt,
                        entity: component,
                        component
                    }
                });
                if (new_prompts?.length) {
                    this.existingPrompts.push(...new_prompts);
                }
            }
            this.backboard.setAttribute('animation', {
                dur: 400,
                easing: 'easeOutExpo',
                property: 'height',
                to: (me.data.itemHeight * (this.existingPrompts.length)) + this.backBoardMargin,
            });
            this.backboardContainer.setAttribute('animation', {
                dur: 400,
                easing: 'easeOutExpo',
                property: 'position',
                to: {
                    y: -(((me.data.itemHeight * (this.existingPrompts.length))) / 2),
                }
            });
        },
        update: function (oldData) {
            if (this.data.prompts) {
                try {
                    let prompts = JSON.parse(this.data.prompts);
                    this.currentPrompts = prompts;
                } catch (e) {
                    this.currentPrompts = [];
                }
                this.renderCurrentPrompts();
            }
        }
    });

    AFRAME.registerPrimitive('a-prompt-list-component', {
        defaultComponents: {
            'prompt-list-component': {}
        },
        mappings: {
            //gui item general
            'centered': 'prompt-item-component.centered',
            'prompts': 'prompt-list-component.prompts'
        }
    });


    AFRAME.registerComponent('prompt-item-component', {
        schema: {
            prompt: {
                type: 'string'
            },
        },
        ...framemixin,
        getWidth: function () {
            return parseFloat(`${this?.container?.getAttribute('width') || 0}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.container?.getAttribute('height') || 0}`);
        },
        init: function () {
            let me = this;
            me.render();
        },
        update: function () {
            let me = this;
            me.render();
        },
        render: function () {
            let me = this;
            if (this.container) {
                this.container.parentNode.removeChild(this.container);
            }
            if (!this.data.prompt) return;
            let prompt: Prompt = JSON.parse(this.data.prompt);
            let loraContainerEl = this.createText(prompt.prompt, prompt.positive)
            let offset = -.5
            let deleteButton = me.createButton({
                type: 'frame-gui-icon-label-button',
                onclick: `testButtonAction`,
                args: `${prompt.id}`,
                prompt,
                value: '',
                position: offset + ' 0 .01',
                buttonSize: .2
            })
            deleteButton.setAttribute('icon-font', "assets/fonts/ionicons.ttf")
            deleteButton.setAttribute('icon', "f129")
            deleteButton.setAttribute('icon-font-size', "0.1")
            deleteButton.setAttribute('font-size', '.05');
            deleteButton.addEventListener('click', function () {
                me.el.emit(PAINTER_CONSTANTS.REMOVE_PROMPT, {
                    id: prompt.id
                });
            })
            let container = document.createElement('frame-container')
            container.setAttribute('direction', 'horizontal')
            container.setAttribute('alignment', 'flexStart')
            container.setAttribute('margin', '.1 .1 .1 .1')
            container.appendChild(deleteButton);
            container.appendChild(loraContainerEl);
            container.addEventListener('size-update', (evt) => {
                if (me.container) {
                    framemixin.updateElementSize(me, me.el);
                }
                console.log(`${container.getAttribute('width')} - ${container.getAttribute('height')}`);
            })
            this.container = container;
            this.el.appendChild(container);
            framemixin.updateElementSize(this, me.el);
        },
        createText: function (newText, isPositive) {
            let me = this;
            var textEntity = document.createElement("frame-troika-text");
            textEntity.setAttribute('value', `${newText}`);
            textEntity.setAttribute('font-size', '..07')
            // updates
            let handler = (evt: any) => {
                if (me.container) {
                    framemixin.updateElementSize(me, me.container);
                }
                evt.preventDefault();
                evt.stopPropagation();
            };
            textEntity.addEventListener('bounding-box-update', handler);
            return textEntity;
        },
        createButton: function ({ type, prompt, onclick, args, value, position, buttonSize }) {
            let me = this;
            let button = document.createElement(type || 'frame-gui-button');

            button.setAttribute('width', buttonSize || me.buttonSize || '0.15')
            button.setAttribute('height', buttonSize || me.buttonSize || '0.15')
            button.setAttribute('onclick', onclick)
            button.setAttribute('args', args)
            button.setAttribute('value', value || ``)
            button.setAttribute('font-color', prompt?.positive ? GetPositiveColor() : GetNegativeColor())
            button.setAttribute('margin', '0 0.02 0.05 0')
            button.setAttribute('position', position || '.4 0 .1')
            return button;
        },
    });
    AFRAME.registerPrimitive('a-prompt-item-component', {
        defaultComponents: {
            'prompt-item-component': {}
        },
        mappings: {
            //gui item general
            'prompt': 'prompt-item-component.prompt',
            'itemHeight': 'prompt-item-component.itemHeight',
        }
    });
}