import { useContext, useEffect, useRef, useState } from "react";
import { APainterContext, APainterContextValue } from "./APainterContext";
import { ModelTypes } from './StableDiffModels';

export default function ModelFilterMenu() {
    const menuContainerRef = useRef(null);
    const context: APainterContextValue = useContext(APainterContext);
    const options = Object.keys(ModelTypes);

    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);
    return (
        <frame-menu-container
            ref={menuContainerRef}
            menu-direction={'down'}
            flex-direction="column"
            justify-content="flexStart"
            align-items="flexStart"
            width="1"
            component-padding="0.01"
            text-value={'Model Types'}
            menu-item-height={.2}
            menu-item-width={1.0}
            position="0 0 0" rotation="0 0 0"
        >
            {options.map((option) => {
                return <RadioOption key={option} option={option} value={context?.selectedModelTypes[option] || false} onChange={(value) => {
                    context.setSelectedModelTypes(option, value);
                }} />
            })}
        </frame-menu-container>
    )
}
function RadioOption({ value, option, onChange }) {
    let ref = useRef(null);
    const currentValue = useRef(value);
    useEffect(() => {
        if (ref?.current) {
            let evt = 'clicked';
            let handler = () => {
                currentValue.current = (!currentValue.current);
                if (ref?.current) {
                    ref?.current.setAttribute('checked', currentValue.current);
                }
                if (onChange) {
                    onChange(currentValue.current);
                }
            };
            ref?.current.addEventListener(evt, handler);
            if (ref?.current) {
                ref?.current.setAttribute('checked', currentValue.current);
            }
            return () => {
                if (ref?.current)
                    ref?.current.removeEventListener(evt, handler);
            }
        }
    }, [ref?.current]);

    return <frame-base-interactive ref={ref} key={option} width="1" interactive-type="radio" height=".2" value={option} font-size=".07"></frame-base-interactive>
}