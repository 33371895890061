import React from 'react';
import './MenuComponent.scss'; // Make sure the path matches your file structure

interface MenuComponentProps {
    onChangePage: (page: string) => void;
}

const MenuComponent: React.FC<MenuComponentProps> = ({ onChangePage }) => {
    const handlePageChange = (page: string) => {
        onChangePage(page);
    };

    return (
        <nav className="menu">
            <button className="menuButton" onClick={() => handlePageChange('home')}>Home</button>
            <button className="menuButton" onClick={() => handlePageChange('about')}>About</button>
            <button className="menuButton" onClick={() => handlePageChange('local')}>Local</button>
            {/* <button className="menuButton" onClick={() => handlePageChange('directions')}>Directions</button> */}

        </nav>
    );
};

export default MenuComponent;
