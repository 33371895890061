import JSZip from 'jszip';

export default async function canvasesToZip(args: { [name: string]: HTMLCanvasElement }) {

    // Utility function to convert a canvas to a Blob
    function canvasToBlob(canvas: HTMLCanvasElement): Promise<Blob> {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error('Canvas to Blob conversion failed'));
                }
            });
        });
    }

    // Main function to process the canvases, zip them, and trigger a download
    async function downloadCanvasesAsZip(canvases: { [name: string]: HTMLCanvasElement }): Promise<void> {
        const zip = new JSZip();
        const folder = zip.folder('images');

        // Convert each canvas to a blob and add it to the ZIP
        for (const [name, canvas] of Object.entries(canvases)) {
            console.log(`convert canvas ${name} to blob `);
            const blob = await canvasToBlob(canvas);
            folder.file(`image-${name}.png`, blob);
            console.log(`adding image-${name}.png`)
        }

        // Generate the ZIP file
        console.log(`generating zip`)
        zip.generateAsync({ type: 'blob' }).then((content) => {
            // Use the HTML5 download attribute to download the generated blob
            const url = URL.createObjectURL(content);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'canvases.zip';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url); // Clean up
        });
    }

    return downloadCanvasesAsZip(args);
}