import { useContext, useEffect, useRef, useState } from "react";
import { APainterContext, APainterContextValue } from "./APainterContext";

export default function SchedulerMenu() {
    const menuContainerRef = useRef(null);
    const context: APainterContextValue = useContext(APainterContext);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const options = `DDPMScheduler,DDIMScheduler,    PNDMScheduler,    LMSDiscreteScheduler,    EulerDiscreteScheduler,    EulerAncestralDiscreteScheduler,    DPMSolverMultistepScheduler,    HeunDiscreteScheduler,    DPMSolverSinglestepScheduler,    UniPCMultistepScheduler,    DDIMInverseScheduler,    IPNDMScheduler ,  KarrasVeScheduler,    ScoreSdeVeScheduler,    LCMScheduler`.split(',').map(v => `${v}`.trim()).filter(x => x);

    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);
    useEffect(() => {
        if (menuContainerRef?.current) {
            menuContainerRef?.current.setAttribute('text-value', context.scheduler)
        }
    }, [context.scheduler, menuContainerRef?.current])
    return (
        <frame-menu-container
            ref={menuContainerRef}
            menu-direction={'down'}
            flex-direction="column"
            justify-content="flexStart"
            align-items="flexStart"
            width="1"
            component-padding="0.01"
            text-value={context.scheduler || 'Scheduler'}
            menu-item-height={.2}
            menu-item-width={1.35}
            position="0 0 0" rotation="0 0 0"
        >
            {options.map((option, optionIndex) => {
                return <RadioOption key={`option-${optionIndex}`} option={option} value={option == context?.scheduler} onChange={(value) => {
                    context.setScheduler(option);
                }} />
            })}
        </frame-menu-container>
    )
}
function RadioOption({ value, option, onChange }) {
    let ref = useRef(null);
    useEffect(() => {
        if (ref?.current) {
            let evt = 'clicked';
            let handler = () => {
                if (onChange) {
                    onChange();
                }
            };
            ref?.current.addEventListener(evt, handler);
            return () => {
                ref?.current.removeEventListener(evt, handler);
            }
        }
    }, [ref?.current])
    useEffect(() => {
        if (ref?.current) {
            ref?.current.setAttribute('checked', value);
        }
    }, [ref?.current, value])
    return <frame-base-interactive ref={ref} checked={value} key={option} width="1.35" interactive-type="radio" height=".2" value={option} font-size=".07"></frame-base-interactive>
}