import React, { useContext, useEffect, useRef } from 'react';
import { APainterContext } from './APainterContext';
import { raiseOn, setAttribute, useBindEventOn, useEventListenerOn, useEventsListenerOn } from 'a-frame-components';
import { getGoogleFonts } from './util';
import { raiseCustomEvent } from './painter/util';
import { PAINTER_CONSTANTS } from './painter/constants';
export default function TextLayerComponent() {
    const margin = '.2 .2 .2 .2';
    const copiedText = useRef(null);
    const context = useContext(APainterContext);
    const {
        selectedOverlayText,
        copyOverylayTexts,
        updateOverlayTexts,
        setSelectedText,
        texts
    } = context;

    const menu = useEventsListenerOn({
        change: (evt: any, element) => {
            let temp = texts.findIndex(v => v.id === evt.detail.value);
            let text = texts.find(v => v.id === evt.detail.value);
            element.setAttribute('text-value', text?.value || `Text ${temp + 1}`);
            setSelectedText(evt.detail.value)
        }
    });
    const addFontBtn = useEventsListenerOn({
        click: (evt) => {
            raiseCustomEvent(PAINTER_CONSTANTS.SHOW_ADD_FONT_INPUT, {});
        }
    })

    const copyBtn = useEventsListenerOn({
        click: (evt: any, element: any) => {
            copiedText.current = selectedOverlayText;
        }
    });

    const pasteBtn = useEventsListenerOn({
        click: (evt: any, element: any) => {
            copyOverylayTexts(
                selectedOverlayText,
                copiedText.current,
                ['layer', 'hidden', 'composite',
                    'position.x', 'position.y', 'font.fontSize',
                    'font.color.r', 'font.color.g', 'font.color.b',
                    'position.rotation', 'position.rotation',
                    'value', 'font.font']);
        }
    });

    const layerMenu = useEventsListenerOn({
        change: (evt: any, element) => {
            element.setAttribute('text-value', evt.detail.value);
            updateOverlayTexts(selectedOverlayText, ['layer'], [evt.detail.value]);
        }
    })
    const hide = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['hidden'], [evt.detail.value]);
        }
    });
    const inComposite = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['composite'], [evt.detail.value]);
        }
    });
    const xSlider = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.x'], [evt.detail.value]);
        }
    })
    const xQuick = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.x'], [evt.detail.value]);
        }
    })
    const yQuick = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.y'], [evt.detail.value]);
        }
    })
    const ySlider = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.y'], [evt.detail.value]);
        }
    })
    const fontSize = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.fontSize'], [evt.detail.value]);
        }
    })
    const red = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.color.r'], [evt.detail.value]);
        }
    })
    const green = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.color.g'], [evt.detail.value]);
        }
    })
    const blue = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.color.b'], [evt.detail.value]);
        }
    })
    const outlineWidth = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.outlineWidth'], [evt.detail.value]);
        }
    });

    const outlineColorR = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.outlineColor.r'], [evt.detail.value]);
        }
    });
    const outlineColorG = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.outlineColor.g'], [evt.detail.value]);
        }
    });
    const outlineColorB = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['font.outlineColor.b'], [evt.detail.value]);
        }
    });

    const rotation = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.rotation'], [evt.detail.value]);
        }
    })
    const rotationQuick = useEventsListenerOn({
        change: (evt: any, element) => {
            updateOverlayTexts(selectedOverlayText, ['position.rotation'], [evt.detail.value]);
        }
    })
    const textValue = useBindEventOn('change', 'text-value', (evt: any) => {
        updateOverlayTexts(selectedOverlayText, ['value'], [evt.detail.value]);
        return evt.detail.value;
    })
    const googleFont = useBindEventOn('change', 'value', (evt: any) => {
        updateOverlayTexts(selectedOverlayText, ['font.font'], [evt.detail.value]);
        return evt.detail.value;
    });
    let googleFonts = context.googleFonts;
    useEffect(() => {
        if (menu?.['frame-id']) {
            if (texts?.length) {
                let text = texts.find(x => x.id === selectedOverlayText)
                if (text) {
                    raiseOn(menu, 'change', { value: text.id });
                    raiseOn(textValue, 'change', { value: text.value });
                    raiseOn(layerMenu, 'raiseOn', { value: text.layer })
                    setAttribute(textValue, 'value', text.value);
                    setAttribute(xSlider, 'percent', text.position.x)
                    setAttribute(ySlider, 'percent', text.position.y)
                    setAttribute(rotation, 'percent', text.position.rotation)
                    setAttribute(fontSize, 'percent', text.font.fontSize)
                    setAttribute(googleFont, 'value', text.font.font)

                    setAttribute(hide, 'value', text.hidden);
                    setAttribute(inComposite, 'value', text.composite)



                    setAttribute(red, 'percent', text.font.color.r)
                    setAttribute(green, 'percent', text.font.color.g)
                    setAttribute(blue, 'percent', text.font.color.b);

                    setAttribute(outlineWidth, 'percent', text.font.outlineWidth)
                    setAttribute(outlineColorR, 'percent', text.font.outlineColor.r);
                    setAttribute(outlineColorG, 'percent', text.font.outlineColor.g);
                    setAttribute(outlineColorB, 'percent', text.font.outlineColor.b);
                }
            }
        }
    }, [menu?.['frame-id'], texts, selectedOverlayText])

    return (
        <frame-container direction="horizontal" alignment="flexStart" margin={margin}>
            <frame-container direction="vertical" alignment="flexStart">
                {texts?.length ? <frame-menu-container
                    {...menu}
                    forward-step="0.05"
                    text-value="---Select---"
                    menu-direction={'down'}
                    flex-direction="column"
                    justify-content="flexStart"
                    align-items="flexStart"
                    component-padding="0.01"
                    menu-item-height={`.2`}
                    menu-item-width={`1.0`}>
                    {
                        texts.map((text, index) => {
                            return (
                                <frame-base-interactive
                                    font-size=".07"
                                    key={text.id}
                                    value={text.id}
                                    title={text.value || `Text ${index + 1}`}
                                    interactive-type={'button'}
                                    width={1}
                                    height={"0.2"}
                                    margin="0 0 0.05 0"
                                />
                            )
                        })
                    }
                </frame-menu-container> : null
                }
                <frame-container direction="vertical" alignment="flexStart" margin={'.1 0 0 0'}>
                    <frame-checkbox
                        {...inComposite}
                        label='In Composite' />
                </frame-container>

                <frame-container direction="horizontal" alignment="flexStart" margin={'.1 0 0 0'}>
                    <frame-base-interactive title='Copy' {...copyBtn} interactive-type={'button'} width={.3} />
                    <frame-base-interactive title='Paste' {...pasteBtn} interactive-type={'button'} width={.3} />
                </frame-container>

            </frame-container>
            <frame-container direction="vertical" alignment="flexStart">
                <frame-text-input
                    {...textValue}
                    value="text field"
                    font-size=".05"></frame-text-input>
                <frame-slider
                    {...xSlider}
                    orientation="horizontal"
                    percent={.5}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title={'X'}
                    title-scale={'.5 .5'}
                    title-position="-.5 0 0"></frame-slider>
                <frame-container direction="horizontal" alignment="flexStart">
                    <frame-radio {...xQuick}
                        options={JSON.stringify([{
                            "text": "25%",
                            "type": "button",
                            "value": .25,
                            "width": ".1",
                            "id": "25%"
                        }, {
                            "text": "50%",
                            "type": "button",
                            "value": .50,
                            "width": ".1",
                            "id": "50%"
                        }, {
                            "text": "75%",
                            "type": "button",
                            "value": .75,
                            "width": ".1",
                            "id": "75%"
                        }])}
                        value="" />
                </frame-container>
                <frame-slider
                    {...ySlider}
                    orientation="horizontal"
                    percent={.5}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.5 .5'}
                    title={'Y'}
                    title-position="-.5 0 0"></frame-slider>
                <frame-container direction="horizontal" alignment="flexStart">
                    <frame-radio {...yQuick}
                        options={JSON.stringify([{
                            "text": "25%",
                            "type": "button",
                            "value": .25,
                            "width": ".1",
                            "id": "25%"
                        }, {
                            "text": "50%",
                            "type": "button",
                            "value": .50,
                            "width": ".1",
                            "id": "50%"
                        }, {
                            "text": "75%",
                            "type": "button",
                            "value": .75,
                            "width": ".1",
                            "id": "75%"
                        }])}
                        value="" />
                </frame-container>
            </frame-container>
            <frame-container direction="vertical" alignment="flexStart">
                <frame-slider
                    {...fontSize}
                    orientation="horizontal"
                    percent={.3}
                    bar-thickness=".1"
                    nearest={1}
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Font Size'}
                    title-position="0 .1 0"></frame-slider>
                <frame-slider
                    {...rotation}
                    orientation="horizontal"
                    percent={0}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Rotation'}
                    title-position="0 .1 0"></frame-slider>
                <frame-container direction="horizontal" alignment="flexStart">
                    <frame-radio {...rotationQuick}
                        options={JSON.stringify([{
                            "text": "25%",
                            "type": "button",
                            "value": .25,
                            "width": ".1",
                            "id": "25%"
                        }, {
                            "text": "50%",
                            "type": "button",
                            "value": .50,
                            "width": ".1",
                            "id": "50%"
                        }, {
                            "text": "75%",
                            "type": "button",
                            "value": .75,
                            "width": ".1",
                            "id": "75%"
                        }])}
                        value="" />
                </frame-container>
            </frame-container>
            <frame-container direction="vertical" alignment="flexStart">
                <a-google-fonts {...googleFont} fonts={JSON.stringify(googleFonts)} value={'Select'} />
                {/* {googleFonts?.length ? <frame-menu-container
                    {...googleFont}
                    forward-step="0.05"
                    text-value="---Select---"
                    menu-direction={'down'}
                    flex-direction="column"
                    justify-content="flexStart"
                    align-items="flexStart"
                    component-padding="0.01"
                    menu-item-height={`.2`}
                    menu-item-width={`1.0`}>
                    {
                        googleFonts.map((text, index) => {
                            return (
                                <frame-base-interactive
                                    font-size=".07"
                                    value={text.name}
                                    title={text.name}
                                    interactive-type={'button'}
                                    width={1}
                                    height={"0.2"}
                                    margin="0 0 0.05 0"
                                />
                            )
                        })
                    }
                </frame-menu-container> : null
                } */}
                <frame-container direction="vertical" alignment="flexStart" margin={'.1 0 0 0'}>
                    <frame-button title='Add Google Font' font-size={.1} width={'1'} margin={'.1 0 0 0'} height={.2} {...addFontBtn} />
                </frame-container>
                <frame-container direction="vertical" alignment="flexStart" margin={'.1 0 0 0'}>
                    <frame-slider
                        {...layerMenu}
                        orientation="horizontal"
                        percent={0}
                        bar-thickness=".1"
                        bar-length="1"
                        height=".3"
                        targetbarsize={.3}
                        title-scale={'.3 .3'}
                        title={'Layer'}
                        title-position="0 .1 0"></frame-slider>
                </frame-container>
                <frame-container direction="vertical" alignment="flexStart" margin={'.1 0 0 0'}>
                    <frame-checkbox
                        {...hide}
                        label='Hide' />
                </frame-container>
            </frame-container>
            <frame-container direction="vertical" alignment="flexStart">
                <frame-troika-text value='Font Color' scale='.5 .5' fontSize={.1} />
                <frame-slider
                    {...red}
                    orientation="horizontal"
                    percent={.3}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Red'}
                    title-position="0 .1 0"></frame-slider>
                <frame-slider
                    {...green}
                    orientation="horizontal"
                    percent={0}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Green'}
                    title-position="0 .1 0"></frame-slider>
                <frame-slider
                    {...blue}
                    orientation="horizontal"
                    percent={0}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Blue'}
                    title-position="0 .1 0"></frame-slider>
            </frame-container>
            <frame-container direction="vertical" alignment="flexStart">
                <frame-troika-text value='Outline Width' scale='.5 .5' fontSize={.1} />
                <frame-slider
                    {...outlineWidth}
                    orientation="horizontal"
                    percent={.3}
                    bar-thickness=".1"
                    nearest={1}
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Outline Size'}
                    title-position="0 .1 0"></frame-slider>
                <frame-troika-text value='Outline Color' scale='.5 .5' fontSize={.1} />
                <frame-slider
                    {...outlineColorR}
                    orientation="horizontal"
                    percent={.3}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Red'}
                    title-position="0 .1 0"></frame-slider>
                <frame-slider
                    {...outlineColorG}
                    orientation="horizontal"
                    percent={0}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Green'}
                    title-position="0 .1 0"></frame-slider>
                <frame-slider
                    {...outlineColorB}
                    orientation="horizontal"
                    percent={0}
                    bar-thickness=".1"
                    bar-length="1"
                    height=".3"
                    targetbarsize={.3}
                    title-scale={'.3 .3'}
                    title={'Blue'}
                    title-position="0 .1 0"></frame-slider>
            </frame-container>
        </frame-container>
    )
}