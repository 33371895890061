import React, { useState } from 'react';

interface SliderProps {
    onChange: (value: number) => void;
}

const Slider: React.FC<SliderProps> = ({ onChange }) => {
    const [value, setValue] = useState<number>(0); // Initial value

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue);
        onChange(newValue);
    };

    return (
        <div>
            <input
                type="range"
                min="0"
                max="100"
                value={value}
                onChange={handleSliderChange}
            />
        </div>
    );
};

export default Slider;
