import { useContext, useEffect, useRef, useState } from "react";
import { APainterContext, APainterContextValue } from "./APainterContext";
import { AspectRatios } from './StableDiffModels';
import { raiseCustomEvent } from "./painter/util";
import { PAINTER_CONSTANTS } from "./painter/constants";

export default function RatioMenu() {
    const menuContainerRef = useRef(null);
    const context: APainterContextValue = useContext(APainterContext);
    const options = Object.keys(AspectRatios);

    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);
    return (
        <frame-menu-container
            ref={menuContainerRef}
            menu-direction={'down'}
            flex-direction="column"
            justify-content="flexStart"
            align-items="flexStart"
            width="1"
            component-padding="0.01"
            text-value={'Aspect Ratio'}
            menu-item-height={.2}
            menu-item-width={1.0}
            position="0 0 0" rotation="0 0 0"
        >
            {options.map((option) => {
                return <RadioOption
                    key={option}
                    option={option}
                    value={`${AspectRatios[option]}` == `${context?.aspectRatio}`}
                    onChange={(value) => {
                        raiseCustomEvent(PAINTER_CONSTANTS.UPDATE_RATIO, {
                            ratio: AspectRatios[option]
                        })
                    }} />
            })}
        </frame-menu-container>
    )
}

function RadioOption({ value, option, onChange }) {
    let ref = useRef(null);
    useEffect(() => {
        if (ref?.current) {
            let evt = 'clicked';
            let handler = () => {
                if (onChange) {
                    onChange();
                }
            };
            ref?.current.addEventListener(evt, handler);
            return () => {
                ref?.current.removeEventListener(evt, handler);
            }
        }
    }, [ref?.current])
    useEffect(() => {
        if (ref?.current) {
            ref?.current.setAttribute('checked', value);
        }
    }, [ref?.current, value])
    return <frame-base-interactive ref={ref} checked={value} key={option} width="1.35"
        interactive-type="radio"
        height=".2"
        value={option}
        font-size=".07" />
}