import React, { useContext, useEffect, useRef, useState } from 'react';
import { APainterContext } from './APainterContext';
import { useCustomEventListener } from './painter/useCustomEventListener';
import { PAINTER_CONSTANTS } from './painter/constants';

export default function ModelSelectionPanel({ listModels }) {
    const modelSelectionPanelRef = useRef(null);
    const context = useContext(APainterContext);
    const modellistRef = useRef<any>(null);
    const [modelComponentUpdated, setModelComponentUpdated] = useState(0);
    const [show, setShow] = useState(false);

    const panelPositions = {
        loraSelection: {
            rotation: '0 0 0',
            show: '0 .5 -1.9',
            hide: '-1.4 -4.34 .3'
        }
    }

    useCustomEventListener(PAINTER_CONSTANTS.OPEN_MODEL_MENU, async () => {
        setShow(true);
    })
    useEffect(() => {
        if (modellistRef?.current) {
            if (listModels) {
                modellistRef?.current.setAttribute('options', JSON.stringify(
                    listModels
                ));
            }
        }
        if (context?.layerPrompts) {
            if (modelComponentUpdated) {
                if (!context?.layerPrompts?.model && context.useStabileDiffusion) {
                    context.setModel('arcane-diffusion')
                }
            }
        }
    }, [
        listModels?.length,
        modellistRef?.current,
        context?.useStabileDiffusion,
        modelComponentUpdated,
        show
    ]);
    useCustomEventListener(PAINTER_CONSTANTS.MODEL_COMPONENT_LOADED, async () => {
        setModelComponentUpdated(Date.now());
    })
    useCustomEventListener(PAINTER_CONSTANTS.OPEN_MODEL_MENU, (evt: any) => {
        // hidePanels();
        setShow(true);
    })
    useCustomEventListener(PAINTER_CONSTANTS.CLOSE_MODEL_SELECTION, async (evt) => {
        setShow(false);
    })
    
    useCustomEventListener(PAINTER_CONSTANTS.OPEN_LORA_SELECTION, async (evt) => {
        setShow(false);
    })
    useEffect(() => {
        if (show) {
            showPanel(modelSelectionPanelRef);
        }
    }, [show])
    function showPanel(ref: any) {
        if (ref.current)
            ref.current.setAttribute('animation', {
                property: 'position',
                from: panelPositions.loraSelection.hide,
                to: panelPositions.loraSelection.show,
                dur: 500,
                easing: 'easeOutQuad',
                loop: false
            });
    }
    if (show)
        return (
            <a-entity ref={modelSelectionPanelRef}
                id="model-selection-panel"
                position={'-2.4 -4.34 .3'}
                rotation={`0 0 0`}>
                <frame-infinite-list
                    ref={modellistRef}
                    width={4}
                    itemsize={1}
                    itemtemplate={'a-model-item'}
                    columns={3}
                    icon="f128"
                    icon-font-size=".14"
                    font-size=".1"
                    icon-font="assets/fonts/ionicons.ttf"
                    selectionevent={PAINTER_CONSTANTS.MODEL_SELECTION}
                    closeevent={PAINTER_CONSTANTS.CLOSE_MODEL_SELECTION}
                    position="0 1 0">
                </frame-infinite-list>
            </a-entity>
        );
    return <></>;
}