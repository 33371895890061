import { useEffect, useRef, useState } from "react";
import { LoraMenuRadio } from "./LoraMenuPanel";
import { ImageSpaces } from "./constants";

export default function SpaceMenuComponent({ myImageSpaceMenu, setMyImageSpaceMenu }) {
    const menuContainerRef = useRef(null);
    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                console.log(evt);
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);

    return (
        <frame-menu-container ref={menuContainerRef}
            menu-direction={'up'}
            flex-direction="column"
            justify-content="flexStart"
            align-items="flexStart"
            component-padding="0.01"
            text-value="My Images"
            menu-item-height={.2}
            menu-item-width={1.0}
            position="0 .3 0"
            rotation="0 180 0">
            <LoraMenuRadio height={.2} width={1} visible={true}
                value={myImageSpaceMenu === ImageSpaces.AllMyImages}
                onChange={(value) => {
                    setMyImageSpaceMenu(ImageSpaces.AllMyImages);
                }} margin={0} word={'My Images'} />
            {/* <LoraMenuRadio height={.2} width={1} visible={true}
                value={myImageSpaceMenu === ImageSpaces.MyImages}
                onChange={(value) => {
                    setMyImageSpaceMenu(ImageSpaces.MyImages);
                }} margin={0} word={'My Images'} /> */}
            <LoraMenuRadio height={.2} width={1} visible={true}
                value={myImageSpaceMenu === ImageSpaces.SearchImages}
                onChange={(value) => {
                    setMyImageSpaceMenu(ImageSpaces.SearchImages);
                }} margin={0} word={'Pexel Images'} />
            <LoraMenuRadio height={.2} width={1} visible={true}
                value={myImageSpaceMenu === ImageSpaces.PixabayImages}
                onChange={(value) => {
                    setMyImageSpaceMenu(ImageSpaces.PixabayImages);
                }} margin={0} word={'Pixabay Images'} />
            <LoraMenuRadio height={.2} width={1} visible={true}
                value={myImageSpaceMenu === ImageSpaces.GoogleImages}
                onChange={(value) => {
                    setMyImageSpaceMenu(ImageSpaces.GoogleImages);
                }} margin={0} word={'Google Images'} />
        </frame-menu-container>
    )
}
