import React, { createContext } from 'react';
import { PainterCanvas } from './PainterCanvas';
import { PainterUserCreds } from './firebase-app';
import { CheckoutSessionLinks, User } from './interface';
import { uuidv4 } from './painter/util';

// Define the context
export interface LoraConfig {
    name: string,
    strength: number,
    url: string,
    words: string[]
}
export interface Prompt { prompt: string, positive: boolean, id: string }
export interface CatalogImage {
    id: any,
    value: any,
    text: any,
    url: any
}
export interface OverlayTextFont {
    fontSize: number,
    font: string,
    outlineColor?: {
        r: number;
        g: number;
        b: number;
    }, // Optional outline color
    outlineWidth?: number, // Optional outline width
    color: {
        r: number;
        g: number;
        b: number;
    }

}
export interface OverlayText {
    id: string;
    value: string;
    position: { x: number, y: number, rotation: number }
    font: OverlayTextFont;
    layer: number;
    composite: boolean;
    hidden: boolean;
}
function createDefaultOverlayText(): OverlayTextFont {
    return {
        fontSize: .1,
        font: '',
        outlineColor: {
            r: 1,
            g: 1,
            b: 1
        },
        outlineWidth: .1,
        color: {
            r: 1,
            g: 1,
            b: 1
        }
    }
}

export function createOverlayText(): OverlayText {
    return {
        id: uuidv4(),
        value: '',
        position: { x: 0, y: 0, rotation: 0 },
        font: createDefaultOverlayText(),
        composite: true,
        hidden: false,
        layer: 1
    }
}

export interface GoogleFont {
    name: string;
    fontFamily: string;
    fontWeight: number;
    fontStyle: string;
    family: string;
}

export enum RenderTarget {
    StableDiffusionApi = "StableDiffusionApi",
    LocalCivitai = "LocalCivitai"
}
export interface CitivaiSetup {
    id: string,
    loraPrompts?: string;
    prompts: Prompt[],
    enabled?: boolean,
    loras: CivitaiLora[];
    steps: number;
    denoise: number;
    model?: CivitaiModel
}
export interface CivitaiDownload {
    downloadUrl?: string;
    fileName?: string;
}
export interface CivitaiLora extends CivitaiDownload {
    url: string;
    name: string;
    id: string;
    strength: number;
    trainedWords: CivitaiTrainedWord[]
    clip: number;
};

export interface CivitaiTrainedWord {
    word: string;
    strength: number;
}

export interface CivitaiModel extends CivitaiDownload {
    url: string;
    name: string;
    id: string;
    trainedWords: CivitaiTrainedWord[]
    strength: number;
    clip: number;
}
export function createDefaultCivitaiSetup(): CitivaiSetup {
    return {
        id: uuidv4(),
        loras: [],
        prompts: [],
        denoise: .7,
        steps: 7,
        model: createDefaultCivitaiModel()
    }
}
export function createDefaultCivitaiModel(): CivitaiModel {
    return {
        id: uuidv4(),
        name: '',
        strength: 0,
        trainedWords: [],
        clip: 0,
        url: ''
    }
}
export function createDefaultCivitaiLora(): CivitaiLora {
    return {
        id: uuidv4(),
        url: '',
        name: '',
        trainedWords: [],
        clip: 0,
        strength: 0
    }
}
export interface APainterContextValue {
    addFont: (googleFont: GoogleFont) => void;
    addText: () => void,
    machineIdentifier: string;
    localRenderUrl: string;
    setLocalRenderUrl: (url: string) => void;
    citivaiSetups: CitivaiSetup[],
    setCivitaiSetups: (setups: CitivaiSetup[]) => void,
    googleFonts: GoogleFont[],
    removeText: (id: string) => void,
    setRenderTarget: (renderTarget: RenderTarget) => void;
    renderTarget: RenderTarget;
    updateOverlayTexts: (id: string, properties: string[], value: any[]) => void,
    copyOverylayTexts: (to_id: string, from_id: string, properties: string[]) => void,
    setSelectedText: (id: string) => void,
    compositeCanvas: any;
    selectedOverlayText: string;
    texts: OverlayText[],
    addPrompt(arg0: { prompt: string; positive: boolean; }): void;
    removePrompt: (id: string) => void,
    webhook: string;
    selectedModelTypes: { [k: string]: boolean },
    setSelectedModelTypes: (key: string, value: boolean) => void,
    scheduler: string;
    focusedElement: string,
    setStrength: (strengh: number) => void;
    supportedModels: any;
    aspectRatio: number;
    strength: number;
    setScheduler: (scheduler: string) => void;
    webhooks: string[];
    currentSetup: string | null,
    setCurrentSetup: (val: string) => void;
    loraOptions: {
        [id: string]: {
            strength: number,
            words: { [word: string]: boolean }
        }
    },
    modelLoraSetups: ModelLoraSetup[];
    currentModelLoraSetup: string;
    upsertModelLoraSetup: (arg: ModelLoraSetup) => void;
    deleteModelLoraSetup: (id: string) => void;
    setCurrentModelLoraSetup: (id: string) => void;
    setImageSpace: (space: string) => void,
    imageSpace: string;
    painterLoaded: boolean;
    search: ({ query, source }: { query: string, source?: string }) => Promise<void>;
    searchImages: CatalogImage[],
    pixalogImages: CatalogImage[],
    googleImages: CatalogImage[],
    user: User,
    drawingDisabled: React.MutableRefObject<boolean>;
    deviceType: string;
    paintSessionId: string;
    userInfo: PainterUserCreds,
    addCanvasContext: (args: { id: string, canvas: PainterCanvas }) => void,
    removeCanvasContext: (id: string) => void,
    painterCanvas: any;
    painterCanvasComponent: any;
    setLayerPrompts: any;
    layerIds: string[];
    checkoutLinks: CheckoutSessionLinks[];
    setLayerIds: (args: string[]) => void;
    setUserInfo: (args: any) => void,
    setSelectedLayer: (arg: string) => void;
    getLoraUrl: (layer: any, index: any) => any;
    selectedLayer,
    useStabileDiffusion: boolean,
    selectedLayerIndex,
    shouldUpdate: any;
    signOutUser: () => Promise<void>;
    setLoraEnabled: (layer: any, lora: any, name: any, word: any, val: any) => void,
    setLayerLoraStyle: (args: { lora: any, layer?: string, loraUrl: string, }) => void,
    setLayerLorasStyle: (args: { lora: any, loraUrl: string }[], model: string) => void,
    setModel: (model: any) => void,
    clearLoraStyle: (lora: any) => void,
    canvasSize: {
        width: number,
        height: number
    },
    layerPrompts: LayerPrompts
    // Define any values that you might want to pass through the context
}
export interface LayerPrompts {
    [key: `lora${number}`]: LoraConfig;
    lora1?: LoraConfig,
    lora2?: LoraConfig,
    lora3?: LoraConfig,
    prompts?: Prompt[],
    model?: any,
    prompt?: string,
    negativePrompt?: string;
    supportedModel?: any;
    [layer: number]: {
        negative: string;
        positive: string;
        enabled: boolean;
        lora1: string;
        lora2: string;
        lora3: string;
        loraConfigs: {
            lora1: {
                [key: string]: {
                    enabled: boolean,
                    strength: number
                }
            },
            lora2: {
                [key: string]: {
                    enabled: boolean,
                    strength: number
                }
            },
            lora3: {
                [key: string]: {
                    enabled: boolean,
                    strength: number
                }
            },
        };
    }
}
export function createDefaultLayerPrompt() {
    return {
        negative: '',
        positive: '',
        enabled: true,
        lora1: '',
        lora2: '',
        lora3: '',
        loraConfigs: {
            lora1: {
            },
            lora2: {
            },
            lora3: {
            },
        }
    }
}
export function createDefaultLoraConfig() {
    return {
        enabled: false,
        strength: 1
    }
}
export const APainterContext = createContext<APainterContextValue | null>(null);

export interface ModelLoraSetup {
    id: string;
    strength: number;
    layerPrompts: LayerPrompts;
    loraOptions: {
        [id: string]: {
            strength: number,
            url: string;
            words: { [word: string]: boolean }
        }
    }
}