import { AFRAME } from "../painter/root";
import { GrabAndDropEvents, customEventListener, } from "../painter/systems/grabanddrop";
import { PAINTER_CONSTANTS } from "../painter/constants";
import { drawTextureOnCanvas } from "../painter/components/paint-canvas";
import { createComponent } from "../util";
const THREE: any = (window as any).THREE;
export default function () {
    AFRAME.registerComponent('canvas-layers', {
        schema: {
            selectionevent: { type: 'string' },
            options: { type: 'string' }
        },
        buildCompositeTexture: function (canvases) {
            // Set the canvas background to white
            var texture = new THREE.Texture(canvases[0]);
            texture.needsUpdate = true; // Important to update the texture
            return {
                canvas: canvases[0], texture, canvases, context: null//canvases[0].getContext('2d')
            };
        },
        init: function () {
            let me = this;
            let layers = [];
            customEventListener(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILT, (detail: any) => {
                const { canvases, id } = detail;
                if (canvases?.length) {
                    layers.push({ id });
                }
            })

        }
    });
    AFRAME.registerPrimitive('a-canvas-layers', {
        defaultComponents: {
            'canvas-layers': {}
        },
        mappings: {
        }
    });
}