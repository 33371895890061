import { useEffect, useRef, useState } from "react";
import { LoraMenuRadio } from "./LoraMenuPanel";
import { DrawMode, getUISystem } from "./painter/systems/ui";
import { raiseCustomEvent } from "./painter/util";
import { PAINTER_CONSTANTS } from "./painter/constants";

export default function DrawMenuComponent() {
    const radioComponentRef = useRef(null);
    const menuContainerRef = useRef(null);
    const [drawMode, setDrawMode] = useState(getUISystem()?.getDrawingMode());
    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                console.log(evt);
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);

    return <frame-radio
        onClick={(evt) => {
            if (evt?.detail?.value) {
                let { value } = evt.detail;
                if (radioComponentRef?.current) {
                    setDrawMode(DrawMode[value]);
                    getUISystem()?.setDrawingMode(DrawMode[value])
                    radioComponentRef?.current.setAttribute('value', value);
                }
            }
        }}
        ref={radioComponentRef}
        direction={'vertical'}
        value={DrawMode.Draw}
        options={JSON.stringify(Object.entries(DrawMode).map((val) => {
            const [key, value] = val;
            return {
                width: (value.length * .05) + .25,
                value: value,
                text: key,
                id: value
            }
        }))}></frame-radio>
}