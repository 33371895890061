import React, { useContext, useEffect, useRef, useState } from 'react';
import TextInput from "./component/TextInput";
import { APainterContext, APainterContextValue, RenderTarget } from './APainterContext';
import SelectInput from './component/SelectInput';
import CheckboxInput from './component/CheckboxInput';
import { NegativePrompts, NegativeRealisticPrompts, NegativeUserPrompts, art_styles, artists, getEmotions, get_animals, get_styles } from './prompts';
import { raiseCustomEvent, removeSubstring, uuidv4 } from './painter/util';
import TextInputArea from './component/TextInputArea';
import Tabs from './Tabs';
import PromptTab from './PromptTab';
import PainterCanvasManager from './PainterCanvasManager';
import ColorPicker from './ColorPicker';
import Slider from './Slider';
import { SUPPORTED_LORAS } from './Lora';
import LoraSelect from './component/LoraSelect';
import { DrawMode, getUISystem } from './painter/systems/ui';
import { useListLora } from './useListModels';
import './PromptInput.scss';
import useCustomEventListenerAsync, { useCustomEventListener } from './painter/useCustomEventListener';
import { PAINTER_CONSTANTS } from './painter/constants';
import { UserProperties } from './interface';
import Credits from './Credits';
import BuyCredits from './BuyCredits';
import { signOutUser, uploadCanvas, writeToDatabase } from './firebase-app';
import { ImageSpaces } from './constants';
import DesktopUsageInstructions from './DesktopInstructions';
import PettyPaintDescription from './PettyPaintDescription';
import { containerStlye } from './util';
import MenuComponent from './MenuComponent';
import APainterAuth from './APainterAuth';
import ImageDropCanvas from './ImageDropCanvas';
import FontInput from './FontInput';
import FontDrop from './FontDrop';
import LocalRendering from './LocalRendering';
export default function PromptInput() {
    const context: APainterContextValue = useContext(APainterContext);
    const [update, setUpdate] = useState(Date.now());
    const promptSearchAreaRef = useRef(null);
    const [credits, setCredits] = useState<number | null>(null);
    const currentLayer = context.selectedLayerIndex;
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [focusedElement, setFocusedElement] = useState('');
    const [page, setPage] = useState('');
    const [search, setSearch] = useState('');
    const [color, setColor] = useState<string>('#ffffff'); // Default color
    const [negCurrentPrompt, setNegCurrentPrompt] = useState('');
    const { layerPrompts, setLayerPrompts } = context;
    const supportedLoras = useListLora(context.useStabileDiffusion);
    useCustomEventListener(PAINTER_CONSTANTS.COLOR_CHANGE, async (evt: any) => {
        setColor(evt.value);
    })
    const { detail } = useCustomEventListener(PAINTER_CONSTANTS.FOCUS_INPUT, (evt: any) => {
        setFocusedElement(evt.element);
        setTimeout(() => {
            let promptSearchArea: any = document.querySelector('#prompt-search-area');
            if (promptSearchArea) {
                promptSearchArea.focus();
            }

        }, 1000)
    });

    async function storeSetups() {
        if (context.renderTarget === RenderTarget.LocalCivitai) {
            let setups = JSON.stringify(context.citivaiSetups.map((setup) => {
                setup.loraPrompts = (setup.loras || []).map((lora) => {
                    if (lora.fileName && lora?.strength) {
                        let words = lora.trainedWords.map((word) => {
                            return `${word.word}<lora:${lora.fileName}:${word.strength}>`
                        }).join(' ')
                        return `<lora:${lora.fileName}:${lora.strength}> ${words}`
                    }
                    return '';
                }).join(' ');
                return setup;
            }));
            await writeToDatabase(`civitai/${context?.userInfo?.uid}/render_targets`, {
                setups,
                stamp: Date.now(),
                machine: context.machineIdentifier
            })
        }
    }
    useEffect(() => {
        setUpdate(Date.now())
    }, [detail, context.layerIds, context.shouldUpdate, context.focusedElement])

    useEffect(() => {
        if (context.user) {
            setCredits(context.user[UserProperties.Credits] || 0);
        }
    }, [context.user]);
    // useEffect(() => {
    //     if (context.focusedElement === 'prompt-search-area')
    //         if (promptSearchAreaRef?.current) {
    //             promptSearchAreaRef?.current.focus();
    //         }
    // }, [promptSearchAreaRef?.current, context.focusedElement]);
    let content = null;
    switch (page) {
        case 'directions':
            content = <div style={{ ...containerStlye, }}><div style={{
                marginTop: '5px',
                flexDirection: 'row', display: context.deviceType === 'VR' ? 'none' : 'flex'
            }}>
                <DesktopUsageInstructions />
            </div></div>
            break;
        case 'about':
            content = <div style={{ ...containerStlye, }}>
                <PettyPaintDescription />
                <a href="javascript:void(0)" onClick={() => {
                    setPage(null);
                }} className="prompt-button">Back</a>
            </div>
            break;
        case 'local':
            content = <div style={{ ...containerStlye, }}>
                <LocalRendering />
                <a href="javascript:void(0)" onClick={() => {
                    setPage(null);
                }} className="prompt-button">Back</a>
            </div>
            break;
        default:
            if (!context.userInfo) {
                content = <>
                    <APainterAuth>
                    </APainterAuth>
                    <div style={{ marginTop: 10 }}>
                        <DesktopUsageInstructions />
                    </div>
                </>;

            }
            else {
                content = (
                    <>
                        <div>
                            {credits !== null ? <Credits></Credits> : <BuyCredits />}
                        </div><div style={{
                            marginTop: '5px',
                            flexDirection: 'row', display: context.deviceType === 'VR' ? 'none' : 'flex'
                        }}>
                            <DesktopUsageInstructions />
                        </div>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <div style={{ flexDirection: 'column', display: 'flex' }}>
                                <div style={{ display: focusedElement === 'prompt-search-area' ? '' : 'none' }}>
                                    <TextInput id="prompt-search-area"
                                        label={'Search Pexel'}
                                        value={search || ''}
                                        onBlur={async () => {
                                            if (search) {
                                                let space = '';
                                                setFocusedElement('');
                                                switch (context.imageSpace) {
                                                    case ImageSpaces.MyImages:
                                                        break;
                                                    case ImageSpaces.PixabayImages:
                                                        space = 'pixabay';
                                                        break;
                                                    case ImageSpaces.SearchImages:
                                                        space = 'pexel';
                                                        break;
                                                    case ImageSpaces.GoogleImages:
                                                        space = 'google';
                                                        break;
                                                }
                                                if (space) {
                                                    await context.search({ query: search, source: space });
                                                    setSearch('');
                                                }
                                            }
                                        }}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setSearch(event.target.value);
                                        }} />
                                </div>
                                <div style={{ flexDirection: 'row', display: 'flex', width: context.deviceType === 'VR' ? '10px' : 'auto' }}>
                                    <div style={{ display: focusedElement === 'prompt-input-area' ? '' : 'none' }}>
                                        <TextInput id="prompt-input-area"
                                            label={'Positive Prompt'}
                                            value={currentPrompt || ''}
                                            onBlur={async () => {
                                                setFocusedElement('');
                                                if (currentPrompt) {
                                                    if (context.renderTarget === RenderTarget.LocalCivitai && context.currentSetup) {
                                                        let setup = context.citivaiSetups.find(v => v.id === context.currentSetup);
                                                        setup.prompts = setup.prompts || []
                                                        setup.prompts.push({
                                                            prompt: currentPrompt,
                                                            positive: true,
                                                            id: uuidv4()
                                                        });
                                                        raiseCustomEvent(PAINTER_CONSTANTS.PROMPTS_UPDATED, {});
                                                        await storeSetups();
                                                    }
                                                    else {
                                                        context.addPrompt({ prompt: currentPrompt, positive: true });
                                                    }
                                                    setCurrentPrompt('');
                                                }
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setCurrentPrompt(event.target.value);
                                            }} />
                                    </div>
                                    <div style={{ display: focusedElement === 'negative-prompt-input-area' ? '' : 'none' }}>
                                        <TextInput
                                            label={'Negative Prompt'}
                                            id="negative-prompt-input-area"
                                            value={negCurrentPrompt || ''}
                                            onBlur={() => {
                                                setFocusedElement('');

                                                if (negCurrentPrompt) {
                                                    if (context.renderTarget === RenderTarget.LocalCivitai && context.currentSetup) {
                                                        let setup = context.citivaiSetups.find(v => v.id === context.currentSetup);
                                                        setup.prompts = setup.prompts || []
                                                        setup.prompts.push({
                                                            prompt: negCurrentPrompt,
                                                            positive: false,
                                                            id: uuidv4()
                                                        });
                                                        raiseCustomEvent(PAINTER_CONSTANTS.PROMPTS_UPDATED, {});
                                                    }
                                                    else {
                                                        context.addPrompt({ prompt: negCurrentPrompt, positive: false });
                                                    }
                                                    setNegCurrentPrompt('');
                                                }
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setNegCurrentPrompt(event.target.value);
                                            }} />
                                    </div>
                                </div>
                                <div style={{ display: 'none' }}>
                                    <SelectInput value={`${currentLayer + 1}`} label={'Layer'} onChange={(e) => {
                                        (window as any).selectLayer(null, context.layerIds[parseInt(e.target.value) - 1])
                                    }} options={['Select Layer', ...(context.layerIds || []).map((_, t) => {
                                        let dt = update
                                        return `${t + 1}`
                                    })]} />
                                </div>
                            </div>
                            <div style={{ display: context.deviceType === 'VR' ? 'none' : 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                                    <div style={{ padding: 10, display: 'none' }}>
                                        <CheckboxInput
                                            label='Eraser Enabled'
                                            checked={getUISystem() && getUISystem().getDrawingMode() === DrawMode.Erase || false}
                                            onChange={(event) => {
                                                if (getUISystem().getDrawingMode() === DrawMode.Erase) {
                                                    getUISystem().setDrawMode();
                                                }
                                                else {
                                                    getUISystem().setEraseMode();
                                                }
                                                setUpdate(Date.now());
                                            }} />
                                    </div>
                                    <div style={{ padding: 10, display: 'none' }}>
                                        <ColorPicker color={color} onChange={(val) => {
                                            (window as any).colorArrayClick(null, val)
                                        }} />
                                    </div>
                                    <div style={{ padding: 10, display: 'none' }}>
                                        <Slider onChange={(val) => {
                                            (window as any).setCursorSize(null, val / 100)
                                        }} />
                                    </div>
                                </div>
                                <div style={{ flex: 1, display: 'none', flexDirection: 'row', }}>
                                    <LoraSelect value={`${layerPrompts?.lora1?.name}`}
                                        onChange={function (event: React.ChangeEvent<HTMLSelectElement>): void {
                                            context.setLayerLoraStyle({
                                                lora: 'lora1',
                                                layer: context.layerIds[currentLayer],
                                                loraUrl: supportedLoras.find(x => x.name === event.target.value)?.image || ''
                                            })
                                        }} />
                                    <LoraSelect value={`${layerPrompts?.lora2?.name}`}
                                        onChange={function (event: React.ChangeEvent<HTMLSelectElement>): void {
                                            context.setLayerLoraStyle({
                                                lora: 'lora2',
                                                layer: context.layerIds[currentLayer],
                                                loraUrl: supportedLoras.find(x => x.name === event.target.value)?.image || ''
                                            })
                                        }} />
                                    <LoraSelect value={`${layerPrompts?.lora3?.name}`}
                                        onChange={function (event: React.ChangeEvent<HTMLSelectElement>): void {
                                            context.setLayerLoraStyle({
                                                lora: 'lora3',
                                                layer: context.layerIds[currentLayer],
                                                loraUrl: supportedLoras.find(x => x.name === event.target.value)?.image || ''
                                            })
                                        }} />
                                </div>
                            </div>
                        </div>


                        <div style={{ flexDirection: 'column', display: (context.deviceType === 'VR' || !layerPrompts?.prompts?.length) ? 'none' : 'flex', padding: 10 }}>
                            <h1>Prompts</h1>
                            {(layerPrompts?.prompts || []).map((prompt: { prompt: string, positive: boolean, id: string }) => {
                                return <>
                                    <div key={prompt.id} style={{
                                        fontSize: 14,
                                        padding: 10,
                                        backgroundColor: 'white',
                                        borderRadius: 4,
                                        marginBottom: 10,
                                        maxWidth: 245,
                                        color: prompt.positive ? 'green' : 'red'
                                    }}><button className="prompt-button"
                                        onClick={() => {
                                            context.removePrompt(prompt.id);
                                        }}
                                        style={{
                                            backgroundColor: 'white',
                                            borderRadius: 3
                                        }}>
                                            <i style={{ color: 'black' }} className="fa-solid fa-xmark"></i>
                                        </button> {prompt?.prompt}</div>
                                </>
                            })}
                        </div>
                        <div style={{ marginTop: 5, width: 200 }}>
                            <div style={{
                                padding: '20px',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                borderRadius: '8px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                zIndex: 1000, // Ensure it's above other content
                            }}>
                                <ImageDropCanvas onImage={async (canvas) => {
                                    raiseCustomEvent(PAINTER_CONSTANTS.IMAGE_ADDED, { canvas })
                                    let fileName = `layer_stable_${uuidv4()}.png`;
                                    let filePath = `stable_renders/${context?.userInfo?.uid}/${fileName}`;
                                    await uploadCanvas(canvas, filePath, fileName);
                                }} />
                            </div>
                        </div>
                        <div>
                            <FontInput />
                        </div>
                        <div>
                            <FontDrop />
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <div>
                                <button className="prompt-button" onClick={async () => {
                                    await signOutUser();
                                    context.setUserInfo(null);
                                }}>Sign out</button>
                            </div>
                        </div>
                    </>
                );
            }
    }

    let painterCanvasManager = <div style={{ display: 'none', flexDirection: 'row', flex: 1 }}>
        <div style={{ display: 'flex', flex: 1 }}>
            <div data-canvasbucket={context.selectedLayer} >
                <PainterCanvasManager />
            </div>
        </div>
    </div>
    return <>
        <div style={{ marginBottom: 4 }}>
            <MenuComponent onChangePage={(page) => {
                setPage(page);
            }}></MenuComponent>
        </div>
        {content}
        {painterCanvasManager}
    </>
}