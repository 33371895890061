import React, { useContext } from 'react';
import { APainterContext, APainterContextValue } from "./APainterContext";
import { UserProperties } from './interface';
import { PurchasableItemType } from './constants';
import CreditsIcons from './CreditsIcon';
import SubscriptionPlan from './SubscriptionPlan';
import { btnstyle, containerStlye } from './util';
export default function BuyCredits({ onClose }: { onClose?: any }) {
    const context: APainterContextValue = useContext(APainterContext);
  
    function isSubscription(itemType) {
        switch (itemType) {
            case PurchasableItemType.Level0:
            case PurchasableItemType.Level1Credits:
            case PurchasableItemType.Level2Credits:
            case PurchasableItemType.Level3Credits:
                return false;
            default:
                return true;
        }
    }
    function toItemPrice(itemType) {
        switch (itemType) {
            case PurchasableItemType.Level0:
                return 5;
            case PurchasableItemType.Level1Credits:
                return 0;
            case PurchasableItemType.Level1Subscription:
                return 20;
            case PurchasableItemType.Level2Credits:
                return 0;
            case PurchasableItemType.Level2Subscription:
                return 0;
            case PurchasableItemType.Level3Credits:
                return 0;
            case PurchasableItemType.Level3Subscription:
                return 0;
        }
    }
    return <>
        <div style={containerStlye}>
            <h1 style={{ display: 'flex' }}>
                <span style={{ paddingRight: 10 }}>
                    <CreditsIcons />
                </span>
            </h1>
            <div style={{ paddingTop: 10, display: 'flex', flexDirection: 'row', alignContent: 'space-around' }} >
                {/* <SubscriptionPlan
                    checkoutLink={{ itemType: PurchasableItemType.Level0, url: '' }}
                    url={''}
                    price={5}
                    billed="once" />
                <SubscriptionPlan
                    checkoutLink={{ itemType: PurchasableItemType.Level0, url: '' }}
                    url={''}
                    price={5}
                    billed="once" />
                <SubscriptionPlan
                    checkoutLink={{ itemType: PurchasableItemType.Level0, url: '' }}
                    url={''}
                    price={5}
                    billed="once" />
                <SubscriptionPlan
                    checkoutLink={{ itemType: PurchasableItemType.Level0, url: '' }}
                    url={''}
                    price={5}
                    billed="once" /> */}
                {
                    (context?.checkoutLinks || []).map((checkoutLink) => {
                        return (
                            <SubscriptionPlan key={checkoutLink.url}
                                checkoutLink={checkoutLink}
                                url={checkoutLink.url} price={toItemPrice(checkoutLink.itemType)}
                                billed={isSubscription(checkoutLink.itemType) ? "monthly" : 'once'} />
                        )
                    })
                }</div>
            {onClose ? <a style={{ ...btnstyle, marginTop: 5 }} onClick={() => {
                onClose();
            }}>No thanks</a> : null}
        </div>
    </>
}