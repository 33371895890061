import { useContext, useEffect, useRef, useState } from "react";
import useEventListener from './painter/useEventListener';
import { PAINTER_CONSTANTS } from "./painter/constants";
import { GetColor } from "./painter/systems/ui";
import { APainterContext } from "./APainterContext";
import { ImageSpaces } from "./constants";
import { raiseCustomEvent } from "./painter/util";
import { formatDate, getFilesDetailsWithPagingAndCache, listFoldersAtPath } from "./firebase-app";
import { findClosestValue, raiseOn, setAttribute, useEventsListenerOn } from "a-frame-components";

export default function ImagePanels({ myImages, myImageSpaceMenu }) {
    const context = useContext(APainterContext);
    const searchImages = context.searchImages || [];
    const [visiblePanels, setVisiblePanels] = useState({});
    const [selectedDate, setSelectedDate] = useState(formatDate(Date.now()));
    const myImageSelectionRef = useRef(null);
    const allMyImageSelectionRef = useRef(null);
    const myImageSelectionContainerRef = useRef(null);
    const allMyImageSelectionContainerRef = useRef(null);
    const googleSelectionContainerRef = useRef(null);
    const searchImageSelectionRef = useRef(null);
    const googleSelectionRef = useRef(null);
    const searchImageSelectionContainerRef = useRef(null);
    const searchPixabayImageSelectionContainerRef = useRef(null);
    const searchPixabayImageSelectionRef = useRef(null);
    const searchButtonRef = useRef(null);
    const googleSearchButtonRef = useRef(null);
    const searchPixabayButtonRef = useRef(null);
    const [renderDates, setRenderDates] = useState([]);
    const allImageBusy = useRef(false);
    const [allImagePaging, setAllImagePaging] = useState<{
        [key: string]: {
            index: number
        }
    }>({})
    const [allImages, setAllImages] = useState<{
        [key: string]: any
    }>({});
    const myImageRef = useRef(null);

    const showPanelRef = (ref: any, position: string, fromPosition: string) => {
        ref.current.setAttribute('animation', {
            property: 'position',
            from: fromPosition,
            to: position,
            dur: 500,
            easing: 'easeOutQuad',
            loop: false
        })
    }

    const allImageCalendarSlider = useEventsListenerOn({
        ['change']: (evt: any) => {
            console.log(evt);
            let { text } = findClosestValue(renderDates, evt.detail.value)
            setSelectedDate(evt.detail.text || (text))
        }
    })
    const allImageList = useEventsListenerOn({
        ['request-more-data']: (evt: any) => {
            const { detail } = evt;
            const { atStart } = detail;
            updateAllImages(atStart)
        }
    })
    useEventListener(myImageSelectionRef, PAINTER_CONSTANTS.MY_IMAGE_SELECTION, (evt) => {
        console.log(evt);
        if (myImageRef.current) {
            myImageRef.current.setAttribute('material', `shader: flat; src: ${evt.detail.url}`)
        }
    });

    useEffect(() => {
        if (context?.userInfo?.uid && allMyImageSelectionRef?.current && visiblePanels[ImageSpaces.AllMyImages]) {
            listFoldersAtPath(`stable_renders/${context?.userInfo?.uid}`).then((res) => {
                let today = formatDate(Date.now());
                if (allMyImageSelectionRef?.current && res?.length) {
                    console.log(res);
                    let addToday = 0;
                    if (!res.some(v => v === today)) {
                        addToday = 1;
                    }
                    let value = (res.map((key, index) => {
                        return ({
                            text: key,
                            value: 100 * (index / (res.length + addToday))
                        })
                    }));
                    if (addToday) {
                        value.push({ text: today, value: 100 })
                    }
                    setRenderDates(value)
                    setAttribute(allImageCalendarSlider, 'text-value-map', JSON.stringify(value));
                    // allImageCalendarSlider.current.setAttribute('text-value-map', value)
                }
                else {
                    let value = [{ text: today, value: 100 }]
                    setRenderDates(value)
                    setAttribute(allImageCalendarSlider, 'text-value-map', JSON.stringify(value));
                }
            })
        }
    }, [context?.userInfo?.uid, allMyImageSelectionRef?.current, visiblePanels[ImageSpaces.AllMyImages]]);
    let updateAllImages = (forceRefresh?: boolean) => {
        if (!allImageBusy?.current && selectedDate && renderDates?.length) {
            allImageBusy.current = (true);
            let { text } = findClosestValue(renderDates, 1);
            getFilesDetailsWithPagingAndCache(`stable_renders/${context?.userInfo?.uid}/${selectedDate}`,
                text === selectedDate ? 0 : allImagePaging[selectedDate]?.index || 0, 1000, forceRefresh
            ).then((res) => {
                let updates = {
                    ...allImages
                };
                res.map(v => {
                    updates[selectedDate] = updates[selectedDate] || {};
                    updates[selectedDate][v.url] = v;
                })
                if (Object.keys(updates[selectedDate]).length !== Object.keys(allImages?.[selectedDate] || {}).length) {
                    setAllImagePaging({
                        ...allImagePaging,
                        [selectedDate]: {
                            index: Object.keys(updates).length
                        }
                    })
                    setAllImages(updates);
                }
            }).catch(console.error).finally(() => {
                allImageBusy.current = false;
            })
        }
    }
    useEffect(updateAllImages, [selectedDate, allImageBusy?.current])

    useEffect(() => {
        if (searchButtonRef?.current) {
            searchButtonRef?.current.addEventListener('click', () => {
                raiseCustomEvent(PAINTER_CONSTANTS.FOCUS_INPUT, { element: 'prompt-search-area' })
            })
        }
    }, [searchButtonRef?.current, visiblePanels])
    useEffect(() => {
        if (googleSearchButtonRef?.current) {
            googleSearchButtonRef?.current.addEventListener('click', () => {
                raiseCustomEvent(PAINTER_CONSTANTS.FOCUS_INPUT, { element: 'prompt-search-area' })
            })
        }
    }, [googleSearchButtonRef?.current, visiblePanels])

    useEffect(() => {
        if (searchPixabayButtonRef?.current) {
            searchPixabayButtonRef?.current.addEventListener('click', () => {
                raiseCustomEvent(PAINTER_CONSTANTS.FOCUS_INPUT, { element: 'prompt-search-area' })
                let promptInputArea: any = document.querySelector('#prompt-search-area')
                promptInputArea.focus();
            })
        }
    }, [searchPixabayButtonRef?.current, visiblePanels])

    useEffect(() => {
        if (searchImageSelectionRef?.current) {
            if (searchImages?.length) {
                searchImageSelectionRef?.current.setAttribute('options', JSON.stringify(searchImages));
            }
        }
    }, [searchImageSelectionRef?.current, searchImages, visiblePanels]);

    useEffect(() => {
        if (googleSelectionRef?.current) {
            if (context?.googleImages?.length) {
                googleSelectionRef?.current.setAttribute('options', JSON.stringify(context.googleImages));
            }
        }
    }, [googleSelectionRef?.current, context.googleImages, visiblePanels]);

    useEffect(() => {
        if (searchPixabayImageSelectionRef?.current) {
            if (context?.pixalogImages) {
                searchPixabayImageSelectionRef?.current.setAttribute('options', JSON.stringify(context.pixalogImages));
            }
        }
    }, [searchPixabayImageSelectionRef?.current, context.pixalogImages, visiblePanels]);

    useEffect(() => {
        if (myImageSelectionRef?.current) {
            let my_images = Object.keys(myImages).map((key, index) => {
                if (myImages?.[key]?.url) {
                    return {
                        id: index,
                        value: index,
                        text: key,
                        url: myImages[key]?.url
                    }
                }
            }).filter(x => x);
            if (my_images?.length) {
                myImageSelectionRef?.current.setAttribute('options', JSON.stringify(my_images));
            }
        }
    }, [myImageSelectionRef?.current, myImages, visiblePanels]);

    useEffect(() => {
        if (selectedDate && allImages?.[selectedDate])
            if (allMyImageSelectionRef?.current) {
                let my_images = Object.keys(allImages?.[selectedDate]).map((key, index) => {
                    if (allImages?.[selectedDate]?.[key]?.url) {
                        // Example usage
                        const readableDate = formatDateString(allImages?.[selectedDate]?.[key]?.metadata?.timeCreated);
                        console.log(readableDate); // Output will vary based on your locale, e.g., "February 15, 2024, 11:08:03 PM GMT+2"
                        const date = new Date(allImages?.[selectedDate]?.[key]?.metadata?.timeCreated);
                        const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });

                        return {
                            id: index,
                            value: index,
                            date: new Date(allImages?.[selectedDate]?.[key]?.metadata?.timeCreated),
                            text: readableDate,
                            url: allImages?.[selectedDate][key]?.url
                        }
                    }
                }).filter(x => x);

                my_images = my_images.sort((b, a) => a.date.getTime() - b.date.getTime());
                my_images.map((d, i) => {
                    d.id = i;
                    d.value = i;
                })
                if (my_images?.length) {
                    allMyImageSelectionRef?.current.setAttribute('options', JSON.stringify(my_images));
                }
            }
    }, [allMyImageSelectionRef?.current, allImages, selectedDate, visiblePanels]);

    useEffect(() => {
        [googleSelectionContainerRef,
            myImageSelectionContainerRef,
            allMyImageSelectionContainerRef,
            searchPixabayImageSelectionContainerRef,
            searchImageSelectionContainerRef].map((ref) => {
                if (ref?.current)
                    showPanelRef(ref, '0 -10 0', '0 -10 0')
            });
        let ref: any = null;
        switch (myImageSpaceMenu) {
            case ImageSpaces.MyImages:
                ref = myImageSelectionContainerRef;
                break;
            case ImageSpaces.AllMyImages:
                ref = allMyImageSelectionContainerRef;
                break;
            case ImageSpaces.PixabayImages:
                ref = searchPixabayImageSelectionContainerRef;
                break;
            case ImageSpaces.SearchImages:
                ref = searchImageSelectionContainerRef;
                break;
            case ImageSpaces.GoogleImages:
                ref = googleSelectionContainerRef;
                break;
        }
        if (ref?.current) {
            showPanelRef(ref, '0 0 0', '0 -10 0')
        }
    }, [visiblePanels]);

    useEffect(() => {
        setVisiblePanels({
            [myImageSpaceMenu]: true
        })
    }, [myImageSpaceMenu]);

    return (
        <a-entity position={'0 1.5a 3.5'} rotation={`0 180 0`}>
            {visiblePanels[ImageSpaces.PixabayImages] ?
                <a-entity ref={searchPixabayImageSelectionContainerRef}>
                    <frame-gui-icon-label-button
                        ref={searchPixabayButtonRef}
                        width=".45" height="0.25"
                        icon="f204"
                        icon-font-size=".14"
                        font-size=".3"
                        icon-font="assets/fonts/ionicons.ttf"
                        value=""
                        margin="0 0 0.05 0"
                        position="0 0 .1"
                        active-color="#009688"
                        background-color={GetColor(0)}
                        hover-color="#00695C"
                        border-color="#000000"
                        font-color="#E0F2F1"
                    ></frame-gui-icon-label-button>
                    <frame-infinite-list ref={searchPixabayImageSelectionRef}
                        itemtemplate="a-catalog-image"
                        hideclose={`true`}
                        selectionevent="my-image-selection"
                        closeevent={'close-my-images-ignored'}
                        width={6}
                        columns={9}>
                    </frame-infinite-list>
                </a-entity> : null}
            {visiblePanels[ImageSpaces.SearchImages] ?
                <a-entity ref={searchImageSelectionContainerRef} >
                    <frame-gui-icon-label-button
                        ref={searchButtonRef}
                        width=".65" height="0.25"
                        icon="f204"
                        icon-font-size=".14"
                        font-size=".1"
                        icon-font="assets/fonts/ionicons.ttf"
                        value="Search"
                        margin="0 0 0.05 0"
                        position="0 0 .1"
                        active-color="#009688"
                        background-color={GetColor(0)}
                        hover-color="#00695C"
                        border-color="#000000"
                        font-color="#E0F2F1"
                    ></frame-gui-icon-label-button>
                    <frame-infinite-list ref={searchImageSelectionRef}
                        itemtemplate="a-catalog-image"
                        hideclose={`true`}
                        selectionevent="my-image-selection"
                        closeevent={'close-my-images-ignored'}
                        width={6}
                        columns={9}>
                    </frame-infinite-list>
                </a-entity>
                : null}
            {visiblePanels[ImageSpaces.MyImages] ?
                <a-entity ref={myImageSelectionContainerRef}>
                    <frame-infinite-list ref={myImageSelectionRef}
                        itemtemplate="a-catalog-image"
                        hideclose={`true`}
                        selectionevent="my-image-selection"
                        closeevent={'close-my-images-ignored'}
                        width={6}
                        columns={9}>
                    </frame-infinite-list>
                </a-entity>
                : null}
            {visiblePanels[ImageSpaces.AllMyImages] ?
                <a-entity ref={allMyImageSelectionContainerRef}>
                    <frame-infinite-list ref={allMyImageSelectionRef}
                        itemtemplate="a-catalog-image"
                        hideclose={`true`}
                        selectionevent="my-image-selection"
                        closeevent={'close-my-images-ignored'}
                        {...allImageList}
                        width={6}
                        columns={9}>
                    </frame-infinite-list>
                    <a-entity position={'0 1.3 0'}>
                        <a-gui-slider {...allImageCalendarSlider} width={3} />
                    </a-entity>
                </a-entity>
                : null}
            {visiblePanels[ImageSpaces.GoogleImages] ?
                <a-entity ref={googleSelectionContainerRef}>
                    <frame-gui-icon-label-button
                        ref={googleSearchButtonRef}
                        width=".65" height="0.25"
                        icon="f204"
                        icon-font-size=".14"
                        font-size=".1"
                        icon-font="assets/fonts/ionicons.ttf"
                        value="Search"
                        margin="0 0 0.05 0"
                        position="0 0 .1"
                        active-color="#009688"
                        background-color={GetColor(0)}
                        hover-color="#00695C"
                        border-color="#000000"
                        font-color="#E0F2F1"
                    ></frame-gui-icon-label-button>
                    <frame-infinite-list ref={googleSelectionRef}
                        itemtemplate="a-catalog-image"
                        hideclose={`true`}
                        selectionevent="my-image-selection"
                        closeevent={'close-my-images-ignored'}
                        width={6}
                        columns={9}>
                    </frame-infinite-list>
                </a-entity>
                : null}
        </a-entity>
    )
}

function formatDateString(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false, // You can set this to false if you prefer 24-hour time.
        timeZoneName: 'short'
    };

    return new Intl.DateTimeFormat('en-US', options).format(date);
}

