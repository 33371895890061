import React, { useState } from 'react';

interface ColorPickerProps {
    onChange: (color: string) => void;
    color: string;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChange }) => {


    const handleColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newColor = event.target.value;
        onChange(newColor);
    };

    return (
        <input
            type="color"
            value={color}
            onChange={handleColorChange}
        />
    );
};

export default ColorPicker;
