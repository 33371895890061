import React from 'react';
import './SubscriptionPlan.scss';
import { CheckoutSessionLinks } from './interface';
import { PurchasableItemType } from './constants';

interface SubscriptionPlanProps {
    url: string;
    price: number;
    billed: string;
    checkoutLink: CheckoutSessionLinks
}

const SubscriptionPlan: React.FC<SubscriptionPlanProps> = ({ checkoutLink, url, price, billed }) => {
    return (
        <div className="subscription-plan">
            <div className="plan">
                <div className="plan-header">
                    {toItemName(checkoutLink)}
                </div>
                <div className="plan-price">
                    ${price}{isSubscription(checkoutLink.itemType) ? '/ month' : ''}
                </div>
                <div className="plan-billing">
                    Billed {billed}
                </div>
                <a href={url}
                    className="subscribe-button"> {isSubscription(checkoutLink.itemType) ? 'Subscribe' : 'Buy'}</a>
                <div className="billing-cycle">
                    {isSubscription(checkoutLink.itemType) ? 'Monthly billing' : 'One time billing'}
                </div>
            </div>
            <ul className="plan-features">
                <li>Limited generations ({toGenerations(checkoutLink.itemType)})</li>
                <li>General commercial terms</li>
                {/* <li>Access to member gallery</li>
                <li>Optional credit top ups</li>
                <li>3 concurrent fast jobs</li> */}
            </ul>
        </div>
    );
};

export default SubscriptionPlan;

function toGenerations(itemType) {
    switch (itemType) {
        case PurchasableItemType.Level0:
            return '220';
        case PurchasableItemType.Level1Credits:
            return 'Level 1';
        case PurchasableItemType.Level1Subscription:
            return '1000 / month';
        case PurchasableItemType.Level2Credits:
            return 'Level 2';
        case PurchasableItemType.Level2Subscription:
            return 'Level 2 Plus';
        case PurchasableItemType.Level3Credits:
            return 'Level 3';
        case PurchasableItemType.Level3Subscription:
            return 'Level 4 Plus';
    }
}
function toItemName(itemType) {
    switch (itemType) {
        case PurchasableItemType.Level0:
            return 'Level 0';
        case PurchasableItemType.Level1Credits:
            return 'Level 1';
        case PurchasableItemType.Level1Subscription:
            return 'Level 1 Plus';
        case PurchasableItemType.Level2Credits:
            return 'Level 2';
        case PurchasableItemType.Level2Subscription:
            return 'Level 2 Plus';
        case PurchasableItemType.Level3Credits:
            return 'Level 3';
        case PurchasableItemType.Level3Subscription:
            return 'Level 4 Plus';
    }
}
function isSubscription(itemType) {
    switch (itemType) {
        case PurchasableItemType.Level0:
        case PurchasableItemType.Level1Credits:
        case PurchasableItemType.Level2Credits:
        case PurchasableItemType.Level3Credits:
            return false;
        default:
            return true;
    }
}
function toItemPrice(itemType) {
    switch (itemType) {
        case PurchasableItemType.Level0:
            return 5;
        case PurchasableItemType.Level1Credits:
            return 0;
        case PurchasableItemType.Level1Subscription:
            return 20;
        case PurchasableItemType.Level2Credits:
            return 0;
        case PurchasableItemType.Level2Subscription:
            return 0;
        case PurchasableItemType.Level3Credits:
            return 0;
        case PurchasableItemType.Level3Subscription:
            return 0;
    }
}