import { useEffect, useState } from 'react';
import { uuidv4 } from './painter/util';

type BrowserDetails = {
    userAgent: string;
    os: string;
};

// Function to extract OS information from the user agent string
function getOS(userAgent: string): string {
    const osRegex = /Windows NT|Mac OS X|CPU (iPhone )?OS [0-9_]* like Mac OS X|Android [0-9.]*/;
    const match = userAgent.match(osRegex);
    return match ? match[0].replace(/_/g, '.') : 'Unknown OS';
}

// Function to get browser details
function getBrowserDetails(): BrowserDetails {
    const userAgent = navigator.userAgent;
    const os = getOS(userAgent);
    return {
        userAgent,
        os
    };
}

// Custom hook to generate a unique identifier
function useUniqueIdentifier(): string {
    const [identifier, setIdentifier] = useState('');

    useEffect(() => {
        const { userAgent, os } = getBrowserDetails();
        const uniquePart = uuidv4();  // Generate a UUID
        const combinedIdentifier = `UUID:${uniquePart}-OS:${os}-UA:${userAgent}`;
        setIdentifier(combinedIdentifier);
    }, []);

    return identifier;
}

export default useUniqueIdentifier;
