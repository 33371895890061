export function magicSelectAndMask(
    sourceCanvas: HTMLCanvasElement,
    outputCanvas: HTMLCanvasElement,
    points: {
        startX: number,
        startY: number,
    }[],
    tolerance: number = 10
): void {
    const srcCtx = sourceCanvas.getContext('2d')!;
    const outCtx = outputCanvas.getContext('2d')!;
    const srcData = srcCtx.getImageData(0, 0, sourceCanvas.width, sourceCanvas.height);
    // Retrieve existing data from the output canvas
    const existingOutData = outCtx.getImageData(0, 0, outputCanvas.width, outputCanvas.height);
    const outData = outCtx.createImageData(outputCanvas.width, outputCanvas.height);

    const pixelStack: any = points.map((point) => {
        const { startX, startY } = point;
        const startColor = getPixel(srcData, startX, startY);
        return [startX, startY, startColor]
    });//[[startX, startY]];

    // Assuming an image width of 1920 pixels for example, adjust based on your needs
    const visited = new Uint8Array(sourceCanvas.width * sourceCanvas.height);

    while (pixelStack.length > 0) {
        const [x, y, startColor] = pixelStack.pop()!;
        if (x < 0 || y < 0 || x >= srcData.width || y >= srcData.height) continue;
        const key = y * sourceCanvas.width + x; // Calculate index
        if (visited[key]) continue; // If already visited
        visited[key] = 1; // Mark as visited

        const color = getPixel(srcData, x, y);
        if (colorSimilarity(startColor, color, tolerance)) {
            setPixel(outData, x, y, [0, 0, 0, 255]); // Drawing the mask in black

            pixelStack.push([x + 1, y, startColor], [x - 1, y, startColor], [x, y + 1, startColor], [x, y - 1, startColor]);
        }
    }

    // Blend the new mask data with the existing canvas content
    for (let i = 0; i < outData.data.length; i += 4) {
        // If the mask pixel is black, we overwrite the existing pixel
        if (outData.data[i] === 0 && outData.data[i + 1] === 0 && outData.data[i + 2] === 0) {
            existingOutData.data[i] = outData.data[i];     // R
            existingOutData.data[i + 1] = outData.data[i + 1]; // G
            existingOutData.data[i + 2] = outData.data[i + 2]; // B
            existingOutData.data[i + 3] = outData.data[i + 3]; // A
        }
        // Otherwise, keep the existing pixel as it is
    }
    // Put the blended image data back onto the output canvas
    outCtx.putImageData(existingOutData, 0, 0);
}

export function applyTransparencyBasedOnSourceAlpha(sourceCanvas: HTMLCanvasElement, targetCanvas: HTMLCanvasElement): void {
    const sourceCtx = sourceCanvas.getContext('2d');
    const targetCtx = targetCanvas.getContext('2d');

    if (!sourceCtx || !targetCtx) {
        throw new Error('Failed to get canvas context');
    }

    const sourceImageData = sourceCtx.getImageData(0, 0, sourceCanvas.width, sourceCanvas.height);
    const targetImageData = targetCtx.getImageData(0, 0, targetCanvas.width, targetCanvas.height);
    const sourceData = sourceImageData.data;
    const targetData = targetImageData.data;

    for (let i = 0; i < sourceData.length; i += 4) {
        // Use the alpha value from the source canvas to determine transparency
        // For example, you can directly copy the alpha value:
        if (sourceData[i + 3] === 255)
            targetData[i + 3] = 0;

        // Or, if you want to make the pixel transparent only if the source pixel is fully transparent:
        // if (sourceData[i + 3] === 0) {
        //   targetData[i + 3] = 0;
        // }
    }

    // Write the modified image data back to the target canvas
    targetCtx.putImageData(targetImageData, 0, 0);
}

function getPixel(imgData: ImageData, x: number, y: number): [number, number, number, number] {
    const position = (y * imgData.width + x) * 4;
    return [
        imgData.data[position],     // R
        imgData.data[position + 1], // G
        imgData.data[position + 2], // B
        imgData.data[position + 3]  // A
    ];
}

function setPixel(imgData: ImageData, x: number, y: number, color: number[]): void {
    const position = (y * imgData.width + x) * 4;
    imgData.data[position] = color[0];
    imgData.data[position + 1] = color[1];
    imgData.data[position + 2] = color[2];
    imgData.data[position + 3] = color[3];
}

function colorSimilarity(color1: number[], color2: number[], tolerance: number): boolean {
    return Math.abs(color1[0] - color2[0]) <= tolerance &&
        Math.abs(color1[1] - color2[1]) <= tolerance &&
        Math.abs(color1[2] - color2[2]) <= tolerance;
}

export function getColorAtRelativePosition(canvas: HTMLCanvasElement, relX: number, relY: number): string {
    // Ensure relative positions are within the bounds [0, 1]
    if (relX < 0 || relX > 1 || relY < 0 || relY > 1) {
        throw new Error("Relative positions must be between 0 and 1.");
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
        throw new Error("Failed to get canvas context.");
    }

    // Calculate the actual x and y positions based on canvas size
    const x = Math.round(canvas.width * relX);
    const y = Math.round(canvas.height * relY);

    // Get the color data at the specified pixel
    const imageData = ctx.getImageData(x, y, 1, 1).data;
    // Convert the color data to a hex string
    const color = rgbToHex(imageData[0], imageData[1], imageData[2]);

    return color;
}

function rgbToHex(r: number, g: number, b: number): string {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}
