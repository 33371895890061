import { useEffect, useRef, useState } from "react"
import { startImageGenerateAsync } from "./service";
import { raiseCustomEvent } from "./painter/util";
import { PAINTER_CONSTANTS } from "./painter/constants";

export default function useStableDiffusionPipeline(onImageRendered: (imageUrl: string, setup: string) => void) {
    const requests = useRef<{
        [setupid: string]: {
            setupid: string,
            bearerToken: string,
            params: any
        }[]
    }>({});
    const requesting = useRef<{ [setupid: string]: boolean }>({})
    const [busy, setBusy] = useState<{ [setupid: string]: boolean }>({});
    function addRequest(request: any) {
        setBusy({ ...busy, [request.setupid]: true });
        if (requesting.current[request.setupid]) {
            requests.current[request.setupid] = requests.current[request.setupid] || [];
            requests.current[request.setupid].push(request);
            if (requests.current[request.setupid].length > 1) {
                requests.current[request.setupid].shift()
            }
        }
        else {
            requesting.current[request.setupid] = true;
            setBusy({ ...busy, [request.setupid]: true });
            startImageGenerateAsync(request).then(async (res) => {
                switch (res?.data?.status) {
                    case 'processing':
                        {
                            await wait(Math.min(res?.data?.eta || 20, 120));
                            let imageUrl = res?.data?.future_links[0];
                            if (imageUrl) {
                                if (await periodicallyCheckImage(imageUrl, 120,(Object.keys(requesting).length||1)* 3000)) {
                                    onImageRendered(imageUrl, request.setupid);
                                }
                                requesting.current[request.setupid] = false;
                                setBusy({ ...busy, [request.setupid]: false });
                            }
                        }
                        break;
                    case 'success':
                        requesting.current[request.setupid] = false;

                        setBusy({ ...busy, [request.setupid]: false });
                        let imageUrl = res.data.output[0];
                        onImageRendered(imageUrl, request.setupid);
                        break;
                }
                if (requests.current[request.setupid]?.length) {
                    let requestToDo = requests.current[request.setupid].shift();
                    addRequest(requestToDo);
                }
                if (res.creditsRemaining) {
                    raiseCustomEvent(PAINTER_CONSTANTS.CREDITS_REMAINING_UPDATE, { credits: res.creditsRemaining })
                }
            }).catch(e => {
                console.log(e);
            }).then(() => {
                requesting.current[request.setupid] = false;
                setBusy({ ...busy, [request.setupid]: false });
            });
        }
    }
    return {
        busy,
        addRequest
    }
}


export function periodicallyCheckImage(url: string, maxAttempts: number, interval: number): Promise<boolean> {
    return new Promise((resolve, reject) => {
        let attempts = 0;
        const checkInterval = setInterval(async () => {
            attempts++;
            console.log(`Attempt ${attempts}: Checking if image exists...`);

            try {
                const exists = await checkIfImageExists(url);
                if (exists) {
                    clearInterval(checkInterval);
                    resolve(true);
                } else if (attempts >= maxAttempts) {
                    clearInterval(checkInterval);
                    resolve(false);
                }
            } catch (error) {
                clearInterval(checkInterval);
                reject(error);
            }
        }, interval);
    });
}

async function wait(seconds: number) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, seconds * 1000)
    })
}

export async function checkIfImageExists(url: string): Promise<boolean> {
    try {
        const response = await fetch(url);
        // Check if the response status is OK and the content type is an image
        return response.ok && response.headers.get('Content-Type')?.startsWith('image/');
    } catch (error) {
        console.error('Error fetching the image:', error);
        return false;
    }
}