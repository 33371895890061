import { useContext } from "react";
import TextInput from "./component/TextInput";
import { APainterContext } from "./APainterContext";

export default function LocalRendering() {
    const context = useContext(APainterContext);
    return (
        <>
            <TextInput value={context.localRenderUrl} onChange={(evt: any) => {
                context.setLocalRenderUrl(evt.target.value)
            }} />
            <br />
        </>
    )
}