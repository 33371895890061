import { useEffect, useState } from "react";
import { SUPPORTED_LORAS } from "../Lora";
import SelectInput from "./SelectInput";

export default function LoraSelect(props: {
    value: any;
    onChange: any;
}) {
    const [supportedLora, setSupportedLora] = useState<{
        file_name,
        image
    }>(null);
    useEffect(() => {
        if (props.value) {
            let supportedLora = SUPPORTED_LORAS.find(x => x.name === props.value);
            setSupportedLora(supportedLora)
        }
        else {
            setSupportedLora(null);
        }
    }, [props.value])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: 300 }}>
            <div style={{ flex: 1 }}>
                <SelectInput value={`${props.value}`} options={['--None--', ...SUPPORTED_LORAS.map(t => {
                    return t.name
                })]} onChange={function (event: React.ChangeEvent<HTMLSelectElement>): void {
                    props.onChange(event);
                }} />
            </div>
            <div style={{ flex: 0, padding: 10 }}>
                {supportedLora?.image ? <img style={{ maxHeight: 100, maxWidth: 100 }} src={supportedLora?.image} /> : null}
            </div>
        </div>

    )
}