// Interface for the creator of the model
export interface Creator {
    username: string;
    image: string;
}

// Interface for the statistics of a model or model version
export interface Stats {
    downloadCount: number;
    favoriteCount?: number;
    commentCount: number;
    ratingCount: number;
    rating: number;
}

// Interface for file metadata
export interface Metadata {
    fp: string;
    size: string;
    format: string;
}

// Interface for file information
export interface File {
    name: string;
    id: number;
    sizeKB: number;
    type: string;
    metadata: Metadata;
    pickleScanResult: string;
    pickleScanMessage: string;
    virusScanResult: string;
    scannedAt: string;
    hashes: {
        AutoV2: string;
        SHA256: string;
        CRC32: string;
        BLAKE3: string;
    };
    downloadUrl: string;
    primary: boolean;
}

// Interface for image information
export interface Image {
    url: string;
    nsfw: boolean;
    width: number;
    height: number;
    hash: string;
    meta?: any;
}

// Interface for each version of a model
export interface ModelVersion {
    id: number;
    modelId: number;
    name: string;
    createdAt: string;
    updatedAt: string;
    trainedWords: string[];
    baseModel: string;
    earlyAccessTimeFrame: number;
    description?: string;
    stats: Stats;
    files: File[];
    images: Image[];
    downloadUrl: string;
}
export enum CivitaiModelType {
    Checkpoint = "Checkpoint",
    TextualInversion = "TextualInversion",
    Hypernetwork = "Hypernetwork",
    AestheticGradient = "AestheticGradient",
    LORA = "LORA",
    Controlnet = "Controlnet",
    Poses = "Poses"
}
// Interface for individual model items
export interface CivitaiModelItem {
    id: number;
    name: string;
    description: string;
    type: CivitaiModelType;
    poi: boolean;
    nsfw: boolean;
    allowNoCredit: boolean;
    allowCommercialUse: string;
    allowDerivatives: boolean;
    allowDifferentLicense: boolean;
    stats: Stats;
    creator: Creator;
    tags: string[];
    modelVersions: ModelVersion[];
}

// Interface for the API response metadata
export interface GetCivitaiModelsResponseMetadata {
    totalItems: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    nextPage?: string;
}

// Interface for the API response
export interface GetCivitaiModelsApiResponse {
    items: CivitaiModelItem[];
    metadata: GetCivitaiModelsResponseMetadata;
}