import { raiseOn, useEventsListenerOn } from "a-frame-components"
import { PAINTER_CONSTANTS } from "./painter/constants"
import { useContext, useRef, useState } from "react";
import canvasesToZip from "./storeImages";
import { upscaleImageAsync } from "./service";
import { APainterContext } from "./APainterContext";
import { makeUnique } from "./util";
import { periodicallyCheckImage } from "./useStableDiffusionPipeline";

export default function HoodieComponent() {
    const margin = '.05 .05 .05 .05';
    const context = useContext(APainterContext);
    const hoodieImages = useRef<{ [key: string]: { canvas: any, url?: string } }>({});
    let hoodieCmp = useEventsListenerOn({
        'dummy': () => { }
    });
    let hoodieImageSources = useEventsListenerOn({
        [PAINTER_CONSTANTS.CANVAS_IMAGE_UPDATED]: (evt: any, element) => {
            if (evt?.target) {
                let hoodieKey = evt.target.getAttribute('image');
                if (hoodieKey) {
                    hoodieImages.current = {
                        ...hoodieImages.current,
                        [hoodieKey]: {
                            ...(hoodieImages.current[hoodieKey] || {}),
                            canvas: evt.detail.canvas,
                        }
                    }

                    raiseOn(hoodieCmp, PAINTER_CONSTANTS.HOODIE_IMAGE_CHANGE, { hoodie: hoodieImages.current });
                }
            }
        }
    });

    let armlImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`arm1: ${evt.detail.url}`);
            // let key: any = 'arml'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let armrImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`armr: ${evt.detail.url}`);
            // let key: any = 'armr'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let frontImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`front: ${evt.detail.url}`);
            // let key: any = 'front'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let insideHoodieImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`insideHoodie: ${evt.detail.url}`);
            // let key: any = 'insideHoodie'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let hoodieLeftImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`hoodie left: ${evt.detail.url}`);
            // let key: any = 'hoodieLeft'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let hoodieRightImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`hoodie right: ${evt.detail.url}`);
            // let key: any = 'hoodieRight'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let backImage = useEventsListenerOn({
        'change': (evt: any, element) => {
            // console.log(`back: ${evt.detail.url}`);
            // let key: any = 'back'
            // hoodieImages.current = {
            //     ...hoodieImages.current,
            //     [key]: {
            //         url: evt.detail.url,
            //         ...(hoodieImages.current[key] || {})
            //     }
            // }
        }
    });

    let downloadButton = useEventsListenerOn({
        'click': async (evt: any, element) => {
            let temp = {};
            if (hoodieImages?.current) {
                Object.entries(hoodieImages?.current).forEach(([key, obj]) => {
                    if (obj?.canvas) {
                        temp[key] = obj.canvas;
                    }
                })
                // if (context?.userInfo?.token) {
                //     let urls = makeUnique(Object.entries(hoodieImages?.current).map(([key, obj]) => {
                //         return obj.url;
                //     }));
                //     let upscaleJobs = urls.map(async (url) => {
                //         let res = await upscaleImageAsync({
                //             params: { imageUrl: url },
                //             bearerToken: context?.userInfo?.token
                //         });
                //         if (res.data.output) {
                //             await periodicallyCheckImage(res.data.output, 120, 3000)
                //         }
                //         else {
                //             return null;
                //         }

                //         return {
                //             res,
                //             url
                //         }
                //     });
                //     let result = await Promise.all(upscaleJobs);
                //     console.log(result);
                // }
                canvasesToZip(temp);
            }
        }
    })

    return (
        <>
            <a-entity scale=".1 .1 .1" animation="property: rotation; to: 0 360 0; loop: true; dur: 8000; easing: linear">
                <a-hoodie {...hoodieCmp} />
            </a-entity>
            <a-entity position="3 0 0" {...hoodieImageSources}>
                <frame-container direction="horizontal" alignment="flexStart" margin={margin}>
                    <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                        <frame-container direction="horizontal" alignment="flexStart" margin={margin}>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.5}
                                    value={'Front'}
                                    title={'Front'} />
                                <frame-canvas-image
                                    height={.5} category="image-catalog" image="front"
                                    {...frontImage}
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    value={'Arm Left'}
                                    width={.5}
                                    title={'Arm Left'} />
                                <frame-canvas-image
                                    height={.5}
                                    {...armlImage}
                                    category="image-catalog" image="arml"
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.5}
                                    value={'Arm Right'}
                                    title={'Arm Right'} />
                                <frame-canvas-image
                                    {...armrImage}
                                    height={.5} category="image-catalog" image="armr"
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.75}
                                    value={'Inside Hoodie'}
                                    title={'Inside Hoodie'} />
                                <frame-canvas-image
                                    {...insideHoodieImage}
                                    height={.5} category="image-catalog" image="insideHoodie"
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                        </frame-container>
                        <frame-container direction="horizontal" alignment="flexStart" margin={margin}>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.75}
                                    value={'Hoodie Left'}
                                    title={'Hoodie Left'} />
                                <frame-canvas-image
                                    height={.5} category="image-catalog" image="hoodieLeft"
                                    {...hoodieLeftImage}
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.75}
                                    value={'Hoodie Right'}
                                    title={'Hoodie Right'} />
                                <frame-canvas-image
                                    height={.5} category="image-catalog" image="hoodieRight"
                                    {...hoodieRightImage}
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                            <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                                <frame-base-interactive
                                    width={.5}
                                    value={'Back'}
                                    title={'Back'} />
                                <frame-canvas-image
                                    height={.5} category="image-catalog" image="back"
                                    {...backImage}
                                    url={'https://cdn.midjourney.com/92deafc2-b7c0-4ca2-8648-ec2f9228c851/0_0.webp'}
                                    draganddropzone={'width:.1; height:.1;'} />
                            </frame-container>
                        </frame-container>
                    </frame-container>
                    <frame-container direction="vertical" alignment="flexStart" margin={margin}>
                        <frame-button
                            height={1}
                            width={1}
                            margin={margin}
                            title="Download"
                            {...downloadButton} />
                    </frame-container>
                </frame-container>
            </a-entity>
        </>
    )
}