export interface ImageGenerationRequest {
    model_id: string;
    prompt: string;
    negative_prompt: string | null;
    init_image: string;
    width: string;
    height: string;
    samples: string;
    num_inference_steps: string;
    safety_checker: string;
    enhance_prompt: string;
    guidance_scale: number;
    strength: number;
    scheduler: string;
    seed: string | null;
    lora_model: string | null;
    tomesd: string;
    use_karras_sigmas: string;
    vae: string | null;
    lora_strength: string | null;
    embeddings_model: string | null;
    webhook: string | null;
    track_id: string | null;
}
export interface CheckoutSessionLinks { url: string, itemType: string }
export enum UserProperties {
    SystemAgent = 'systemAgent',
    Version = "version",
    Id = 'id',
    Credits = 'credits'
}

export interface MaskingData {
    mode?: string;
    maskAllLayers?: boolean;
    sensitivity: number;
    maskResultCanvas?: HTMLCanvasElement;
    maskResultContext?: CanvasRenderingContext2D
    colors?: {
        color: string,
        x: number,
        y: number,
        subtractive?: boolean
    }[];
    canvas?: HTMLCanvasElement
}

export interface User {
    [UserProperties.Id]?: string
    [UserProperties.SystemAgent]?: string
    [UserProperties.Credits]: number;
    [UserProperties.Version]?: number;
    fire_id?: string
}

export interface StableDiffusionImgToImageResponse {
    status: string;
    generationTime: number;
    eta?: number;
    id: number;
    fetch_result?: string;
    output: string[];
    future_links: string[];
    proxy_links: string;
    nsfw_content_detected: boolean;
    webhook_status: string;
    meta: {
        prompt: string;
        model_id: string;
        scheduler: string;
        safety_checker: string;
        negative_prompt: string;
        W: number;
        H: number;
        guidance_scale: number;
        init_image: string;
        steps: number;
        n_samples: number;
        strength: number;
        multi_lingual: string;
        tomesd: string;
        use_karras_sigmas: string;
        algorithm_type: string;
        safety_checker_type: string;
        instant_response: string;
        full_url: string;
        upscale: string;
        vae: string | null;
        ip_adapter_id: string | null;
        ip_adapter_scale: number;
        ip_adapter_image: string | null;
        lora: string | null;
        lora_strength: number;
        embeddings: string | null;
        seed: number;
        outdir: string;
        file_prefix: string;
        clip_skip: number;
        temp: string;
        base64: string;
    };
    tip: string;
}

export interface ImageGenerationResponse {
    creditsRemaining: number;
    data: StableDiffusionImgToImageResponse,
    message: string;
    status: 'success' | 'processing'
}

export interface PexelPhoto {
    id: number;
    width: number;
    height: number;
    url: string;
    photographer: string;
    photographer_url: string;
    photographer_id: number;
    avg_color: string;
    src: PexelPhotoSources;
    liked: boolean;
    alt: string;
}

export interface PexelPhotoSources {
    original: string;
    large2x: string;
    large: string;
    medium: string;
    small: string;
    portrait: string;
    landscape: string;
    tiny: string;
}

export interface PexelApiResponse {
    total_results: number;
    page: number;
    per_page: number;
    photos: PexelPhoto[];
    next_page: string;
}


export interface PixabayImage {
    id: number;
    pageURL: string;
    type: string;
    tags: string;
    previewURL: string;
    previewWidth: number;
    previewHeight: number;
    webformatURL: string;
    webformatWidth: number;
    webformatHeight: number;
    largeImageURL: string;
    imageWidth: number;
    imageHeight: number;
    imageSize: number;
    views: number;
    downloads: number;
    collections: number;
    likes: number;
    comments: number;
    user_id: number;
    user: string;
    userImageURL: string;
}

export interface PixabayResponse {
    total: number;
    totalHits: number;
    hits: PixabayImage[];
}
