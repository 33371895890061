import React, { useContext, useEffect, useState } from 'react';
import StrengthComponent from './StrengthComponent';
import { findFrameElement, setAttribute, useEventsListenerOn } from 'a-frame-components';
import { APainterContext, RenderTarget } from './APainterContext';
import { raiseCustomEvent, uuidv4 } from './painter/util';
import { PAINTER_CONSTANTS } from './painter/constants';
import { mapRange } from './util';
import { useMode } from './painter/useDragAndDrop';
import CivitaiModelLoraConfigurationComponent from './civitai/CivitaiModelLoraConfigurationComponent';

export default function ModelLoraConfigurationComponent() {
    const context = useContext(APainterContext);
    const [listWidth, setListWidth] = useState(0);
    const [verticalY, setVerticalY] = useState(0);
    const addModelBtn = useEventsListenerOn({
        click: (evt, element) => {
            context.upsertModelLoraSetup({
                id: uuidv4(),
                layerPrompts: {},
                loraOptions: {},
                strength: .7
            })
        }
    });
    const modelLoraConfigurations = useEventsListenerOn({
        select: (evt: any, element: any) => {
            if (evt?.detail?.id) {
                context.setCurrentModelLoraSetup(evt.detail.id)
            }
        },
        delete: (evt: any, element: any) => {
            if (evt?.detail?.id) {
                context.deleteModelLoraSetup(evt.detail.id)
            }
        },
        'size-update': (evt: any, element: any) => {
            let el = findFrameElement(modelLoraConfigurations);
            if (evt.srcElement === el) {
                setListWidth(parseFloat(evt.detail.width));
            }
        }
    });
    const listPosX = .5;
    useEffect(() => {
        setAttribute(configPositioning, 'position', `${listPosX + listWidth / 2} ${(verticalY)} 0`);
    }, [verticalY, listWidth])
    const configPositioning = useEventsListenerOn({})
    const relativePosition = useEventsListenerOn({
        change: (evt: any, element: any) => {
            let { detail } = evt;
            let { value } = detail;
            let y = mapRange(value, 1 * context.modelLoraSetups.length, -1 * context.modelLoraSetups.length, 0, 1)
            setVerticalY(y);
        }
    })
    useEffect(() => {
        console.log('model lora setup updated')
        setAttribute(modelLoraConfigurations, 'configurations', JSON.stringify(context.modelLoraSetups));
    }, [context.modelLoraSetups, context.currentModelLoraSetup]);

    const LIST_MODE = 'LIST_MODE';
    const EDIT_MODE = 'EDIT_MODE';
    const STABLEDIFFUSION_API = 'STABLEDIFFUSION_API';
    const CIVITAI_API = 'CIVITAI_API';
    useMode({
        [LIST_MODE]: {
            mode: 'list',
            hidden: {
                scale: '1 1 1',
                position: `1 0 0 `,
                rotation: `0 -90 0 `
            }
        },
        [EDIT_MODE]: {
            mode: 'edit',
            hidden: {
                scale: '1 1 1',
                position: `-1 0 0 `,
                rotation: `0 90 0 `
            }
        }
    }, 'model-lora-mode', (detail) => detail?.mode, 'list', context?.painterLoaded);
    useMode({
        [CIVITAI_API]: {
            mode: RenderTarget.LocalCivitai,
            hidden: {
                scale: '.01 .01 .01',
                position: `1 0 0 `,
                rotation: `0 -90 0 `
            }
        },
        [STABLEDIFFUSION_API]: {
            mode: RenderTarget.StableDiffusionApi,
            hidden: {
                scale: '.01 .01 .01',
                position: `-1 0 0 `,
                rotation: `0 90 0 `
            }
        }
    }, 'render-target-mode', (detail) => detail?.mode, RenderTarget.StableDiffusionApi, context?.painterLoaded);
    let modeRadio = useEventsListenerOn({
        'change': (evt: any, el) => {
            raiseCustomEvent('model-lora-mode', { mode: evt.detail.value })
        }
    });
    let renderTarget = useEventsListenerOn({
        'change': (evt: any, el) => {
            raiseCustomEvent('render-target-mode', { mode: evt.detail.value })
            context.setRenderTarget(evt.detail.value)
        }
    })

    return (
        <>
            <a-entity rotation="0 0 0" position="0 .5 -1.5">
                <frame-container direction="horizontal" alignment="flexStart" margin="0 1 0 0">
                    <frame-radio {...modeRadio}
                        options={JSON.stringify([
                            {
                                "text": "List", "value": "list", "id": "LIST_MODE"
                            }, {
                                "text": "Edit", "value": "edit", "id": "EDIT_MODE"
                            }])}
                        value="list" />
                    <frame-radio {...renderTarget}
                        options={JSON.stringify([
                            {
                                width: .5,
                                "text": "Local Civitai",
                                "value": RenderTarget.LocalCivitai,
                                "id": RenderTarget.LocalCivitai
                            }, {
                                "text": "StableDiffusionApi",
                                "value": RenderTarget.StableDiffusionApi,
                                "id": RenderTarget.StableDiffusionApi
                            }])}
                        value="StableDiffusionApi" />
                </frame-container>
            </a-entity>
            <a-entity {...{ [CIVITAI_API]: true }}>
                <CivitaiModelLoraConfigurationComponent />
            </a-entity>
            <a-entity {...{ [STABLEDIFFUSION_API]: true }}>
                <a-entity   {...{ [EDIT_MODE]: true }}>
                    <a-entity position="0 .5 -2.1" >
                        <a-entity rotation="0 0 0">
                            <a-entity position="0 1.5 0">
                                <a-styles-component ids="lora1;lora2;lora3;lora4"></a-styles-component>
                            </a-entity>
                            <a-entity position="0 .5 0">
                                <a-models-component ids="model1"></a-models-component>
                            </a-entity>
                            <a-entity position=".65 .3 0">
                                <StrengthComponent />
                            </a-entity>
                        </a-entity>
                    </a-entity>
                </a-entity>
                <a-entity   {...{ [LIST_MODE]: true }}>
                    <a-entity position="-2 1.5 -2.1" rotation="0 0 0" >
                        <frame-container direction='horizontal' justify-content='flexStart' margin=".2 .2 .2 .2" alignment='flexStart'>
                            <frame-base-interactive {...addModelBtn} title="Add" />
                            <frame-slider
                                {...relativePosition}
                                orientation="vertical"
                                percent={.3}
                                bar-thickness=".1"
                                nearest={1}
                                bar-length="2"
                                height=".3"
                                targetbarsize={.3}
                                title-scale={'.3 .3'}
                                title={''}
                                title-position="0 .1 0" />
                        </frame-container>
                        <a-entity position="0 0 0">
                            <a-entity {...configPositioning}>
                                <a-model-lora-configurations
                                    max-item-width=".5"
                                    max-item-height=".5" {...modelLoraConfigurations} />
                            </a-entity>
                        </a-entity>
                    </a-entity>
                </a-entity>
            </a-entity>
        </>
    )
}