import { watchAttribute } from "a-frame-components";
import { GetColor } from "../painter/systems/ui";
export default function () {
    const AFRAME: any = (window as any).AFRAME || {}
    AFRAME.registerComponent('civitai-item', {
        schema: {
            selectionevent: { type: 'string' },
            options: { type: 'string' }
        },
        init: function () {
            let me = this;
            var el = this.el;

            watchAttribute(el, {
                'options': (name, v, el) => {
                    me.render(v);
                }
            });

            if (this.data.options) {
                me.render(JSON.parse(this.data.options));
            }
        },
        render: function (args: any) {
            const { guiItem, value, id, text, url, imageMargin } = args;
            let me = this;
            var el = this.el;
            me.imageMargin = imageMargin;
            me.guiItem = guiItem;

            let entity: any = document.createElement('a-entity');
            entity.setAttribute('rotation', `0 0 0`)

            let imageEntity: any = document.createElement('frame-ar-image');
            imageEntity.setAttribute('maxwidth', `${me.guiItem.height - me.imageMargin}`);
            imageEntity.setAttribute('maxheight', `${me.guiItem.height - me.imageMargin}`);
            imageEntity.setAttribute('position', `${(-me.guiItem.width / 2) + (me.guiItem.height / 2)} 0 .03`)
            imageEntity.setAttribute('url', url);

            var buttonContainer = document.createElement("frame-gui-button");
            buttonContainer.setAttribute('geometry', `primitive: plane; width: ${me.guiItem.width}; height: ${me.guiItem.height}; depth: 0.02;`);
            buttonContainer.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${GetColor(1)}`);
            buttonContainer.setAttribute('rotation', '0 0 0');
            buttonContainer.setAttribute('panel-color', GetColor(4))
            buttonContainer.setAttribute('panel-rounded', "0.1");
            buttonContainer.setAttribute('gap', '0.01')
            buttonContainer.setAttribute('base-depth', '0.01')
            buttonContainer.setAttribute('value', ``)
            buttonContainer.setAttribute('font-color', '#ffffff')
            buttonContainer.setAttribute('font-size', '.05')
            buttonContainer.setAttribute('position', `0 0 0.01`);
            buttonContainer.setAttribute('text-position', `0 ${(-me.guiItem.height / 2) + 0.04} .04`)
            buttonContainer.setAttribute('height', me.guiItem.height);
            buttonContainer.setAttribute('margin', `0 0 0.01 0`);
            buttonContainer.setAttribute('width', me.guiItem.width);
            buttonContainer.classList.add('raycastable')
            buttonContainer.addEventListener('click', function () {
                me.el.emit(me.data.selectionevent, { value, id, text, url, options: (args) });
            })

            let menu = me.createMenu({
                width: me.guiItem.width,
                text: text || value || ``,
                height: me.guiItem.height,
                options: (args)
            });
            buttonContainer.appendChild(imageEntity);
            entity.appendChild(menu);
            entity.appendChild(buttonContainer);
            this.el.appendChild(entity);
        },
        createMenu: function ({ width, text, height, options }) {
            let me = this;
            let open = false;
            let menuContainer = document.createElement('frame-menu-container');
            menuContainer.setAttribute('menu-direction', 'up');
            menuContainer.setAttribute('flex-direction', 'column');
            menuContainer.setAttribute('justify-content', 'flexStart');
            menuContainer.setAttribute('algin-items', 'flexStart');
            menuContainer.setAttribute('component-padding', '.01');
            menuContainer.setAttribute('width', width);
            menuContainer.setAttribute('text-value', text);
            menuContainer.setAttribute('menu-item-height', `.2`);
            menuContainer.setAttribute('menu-item-width', width);
            menuContainer.addEventListener('click', (evt) => {
                evt.preventDefault();
                open = !open;
                menuContainer.setAttribute('open', `${open}`)
                return false;
            });

            let promptButton = document.createElement('frame-base-interactive');
            promptButton.setAttribute('width', width)
            promptButton.setAttribute('height', '.2');
            promptButton.setAttribute('interactive-type', "button");
            promptButton.setAttribute('value', 'Select');
            promptButton.setAttribute('margin', '0 0 0.05 0');
            promptButton.setAttribute('font-size', '.07');
            promptButton.addEventListener('click', (evt) => {
                evt.preventDefault();
                me.el.emit(me.data.selectionevent, options);

                return null;
            })
            menuContainer.appendChild(promptButton);
            let entity = document.createElement('a-entity');
            entity.setAttribute('position', `0 ${(-height / 2)} .1`)
            entity.appendChild(menuContainer);
            return entity;
        },
        createText: function (newText) {
            var textEntity = document.createElement("a-entity");
            textEntity.setAttribute('troika-text', `value: ${newText}; 
                                                align:center; 
                                                anchor:center; 
                                                baseline:center;
                                                letterSpacing:0;
                                                color:${this.data.fontColor || '#ffffff'};
                                                font:${this.data.fontFamily};
                                                fontSize:${'14px'};
                                                depthOffset:1;
                                                maxWidth:611;
                                                `);
            textEntity.setAttribute('position', `0 0 0.01`);
            let scle = 0.004;
            textEntity.setAttribute('scale', `${scle} ${scle} ${scle}`);

            //        textEntity.setAttribute('troika-text-material', `shader: flat;`);
            return (textEntity);
        },
    });
    AFRAME.registerPrimitive('a-civitai-item', {
        defaultComponents: {
            'civitai-item': {}
        },
        mappings: {
            options: 'civitai-item.options',
            selectionevent: 'civitai-item.selectionevent'
        }
    });

}