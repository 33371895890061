import React from 'react';
import './TextInput.scss'; // Import the CSS file for styles

interface TextInputProps {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    label?: string;
}


function TextInput({ id, value, onBlur, onChange, placeholder, label }: any) {
    return (
        <div style={{ padding: 10 }}>
            {label && <label className="select-label">{label}</label>}
            <input type="text"
                id={id}
                className="text-input"
                style={{
                    width: 'calc(100% - 30px)'
                }}
                value={value || ''}
                onBlur={() => {
                    if (onBlur)
                        onBlur();
                }}
                placeholder={placeholder}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(event)
                }} />
        </div>
    );
};
export default TextInput;

export function TextAreaInput({ id, value, onBlur, onChange, placeholder, label }: any) {
    return (
        <div style={{ padding: 10 }}>
            {label && <label className="select-label">{label}</label>}
            <textarea
                id={id}
                className="text-input"
                style={{
                    width: 'calc(100% - 30px)'
                }}
                value={value || ''}
                onBlur={() => {
                    onBlur();
                }}
                placeholder={placeholder}
                onChange={(event: any) => {
                    onChange(event)
                }} />
        </div>
    );
};