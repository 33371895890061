import { LayerPrompts } from "./APainterContext"
import { GetCivitaiModelsApiResponse } from "./civitai/interface"
import { TestCivitai } from "./civitai/test"
import { ImageGenerationRequest, ImageGenerationResponse } from "./interface"

function ResolveUrl(url: string) {
    return `${url}`
}

export async function searchImages({ params, bearerToken }: {
    params: {
        pexel?: { query: string },
        pixabay?: { query: string },
        google?: { query: string }
    },
    bearerToken: string
}): Promise<any> {
    if (bearerToken) {
        let response = await fetch(ResolveUrl('api/image/search'),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(params),
            },
        )
        let res = await response.json()
        return res
    } else {
        throw 'not signed in'
    }
}

export async function fetchCivitai({ params, bearerToken }: {
    params: {
        url?: string,
        query?: { [id: string]: any }
    },
    bearerToken: string
}): Promise<{ data: GetCivitaiModelsApiResponse }> {
    if (window.location.host.includes('localhost')) {
        return Promise.resolve(TestCivitai())
    }
    if (bearerToken) {
        let response = await fetch(ResolveUrl('api/civitai/models'),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(params),
            },
        )
        let res = await response.json()
        return res
    } else {
        throw 'not signed in'
    }
}

export async function startImageGenerateAsync({ params, bearerToken }: {
    params: ImageGenerationRequest,
    bearerToken: string
}): Promise<ImageGenerationResponse> {
    if (bearerToken) {
        let response = await fetch(ResolveUrl('api/generate'),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(params),
            },
        )
        let res = await response.json()
        return res
    } else {
        throw 'not signed in'
    }
}

export async function upscaleImageAsync({ params, bearerToken }: {
    params: {
        imageUrl: string
    },
    bearerToken: string
}): Promise<{
    data: {
        "status": string,
        "generationTime": number,
        "id": number,
        "output": string
    }
}> {
    if (bearerToken) {
        let response = await fetch(ResolveUrl('api/image/upscale'),
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify(params),
            },
        )
        let res = await response.json()
        return res
    } else {
        throw 'not signed in'
    }
}

export async function createCheckoutLinksAsync({ bearerToken }) {
    if (!window.location.hostname.includes('localhost'))
        if (bearerToken) {
            let response = await fetch(ResolveUrl('api/auto/create/checkout/session'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({}),
            })
            let res = await response.json()
            return res
        }
        else {
            return Promise.resolve({})
        }
}

export async function checkAccount({ bearerToken }) {
    if (!window.location.hostname.includes('localhost'))
        if (bearerToken) {
            let response = await fetch(ResolveUrl('api/auto/check/account'), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${bearerToken}`,
                    'Access-Control-Allow-Origin': '*',
                },
                body: JSON.stringify({}),
            })
            let res = await response.json();
            let { data } = res;
            let { user } = data;
            return user
        }
    return Promise.resolve(null);
}