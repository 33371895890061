import { AFRAME } from "../../painter/root";
import { key_grey, key_orange } from "../vars";
import { createElement } from "a-frame-components";
import { PAINTER_CONSTANTS } from '../../painter/constants';
import { ModelLoraSetup } from '../../APainterContext';
import { framemixin } from 'a-frame-components';
import { create } from "domain";
export default function () {
    const THREE: any = (window as any).THREE;
    AFRAME.registerComponent('model-lora-configurations', {
        schema: {
            configurations: { type: 'string' },
            maxItemWidth: { type: 'number', default: .3 },
            maxItemHeight: { type: 'number', default: .3 },
        },
        ...framemixin,
        getWidth: function () {
            let me = this;
            return me.size.width || 0;
        },
        getHeight: function () {
            let me = this;
            return me.size.height || 0;
        },
        init: function () {
            let me = this;
            let entity = document.createElement('frame-container');
            entity.setAttribute('direction', 'vertical');
            if (this.data.configurations) {
                this.configurations = JSON.parse(this.data.configurations);
            }
            this.container = entity;
            this.size = {};
            entity.addEventListener('size-update', (evt: any) => {
                const { detail, srcElement } = evt;
                if (entity === srcElement) {
                    let { width, height } = detail;
                    width = parseFloat(width);
                    height = parseFloat(height);
                    me.size.width = width;
                    me.size.height = height;
                    me.updateElementSize(me, me.el);
                }
            })
            this.el.appendChild(entity);
            this.itemHeight = this.data.maxItemHeight || 1;
            this.itemMargin = .25;
            this.render();
        },
        update: function (oldData) {
            if (this.data.configurations !== oldData.configurations) {
                if (this.data.configurations) {
                    this.configurations = JSON.parse(this.data.configurations);
                    this.render();
                }
            }
        },
        render: function () {
            let me = this;
            let position = -this.getWidth() / 2;
            if (me.configurations) {
                let configurations: ModelLoraSetup[] = this.configurations;
                me.configurationEls = me.configurationEls || {};
                Object.keys(me.configurationEls)
                    .filter(x => !configurations.find(t => t.id === x)).forEach((x) => {
                        me.configurationEls[x].mlConfig.parentNode.removeChild(me.configurationEls[x].mlConfig);
                        delete me.configurationEls[x];
                    })
                configurations.map((configuration, index) => {
                    if (me.configurationEls[configuration.id]) {
                        let { mlConfig } = me.configurationEls[configuration.id];
                        mlConfig.setAttribute('options', JSON.stringify({
                            config: configuration
                        }));
                    }
                    else {
                        let mlConfig = document.createElement('a-model-lora-configuration');
                        mlConfig.setAttribute('options', JSON.stringify({
                            config: configuration
                        }));
                        mlConfig.setAttribute('maxheight', me.data.maxItemHeight);
                        mlConfig.setAttribute('maxwidth', me.data.maxItemWidth);
                        mlConfig.setAttribute('id', configuration.id);
                        me.configurationEls[configuration.id] = {
                            mlConfig
                        }
                        me.container.appendChild(mlConfig);
                    }
                })
                Object.keys(me.configurationEls).map((key) => {
                    if (!configurations.some(v => v.id === key)) {
                        delete me.configurationEls[key];
                    }
                });
            }
        }
    })
    AFRAME.registerPrimitive('a-model-lora-configurations', {
        defaultComponents: {
            'gui-item': {},
            'model-lora-configurations': {}
        },
        mappings: {
            configurations: 'model-lora-configurations.configurations',
            'max-item-width': 'model-lora-configurations.maxItemWidth',
            'max-item-height': 'model-lora-configurations.maxItemHeight',
        }
    });
    AFRAME.registerComponent('model-lora-configuration', {
        schema: {
            options: { type: 'string' }
        },
        init: function () {
            let me = this;
            let data = this.data;
            let el = this.el;
            me.config = JSON.parse(data.options).config;
            me.render();
        },
        ...framemixin,
        getWidth: function () {
            return parseFloat(`${this?.size?.width || this?.guiItem?.width || 0}`);
        },
        getHeight: function () {
            return parseFloat(`${this?.size?.height || this?.guiItem?.height || 0}`);
        },
        render: function () {
            let me = this;
            let loraContainer = null;
            let imageSize = .3;
            let data = this.data;
            if (me.config) {
                if (!this.root) {
                    let container = createElement('frame-container', {
                        'direction': 'horizontal',
                        alignment: "flexStart",
                        justifyContent: 'flexStart',
                        margin: '.1 .1 .1 .1'
                    })({});
                    container.addEventListener('size-update', (evt: any) => {
                        const { detail, srcElement } = evt;
                        if (srcElement === container) {
                            me.containerSize = { ...detail }
                        }
                        else if (srcElement === selectButton) {
                            me.selectButtonSize = { ...detail }
                        }

                        this.size = {
                            width: parseFloat(`${(me?.containerSize?.width || 0)}`) + parseFloat(`${(me?.selectButtonSize?.width || 0)}`),
                            height: parseFloat(`${(me?.containerSize?.height || 0)}`) + parseFloat(`${(me?.selectButtonSize?.height || 0)}`),
                        }
                        me.updateElementSize(me, me.el);
                    })
                    let width = .35;
                    let selectButton = createElement("frame-base-interactive",
                        {
                            width: width,
                            ['interactive-type']: 'button',
                            height: .2,
                            value: 'Select',
                            ['font-size']: .07
                        }
                    )({});
                    let deleteButton = createElement("frame-base-interactive",
                        {
                            width: width,
                            ['interactive-type']: 'button',
                            height: .2,
                            value: 'Remove',
                            ['font-size']: .07
                        }
                    )({});
                    me.selectButtonSize = {
                        width,
                        height: .2
                    }
                    selectButton.addEventListener('click', () => {
                        me.el.emit('select', {
                            id: me.config.id
                        });
                    });
                    deleteButton.addEventListener('click', () => {
                        me.el.emit('delete', {
                            id: me.config.id
                        });
                    });
                    loraContainer = createElement('frame-container', {
                        'direction': 'horizontal',
                        alignment: "flexStart",
                        justifyContent: 'flexStart',
                        'margin': '.1 .1 .1 .1'
                    })({});
                    let buttonContainer = createElement('frame-container', {
                        'direction': 'vertical',
                        alignment: "flexStart",
                        justifyContent: 'flexStart',
                        'margin': '.1 .1 .1 .1'
                    })({});
                    this.loraContainer = loraContainer;
                    buttonContainer.appendChild(selectButton);
                    buttonContainer.appendChild(deleteButton);
                    loraContainer.appendChild(buttonContainer);
                    container.appendChild(loraContainer);

                    let styleConfigImageOutput = document.createElement('frame-ar-image');
                    styleConfigImageOutput.setAttribute('maxwidth', `2`);
                    styleConfigImageOutput.setAttribute('maxheight', `2`);
                    document.body.addEventListener(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, (evt: any) => {
                        let { detail } = evt;
                        if (detail) {
                            let { data } = detail;
                            if (data) {
                                const { imageUrl, setup } = data;
                                if (setup === me.config.id) {
                                    styleConfigImageOutput.setAttribute('url', imageUrl);
                                }
                            }
                        }
                    })
                    loraContainer.appendChild(styleConfigImageOutput);

                    let entity = document.createElement('a-entity');
                    entity.setAttribute('position', '0 0 .01')
                    entity.appendChild(container);
                    this.el.appendChild(entity);
                    this.root = entity;
                    let loras = ['lora1', 'lora2', 'lora3', 'lora4'];
                    this.model = document.createElement('frame-ar-image');
                    this.model.setAttribute('maxheight', me.data.maxheight || imageSize || `.2`);
                    this.model.setAttribute('maxwidth', me.data.maxwidth || imageSize || `.2`);
                    if (me?.config?.layerPrompts?.supportedModel?.imageUrl) {
                        this.model.setAttribute('url', me?.config?.layerPrompts?.supportedModel?.imageUrl || '');
                    }
                    loraContainer.appendChild(this.model);
                    this.loras = loras.map((lora, index) => {
                        let imageEntity: any = document.createElement('frame-ar-image');
                        imageEntity.setAttribute('maxheight', me.data.maxheight || imageSize || `.2`);
                        imageEntity.setAttribute('maxwidth', me.data.maxwidth || imageSize || `.2`);
                        var text = document.createElement("frame-troika-text");
                        text.setAttribute('value', '');
                        text.setAttribute('position', `0 ${(me.data.maxheight / 2 + .1) * (index % 2 ? -1 : 1)} 0.01`);
                        text.setAttribute('scale', `.2 .2`);
                        imageEntity.appendChild(text);
                        loraContainer.appendChild(imageEntity);
                        if (imageEntity) {
                            if (me.config) {
                                if (me.config.layerPrompts?.[lora]?.url) {
                                    let url = me.config.layerPrompts?.[lora]?.url;
                                    imageEntity.setAttribute('url', url || '');
                                    imageEntity.setAttribute('name', me.config.layerPrompts?.[lora].name || '');
                                    if (text) {
                                        text.setAttribute('value', me.config.layerPrompts?.[lora].name)
                                    }
                                }
                            }
                        }

                        return {
                            lora,
                            text,
                            imageEntity
                        }
                    });
                }
                else {
                    loraContainer = this.loraContainer;
                    if (this.model && me?.config?.layerPrompts?.supportedModel?.imageUrl) {
                        this.model.setAttribute('maxheight', me.data.maxheight || imageSize || `.2`);
                        this.model.setAttribute('maxwidth', me.data.maxwidth || imageSize || `.2`);
                        this.model.setAttribute('url', me?.config?.layerPrompts?.supportedModel?.imageUrl || '');
                    }
                    this.loras.forEach(({ lora, text, imageEntity }) => {
                        let loraData = me.loras.find(v => v.lora === lora);
                        if (loraData) {
                            if (imageEntity) {
                                if (me?.config?.layerPrompts?.[lora]?.url) {
                                    let url = me.config.layerPrompts[lora].url;
                                    imageEntity.setAttribute('maxheight', me.data.maxheight || imageSize || `.2`);
                                    imageEntity.setAttribute('maxwidth', me.data.maxwidth || imageSize || `.2`);
                                    imageEntity.setAttribute('url', url || '');
                                    imageEntity.setAttribute('name', me.config.layerPrompts?.[lora].name || '');
                                    if (text) {
                                        text.setAttribute('value', me.config.layerPrompts?.[lora].name)
                                    }
                                }
                                else {
                                    imageEntity.setAttribute('url', '');
                                }
                            }
                        }
                    })
                }
            }
        },
        update: function (oldData) {
            let data = this.data;
            let me = this;
            if (data.options !== oldData) {
                me.config = JSON.parse(data.options).config;
                me.render();
            }
        },
        tick: function () {
        },
        remove: function () {
        },
        pause: function () {
        },
        play: function () {
        },
    });

    AFRAME.registerPrimitive('a-model-lora-configuration', {
        defaultComponents: {
            'gui-item': { type: 'progressbar' },
            'model-lora-configuration': {}
        },
        mappings: {
            options: 'model-lora-configuration.options',
            maxheight: 'model-lora-configuration.maxheight',
            maxwidth: 'model-lora-configuration.maxwidth',
        }
    });
}