import React, { useState } from 'react';
import { useEventsListenerOn } from 'a-frame-components';
import { useCustomEventListener } from './painter/useCustomEventListener';
import { PAINTER_CONSTANTS } from './painter/constants';
import { useMode } from './painter/useDragAndDrop';
import { DrawMode, MaskMode } from './painter/systems/ui';
import { raiseCustomEvent } from './painter/util';

export default function MaskModeMenuComponent() {
    const [show, setShow] = useState(false);
    useCustomEventListener(PAINTER_CONSTANTS.DRAWING_MODE_CHANGED, (evt: any) => {
        const { mode } = evt;
        setShow(mode === DrawMode.Mask)
    })
    const radio = useEventsListenerOn({
        'change': (evt: any) => {
            raiseCustomEvent(PAINTER_CONSTANTS.CHANGE_MASK_MODE, { value: evt.detail.value })
        }
    })
    const sensitivity = useEventsListenerOn({
        'change': (evt: any) => {
            raiseCustomEvent(PAINTER_CONSTANTS.MASK_SENSITIVITY, {
                value: evt.detail.value * 100
            })
        }
    });
    const allLayerCheckbox = useEventsListenerOn({
        'change': (evt: any) => { 
            raiseCustomEvent(PAINTER_CONSTANTS.MASK_ALL_LAYERS, {
                value: evt.detail.value 
            })
        }
    })
    const clearBtn = useEventsListenerOn({
        'click': (evt: any) => {
            raiseCustomEvent(PAINTER_CONSTANTS.CLEAR_MASK, {});
        }
    })
    const deleteBtn = useEventsListenerOn({
        'click': (evt: any) => {
            raiseCustomEvent(PAINTER_CONSTANTS.DELETE_MASKED_COLORS, {})
        }
    })
    const MASK_MODE = 'MASK_MODE'

    if (!show) { return null; }

    return (
        <frame-container margin=".25 0 0 0" direction='vertical'>
            <frame-radio {...radio} direction={'vertical'}
                options={JSON.stringify([{
                    "text": "Select +", "value": MaskMode.SelectAdditive, "id": MaskMode.SelectAdditive
                }, {
                    "text": "Select", "value": MaskMode.Select, "id": MaskMode.Select
                }
                ])}
                value={'additive'}
            ></frame-radio>
            <frame-slider
                {...sensitivity}
                orientation="horizontal"
                percent={.3}
                bar-thickness=".1"
                nearest={1}
                bar-length="1"
                height=".3"
                targetbarsize={.3}
                title-scale={'.3 .3'}
                title={'Sensitivity'}
                title-position="0 .1 0" />
            <frame-checkbox {...allLayerCheckbox} label='All Layers' value={false} />
            <frame-base-interactive
                {...clearBtn} interactive-type='button'
                width={.75}
                value={'Clear Mask'}
                title={'Clear Mask'} />


            <frame-base-interactive
                {...deleteBtn} interactive-type='button'
                width={.75}
                value={'Delete'}
                title={'Delete'} />
        </frame-container>
    )
}