import React, { useContext, useEffect } from 'react';
import { useState } from "react";
import TextInput, { TextAreaInput } from './component/TextInput';
import { convertCssToObject } from './util';
import { raiseCustomEvent } from './painter/util';
import { PAINTER_CONSTANTS } from './painter/constants';
import { useCustomEventListener } from './painter/useCustomEventListener';
import { APainterContext } from './APainterContext';

export default function FontInput() {
    const [show, setShow] = useState(false);
    const [value, setValue] = useState('');
    const context = useContext(APainterContext);
    const [invalid, setInvalid] = useState(false);
    const [record, setRecord] = useState(null);
    useEffect(() => {
        if (value) {
            try {
                let record = convertCssToObject(value)
                setRecord(record);
                setInvalid(false);
            } catch (e) {
                setInvalid(true);
            }
        }
        else {
            setInvalid(true)
        }
    }, [value])
    useCustomEventListener(PAINTER_CONSTANTS.SHOW_ADD_FONT_INPUT, () => {
        setShow(true);
        setTimeout(() => {
            let input: any = document.querySelector('#font-input');
            input.focus();
        }, 400)
    })
    return (
        <>
            <div style={{ display: show ? '' : 'none' }}>
                <TextAreaInput
                    id={'font-input'} value={value}
                    onBlur={() => {
                        setShow(false);
                        if (!invalid) {
                            raiseCustomEvent(PAINTER_CONSTANTS.ADD_FONT, { record })
                            context.addFont(record);
                        }
                        setRecord(null);
                        setValue('');
                    }} onChange={(val) => {
                        setValue(val.target.value);
                    }}
                    placeholder={'Paste css here'}
                    label={'Google Font'} />
                {invalid ? <span style={{ color: 'red' }}>Invalid</span> : null}
            </div>
        </>
    )
}