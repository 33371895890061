import { AFRAME } from "../../painter/root";
import { GrabAndDropEvents, } from "../../painter/systems/grabanddrop";
import { PAINTER_CONSTANTS } from "../../painter/constants";
import { raiseCustomEvent } from "../../painter/util";
import { createBaseIconInteractive, createComponent, createContainer, createMenu, createMenuItem, onClick, position } from "../../util";
import { drawTextureOnCanvas } from "../../painter/components/paint-canvas";
const THREE: any = (window as any).THREE;
export default function () {
    AFRAME.registerComponent('canvas-layer', {
        schema: {
            selectionevent: { type: 'string' },
            layerId: { type: 'string' }
        },
        init: function () {
            let me = this;
            this.el.addEventListener(PAINTER_CONSTANTS.LAYER_DISPLAY_READY, (evt: any) => {
                let { entity, id } = evt.detail;
                if (me.displayContainer && me.data.layerId === id) {
                    me.displayContainer.appendChild(entity);
                }
            })
            let buttonContainer = createContainer('a-entity', {
                radius: .1,
                color: "#000",
                opacity: .7
            }, { left: 0.05, right: .05, top: .1, bottom: .1 })
            let buttonContaine2r = createContainer('frame-rounded', {
                radius: .1,
                color: "#000",
                opacity: .7
            }, { left: 0.05, right: .05, top: .1, bottom: .1 })
            let menu = createMenu({
                width: .5,
                children: [
                    (createMenuItem({
                        text: 'Select',
                        onRender: (el) => {
                            onClick(el, () => {
                                raiseCustomEvent(PAINTER_CONSTANTS.SELECT_LAYER, { id: me.data.layerId });
                                el.emit('close-menu', {});
                            })
                        }
                    })),
                    (createMenuItem({
                        text: 'Clear',
                        onRender: (el) => {
                            onClick(el, () => {
                                raiseCustomEvent(PAINTER_CONSTANTS.CLEAR_LAYER, { id: me.data.layerId });
                                el.emit('close-menu', {});
                            })
                        }
                    })),
                    (createMenuItem({
                        text: 'Delete',
                        onRender: (el) => {
                            onClick(el, () => {
                                raiseCustomEvent(PAINTER_CONSTANTS.LAYER_DELETE, { id: me.data.layerId });
                                el.emit('close-menu', {});
                            })
                        }
                    })),
                ]
            });


            let iconButton = createBaseIconInteractive({ icon: 'f103', height: .2, width: .2 })
            let iconButton2 = createBaseIconInteractive({ icon: 'f10c', height: .2, width: .2 })
            let iconButtonHidden = createBaseIconInteractive({
                icon: 'f10c',
                value: 'Hide',
                height: .2,
                width: .5,
                fontSize: .1,
                type: 'radio'
            });

            iconButtonHidden.onRender = (el) => {
                let value = false;
                onClick(el, () => {
                    value = !value
                    el.emit(PAINTER_CONSTANTS.TOGGLE_VISIBILITY, {
                        value,
                        id: me.data.layerId
                    });
                    el.setAttribute('checked', value)
                })
            };

            iconButton.onRender = (el) => {
                onClick(el, () => {
                    el.emit(PAINTER_CONSTANTS.LAYER_DOWN, {});
                })
            };

            iconButton2.onRender = (el) => {
                onClick(el, () => {
                    el.emit(PAINTER_CONSTANTS.LAYER_UP, {});
                })
            };

            let previewLayer = createComponent('a-entity', {
                canvasId: '',
                size: .1
            }, .2, .8, undefined, (el) => {
                me.displayContainer = el;
                me.el.emit(PAINTER_CONSTANTS.LAYER_READY, { id: me.data.layerId })
            });
            buttonContainer.appendChild((iconButton));
            buttonContainer.appendChild((iconButton2));
            buttonContainer.appendChild((iconButtonHidden));

            buttonContainer.appendChild((previewLayer));
            buttonContainer.appendChild((menu));
            buttonContaine2r.appendChild(buttonContainer)
            let { element } = buttonContaine2r.render();
            this.el.appendChild(element.element());
        }
    });
    AFRAME.registerPrimitive('a-canvas-layer', {
        defaultComponents: {
            'canvas-layer': {}
        },
        mappings: {
            'layer-id': 'canvas-layer.layerId',
        }
    });

    AFRAME.registerComponent('preview-layer', {
        schema: {
            size: { type: 'number', default: .1 },
            canvasId: { type: 'string', default: '' },
            layerId: { type: 'string', default: '' }
        },
        init: function () {
            let me = this;
            let size = this.data.size;

            let canvas: HTMLCanvasElement = document.querySelector(`#${this.data.canvasId}`);
            if (canvas) {
                var texture = new THREE.Texture(canvas);
                let material = new THREE.MeshBasicMaterial({ map: texture, side: THREE.DoubleSide });
                let ratio = me.data.width / me.data.height;
                let height = size;
                let width = ratio * size;
                let mesh = new THREE.Mesh(
                    new THREE.PlaneGeometry(width, height),
                    material
                );
                // Create an A-Frame entity to wrap the Three.js mesh
                let layerDisplayEntity: any = document.createElement('a-entity');
                // layerDisplayEntity.setAttribute('layer', 'display')
                // // Add the Three.js mesh to the A-Frame entity
                layerDisplayEntity.setObject3D('mesh', mesh);
                layerDisplayEntity.setAttribute('draganddropzone', {})
                layerDisplayEntity.addEventListener(GrabAndDropEvents.DROPPED_GRABBED_DATA, async (evt) => {
                    const { data, category } = evt.detail;
                    switch (category) {
                        case 'image-catalog':
                            console.log(GrabAndDropEvents.DROPPED_GRABBED_DATA)
                            console.log(data)
                            if (data?.url && canvas) {
                                await drawTextureOnCanvas(canvas, canvas.getContext('2d'), data?.url);
                                texture.needsUpdate = true;
                                me.updateCompositeTexture();
                            }
                            break;
                    }
                })
                // // Position the entity
                this.el.appendChild(layerDisplayEntity);
            }
        }
    })
    AFRAME.registerPrimitive('a-preview-layer', {
        defaultComponents: {
            'preview-layer': {}
        },
        mappings: {
            'size': 'preview-layer.size',
            'canvas-id': 'preview-layer.canvasId',
            'layer-id': 'preview-layer.layerId',
        }
    });
}

/*
           <frame-rounded position="0 1 -1" radius=".1" color={'#000'} opacity={.75} width={3} height={.78}>
                    <a-entity position="0 0 .01">
                        <a-entity position="-1 0 0">
                            <frame-base-interactive width={.8} height={"0.4"} interactive-type="button" value={'Merge'} margin="0 0 0.05 0" font-size=".17" />
                        </a-entity>
                        <a-entity position="0 0 0">
                            <frame-base-interactive
                                width={.8}
                                height={"0.4"}
                                interactive-type="button"
                                value={'Clear'}
                                margin="0 0 0.05 0"
                                font-size=".17"
                            />
                        </a-entity>
                        <a-entity position="1 0 0">
                            <frame-base-interactive
                                width={.8}
                                height={"0.4"}
                                interactive-type="button"
                                value={'Select'}
                                margin="0 0 0.05 0"
                                font-size=".17"
                            />
                        </a-entity>
                        <a-entity position="2 0 0">
                            <frame-base-interactive width={.4} height={"0.4"} interactive-type="icon-button" value={'f103'} margin="0 0 0.05 0" font-size=".17" />
                        </a-entity>
                    </a-entity>
                </frame-rounded>
*/