import React, { useContext, useEffect, useRef } from 'react';
import { findGrayByPercentage, rgb2hsv } from './painter/util';
import { getUISystem } from './painter/systems/ui';
import { APainterContext, APainterContextValue } from './APainterContext';
import { setAttribute, useEventsListenerOn } from 'a-frame-components';
import { useListLora } from './useListModels';
const THREE: any = (window as any).THREE;

export default function StrengthComponent() {
    const sliderRef = useRef(null);
    const context: APainterContextValue = useContext(APainterContext);
    const supportedLoras = useListLora(context.useStabileDiffusion);

    let powerSlider = useEventsListenerOn({
        change: (evt: any) => {
            let { value } = evt.detail;
            context.setStrength(value)
        }
    })
    useEffect(() => {
        if (context.modelLoraSetups) {
            let setup = context.modelLoraSetups.find(v => v.id === context.currentModelLoraSetup);
            if (setup) {
                setAttribute(powerSlider, 'percent', setup.strength);
            }
        }
    }, [context.currentModelLoraSetup])

    const randomBtn = useEventsListenerOn({
        click: (evt: any) => {
            let model = context.supportedModels[Math.floor(context.supportedModels.length * Math.random())]
            context.setModel(model?.fileName || 'arcane-diffusion');
            let loracount = 4
            let result: { lora: string, loraUrl: string }[] = []
            for (let i = 0; i < loracount; i++) {
                let loradetails = supportedLoras[Math.floor(supportedLoras.length * Math.random())]
                result.push({ lora: `lora${i + 1}`, loraUrl: loradetails.imageUrl });
            }
            context.setLayerLorasStyle(result, model?.fileName || 'arcane-diffusion');
        }
    })
    // useEffect(() => {
    //     let handler: any = null;
    //     if (sliderRef?.current) {
    //         handler = (evt) => {
    //             let { value } = evt.detail;
    //             context.setStrength(value)
    //         };
    //         sliderRef?.current.addEventListener('change', handler)
    //     }
    //     return () => {
    //         if (sliderRef?.current && handler) {
    //             sliderRef?.current.removeEventListener('change', handler);
    //         }
    //     }
    // }, [sliderRef?.current])
    return (
        <>
            <a-entity position="-.25 .19 .15" scale=".5 .5 .5">
                <frame-troika-text anchor={'left'} value="Strength " />
            </a-entity>
            <a-entity position="-1.2 .19 .15" scale=".5 .5 .5">
                <frame-base-interactive
                    {...randomBtn}
                    value={'Random'}
                    width={.5}
                    title={'Random'} />
            </a-entity>
            <frame-slider bar-length={'1'} bar-thickness={'.1'}
                {...powerSlider} ref={sliderRef} percent={.7} />
        </>
    )
}