import { useContext, useEffect, useState } from "react"
import { APainterContext, RenderTarget, createDefaultCivitaiLora, createDefaultCivitaiModel, createDefaultCivitaiSetup } from "../APainterContext";
import { setAttribute, updateAttribute, useEventsListenerOn } from "a-frame-components";
import { fetchCivitaiModels } from ".";
import { CivitaiModelItem, CivitaiModelType, ModelVersion } from "./interface";
import { fetchCivitai } from "../service";
import { useMode } from "../painter/useDragAndDrop";
import { raiseCustomEvent } from "../painter/util";
import { useCustomEventListener } from "../painter/useCustomEventListener";
import { writeToDatabase } from "../firebase-app";
import { PAINTER_CONSTANTS } from "../painter/constants";

export default function CivitaiModelLoraConfigurationComponent() {
    const context = useContext(APainterContext);
    const [updated, setUpdated] = useState(Date.now());
    const [updateCivitaiModel, setUpdateCivitaiModel] = useState(Date.now());
    const [civitaiModels, setCivitaiModels] = useState<{ [id: string]: CivitaiModelItem[] }>({});
    const [nextUrl, setNextUrl] = useState<{ [id: string]: string | undefined }>({});
    const [civitaiModelType, setCivitaiModelType] = useState(CivitaiModelType.Checkpoint);
    const [currentLora, setCurrentLora] = useState<{ id: any, loraId: any, value: any, loraWord: string } | null>(null);
    const [fetchCivitaiModel, setFetchCivitaiModels] = useState(false);

    useCustomEventListener(PAINTER_CONSTANTS.UPDATED_FROM_OTHER_MATCHINES, () => {
        setUpdated(Date.now());
    });

    useEffect(() => {
        if (fetchCivitaiModel) {
            let modelType = civitaiModelType;
            fetchCivitai({
                bearerToken: context?.userInfo?.token,
                params: {
                    url: nextUrl?.[modelType],
                    query: { types: modelType }
                }
            }).then((res) => {
                let { data } = res;
                setNextUrl({
                    ...nextUrl,
                    [modelType]: data.metadata.nextPage
                });
                setCivitaiModels({
                    ...civitaiModels,
                    [modelType]: [
                        ...(civitaiModels[modelType] || []), ...data.items
                    ]
                });
            })
        }
    }, [fetchCivitaiModel, updateCivitaiModel, civitaiModelType]);

    useEffect(() => {
        let options = (
            civitaiModels[civitaiModelType] || []
        ).filter(x => x.type === civitaiModelType).map((item, index) => {
            let modelVersion = item.modelVersions.find(x => x);
            let image = modelVersion.images.find(image => {
                return image.url;
            })
            return ({
                id: index,
                value: index,
                text: filterNonEnglishCharacters(item.name),
                url: image?.url
            })
        }).filter(x => x.url && x.text)
            .sort((a, b) => a.text.localeCompare(b.text))
            .map((v, index) => {
                v.id = index;
                v.value = index;
                return v;
            });
        updateAttribute(civitaSelection, 'options', options)
    }, [civitaiModels, civitaiModelType])
    function filterNonEnglishCharacters(input: string): string {
        // This regex matches only English letters, digits, and common punctuation
        return input.replace(/[^a-zA-Z0-9 ,.!?;:'"-]/g, '');
    }

    useEffect(() => {
        if (context?.renderTarget) {
            switch (context?.renderTarget) {
                case RenderTarget.LocalCivitai:
                    if (!civitaiModels?.length) {
                        setFetchCivitaiModels(true);
                    }
                    break;
            }
        }
    }, [context?.renderTarget]);

    const civitalModelName = useEventsListenerOn({});

    const civitaSelection = useEventsListenerOn({
        ["civitai-selection"]: (event: any, element: any) => {
            let modelItem = (civitaiModels[civitaiModelType] || []).find(model => model.modelVersions.find(x => x.images.find(v => v.url === event?.detail?.url)));
            let modelOptions = modelItem.modelVersions.map((modelVersion: ModelVersion, index) => {
                let image = modelVersion.images.find(v => v.url);
                let fileName = modelVersion.files.find(v => v.name && v.name.endsWith('safetensors')).name
                return ({
                    id: index,
                    value: index,
                    text: filterNonEnglishCharacters(modelVersion.name),
                    fileName,
                    url: image?.url,
                    downloadUrl: modelVersion.downloadUrl,
                    trainedWords: modelVersion.trainedWords
                })
            }).filter(x => x.url && x.text && x.downloadUrl);
            setAttribute(civitalModelName, 'value', modelItem.name);
            // setAttribute(civitaModelSelection, 'options', JSON.stringify(modelOptions));
            updateAttribute(civitaModelSelection, 'options', modelOptions)

            raiseCustomEvent('citivai-mode', { mode: MODEL_VIEW })
        },
        ['request-more-data']: (event: any, element: any) => {
            console.log('request more data')
            setUpdateCivitaiModel(Date.now())
        }
    });
    const modelTypeSelection = useEventsListenerOn({
        'change': (evt: any, element: any) => {
            setAttribute(modelTypeSelection, 'value', evt.detail.value);
            setCivitaiModelType(evt.detail.value);
        }
    })
    const civitaModelSelection = useEventsListenerOn({
        'civitai-model-selection': async (evt: any, element: any) => {
            if (currentLora) {
                if (context.citivaiSetups) {
                    let setup = context.citivaiSetups.find(v => v.id === currentLora.id);
                    if (setup) {
                        if (currentLora.loraId) {
                            let lora = setup.loras.find(v => v.id === currentLora.loraId);
                            if (lora) {
                                console.log(evt)
                                lora.downloadUrl = evt?.detail?.options?.downloadUrl || null;
                                lora.fileName = evt?.detail?.options?.fileName || null;
                                lora.trainedWords = (evt?.detail?.options?.trainedWords || []).map((word) => {
                                    return {
                                        word,
                                        strength: 1
                                    }
                                });
                                lora.url = evt.detail.url;
                                lora.name = evt.detail.text;
                                context.setCivitaiSetups([...context.citivaiSetups]);
                                raiseCustomEvent('citivai-mode', { mode: RENDER_TARGETS })
                                setUpdated(Date.now())
                                await storeSetups();
                            }
                        }
                        else {
                            //setting a model
                            let { model } = setup;
                            if (model) {
                                model.downloadUrl = evt?.detail?.downloadUrl || null;
                                model.fileName = evt?.detail?.fileName || null;
                                model.trainedWords = (evt?.detail?.trainedWords || []).map((word) => {
                                    return {
                                        word,
                                        strength: 1
                                    }
                                });
                                model.url = evt.detail.url;
                                model.name = evt.detail.text;
                                context.setCivitaiSetups([...context.citivaiSetups]);
                                raiseCustomEvent('citivai-mode', { mode: RENDER_TARGETS })
                                setUpdated(Date.now())
                                await storeSetups();
                            }
                        }
                    }
                }
            }
        }
    })

    const trainedWordsCloseButton = useEventsListenerOn({
        'click': (evt: any, element: any) => {
            evt.stopPropagation()
            raiseCustomEvent('citivai-mode', { mode: RENDER_TARGETS })
        }
    })

    const trainedWordStrength = useEventsListenerOn({
        'change': (evt: any, element: any) => {
            evt.stopPropagation()
            if (currentLora) {
                let { id, loraId, } = currentLora;
                let setup = context.citivaiSetups.find(x => x.id === id)
                if (setup) {
                    let lora = setup.loras.find(v => v.id === loraId)
                    if (lora) {
                        if (evt?.detail?.value) {
                            let trainedWord = lora.trainedWords.find(v => v.word === currentLora?.loraWord);
                            if (trainedWord) {
                                trainedWord.strength = parseFloat(evt.detail.value);
                            }
                        }
                    }
                }
            }
        }
    });

    const trainedWordsSelection = useEventsListenerOn({
        'change': (evt: any, element: any) => {
            evt.stopPropagation()
            if (currentLora) {
                let { id, loraId, } = currentLora;
                let setup = context.citivaiSetups.find(x => x.id === id)
                if (setup) {
                    let lora = setup.loras.find(v => v.id === loraId)
                    if (lora) {
                        if (evt?.detail?.value) {
                            let trainedWord = lora.trainedWords.find(v => v.word === evt?.detail?.value);
                            setCurrentLora({ ...currentLora, loraWord: trainedWord?.word || '' })
                            setAttribute(trainedWordStrength, 'percent', trainedWord?.strength || 0);
                            setAttribute(trainedWordsSelection, 'value', trainedWord.word);
                        }
                    }
                }
            }
        }
    });

    const civitaLoraSetups = useEventsListenerOn({
        'add-lora': (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                let setup = context.citivaiSetups.find(v => v.id === detail?.id);
                setup.loras.push(createDefaultCivitaiLora());
                context.setCivitaiSetups([...context.citivaiSetups]);
                setUpdated(Date.now())
            }
        },
        'steps-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                let setup = context.citivaiSetups.find(v => v.id === detail?.id);
                setup.steps = detail.value;
                context.setCivitaiSetups([...context.citivaiSetups]);
                await storeSetups();
            }
        },
        'denoise-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                let setup = context.citivaiSetups.find(v => v.id === detail?.id);
                setup.denoise = detail.value;
                context.setCivitaiSetups([...context.citivaiSetups]);
                await storeSetups();
            }
        },
        'add-prompt': (evt: any, element: any) => {
            context.setCurrentSetup(evt.detail.id);
            (window as any).onPromptPositive();
        },
        'remove-setup-prompt': (evt: any, element: any) => {
            let { detail } = evt;
            let { promptId } = detail;
            let setup = context.citivaiSetups.find(x => x.id === detail?.id)
            if (setup) {
                setup.prompts = [...setup.prompts.filter(x => x.id !== promptId)];
                raiseCustomEvent(PAINTER_CONSTANTS.PROMPTS_UPDATED, {});
            }
        },
        'add-negative-prompt': (evt: any, element: any) => {
            context.setCurrentSetup(evt.detail.id);
            (window as any).onPromptNegative()
        },
        'config-enabled': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                context.setCivitaiSetups([...context.citivaiSetups.map(x => {
                    if (x.id === detail?.id) {
                        x.enabled = detail.value;
                    }
                    else {
                        if (detail.value) {
                            x.enabled = false;
                        }
                    }
                    return x;
                })]);
                await storeSetups();
            }
        },
        'remove-setup': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                context.setCivitaiSetups([...context.citivaiSetups.filter(x => x.id !== detail?.id)]);
                setUpdated(Date.now())
                await storeSetups();
            }
        },
        'model-action': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail?.id) {
                const { id, loraId, value } = detail;
                if (value === 'set_model') {
                    setCurrentLora({ id: detail?.id, loraId: '', value: '', loraWord: '' })
                    raiseCustomEvent('citivai-mode', {
                        mode: MODELS_VIEW
                    });

                    setAttribute(modelTypeSelection, 'value', CivitaiModelType.Checkpoint);
                    setCivitaiModelType(CivitaiModelType.Checkpoint);
                    setUpdated(Date.now())
                    await storeSetups();
                }
            }
        },
        'lora-action': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail) {
                const { id, loraId, value } = detail;
                if (value === 'remove_lora') {
                    let setup = context.citivaiSetups.find(v => v.id === detail?.id);
                    setup.loras = setup.loras.filter(x => x.id !== loraId)
                    context.setCivitaiSetups([...context.citivaiSetups]);
                    setUpdated(Date.now())
                }
                else if (value === 'set_lora') {
                    let temp = { id, loraId, value, loraWord: '' };
                    let setup = context.citivaiSetups.find(x => x.id === id)
                    if (setup) {
                        let lora = setup.loras.find(v => v.id === loraId)
                        if (lora) {
                            temp = ({ id, loraId, value, loraWord: lora.trainedWords?.[0]?.word || '' })
                        }
                    }
                    setCurrentLora(temp);
                    raiseCustomEvent('citivai-mode', {
                        mode: MODELS_VIEW
                    });

                    setAttribute(modelTypeSelection, 'value', CivitaiModelType.LORA);
                    setCivitaiModelType(CivitaiModelType.LORA);
                    setUpdated(Date.now())
                }
                else if (value === 'trained-words') {
                    let temp = { id, loraId, value, loraWord: '' };
                    raiseCustomEvent('citivai-mode', {
                        mode: LORA_TRAINED_WORDS
                    });
                    let setup = context.citivaiSetups.find(x => x.id === id)
                    if (setup) {
                        let lora = setup.loras.find(v => v.id === loraId)
                        if (lora) {
                            temp = ({ id, loraId, value, loraWord: lora.trainedWords?.[0].word || '' })
                            setAttribute(trainedWordsSelection, 'options', JSON.stringify(lora.trainedWords.map(v => ({ name: v.word, value: v.word }))))
                            if (lora?.trainedWords?.length) {
                                setAttribute(trainedWordsSelection, 'value', lora.trainedWords[0].word);
                                setAttribute(trainedWordStrength, 'percent', lora.trainedWords[0].strength);
                            }
                        }
                    }
                    setCurrentLora(temp)
                }
                await storeSetups();
            }
        },
        'model-strength-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail) {
                const { value, loraId, setupId } = detail;
                let setup = context.citivaiSetups.find(v => v.id == setupId)
                if (setup) {
                    setup.model.strength = value;
                    context.setCivitaiSetups([...context.citivaiSetups]);
                    await storeSetups();
                }
            }
        },
        'model-clip-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail) {
                const { value, loraId, setupId } = detail;
                let setup = context.citivaiSetups.find(v => v.id == setupId)
                if (setup) {
                    setup.model.clip = value;
                    context.setCivitaiSetups([...context.citivaiSetups]);
                    await storeSetups();
                }
            }
        },
        'lora-strength-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail) {
                const { value, loraId, setupId } = detail;
                let setup = context.citivaiSetups.find(v => v.id == setupId)
                if (setup) {
                    let lora = setup.loras.find(v => v.id === loraId)
                    if (lora) {
                        lora.strength = value;
                        context.setCivitaiSetups([...context.citivaiSetups]);
                        await storeSetups();
                    }
                }
            }
        },
        'lora-clip-change': async (evt: any, element: any) => {
            let { detail } = evt;
            if (detail) {
                const { value, loraId, setupId } = detail;
                let setup = context.citivaiSetups.find(v => v.id == setupId)
                if (setup) {
                    let lora = setup.loras.find(v => v.id === loraId)
                    if (lora) {
                        lora.clip = value;
                        context.setCivitaiSetups([...context.citivaiSetups]);
                        await storeSetups();
                    }
                }
            }
        }
    });
    useEffect(() => {
        if (context.citivaiSetups) {
            updateAttribute(civitaLoraSetups, 'options',
                context.citivaiSetups.map((config, index) => {
                    return {
                        id: index,
                        config
                    }
                })
            );

        }
    }, [updated, JSON.stringify(civitaLoraSetups || {})]);

    async function storeSetups() {
        if (context.renderTarget === RenderTarget.LocalCivitai) {
            let setups = JSON.stringify(context.citivaiSetups.map((setup) => {
                setup.loraPrompts = (setup.loras || []).map((lora) => {
                    if (lora.fileName && lora?.strength) {
                        let words = lora.trainedWords.map((word) => {
                            return `${word.word}<lora:${lora.fileName}:${word.strength}>`
                        }).join(' ')
                        return `<lora:${lora.fileName}:${lora.strength}> ${words}`
                    }
                    return '';
                }).join(' ');
                return setup;
            }));
            await writeToDatabase(`civitai/${context?.userInfo?.uid}/render_targets`, {
                setups,
                stamp: Date.now(),
                machine: context.machineIdentifier
            })
        }
    }
    const addSetupButton = useEventsListenerOn({
        'click': async (evt: any, element: any) => {
            context.setCivitaiSetups([...context.citivaiSetups, createDefaultCivitaiSetup()]);
            await storeSetups();
            setUpdated(Date.now());
        }
    });
    const MODELS_VIEW = 'MODELS_VIEW';
    const MODEL_VIEW = 'MODEL_VIEW';
    const LORA_TRAINED_WORDS = 'LORA_TRAINED_WORDS';
    const RENDER_TARGETS = 'RENDER_TARGETS';
    useMode({
        [RENDER_TARGETS]: {
            mode: RENDER_TARGETS,
            hidden: {}
        },
        [MODELS_VIEW]: {
            mode: MODELS_VIEW,
            hidden: {}
        },
        [LORA_TRAINED_WORDS]: {
            mode: LORA_TRAINED_WORDS,
            hidden: {}
        },
        [MODEL_VIEW]: {
            mode: MODEL_VIEW,
            hidden: {}
        }
    }, 'citivai-mode', (detail) => detail?.mode, RENDER_TARGETS, context?.painterLoaded);

    useCustomEventListener('civitai-model-close', () => {
        raiseCustomEvent('citivai-mode', { mode: MODELS_VIEW })
    });
    useCustomEventListener('civitai-models-close', () => {
        raiseCustomEvent('citivai-mode', { mode: RENDER_TARGETS })
    })

    return (
        <>
            <a-entity position={'0 0 -2'}>
                <a-entity {...{ RENDER_TARGETS: true }}>
                    <a-entity position={'0 2 0'}>
                        <frame-container direction="horizontal" margin={'.3 .3 .3 .3'}>
                            <frame-base-interactive
                                {...addSetupButton}
                                width={.75}
                                value={'Add Setup'}
                                title={'Add Setup'} />
                            <frame-infinite-list
                                {...civitaLoraSetups}
                                width={10}
                                itemsize={1.5}
                                icon="f128" direct
                                icon-font-size=".14"
                                font-size=".1"
                                icon-font="assets/fonts/ionicons.ttf"
                                itemtemplate={'a-civitai-lora-item'}
                                columns={1}
                                hideclose
                                position="0 1 0">
                            </frame-infinite-list>
                        </frame-container>
                    </a-entity>
                </a-entity>
                <a-entity {...{ LORA_TRAINED_WORDS: true }}>
                    <a-entity position={'0 2 0'}>
                        <frame-container direction="horizontal" margin={'.3 .3 .3 .3'}>
                            <frame-base-interactive
                                {...trainedWordsCloseButton}
                                value={'Close'}
                                width={.5}
                                title={'Close'} />
                            <frame-drop-down
                                {...trainedWordsSelection}
                                options={JSON.stringify([{ name: '', value: '' }])} />
                            <frame-slider
                                {...trainedWordStrength}
                                orientation="horizontal"
                                percent={.5}
                                bar-thickness=".1"
                                bar-length="1"
                                height=".3"
                                targetbarsize={.3}
                                title-scale={'.5 .5'}
                                title={'Y'}
                                title-position="-.5 0 0" />
                        </frame-container>
                    </a-entity>
                </a-entity>
                <a-entity {...{ MODELS_VIEW: true }}>
                    <a-entity position={'0 2 0'}>
                        <frame-container direction="horizontal" margin={'.3 .3 .3 .3'}>
                            <frame-drop-down {...modelTypeSelection} options={
                                JSON.stringify(
                                    Object.entries(CivitaiModelType).map(([key, option]) => {
                                        return { name: key, value: key }
                                    })
                                )} />
                            <frame-infinite-list
                                {...civitaSelection}
                                selectionevent="civitai-selection"
                                width={4}
                                itemsize={1}
                                icon="f128"
                                icon-font-size=".14"
                                direct
                                font-size=".1"
                                icon-font="assets/fonts/ionicons.ttf"
                                itemtemplate={'a-civitai-item'}
                                columns={3}
                                closeevent="civitai-models-close"
                                position="0 1 0">
                            </frame-infinite-list>
                        </frame-container>
                    </a-entity>
                </a-entity>
                <a-entity {...{ MODEL_VIEW: true }}>
                    <a-entity position={'0 2 0'}>
                        <frame-container direction="vertical" margin="0 0 .2 0 ">
                            <frame-text-input height={''} {...{ width: "2" }} {...civitalModelName} value={'Name'} />
                            <frame-infinite-list
                                {...civitaModelSelection}
                                selectionevent="civitai-model-selection"
                                width={4}
                                itemsize={1}
                                icon="f128"
                                direct
                                icon-font-size=".14"
                                font-size=".1"
                                icon-font="assets/fonts/ionicons.ttf"
                                itemtemplate={'a-civitai-item'}
                                columns={3}
                                closeevent="civitai-model-close"
                                position="0 1 0">
                            </frame-infinite-list>
                        </frame-container>
                    </a-entity>
                </a-entity>
            </a-entity>
        </>
    )
}