import { startSpeechRecognition } from '../../SpeechRecognition';
import { AFRAME } from '../systems/brush';
import { INTERACTABLES } from '../systems/ui';
import { Utils, clamp, getRgbFromPosition, hsb2rgb, limitToUnitCircle, rgb2hsv, rgbToHex, setConsoleText } from '../util';
const THREE: any = (window as any).THREE;
/* globals AFRAME THREE */
export default function () {
    AFRAME.registerComponent('paint-ui', {
        schema: {
            brightness: {
                default: 1.0, max: 1.0, min: 0.0
            },
            opacity: { default: 1 }
        },
        update: function () {

        },

        init: function () {
            var el = this.el;
            var uiEl = this.uiEl = document.createElement('a-entity');
            var system = this.el.sceneEl.systems.ui; // Access by system name
            this.system = system;
            this.recordingFuncEnd = null;
            this.executingFunction = false;
            this.functionSelectionIndex = 0;
            this.sizeSlideValue = .1;
            this.colorWheelPosition = { x: 0, y: 0 }
            this.brightnessSliderValue = 1;
            this.focusedControlIndex = 0;
            this.focusableControllers = ['sizeSlider', 'hueWheel', 'brightnessSlider']
            this.focusableModels = []
            this.functionSelections = ['prompt-function', 'negative-prompt-function']
            this.focusableControllerCursors = []
            this.closed = true;
            this.isTooltipPaused = false;
            this.colorStack = ['#272727', '#727272', '#FFFFFF', '#24CAFF', '#249F90', '#F2E646', '#EF2D5E'];
            this.colorHasChanged = true;
            this.highlightMaterials = {};
            this.intersectedObjects = [];
            this.hoveredOffObjects = [];
            this.hoveredOnObjects = [];
            this.pressedObjects = {};
            this.selectedObjects = {};
            this.unpressedObjects = {};
            this.brushButtonsMapping = {};
            this.brushRegexp = /^(?!.*(fg|bg)$)brush[0-9]+/;
            this.colorHistoryRegexp = /^(?!.*(fg|bg)$)colorhistory[0-9]+$/;
            this.hsv = { h: 0.0, s: 0.0, v: 1.0 };
            this.rayAngle = 45;
            this.rayDistance = 0.2;
            const sizeOffset = .1
            this.bindMethods();
            // el.addEventListener('model-loaded', this.onModelLoaded);

            // The cursor is centered in 0,0 to allow scale it easily
            // This is the offset to put it back in its original position on the slider
            this.cursorOffset = new THREE.Vector3(0.06409, 0.01419, -0.10242 + sizeOffset);

            // UI entity setup
            (uiEl as any).setAttribute('material', {
                color: '#ffffff',
                flatShading: true,
                shader: 'flat',
                transparent: true,
                fog: false,
                src: '#uinormal'
            });
            uiEl.setAttribute('obj-model', 'obj:#uiobj');
            uiEl.setAttribute('position', '0 0.04 -0.15');

            uiEl.setAttribute('scale', '1 1 1');
            (uiEl as any).setAttribute('visible', true);
            el.appendChild(uiEl);
            this.menuEls = this.uiEl.object3D.children;
            this.controller = null;
        },
        changeFocusedController: function (dir: number) {
            if (dir > 0) {
                this.focusedControlIndex = (this.focusedControlIndex + 1) % this.focusableControllers.length;
            }
            else if (dir < 0) {
                this.focusedControlIndex = (this.focusedControlIndex - 1)
                if (this.focusedControlIndex < 0) {
                    this.focusedControlIndex = this.focusableControllers.length - 1;
                }
            }
            console.log('chang focused controller')
            console.log('updated focused controller cursor')
        },
        changeFunctionSelection: function (dir: number) {
            if (dir > 0) {
                this.functionSelectionIndex = (this.functionSelectionIndex + 1) % this.functionSelections.length;
            }
            else if (dir < 0) {
                this.functionSelectionIndex = (this.functionSelectionIndex - 1)
                if (this.functionSelectionIndex < 0) {
                    this.functionSelectionIndex = this.functionSelections.length - 1;
                }
            }
        },
        updateFunctionSelectionUi: function () {
            for (let i = 0; i < this.functionSelections.length; i++) {
                let element: any = this.el.sceneEl.querySelector(`.${this.functionSelections[i]}`);
                if (element) {
                    element.setAttribute('visible', this.functionSelectionIndex === i ? 'true' : 'false')
                }
            }
        },
        bindMethods: function () {
            this.onModelLoaded = this.onModelLoaded.bind(this);
            this.onButtonPressed = this.onButtonPressed.bind(this);
            this.onFunctionSelection = this.onFunctionSelection.bind(this);
            this.onFunctionExecute = this.onFunctionExecute.bind(this);
            this.updateFocusedController = this.updateFocusedController.bind(this);
            this.updateSelectedControlCursorColor = this.updateSelectedControlCursorColor.bind(this);
        },
        onStartRecording: function (functionSelection) {
            console.log('onStartRecording');
            let element: any = this.el.sceneEl.querySelector(`.${functionSelection}`);
            if (element) {
                element.setAttribute('material', 'color', 'green')
            }
            console.log('start recording')
            let recognition = startSpeechRecognition((text: any, isFinal: boolean) => {
                if (isFinal) {
                    this.recordedText = text;
                }
                else {
                    this.recordedText = this.recordedText + text;
                }
            })
            console.log('setting recording func end')
            this.recordingFuncEnd = function () {
                this.functionSelectionOutput[functionSelection] = this.recordedText;
                recognition();
                let element: any = this.el.sceneEl.querySelector(`.${functionSelection}`);
                if (element) {
                    element.setAttribute('material', 'color', 'red');
                }
            }
        },
        onFunctionExecute: function (evt, args) {
            if (args[0].on) {
                console.log('turn on function')
                if (this.executingFunction) {
                    console.log('turning running off function ' + this.executingFunction)
                    let functionName = this.functionSelections[this.functionSelectionIndex];
                    switch (functionName) {
                        case 'prompt-function':
                        case 'negative-prompt-function':
                            if (this.recordingFuncEnd) {
                                this.recordingFuncEnd();
                                setConsoleText(this.functionSelectionOutput[functionName] || '', `.${functionName}-console`)
                            }
                            break;
                    }
                    this.executingFunction = null;
                }
                else {
                    this.executingFunction = this.functionSelections[this.functionSelectionIndex];
                    console.log(`Selected function : ${this.executingFunction}, ${this.functionSelectionIndex}`)
                    switch (this.executingFunction) {
                        case 'prompt-function':
                        case 'negative-prompt-function':
                            this.onStartRecording(this.executingFunction);
                    }
                }
            }
            else {
                if (this.executingFunction) {
                    let functionName = this.functionSelections[this.functionSelectionIndex];
                    switch (functionName) {
                        case 'prompt-function':
                        case 'negative-prompt-function':
                            if (this.recordingFuncEnd) {
                                this.recordingFuncEnd();
                                setConsoleText(this.functionSelectionOutput[functionName] || '', `.${functionName}-console`)
                            }
                            break;
                    }
                    this.executingFunction = null;
                }
            }
        },
        onFunctionSelection: function (evt, args) {
            if (!this.executingFunction) {//you cant switch functions while doing a function
                switch (args[0].key) {
                    case 'up':
                        this.changeFunctionSelection(1);
                        break;
                    case 'down':
                        this.changeFunctionSelection(-1);
                        break;
                }
                this.updateFunctionSelectionUi();
            }
        },
        onButtonPressed: function (evt, args) {
            switch (args[0].key) {
                // case 'x':
                //     this.changeFocusedController(1)
                //     break;
                // case 'y':
                //     this.changeFocusedController(-1)
                //     break;
                case 'grip':
                    this.lockinChange(evt, args);
                    break;
                case 'thumbstick':
                    this.updateFocusedController(evt, args);
                    break;
                default:
                    console.log('not found');
                    console.log(JSON.stringify(args))
                    break;
            }
        },
        onTriggerChanged: function (evt) {
            var triggerValue = evt.detail.value;
            this.lastTriggerValue = triggerValue;
            if (evt.detail.value >= 0.25) {
                this.triggeredPressed = true;
            } else {
                this.triggeredPressed = false;
                this.handleButtonUp();
            }
        },

        lockinChange: function (evt, args) {
            switch (this.focusableControllers[this.focusedControlIndex]) {
                case 'hueWheel':
                case 'brightnessSlider':
                    this.updateColorHistory(this.getCurrentColor())
                    break;
            }
        },
        getCurrentColor: function () {
            let color = getRgbFromPosition(this.colorWheelPosition.x, this.colorWheelPosition.y);
            var colorRGB = new THREE.Color(color);
            var hsv = rgb2hsv(colorRGB.r, colorRGB.g, colorRGB.b);
            hsv.v = this.brightnessSliderValue;
            let new_color = rgbToHex(hsb2rgb([hsv.h, hsv.s, hsv.v]))
            console.log(`new_color: ${new_color}`)
            return new_color;
        },
        updateFocusedController: function (evt, args) {
            // Accessing the thumbstick event details
            console.log('Thumbstick Event Details:', evt.detail);
            if (evt.detail) {
                // Example: Accessing the x and y values of the thumbstick position
                var x = evt.detail.x;
                let el = this.el;
                var y = evt.detail.y;
                console.log(`Thumbstick position: x:${x}, y:${y}`);
                let direction = Math.abs(x) > Math.abs(y) ? x : y;
                // You can also check for the source of the event (e.g., left or right controller)
                var hand = evt.detail.hand;
                console.log(`Hand: ${hand}`);
                switch (this.focusableControllers[this.focusedControlIndex]) {
                    case 'sizeSlider':
                        var magnitude = Math.abs(x) > Math.abs(y) ? -x : y;
                        console.log('size slider selected')
                        var delta = magnitude / 100;
                        if (el?.components?.brush) {
                            console.log('brush found')
                        }
                        else {
                            console.log('no brush found')
                        }
                        var size = el.components.brush.schema.size;
                        console.log(`size`)
                        console.log(size)
                        console.log(`el.getAttribute('brush').size`)
                        console.log(delta)
                        var value = clamp((this.sizeSlideValue) - delta, 0.001, 1);
                        console.log(`setting the brush size to ${value}`)
                        this.sizeSlideValue = value;
                        this.updateSizeSlider(value)
                        break;
                    case 'hueWheel': {
                        console.log('huewheel')
                        let new_x = this.colorWheelPosition.x + (-x / 100);
                        let new_y = this.colorWheelPosition.y + (y / 100);
                        console.log(`new_x: ${new_x}, new_y: ${new_y}`)
                        const [limitedX, limitedY] = limitToUnitCircle(new_x, new_y)
                        console.log(`limitedX: ${limitedX}, limitedY: ${limitedY}`)
                        this.colorWheelPosition.x = limitedX;
                        this.colorWheelPosition.y = limitedY;
                        let color = getRgbFromPosition(limitedX, limitedY);
                        var colorRGB = new THREE.Color(color);
                        var hsv = rgb2hsv(colorRGB.r, colorRGB.g, colorRGB.b);
                        hsv.v = this.brightnessSliderValue;
                        let new_color = rgbToHex(hsb2rgb([hsv.h, hsv.s, hsv.v]))
                        console.log(`new_color: ${new_color}`)
                        this.updateColorUI(new_color)
                        console.log(`color updated`)
                    }
                        break;
                    case 'brightnessSlider': {
                        var magnitude = direction;
                        console.log('size slider selected')
                        var delta = magnitude / 100;
                        console.log(`el.getAttribute('brush').size`)
                        var value = clamp((this.brightnessSliderValue) - delta, 0.001, 1);
                        console.log(`setting the brightnessSliderValue to ${value}`)
                        this.brightnessSliderValue = value;
                        let color = getRgbFromPosition(this.colorWheelPosition.x, this.colorWheelPosition.y);
                        var colorRGB = new THREE.Color(color);
                        var hsv = rgb2hsv(colorRGB.r, colorRGB.g, colorRGB.b);
                        hsv.v = this.brightnessSliderValue;
                        let new_color = rgbToHex(hsb2rgb([hsv.h, hsv.s, hsv.v]))
                        this.updateColorUI(new_color)
                    }
                        break;
                    default:
                        console.log('no controller focused');
                        break;
                }
            }
        },
        onModelLoaded: function (evt) {
            var uiEl = this.uiEl;
            var model = uiEl.getObject3D('mesh');
            model = evt.detail.model;
            if (evt.detail.format !== 'obj' || !model.getObjectByName('brightnesscursor')) { return; }

            this.objects = {};
            this.objects.brightnessCursor = model.getObjectByName('brightnesscursor');
            this.objects.brightnessSlider = model.getObjectByName('brightness');
            this.objects.brightnessSlider.geometry.computeBoundingBox();
            this.objects.previousPage = model.getObjectByName('brushprev');
            this.objects.brightnessSlider.visible = false;
            this.objects.brightnessCursor.visible = false;
            this.objects.previousPage.visible = false;
            this.objects.nextPage = model.getObjectByName('brushnext');
            this.objects.nextPage.visible = false;


            model.getObjectByName('colorsbg').visible = false
            for (let i = 0; i < 15; i++) {
                let b = model.getObjectByName(`brush${i}bg`);
                if (b) {
                    b.visible = false
                }
                b = model.getObjectByName(`brush${i}fg`);
                if (b) {
                    b.visible = false
                }
                b = model.getObjectByName(`brush${i}`);
                if (b) {
                    b.visible = false
                }
            }
            model.getObjectByName('save').visible = false;
            model.getObjectByName('clear').visible = false;
            model.getObjectByName('copy').visible = false;
            this.objects.hueCursor = model.getObjectByName('huecursor');
            this.objects.hueWheel = model.getObjectByName('hue');
            this.objects.hueWheel.geometry.computeBoundingSphere();
            this.colorWheelSize = this.objects.hueWheel.geometry.boundingSphere.radius;
            this.objects.hueWheel.visible = false;
            this.objects.hueCursor.visible = false;


            this.objects.sizeCursor = model.getObjectByName('size');
            this.objects.sizeCursor.position.copy(this.cursorOffset);
            this.objects.colorHistory = [];
            for (var i = 0; i < 7; i++) {
                this.objects.colorHistory[i] = model.getObjectByName('colorhistory' + i);
                this.objects.colorHistory[i].visible = false;
            }
            this.objects.currentColor = model.getObjectByName('currentcolor');
            this.objects.sizeSlider = model.getObjectByName('sizebg');
            console.log(this.objects.sizeSlider.position)
            const sizeOffset = .1;
            this.objects.sizeSlider.position.set(0, 0, sizeOffset)
            this.objects.sizeSlider.geometry.computeBoundingBox();
            // Hide bounding box
            model.getObjectByName('bb').material = new THREE.MeshBasicMaterial(
                { color: 0x248f24, alphaTest: 0, visible: false });
            // Hide objects
            var self = this;

            this.messagesMaterial = new THREE.MeshBasicMaterial({ map: null, transparent: true, opacity: 0.0 });
            this.objects.messageSave = model.getObjectByName('msg_save');
            this.objects.messageSave.material = this.messagesMaterial;
            this.objects.messageSave.visible = false;
            this.objects.messageError = model.getObjectByName('msg_error');
            this.objects.messageError.visible = false;
            this.objects.messageError.material = this.messagesMaterial;

            var messagesImageUrl = 'assets/images/messages.png';

            this.el.sceneEl.systems.material.loadTexture(messagesImageUrl, { src: messagesImageUrl }, function (texture) {
                var material = self.messagesMaterial;
                material.map = texture;
                material.needsUpdate = true;
            });

            this.el.setAttribute('animation__showmessage', { dur: 500, property: 'ui.opacity', from: 0, to: 1, startEvents: 'showmessage' });
            this.el.setAttribute('animation__hidemessage', { dur: 500, delay: 3000, property: 'ui.opacity', from: 1, to: 0, startEvents: 'animationcomplete__showmessage' });
            function showMessage(msgObject) {
                // msgObject.visible = true;
                // self.el.emit('showmessage');
            }

            this.el.sceneEl.addEventListener('drawing-upload-completed', function (event) {
                showMessage(self.objects.messageSave);
            });
            this.el.sceneEl.addEventListener('drawing-upload-error', function (event) {
                showMessage(self.objects.messageError);
            });

            this.focusableControllers = ['sizeSlider', 'hueWheel', 'brightnessSlider']
            this.focusableControllerCursors = [
                () => {
                },
                () => {
                },
                () => {
                }
            ]

            this.initColorWheel();
            this.initColorHistory();
            this.initBrushesMenu();
            this.setCursorTransparency();
            // this.updateColorUI(this.el.getAttribute('brush').color);
            // this.updateSizeSlider(this.el.getAttribute('brush').size);
        },
        initBrushesMenu: function () {
            var previousPage = this.objects.previousPage;
            var nextPage = this.objects.nextPage;
            var brushes = Object.keys(AFRAME.BRUSHES);
            this.initHighlightMaterial(nextPage);
            this.initHighlightMaterial(previousPage);
            previousPage.visible = false;
            nextPage.visible = false;
            this.brushesPerPage = 15;
            this.brushesPagesNum = Math.ceil(brushes.length / this.brushesPerPage);
            this.brushesPage = 0;
            // this.loadBrushes(this.brushesPage, this.brushesPerPage);
        },
        updateColorUI: function (color) {
            var colorRGB = new THREE.Color(color);
            var hsv = this.hsv = rgb2hsv(colorRGB.r, colorRGB.g, colorRGB.b);
            // Update color wheel
            var angle = hsv.h * 2 * Math.PI;
            var radius = hsv.s * this.colorWheelSize;
            var x = radius * Math.cos(angle);
            var y = radius * Math.sin(angle);
            this.objects.hueCursor.position.setX(x);
            this.objects.hueCursor.position.setZ(-y);

            console.log(`Update color brightness`)
            // Update color brightness
            this.objects.hueWheel.material.uniforms['brightness'].value = this.hsv.v;
            console.log(`this.hsv.v => ${this.hsv.v}`)
            this.objects.brightnessCursor.rotation.y = this.hsv.v * 1.5 - 1.5;
        },
        updateSizeSlider: function (percentage) {
            var slider = this.objects.sizeSlider;
            var sliderBoundingBox = slider.geometry.boundingBox;
            var cursor = this.objects.sizeCursor;
            var sliderWidth = sliderBoundingBox.max.x - sliderBoundingBox.min.x;
            var normalizedSize = percentage;
            this.el.emit('sizechanged', { size: percentage })
            this.system.setCursorSize(percentage);
            var positionX = normalizedSize * sliderWidth;
            cursor.position.setX(positionX - this.cursorOffset.x);

            var scale = normalizedSize + 0.3;
            cursor.scale.set(scale, 1, scale);
        },
        updateSelectedControlCursorColor: function () {
            for (let i = 0; i < this.focusableControllers.length; i++) {
                let cursor = this.focusableControllerCursors[i];
                if (typeof cursor === 'function') {
                    cursor(i === this.focusedControlIndex);
                }
            }
        },
        updateMaterialColor(parts, opacity) {
            parts.forEach((part) => {
                if (part.material.opacity !== opacity) {
                    part.material.opacity = opacity;
                    part.material.needsUpdate = true;
                }
            })

        },
        setCursorTransparency: function () {
            var hueCursor = this.objects.hueCursor;
            let hueWheel = this.objects.hueWheel
            var brightnessCursor = this.objects.brightnessCursor;
            var sizeCursor = this.objects.sizeCursor;
            sizeCursor.material.alphaTest = 0.5;
            hueCursor.material.alphaTest = 0.5;
            hueWheel.material.alphaTest = 0.5;
            brightnessCursor.material.alphaTest = 0.5;
            sizeCursor.material.transparent = true;
            hueCursor.material.transparent = true;
            hueWheel.material.transparent = true;
            brightnessCursor.material.transparent = true;
        },
        loadBrushes: (function () {
            var brushesMaterials = {};
            return function (page, pageSize) {
                var brush;
                var brushNum = 0;
                var uiEl = this.uiEl.getObject3D('mesh');
                var brushes = Object.keys(AFRAME.BRUSHES);
                var thumbnail;
                var brushIndex;
                var self = this;
                var i;
                if (page < 0 || page >= this.brushesPagesNum) { return; }
                if (page === 0) {
                    this.objects.previousPage.visible = false;
                } else {
                    this.objects.previousPage.visible = false;
                }
                if (page === this.brushesPagesNum - 1) {
                    this.objects.nextPage.visible = false;
                } else {
                    this.objects.nextPage.visible = false;
                }
                for (i = 0; i < pageSize; i++) {
                    brushIndex = page * pageSize + i;
                    brush = brushes[brushIndex];
                    thumbnail = brush && AFRAME.BRUSHES[brush].prototype.options.thumbnail;
                    loadBrush(brush, brushNum, thumbnail);
                    brushNum += 1;
                }
                function loadBrush(name, id, thumbnailUrl) {
                    var brushName = !name ? undefined : (name.charAt(0).toUpperCase() + name.slice(1)).toLowerCase();
                    if (thumbnailUrl && !brushesMaterials[brushName]) {
                        self.el.sceneEl.systems.material.loadTexture(thumbnailUrl, { src: thumbnailUrl }, onLoadThumbnail);
                        return;
                    }
                    onLoadThumbnail();
                    function onLoadThumbnail(texture?: any) {
                        var button = uiEl.getObjectByName('brush' + id);
                        self.brushButtonsMapping['brush' + id] = brushName;
                        setBrushThumbnail(texture, button);
                    }
                }
                function setBrushThumbnail(texture, button) {
                    var brushName = self.brushButtonsMapping[button.name];
                    var material = brushesMaterials[brushName] || new THREE.MeshBasicMaterial();
                    if (texture) {
                        material.map = texture;
                        material.alphaTest = 0.5;
                        material.transparent = true;
                    } else if (!brushesMaterials[brushName]) {
                        material.visible = false;
                    }
                    brushesMaterials[brushName] = material;
                    self.highlightMaterials[button.name] = {
                        normal: material,
                        hover: material,
                        pressed: material,
                        selected: material
                    };
                    button.material = material;
                }
            };
        })(),
        initHighlightMaterial: function (object) {
            var buttonName = object.name;
            var isBrushButton = this.brushRegexp.test(buttonName);
            var isHistory = buttonName.indexOf('history') !== -1;
            var isHue = buttonName === 'hue' || buttonName === 'huecursor';
            var materials = {
                normal: object.material,
                hover: object.material,
                pressed: object.material,
                selected: object.material
            };
            if (!isBrushButton && !isHistory && !isHue) {
                materials.normal = object.material;
                materials.hover = object.material.clone();
                materials.hover.map = this.system.hoverTexture;
                materials.selected = object.material.clone();
                materials.selected.map = this.system.pressedTexture;
                materials.pressed = object.material.clone();
                materials.pressed.map = this.system.pressedTexture;
            }
            this.highlightMaterials[buttonName] = materials;
        },
        initColorWheel: function () {
            var colorWheel = this.objects.hueWheel;

            var vertexShader = '\
        varying vec2 vUv;\
        void main() {\
          vUv = uv;\
          vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);\
          gl_Position = projectionMatrix * mvPosition;\
        }\
        ';

            var fragmentShader = '\
        #define M_PI2 6.28318530718\n \
        uniform float brightness;\
        varying vec2 vUv;\
        vec3 hsb2rgb(in vec3 c){\
            vec3 rgb = clamp(abs(mod(c.x * 6.0 + vec3(0.0, 4.0, 2.0), 6.0) - 3.0) - 1.0, \
                             0.0, \
                             1.0 );\
            rgb = rgb * rgb * (3.0 - 2.0 * rgb);\
            return c.z * mix( vec3(1.0), rgb, c.y);\
        }\
        \
        void main() {\
          vec2 toCenter = vec2(0.5) - vUv;\
          float angle = atan(toCenter.y, toCenter.x);\
          float radius = length(toCenter) * 2.0;\
          vec3 color = hsb2rgb(vec3((angle / M_PI2) + 0.5, radius, brightness));\
          gl_FragColor = vec4(color, 1.0);\
        }\
        ';

            var material = new THREE.ShaderMaterial({
                uniforms: { brightness: { type: 'f', value: this.hsv.v } },
                vertexShader: vertexShader,
                fragmentShader: fragmentShader
            });
            colorWheel.material = material;
        },

        initColorHistory: function () {
            var colorHistoryObject;
            var currentColor = this.objects.currentColor;
            for (var i = 0; i < this.objects.colorHistory.length; i++) {
                colorHistoryObject = this.objects.colorHistory[i];
                // colorHistoryObject.material = colorHistoryObject.material.clone();
                // colorHistoryObject.material.map = this.system.selectedTexture;
                colorHistoryObject.visible = false;
            }
            currentColor.material = currentColor.material.clone();
            currentColor.material.map = this.system.selectedTexture;
            currentColor.visible = false;
            this.updateColorHistory(null);
        },

        updateColorHistory: function (color) {
            var colorStack = this.colorStack;
            if (!color) { color = this.el.components.brush.schema.color.default; }
            this.objects.currentColor.material.color.set(color);
            this.objects.currentColor.visible = false;
            console.log(`colorStack: ${JSON.stringify(colorStack)}`)
            if (colorStack.length === 7) { colorStack.shift(); }

            console.log('emitting colorchanged')
            this.el.emit('colorchanged', { color })
            console.log('emitted colorchanged ' + color)
            colorStack.push(color);
            console.log(`colorStack: ${JSON.stringify(colorStack)}`)
            for (var i = 0; i < colorStack.length; i++) {
                color = colorStack[colorStack.length - i - 1];
                this.objects.colorHistory[i].material.color.set(color);
                this.objects.colorHistory[i].visible = false;
            }
            console.log('updated color history')
        },
        // updateIntersections: function () {
        //     if (!this.raycaster) { return; } // Exit if not pressed or not intersecting
        //     this.intersectedObjects = this.raycaster.intersectObjects(this.menuEls, true);
        // },
        // handleHover: function () {
        //     this.updateHoverObjects();
        //     this.updateMaterials();
        // },
        // updateHoverObjects: function () {
        //     var intersectedObjects = this.intersectedObjects;
        //     if (intersectedObjects) {
        //         intersectedObjects = intersectedObjects.filter(function (obj) {
        //             return obj.object.name !== 'bb' && obj.object.name !== 'msg_save';
        //         });
        //         this.hoveredOffObjects = this.hoveredOnObjects.filter(function (obj) {
        //             return intersectedObjects.indexOf(obj) === -1;
        //         });
        //         this.hoveredOnObjects = intersectedObjects;
        //     }
        // },
        // updateMaterials: (function () {
        //     var point = new THREE.Vector3();
        //     return function () {
        //         var self = this;
        //         var pressedObjects = this.pressedObjects;
        //         var unpressedObjects = this.unpressedObjects;
        //         var selectedObjects = this.selectedObjects;
        //         // Remove hover highlights
        //         this.hoveredOffObjects.forEach(function (obj) {
        //             var object = obj.object;
        //             object.material = self.highlightMaterials[object.name].normal;
        //         });
        //         // Add highlight to newly intersected objects
        //         this.hoveredOnObjects.forEach(function (obj) {
        //             var object = obj.object;
        //             point.copy(obj.point);
        //             if (!self.highlightMaterials[object.name]) {
        //                 self.initHighlightMaterial(object);
        //             }
        //             // Update ray
        //             self.handRayEl.object3D.worldToLocal(point);
        //             self.handRayEl.setAttribute('line', 'end', point);
        //             object.material = self.highlightMaterials[object.name].hover;
        //         });
        //         // Pressed Material
        //         Object.keys(pressedObjects).forEach(function (key) {
        //             var object = pressedObjects[key];
        //             var materials = self.highlightMaterials[object.name];
        //             object.material = materials.pressed || object.material;
        //         });
        //         // Unpressed Material
        //         Object.keys(unpressedObjects).forEach(function (key) {
        //             var object = unpressedObjects[key];
        //             var materials = self.highlightMaterials[object.name];
        //             object.material = materials.normal;
        //             delete unpressedObjects[key];
        //         });
        //         // Selected material
        //         Object.keys(selectedObjects).forEach(function (key) {
        //             var object = selectedObjects[key];
        //             var materials = self.highlightMaterials[object.name];
        //             if (!materials) { return; }
        //             object.material = materials.selected;
        //         });
        //     };
        // })(),
        // handlePressedButtons: function () {
        //     var self = this;
        //     if (!this.triggeredPressed) { return; }
        //     this.hoveredOnObjects.forEach(function triggerAction(button) {
        //         self.handleButtonDown(button.object, button.point);
        //     });
        // },

    });
}
