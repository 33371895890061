import React, { useCallback, useEffect, useRef, useState } from 'react';

interface Props {
    onImage: (canvas: HTMLCanvasElement) => void; // Define the type of the onImage prop
}

const ImageDropCanvas: React.FC<Props> = ({ onImage }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [imageSrc, setImageSrc] = useState<string>('');

    useEffect(() => {
        if (!imageSrc || !canvasRef.current) return;
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const img = new Image();
        img.onload = () => {
            const canvas = canvasRef.current;
            if (canvas) {
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                if (ctx) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
                    onImage(canvas); // Call the onImage function with the canvas
                }
            }
        };
        img.src = imageSrc;
    }, [imageSrc]);

    const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Prevent default behavior (Prevent file from being opened)
        const file = event.dataTransfer.files[0]; // Get the first file dropped
        if (file && file.type.startsWith('image/')) {
            if (file.type === 'image/svg+xml') {
                const reader = new FileReader();

                reader.onload = (event) => {
                    setImageSrc(event.target!.result as string);
                };

                reader.readAsDataURL(file);
                return;
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                const img = new Image();
                img.onload = () => {
                    const canvas = canvasRef.current;
                    if (canvas) {
                        canvas.width = img.width;
                        canvas.height = img.height;
                        const ctx = canvas.getContext('2d');
                        if (ctx) {
                            ctx.clearRect(0, 0, canvas.width, canvas.height);
                            ctx.drawImage(img, 0, 0);
                            onImage(canvas); // Call the onImage function with the canvas
                        }
                    }
                };
                img.src = e.target?.result as string;
            };
            reader.readAsDataURL(file);
        }
    }, [onImage]);

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault(); // Necessary to allow for drop
    }, []);

    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver} style={{ width: '100%', height: '100%', border: '2px dashed #ccc' }}>
            <canvas ref={canvasRef} style={{ maxWidth: '100%', maxHeight: '100%' }} />
            Drag and drop an image here
        </div>
    );
};

export default ImageDropCanvas;
