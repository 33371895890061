import { useEffect } from 'react';
import { useCustomEventListener } from './useCustomEventListener';

type FileReaderEventTarget = EventTarget & {
    result: string;
}

type FileReaderEvent = ProgressEvent<FileReader> & {
    target: FileReaderEventTarget;
}

export const useDragAndDrop = () => {
    useEffect(() => {
        const dropArea = document.body;

        const handleDragOver = (event: DragEvent) => {
            event.stopPropagation();
            event.preventDefault();
            if (event.dataTransfer) {
                event.dataTransfer.dropEffect = 'copy';
            }
        };

        const handleDrop = (event: DragEvent) => {
            event.stopPropagation();
            event.preventDefault();

            if (!event.dataTransfer) return;

            const files = event.dataTransfer.files;
            Array.from(files).forEach(file => {
                // Handle file processing here
                // Example: Process .json files
                if (file.name.endsWith('.json')) {
                    const reader = new FileReader();
                    reader.onload = (event: FileReaderEvent) => {
                        // Process JSON file
                        // Example: document.querySelector('a-scene').systems.brush.loadJSON(JSON.parse(event.target.result));
                    };
                    reader.readAsText(file);
                }

                // Handle other file types similarly...
            });
        };

        dropArea.addEventListener('dragover', handleDragOver, false);
        dropArea.addEventListener('drop', handleDrop, false);

        return () => {
            dropArea.removeEventListener('dragover', handleDragOver);
            dropArea.removeEventListener('drop', handleDrop);
        };
    }, []);
};


export const useMode = (args: {
    [key: string]: {
        mode: string,
        hidden: {
            scale?: string,
            position?: string,
            rotation?: string
        }
    }
}, evtName: string, modeKeyExtractionFunc: (detail: any) => any, defaultMode: string, loaded?: boolean) => {
    useCustomEventListener(evtName, (detail) => {
        let currentMode = modeKeyExtractionFunc(detail);
        processMode(currentMode)
    })
    function processMode(currentMode) {
        Object.entries(args).map(([key, val]) => {
            let scale = '1 1 1';
            let rotation = '0 0 0';
            let position = '0 0 0';
            if (currentMode !== val.mode) {
                if (val?.hidden?.scale) {
                    scale = val.hidden.scale;
                }
                else {
                    scale = '.01 .01 .01';
                }
                if (val?.hidden?.position) {
                    position = val.hidden.position;
                }
                else {
                    position = `-1 -10 0 `;
                }
                if (val?.hidden?.rotation) {
                    rotation = val.hidden.rotation;
                }
                else {
                    rotation = `0 -90 0 `;
                }
            }
            let els = document.querySelectorAll(`[${key}]`);
            if (els) {
                for (let i = 0; i < els.length; i++) {
                    let el = els[i];
                    el.setAttribute('animation__scale1', `property: scale; to:${scale}; dur:200; easing:easeInOutCubic;`);
                    el.setAttribute('animation__position1', `property: position; to:${position}; dur:200; easing:easeInOutCubic;`);
                    el.setAttribute('animation__rotation1', `property: rotation; to:${rotation}; dur:200; easing:easeInOutCubic;`);
                }
            }
        })
    }
    useEffect(() => {
        if (loaded) {
            if (defaultMode) {
                processMode(defaultMode)
            }
        }
    }, [loaded, defaultMode])
}