import bevelbox from "./bevelbox";
import button from "./button";
import circleLoader from "./circle-loader";
import circleTimer from "./circle-timer";
import cursor from "./cursor";
import flexContainer from "./flex-container";
import iconButton from './icon-button';
import iconLabelButton from "./icon-label-button";
import interactable from "./interactable";
import input from "./input";
import item from "./item";
import label from "./label";
import progressBar from "./progress-bar";
import radio from "./radio";
import rounded from './rounded';
import slider from "./slider";
import troikaTextComponent from "./troika-text-component";
import imageCube from "./image-cube";
import catalogImage from "../../painter/components/catalog-image";
import baseInteractive from "./base-interactive";
import menuContainer from "./menu-container";
import transformer from "./transformer";
import modelItem from "./model-item";
import canvasLayer from "./canvas-layer";
import canvasLayers from "../../painter/canvas-layers";
import flashRed from "../../painter/components/flash-red";
import canvasImage from "../../painter/components/canvas-image";
import radioComponent from "./radio-component";
import dualArcs from "./dual-arcs";
import hoodie from "../../painter/components/clothes/hoodie";
import skeletonBody from "./skeleton-body";
import modelloraconfiguration from './model-lora-configuration';

export default function () {
    dualArcs();
    hoodie();
    modelloraconfiguration();
    skeletonBody();
    transformer();
    radioComponent();
    if (false)
        canvasImage();
    canvasLayers();
    canvasLayer();
    modelItem();
    if (false)
        troikaTextComponent();
    if (false)
        menuContainer();
    if (false)
        baseInteractive();
    if (false)
        imageCube();
    catalogImage();
    if (false)
        bevelbox();
    button();
    if (false)
        circleLoader();
    if (false)
        circleTimer();
    if (false)
        cursor();
    flexContainer();
    if (false)
        iconButton();
    if (false)
        iconLabelButton();
    if (false)
        interactable();
    if (false)
        input();
    if (false)
        item();
    if (false)
        label();
    if (false)
        progressBar();
    radio();
    if (false)
        rounded();
    if (false)
        slider();
    flashRed();
}
