import { AFRAME } from "../../painter/root";
import { key_grey, key_grey_dark, key_offwhite, key_orange, key_white } from "../vars";
const THREE: any = (window as any).THREE;

export default function () {

    AFRAME.registerComponent('gui-input', {
        schema: {
            align: { type: 'string', default: 'left' },
            on: { default: 'click' },
            value: { type: 'string', default: '' },
            toggle: { type: 'boolean', default: false },
            toggleState: { type: 'boolean', default: false },
            fontSize: { type: 'number', default: 0.2 },
            fontFamily: { type: 'string', default: '' },
            fontColor: { type: 'string', default: key_grey_dark },
            borderColor: { type: 'string', default: key_grey_dark },
            borderHoverColor: { type: 'string', default: key_grey },
            backgroundColor: { type: 'string', default: key_offwhite },
            hoverColor: { type: 'string', default: key_white },
            activeColor: { type: 'string', default: key_orange },
        },
        init: function () {

            let data: any = this.data;
            var el = this.el;
            var guiItem = el.getAttribute("gui-item");
            this.guiItem = guiItem;
            var toggleState = this.toggleState = data.toggle;
            var guiInteractable = el.getAttribute("gui-interactable");
            this.guiInteractable = guiInteractable;

            //fallback for old font-sizing
            if (data.fontSize > 20) { // 150/750
                var newSize = data.fontSize / 750;
                data.fontSize = newSize;
            }

            el.setAttribute('geometry', `primitive: plane; height: ${guiItem.height}; width: ${guiItem.width};`);
            el.setAttribute('material', `shader: flat; transparent: false; side:front; color:${data.backgroundColor};`);

            let borderTopEntity: any = document.createElement("a-entity");
            borderTopEntity.setAttribute('geometry', `primitive: box; width: ${(guiItem.width)}; height: 0.05; depth: 0.02;`);
            borderTopEntity.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${data.borderColor}`);
            borderTopEntity.setAttribute('position', `0 -${(guiItem.height / 2) - 0.025} 0.01`);
            el.appendChild(borderTopEntity);
            var borderBottomEntity: any = document.createElement("a-entity");
            borderBottomEntity.setAttribute('geometry', `primitive: box; width: ${(guiItem.width)}; height: 0.05; depth: 0.02;`);
            borderBottomEntity.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${data.borderColor}`);
            borderBottomEntity.setAttribute('position', `0 ${(guiItem.height / 2) - 0.025} 0.01`);
            el.appendChild(borderBottomEntity);
            var borderLeftEntity: any = document.createElement("a-entity");
            borderLeftEntity.setAttribute('geometry', `primitive: box; width: 0.05; height: ${(guiItem.height)}; depth: 0.02;`);
            borderLeftEntity.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${data.borderColor}`);
            borderLeftEntity.setAttribute('position', `-${(guiItem.width / 2) - 0.025} 0 0.01`);
            el.appendChild(borderLeftEntity);
            var borderRightEntity: any = document.createElement("a-entity");
            borderRightEntity.setAttribute('geometry', `primitive: box; width: 0.05; height: ${(guiItem.height)}; depth: 0.02;`);
            borderRightEntity.setAttribute('material', `shader: flat; opacity: 1; side:double; color: ${data.borderColor}`);
            borderRightEntity.setAttribute('position', `${(guiItem.width / 2) - 0.025} 0 0.01`);
            el.appendChild(borderRightEntity);

            this.setText(data.value);

            ////WAI ARIA Support
            el.setAttribute('role', 'input');

            el.addEventListener('mouseenter', function (evt) {
                el.setAttribute('material', 'color', data.hoverColor);
                borderTopEntity.setAttribute('material', 'color', data.borderHoverColor);
                borderBottomEntity.setAttribute('material', 'color', data.borderHoverColor);
                borderLeftEntity.setAttribute('material', 'color', data.borderHoverColor);
                borderRightEntity.setAttribute('material', 'color', data.borderHoverColor);
            });

            el.addEventListener('mouseleave', function (evt) {
                el.setAttribute('material', 'color', data.backgroundColor);
                borderTopEntity.setAttribute('material', 'color', data.borderColor);
                borderBottomEntity.setAttribute('material', 'color', data.borderColor);
                borderLeftEntity.setAttribute('material', 'color', data.borderColor);
                borderRightEntity.setAttribute('material', 'color', data.borderColor);
            });

            el.addEventListener(data.on, function (evt) {
                console.log('I was clicked at: ', evt.detail.intersection.point);
                var guiInteractable = el.getAttribute("gui-interactable");
                console.log("guiInteractable: " + guiInteractable);
                var clickActionFunctionName = guiInteractable.clickAction;
                console.log("clickActionFunctionName: " + clickActionFunctionName);
                // find object
                var clickActionFunction: any = window[clickActionFunctionName];
                //console.log("clickActionFunction: "+clickActionFunction);
                // is object a function?
                if (typeof clickActionFunction === "function") clickActionFunction(evt);
            });


        },
        setText: function (newText) {
            var textEntityX = this.guiItem.height * 0.25 - this.guiItem.width * 0.5;
            var textEntity = document.createElement("a-entity");
            this.textEntity = textEntity;
            textEntity.setAttribute('troika-text', `value: ${newText}; 
                                                align:left; 
                                                anchor:left; 
                                                baseline:center;
                                                letterSpacing:0;
                                                color:${this.data.fontColor};
                                                font:${this.data.fontFamily};
                                                fontSize:${this.data.fontSize};
                                                depthOffset:1;
                                                maxWidth:${this.guiItem.width / 1.05};
                                                `);
            textEntity.setAttribute('position', `${textEntityX} 0 0.05`);

            //        textEntity.setAttribute('troika-text-material', `shader: flat;`);
            this.el.appendChild(textEntity);
        },
        play: function () {

        },
        update: function (oldData) {
            var data = this.data;
            var el = this.el;
            this.textEntity.setAttribute('troika-text', `value: ${data.value};`);
        },
        appendText(text) {
            var newText = this.data.value + text;
            this.el.setAttribute('gui-input', 'text', newText);
        },
        delete() {
            if (this.data.value && this.data.value.length > 0) {
                var newText = this.data.value.slice(0, -1);
                this.el.setAttribute('gui-input', 'text', newText);
            }
        }
    });

    AFRAME.registerPrimitive('a-gui-input', {
        defaultComponents: {
            'gui-interactable': {},
            'gui-item': { type: 'input' },
            'gui-input': {}
        },
        mappings: {
            //gui interactable general
            'onclick': 'gui-interactable.clickAction',
            'onhover': 'gui-interactable.hoverAction',
            //gui item general
            'width': 'gui-item.width',
            'height': 'gui-item.height',
            'margin': 'gui-item.margin',
            //gui input specific
            'value': 'gui-input.value',
            'font-size': 'gui-input.fontSize',
            'font-family': 'gui-input.fontFamily',
            'font-color': 'gui-input.fontColor',
            'background-color': 'gui-input.backgroundColor',
            'hover-color': 'gui-input.hoverColor',
            'border-color': 'gui-input.borderColor',
            'border-hover-color': 'gui-input.borderHoverColor',
        }
    });
}