import { useEffect, useRef, useState } from "react";
import { LoraMenuRadio } from "./LoraMenuPanel";
import { DrawMode, getUISystem } from "./painter/systems/ui";
import useCustomEventListenerAsync from "./painter/useCustomEventListener";
import { PAINTER_CONSTANTS } from "./painter/constants";

export default function BigImageMenu() {
    const menuContainerRef = useRef(null);
    const downloadButtonRef = useRef(null);
    const [currentImageUrl, setCurrentImageUrl] = useState(null);
    const [drawMode, setDrawMode] = useState(getUISystem()?.getDrawingMode());
    useCustomEventListenerAsync(PAINTER_CONSTANTS.CANVAS_IMAGE_CHANGED, async (detail: { url: string }) => {
        setCurrentImageUrl(detail.url || '')
    });

    useEffect(() => {
        if (menuContainerRef?.current) {
            let handler = (evt) => {
                console.log(evt);
                if (evt.detail) {
                    if (menuContainerRef.current) {
                        menuContainerRef.current.setAttribute('open', !evt.detail.open)
                    }
                }
            };
            menuContainerRef.current.addEventListener('open-changed', handler);

            return () => {
                if (menuContainerRef.current) {
                    menuContainerRef.current.removeEventListener('open-changed', handler);
                }
            }
        }
    }, [menuContainerRef?.current]);
    useEffect(() => {
        if (downloadButtonRef?.current) {
            let handler = (evt) => {
                if (currentImageUrl) {
                    downloadImage(currentImageUrl, 'image.png');
                }
            }
            downloadButtonRef?.current.addEventListener('click', handler);
            return () => {
                if (downloadButtonRef.current) {
                    downloadButtonRef.current.removeEventListener('click', handler);
                }
            }
        }
    }, [downloadButtonRef?.current, currentImageUrl])
    function downloadImage(imageUrl: string, fileName: string): void {
        // Create a new anchor element
        const anchor = document.createElement('a');

        // Use fetch to get the image as a Blob if you're dealing with cross-origin images
        fetch(imageUrl)
            .then(response => response.blob()) // Convert the response to a blob
            .then(blob => {
                // Create a local URL from the blob
                const blobUrl = window.URL.createObjectURL(blob);
                anchor.href = blobUrl;
                anchor.download = fileName || 'downloaded-image';

                // Append the anchor to the body (required for Firefox)
                document.body.appendChild(anchor);

                // Trigger the download
                anchor.click();

                // Clean up by revoking the Object URL and removing the anchor from the body
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(anchor);
            })
            .catch(error => console.error('Error downloading the image:', error));
    }

    if (!currentImageUrl) {
        return <></>;
    }
    return (
        <frame-menu-container
            ref={menuContainerRef}
            menu-direction={'down'}
            flex-direction="column"
            justify-content="flexStart"
            align-items="flexStart"
            width="1"
            component-padding="0.01"
            text-value={'Menu'}
            menu-item-height={.2}
            menu-item-width={1.0}
            position="0 0 0" rotation="0 0 0"
        >
            <frame-base-interactive ref={downloadButtonRef} width="1" interactive-type="button" height=".2" value="Download" font-size=".07"></frame-base-interactive>
        </frame-menu-container>
    )
}