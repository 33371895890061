import { useContext, useEffect, useRef, useState } from 'react';
import 'aframe';
import { APainterContext, RenderTarget } from './APainterContext';
import useCustomEventListenerAsync from './painter/useCustomEventListener';
import {
    deleteFileFromFirebaseStorage, deleteFromFirebaseRTDB,
    fetchAndUploadImage, fetchDownloadUrl, formatDate, readFromDatabase, uploadCanvas, watchForChildUpdates, writeToDatabase
} from './firebase-app';
import { raiseCustomEvent, uuidv4 } from './painter/util';
import { PAINTER_CONSTANTS } from './painter/constants';
import { DrawMode, GetNegativeColor, GetPositiveColor, getUISystem } from './painter/systems/ui';
import useEventListener from './painter/useEventListener';
import LoraMenuPanel from './LoraMenuPanel';
import { useListLora } from './useListModels';
import useStableDiffusionPipeline from './useStableDiffusionPipeline';
import { UserProperties } from './interface';
import { DeviceTypes } from './painter/useVrAnalytics';
import { GrabAndDropEvents } from './painter/systems/grabanddrop';
import ModelSelectionPanel from './ModelSelectionPanel';
import { useEventsListenerOn } from 'a-frame-components';
import SpaceMenuComponent from './SpaceMenuComponent';
import DrawMenuComponent from './DrawMenuComponent';
import ImagePanels from './ImagePanels';
import BigImageMenu from './BigImageMenu';
import ColorBrightnessComponent from './ColorBrightnessComponent';
import BrushSizeComponent from './BrushSizeComponent';
import SchedulerMenu from './SchedulerMenu';
import ModelFilterMenu from './ModelFilterMenu';
import RatioMenu from './RatioMenu';
import MaskModeMenuComponent from './MaskModeMenuComponent';
import ModelLoraConfigurationComponent from './ModelLoraConfigurationComponent';
import TextLayerComponent from './TextLayerComponent';
import HoodieComponent from './HoodieComponent';
import { useMode } from './painter/useDragAndDrop';
import { ImageSpaces } from './constants';

export default function AFrameComponent() {
    const context = useContext(APainterContext);
    const loraOptions = context.loraOptions;
    const painterLoaded = context?.painterLoaded;
    const listModels = context.supportedModels;
    const supportedLoras = useListLora(context.useStabileDiffusion);
    const modellistRef = useRef(null);
    const [myImageSpaceMenu, setMyImageSpaceMenu] = useState(ImageSpaces.AllMyImages);
    const [transformModeOn, setTransformModeOn] = useState(false);
    const myImageRef = useRef(null);
    const loralistRef = useRef(null);
    const searchImageSelectionRef = useRef(null);
    const painterCanvasRef = useRef(null);
    const saveImageRef = useRef(null);
    const [modelComponentUpdated, setModelComponentUpdated] = useState(0);
    useCustomEventListenerAsync(PAINTER_CONSTANTS.MODEL_COMPONENT_LOADED, async () => {
        setModelComponentUpdated(Date.now());
    })


    const stableDiffusionPipeline = useStableDiffusionPipeline(async (imageUrl: string, setup: string) => {
        let fileName = `layer_stable_${uuidv4()}.png`;
        let filePath = `stable_renders/${context?.userInfo?.uid}/${formatDate(Date.now())}/${fileName}`;
        let url = await fetchAndUploadImage(imageUrl, filePath);
        url = await fetchDownloadUrl(url);
        lastImageUrl.current = (url);
        raiseCustomEvent(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, {
            data: { imageUrl: url, setup, save: true },
        });
    });
    const loraSelectionPanelRef = useRef(null);
    const modelSelectionPanelRef = useRef(null);
    const promptListComponentRef = useRef(null);
    const loraMenuPanelRef = useRef(null);
    const [hasDrawn, setHasDrawn] = useState(false);
    const [myImages, setMyImages] = useState({});
    const [searchedImages, setSearchedImages] = useState({});
    const lastImageUrl = useRef(null);
    const [targetLora, setTargetLora] = useState<{ lora: number, layer: string } | null>(null);
    useEventListener(loralistRef, 'lora-selection', (evt) => {
        console.log(evt)
        context.setLayerLoraStyle({
            ...targetLora,
            loraUrl: evt.detail.url
        })
    });
    const HOODIE = 'hoodie-els';
    const PAINTING_SET = 'painting-set';
    const MODELS_SET = 'models-els';
    const renderBusy = (stableDiffusionPipeline?.busy && !!Object.values(stableDiffusionPipeline?.busy).length && !Object.values(stableDiffusionPipeline?.busy).some(v => v));
    useMode({
        [PAINTING_SET]: {
            mode: 'painting',
            hidden: {
                scale: '.0001 .0001 .0001',
                position: `0 0 -10`
            }
        },
        [HOODIE]: {
            mode: 'hoodie',
            hidden: {
                scale: '.0001 .0001 .0001',
                position: `0 0 -10`
            }
        },
        [MODELS_SET]: {
            mode: 'models_and_loras',
            hidden: {
                scale: '.0001 .0001 .0001',
                position: `0 0 -10`
            }
        }
    }, PAINTER_CONSTANTS.APPLICATION_MODE, (detail) => detail?.mode, 'painting', painterLoaded);

    let modeRadio = useEventsListenerOn({
        'change': (evt: any, el) => {
            raiseCustomEvent(PAINTER_CONSTANTS.APPLICATION_MODE, { mode: evt.detail.value })
        }
    })
    useCustomEventListenerAsync(PAINTER_CONSTANTS.DRAWING, async () => {
        setHasDrawn(true);
    })

    useCustomEventListenerAsync(PAINTER_CONSTANTS.SELECT_LAYER, async (detail: { id: string }) => {
        context.setSelectedLayer(detail.id);
    });

    useCustomEventListenerAsync(PAINTER_CONSTANTS.DRAWING_MODE_CHANGED, async (detail: any) => {
        const { mode } = detail;
        switch (mode) {
            case DrawMode.Transform:
                setTransformModeOn(true);
                break;
            default:
                setTransformModeOn(false);
                break;
        }
    })
    useEffect(() => {
        context.setImageSpace(myImageSpaceMenu)
    }, [myImageSpaceMenu])
    // useEventListener(searchImageSelectionRef, PAINTER_CONSTANTS.MY_IMAGE_SELECTION, (evt) => {
    //     console.log(evt);
    //     if (myImageRef.current) {
    //         myImageRef.current.setAttribute('url', `${evt.detail.url}`)
    //     }
    // })
    useEffect(() => {
        if (myImageRef.current) {
            let handler = (evt) => {
                const { data, category } = evt.detail;
                switch (category) {
                    case 'image-catalog':
                        if (data?.url) {
                            myImageRef.current.setAttribute('url', `${evt?.detail?.data?.url || evt.detail.url}`)
                        }
                        break;
                }
            };
            myImageRef.current.addEventListener(GrabAndDropEvents.DROPPED_GRABBED_DATA, handler);
        }
    }, [myImageRef.current])

    const panelPositions = {
        loraSelection: {
            rotation: '0 0 0',
            show: '0 .5 -1.9',
            hide: '-1.4 -4.34 .3'
        }
    }
    const hidePanels = () => {
        [loraSelectionPanelRef, loraMenuPanelRef, modelSelectionPanelRef].map(ref => {
            if (ref.current)
                ref.current.setAttribute('animation', {
                    property: 'position',
                    to: panelPositions.loraSelection.hide,
                    dur: 500,
                    easing: 'easeOutQuad',
                    loop: false
                })
        });
    }
    const closeLoraSelection = (evt) => {
        console.log(evt)
        hidePanels();
        // showPanel(loraMenuPanelRef);
    }
    useEventListener(loralistRef, PAINTER_CONSTANTS.CLOSE_LORA_SELECTION, closeLoraSelection);
    useEventListener(painterCanvasRef, PAINTER_CONSTANTS.CLOSE_LORA_SELECTION, closeLoraSelection);
    useCustomEventListenerAsync(PAINTER_CONSTANTS.CLOSE_MODEL_SELECTION, async (evt) => {
        hidePanels();
    })
    useCustomEventListenerAsync(PAINTER_CONSTANTS.CLOSE_LORA_MENU, async (evt) => {
        hidePanels();
    })
    useCustomEventListenerAsync(PAINTER_CONSTANTS.COLOR_CHANGE, async (evt) => {
    })
    useCustomEventListenerAsync(PAINTER_CONSTANTS.OPEN_LORA_SELECTION, async (evt: any) => {
        console.log(evt)
        // setTargetLora(evt.detail);
        setTargetLora(evt);
        hidePanels();
        showPanel(loraSelectionPanelRef);
    });
    useCustomEventListenerAsync(PAINTER_CONSTANTS.OPEN_MODEL_MENU, async (evt: any) => {
        hidePanels();
        // showPanel(modelSelectionPanelRef);
    })
    useCustomEventListenerAsync(PAINTER_CONSTANTS.MODEL_SELECTION, async (evt: any) => {
        context.setModel(evt.text)
    })

    useCustomEventListenerAsync(PAINTER_CONSTANTS.OPEN_LORA_MENU, async (evt: any) => {
        console.log(evt)
        setTargetLora(evt);
        hidePanels();
        showPanel(loraMenuPanelRef);
    });

    useEffect(() => {
        if (promptListComponentRef?.current && context?.layerPrompts?.prompts) {
            promptListComponentRef.current.setAttribute('prompts', JSON.stringify(context?.layerPrompts?.prompts || []))
        }
    }, [promptListComponentRef?.current, context?.layerPrompts?.prompts, context?.layerPrompts?.prompts?.length]);

    function showPanel(ref: any) {
        ref.current.setAttribute('animation', {
            property: 'position',
            to: panelPositions.loraSelection.show,
            dur: 500,
            easing: 'easeOutQuad',
            loop: false
        });
    }
    useCustomEventListenerAsync(PAINTER_CONSTANTS.REMOVE_PROMPT, async (evt: any) => {
        context.removePrompt(evt.id)
    });
    useCustomEventListenerAsync(PAINTER_CONSTANTS.CLEAR_LORA_STYLE, async (evt: any) => {
        context.clearLoraStyle(evt.id)
    });

    useCustomEventListenerAsync<{ canvas: any, layerId: string, layer: string }>(PAINTER_CONSTANTS.LAYER_UPDATED, async (detail) => {
        console.log('layer updated')
        if (context?.userInfo?.uid && context?.layerPrompts?.model && hasDrawn) {
            let fileName = `layer_${detail.layerId}_${uuidv4()}.png`;
            let filePath = `layers/${context?.userInfo?.uid}/${fileName}`;
            let url = await uploadCanvas(detail.canvas, filePath, fileName);
            console.log(url)
            await writeToDatabase(`layers/${context?.userInfo?.uid}/currentSession`, {
                id: context.paintSessionId,
            })
            for (let i = 0; i < context.modelLoraSetups.length; i++) {
                let modelLoraSetup = context.modelLoraSetups[i];
                let layerPrompts = modelLoraSetup.layerPrompts

                let default_negative = 'low quality  low res  blurry  normal quality worst quality  text  watermark  banner  logo  extra digits  cropped  jpeg artifacts  disgusting  mutation  geometry   monochrome  duplicate  sketch   error  username  signature  three hands  three legs  bad arms  missing legs  missing arms  poorly drawn face  bad face  fused face bad anatomy  cloned face  worst face  missing fingers ';
                let prompt = `${layerPrompts.prompt || ''}, ${(layerPrompts.prompts || []).filter(x => x.positive).map(t => t.prompt).join()}`;
                let negative_prompt = `${layerPrompts.negativePrompt || default_negative}`;
                let layerStack = [];
                for (let i in layerPrompts) {
                    console.log('i ' + i)
                    if (!isNaN(parseInt(i))) {
                        prompt += ` ${layerPrompts?.[i]?.positive || ''}`;
                        if (layerPrompts[i].negative)
                            negative_prompt += ` ${layerPrompts[i].negative}`
                    }
                }
                let stack_files = [];
                new Array(10).fill(0).map((t, i) => `lora${i}`).map((loraId, index) => {
                    if (layerPrompts?.[loraId]) {
                        let supportedLora = supportedLoras.find(x => x.name === layerPrompts[loraId].name);
                        if (supportedLora) {
                            let { trainedWords, file_name } = supportedLora;
                            if (file_name) {
                                stack_files.push({ name: file_name, index: index });
                                if (layerPrompts?.[loraId]?.strength) {
                                    stack_files[stack_files.length - 1].strength = layerPrompts?.[loraId]?.strength;
                                    prompt += `<lora:${file_name}:${layerPrompts?.[loraId]?.strength}>`;
                                }
                                else if (loraOptions[loraId]?.strength) {
                                    stack_files[stack_files.length - 1].strength = loraOptions[loraId]?.strength;
                                    prompt += `<lora:${file_name}:${loraOptions[loraId]?.strength}>`;
                                }
                            }
                            if (loraOptions[loraId]?.words) {
                                for (let word in loraOptions[loraId]?.words) {
                                    if (loraOptions[loraId]?.words?.[word]) {
                                        prompt += ` ${word} `
                                    }
                                }
                            }
                            if (trainedWords) {
                                trainedWords.map(word => {
                                    prompt += `<lora:${word}:${loraOptions[loraId]}>`
                                })
                            }
                        }
                    }
                });
                if (stack_files?.length) {
                    layerStack.push({
                        layer: 1,
                        loras: stack_files
                    });
                }
                if (context.renderTarget === RenderTarget.StableDiffusionApi) {
                    if (context.useStabileDiffusion && context.webhooks.some(hr => {
                        return window.location.href.startsWith(hr);
                    })) {

                        try {
                            stableDiffusionPipeline.addRequest({
                                bearerToken: context?.userInfo?.token,
                                setupid: modelLoraSetup.id,
                                params: {
                                    model_id: layerPrompts.model,
                                    prompt: prompt,
                                    negative_prompt: negative_prompt,
                                    init_image: url,
                                    width: `${context.canvasSize.width}`,
                                    height: `${context.canvasSize.height}`,
                                    "samples": "1",
                                    "num_inference_steps": "30",
                                    "safety_checker": "no",
                                    "enhance_prompt": "yes",
                                    "guidance_scale": 7.5,
                                    "strength": modelLoraSetup?.strength || context.strength || 0.7,
                                    "scheduler": context.scheduler || "UniPCMultistepScheduler",
                                    "seed": null,
                                    "lora_model": `${stack_files.map(t => t.name).join()}`,
                                    "tomesd": "yes",
                                    "use_karras_sigmas": "yes",
                                    "vae": null,
                                    "lora_strength": `${stack_files.map(t => t.strength || `.5`).join()}`,
                                    "embeddings_model": null,
                                    "webhook": `${context.webhook}/api/webhook/${context.userInfo.uid}/${uuidv4()}`,
                                    "track_id": null
                                }
                            })
                        } catch (e) {
                        }
                    }
                }
            }

            if (context.renderTarget === RenderTarget.LocalCivitai) {
                await writeToDatabase(`civitai/${context?.userInfo?.uid}/draw_images`, {
                    url,
                    path: filePath,
                    stamp: Date.now()
                })
            }
            await writeToDatabase(`drawings/${context?.userInfo?.uid}/drawn_images/${uuidv4()}`, {
                url,
                path: filePath,
                stamp: Date.now()
            })
        }
    });

    useEffect(() => {
        if (loralistRef?.current) {
            if (supportedLoras) {
                loralistRef?.current.setAttribute('options', JSON.stringify(
                    supportedLoras.map((d, index) => {
                        return ({
                            id: index,
                            value: index,
                            text: d.name,
                            url: d.image
                        })
                    })
                ));
            }
        }
    }, [supportedLoras?.length, loralistRef?.current]);


    useEffect(() => {
        if (searchImageSelectionRef?.current) {
            let images = Object.keys(searchedImages).map((key, index) => {
                if (searchedImages?.[key]?.url) {
                    return {
                        id: index,
                        value: index,
                        text: key,
                        url: searchedImages[key]?.url
                    }
                }
            }).filter(x => x);
            if (images?.length) {
                searchImageSelectionRef?.current.setAttribute('options', JSON.stringify(images));
            }
        }
    }, [searchImageSelectionRef?.current, searchedImages])

    useEffect(() => {
        if (modellistRef?.current) {
            if (listModels) {
                modellistRef?.current.setAttribute('options', JSON.stringify(
                    listModels
                ));
            }
        }
        if (context?.layerPrompts) {
            if (modelComponentUpdated) {
                if (!context?.layerPrompts?.model && context.useStabileDiffusion) {
                    context.setModel('arcane-diffusion')
                }
            }
        }
    }, [
        listModels?.length,
        modellistRef?.current,
        context.useStabileDiffusion,
        modelComponentUpdated
    ]);

    useCustomEventListenerAsync('on-save-image', async () => {
        if (context?.painterCanvasComponent?.compositeResultCanvas) {
            let fileName = `layer_stable_${uuidv4()}.png`;
            let filePath = `stable_renders/${context?.userInfo?.uid}/${formatDate(Date.now())}/${fileName}`;
            let url = await uploadCanvas(context?.painterCanvasComponent?.compositeResultCanvas, filePath, fileName);
        }
    });

    useEffect(() => {
        let handler = () => {
            if (saveImageRef.current)
                saveImageRef.current.emit('on-save-image', {})
        };
        if (saveImageRef.current) {
            saveImageRef.current.addEventListener('click', handler)
        }
        return () => {
            if (saveImageRef?.current) {
                saveImageRef.current.removeEventListener('click', handler)
            }
        }
    }, [saveImageRef.current]);

    useEffect(() => {
        (window as any).testToggleAction = function () {
            console.log('test toggle action')
        };
        (window as any).listItemClicked = function () {
            console.log('listItemClicked action')
        };

        (window as any).testButtonAction = function () {
            console.log('test button action')
        };
        (window as any).testSliderAction = function () {
            console.log('test slider action')
        };
        (window as any).testInputAction = function () {
            console.log('test input action')
        };
        (window as any).onPromptPositive = function () {
            raiseCustomEvent(PAINTER_CONSTANTS.FOCUS_INPUT, { element: 'prompt-input-area' })
            setTimeout(() => {
                let promptInputArea: any = document.querySelector('#prompt-input-area')
                promptInputArea.focus();
            }, 400)
        };
        (window as any).onPromptNegative = function () {
            raiseCustomEvent(PAINTER_CONSTANTS.FOCUS_INPUT, { element: 'negative-prompt-input-area' })
            setTimeout(() => {
                let promptInputArea: any = document.querySelector('#negative-prompt-input-area')
                promptInputArea.focus();
            }, 400)
        };
        (window as any).onSaveImage = function () {
        };


        (window as any).setLayerCount = function (layers) {
            context.setLayerIds(layers);
        };

        (window as any).addLayer = function () {
            raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILD, {});
        };
        (window as any).selectLayer = function (evt, t) {
            let arg = evt ? evt.target.getAttribute('args') : t;
            raiseCustomEvent(PAINTER_CONSTANTS.SELECT_LAYER, { id: arg });
            context.setSelectedLayer(arg);
        };
        (window as any).clearLayer = function (evt) {
            let arg = evt.target.getAttribute('args')
            raiseCustomEvent(PAINTER_CONSTANTS.CLEAR_LAYER, { id: arg });
        };
        (window as any).colorArrayClick = function (evt, t) {
            let arg = evt ? evt.target.getAttribute('args') : t;
            console.log(arg);
            getUISystem().setCurrentColorState(arg)
        };
        (window as any).setCursorSize = function (evt, t) {
            let arg = evt ? evt.target.getAttribute('args') : t;
            console.log(arg);
            getUISystem().setCursorSize(arg)
        };

        const cameraEl = document.getElementById('acamera')
        cameraEl.setAttribute('position', '-1 1.6 5');
    }, [])

    useCustomEventListenerAsync(PAINTER_CONSTANTS.COLOR_SELECT, async (evt: any) => {
        getUISystem().setCurrentColorState(evt.color)
    })

    useEffect(() => {
        let keyDownHandler = null;
        let keyUpHandler = null;
        let mouseDownHandler = null;
        let mouseUpHandler = null;
        if (context.deviceType == DeviceTypes.OtherDevice) {
            keyDownHandler = (evt) => {
                if (evt.ctrlKey || evt.metaKey) { // Check for Ctrl or Command key
                    getUISystem().setButtonState("mouseCursor", 'trigger', 'down')
                }
            }
            keyUpHandler = (evt) => {
                if (!evt.ctrlKey && !evt.metaKey) { // Check if neither Ctrl nor Command key is pressed
                    getUISystem().setButtonState("mouseCursor", 'trigger', 'up')
                }
            }
            document.body.addEventListener('keydown', keyDownHandler);
            document.body.addEventListener('keyup', keyUpHandler);
        }
        return () => {
            if (keyDownHandler) {
                document.body.removeEventListener('keydown', keyDownHandler);
            }
            if (keyUpHandler) {
                document.body.removeEventListener('keyup', keyUpHandler);
            }
            if (mouseUpHandler) {
                document.body.removeEventListener('keydown', mouseUpHandler);
            }
            if (mouseDownHandler) {
                document.body.removeEventListener('keyup', mouseDownHandler);
            }
        }
    }, [context.deviceType])
    useCustomEventListenerAsync('my-images-update', (async (evt: any) => {
        let updates = {}
        for (let k in evt) {
            if (evt[k]?.url) {
                let url = evt[k]?.url;// await fetchDownloadUrl(evt[k]?.url)
                updates[k] = { url };
            }
        }
        setMyImages({
            ...myImages,
            ...updates
        })
    }));
    const [myDrawings, setMyDrawings] = useState([]);
    useEffect(() => {
        if (context?.userInfo?.uid) {
            console.log('watch for child updates')
            watchForChildUpdates(`renders/${context?.userInfo?.uid}`, (eventType, key, data) => {
                if (data?.sessionId === context.paintSessionId) {
                    console.log(`Event: ${eventType}, Key: ${key}, Data:`, data);
                    console.log(`${JSON.stringify(data)}`)
                    const { imageUrl } = data;
                    lastImageUrl.current = (imageUrl);
                    raiseCustomEvent(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, {
                        sessionId: context.paintSessionId,
                        data,
                    });
                }
            });

            watchForChildUpdates(`civitai/${context?.userInfo?.uid}/draw_images`, (eventType, key, data) => {
                raiseCustomEvent(PAINTER_CONSTANTS.CIVITAI_DRAW_IMAGES, { eventType, key, data })

            })

            watchForChildUpdates(`civitai/${context?.userInfo?.uid}/render_targets`, (eventType, key, data) => {
                raiseCustomEvent(PAINTER_CONSTANTS.CIVITAI_RENDER_TARGETS, { eventType, key, data })
            })
            watchForChildUpdates(`civitai/${context?.userInfo?.uid}/latest`, (eventType, key, data) => {
                if (key === 'url') {
                    raiseCustomEvent(PAINTER_CONSTANTS.LAYER_RESULT_UPDATED, {
                        data: { imageUrl: data, save: true },
                    });
                }
            })
            watchForChildUpdates(`civitai/${context?.userInfo?.uid}`, (eventType, key, data) => {
                let machine = data?.machine;
                let machineIdentifier = context.machineIdentifier;
                if (machine && machineIdentifier) {
                    if (machine !== machineIdentifier) {
                        context.setCivitaiSetups(JSON.parse(data?.setups));
                        raiseCustomEvent(PAINTER_CONSTANTS.UPDATED_FROM_OTHER_MATCHINES, {})
                    }
                }
            })

            readFromDatabase(`myimages/${context?.userInfo?.uid}/images`).then((res) => {
                raiseCustomEvent('my-images-update', res)
            })

            watchForChildUpdates(`myimages/${context?.userInfo?.uid}/images`, (eventType, key, data) => {
                raiseCustomEvent('my-images-update', { [key]: data })
            });

            readFromDatabase(`drawings/${context?.userInfo?.uid}/drawn_images`).then((res) => {
                console.log(res);
                if (res) {
                    setMyDrawings([...myDrawings, ...Object.entries(res).map(([key, data]) => {
                        return { key, data }
                    })]);
                }
            });

            watchForChildUpdates(`drawings/${context?.userInfo?.uid}/drawn_images`, (eventType, key, data) => {
                console.log(data);
                setMyDrawings([...myDrawings, { key, data }])
            });
        }
    }, [context?.userInfo?.uid]);

    useEffect(() => {
        if (myDrawings?.length) {
            let oldDrawings = myDrawings.filter(x => isOlderThanMinutes(x.data.stamp));
            let promise = Promise.resolve();
            oldDrawings.forEach((d: {
                key: string, data: {
                    stamp: number,
                    url: string,
                    path: string
                }
            }) => {
                promise = promise.then(async () => {
                    await deleteFileFromFirebaseStorage(d.data.path);
                    return await deleteFromFirebaseRTDB(`drawings/${context?.userInfo?.uid}/drawn_images/${d.key}`);
                });
            })
        }
    }, [myDrawings?.length])

    useEffect(() => {
        if (context?.userInfo?.uid && context.paintSessionId) {
            watchForChildUpdates(`hook/${context?.userInfo?.uid}/response/${context.paintSessionId}`, (eventType, key, data) => {
                console.log(`Event: ${eventType}, Key: ${key}, Data:`, data);
                console.log(`${JSON.stringify(data)}`)
            });
        }
    }, [context.paintSessionId, context?.userInfo?.uid])
    function isOlderThanMinutes(timestamp: number, minutes = 1): boolean {
        // Get the current timestamp in milliseconds
        const now = Date.now();

        // Calculate the difference in milliseconds
        const difference = now - timestamp;

        // Convert 3 minutes to milliseconds (3 minutes * 60 seconds * 1000 milliseconds)
        const threeMinutesInMilliseconds = minutes * 60 * 1000;

        // Return true if the difference is greater than three minutes, false otherwise
        return difference > threeMinutesInMilliseconds;
    }


    return (
        <a-scene renderer="colorManagement: true">
            <a-assets>
                <a-asset-item id="iconfontsolid" src="assets/fonts/fa-solid-900.ttf"></a-asset-item>
                <a-asset-item id="iconfontbrand" src="assets/fonts/fa-brands-400.ttf"></a-asset-item>
                <a-asset-item id="textfont1" src="assets/fonts/PermanentMarker-Regular.ttf"></a-asset-item>
                <a-asset-item id="textfont2" src="assets/fonts/PressStart2P-Regular.ttf"></a-asset-item>
                <a-asset-item id="textfont3" src="assets/fonts/Plaster-Regular.ttf"></a-asset-item>
                <a-asset-item id="textfont4" src="assets/fonts/DiplomataSC-Regular.ttf"></a-asset-item>
                <img id="skymap" src="assets/images/sky.jpg" crossOrigin="anonymous" />
                <img id="uinormal" src="assets/images/ui-normal.png" crossOrigin="anonymous" />
                <img id="floor" src="assets/images/floor.jpg" crossOrigin="anonymous" />
                <img id="selectedicon" src="assets/images/selected_icon.png" crossOrigin="anonymous" />
                <img id="pettypaint" src="img/pettypaint.png" crossOrigin="anonymous" />
                <a-asset-item id="logoobj" src="assets/models/logo.obj"></a-asset-item>
                <a-asset-item id="logomtl" src="assets/models/logo.mtl"></a-asset-item>
                <a-asset-item id="uiobj" src="assets/models/ui.obj"></a-asset-item>
                <a-asset-item id="combined_shapes" src="assets/models/combined_shapes.obj"></a-asset-item>
                <a-asset-item id="combined_shapes_material" src="assets/models/combined_shapes.mtl"></a-asset-item>
                <a-asset-item id="hitEntityObj" src="assets/models/teleportHitEntity.obj"></a-asset-item>
                <a-asset-item id="tipObj" src="assets/models/controller-tip.glb"></a-asset-item>
                <a-asset-item id="rigged-model" src="assets/models/basic-inverse-kinematics-rig.glb"></a-asset-item>
                <a-asset-item id="hoodie_obj" src="assets/models/clothes/hoodie/GetProduct3DModel.glb"></a-asset-item>
                <audio id="ui_click0" src="https://cdn.aframe.io/a-painter/sounds/ui_click0.ogg" />
                <audio id="ui_click1" src="https://cdn.aframe.io/a-painter/sounds/ui_click1.ogg" />
                <audio id="ui_menu" src="https://cdn.aframe.io/a-painter/sounds/ui_menu.ogg" />
                <audio id="ui_undo" src="https://cdn.aframe.io/a-painter/sounds/ui_undo.ogg" />
                <audio id="ui_tick" src="https://cdn.aframe.io/a-painter/sounds/ui_tick.ogg" />
                <audio id="ui_paint" src="https://cdn.aframe.io/a-painter/sounds/ui_paint.ogg" />
            </a-assets>
            {/* */}
            {painterLoaded ? <a-entity id="scene" interactions="" ></a-entity> : null}
            <a-light type="point" light="color: #fff; intensity:0.6" position="3 10 1"></a-light>
            <a-light type="point" light="color: #fff; intensity:0.2" position="-3 -10 0"></a-light>
            <a-light type="hemisphere" groundColor="#888" intensity="0.8"></a-light>
            <a-entity id="ground"
                geometry="primitive: box; width: 12; height: 0.01; depth: 12"
                material="shader: flat; src: #floor">
            </a-entity>
            <a-entity id="sky" geometry="primitive:sphere; radius:30; phiLength:360; phiStart:0; thetaLength:90" material="shader:flat; side:back; height:2048; src:#skymap; width:2048"></a-entity>
            {painterLoaded ? <>
                <a-entity position="0 1 -1">
                    <a-canvas-layers></a-canvas-layers>
                </a-entity>
            </> : null}
            <a-entity position="0 0 -1">
                <a-entity id="prettypaintlogo" rotation="90 0 0" position="0 1.5 -1.5"
                    geometry="primitive: box; width: 2; height: 0.01; depth: 2"
                    material="shader: flat; src: #pettypaint; transparent: true; alphaTest: 0.5"
                    animation__position="property: position; dur: 1000; easing: linear; to: 0 4.5 -2; delay: 2500"
                    animation__rotation="property: rotation; dur: 1000; easing: linear; to: -45 0 0; delay: 2500">
                </a-entity>
                <a-entity rotation="0 0 0" position="0 .1 -1.5">
                    <frame-container direction="horizontal" alignment="flexStart" >
                        <frame-radio {...modeRadio}
                            options={JSON.stringify([
                                {
                                    "text": "Painting", "value": "painting", "id": "painting"
                                }, {
                                    "text": "Models", "value": "models_and_loras", "id": "models_and_loras"
                                }, {
                                    "text": "Hoodie", "value": "hoodie", "id": "hoodie"
                                }])}
                            value="painting" />
                    </frame-container>
                </a-entity>
                {painterLoaded ? <>
                    {/* <a-entity ref={myImageRef} draganddropzone='' rotation="-75 180 0" position="0 5 4"
                    geometry={`primitive: box; width: 8; height: 0.01; adepth: ${9 / 16 * 8}`}
                    material="shader: flat; src: #floor">
                </a-entity> */}
                    <a-entity {...{ [HOODIE]: true }}>
                        <a-entity position="0 1.7 -2.1" rotation="0 0 0">
                            <HoodieComponent />
                        </a-entity>
                    </a-entity>
                    <a-entity rotation="0 180 0" position="-3 3.25 3.5">
                        <BigImageMenu />
                    </a-entity>
                    <a-entity position="0 .0 3" >
                        <SpaceMenuComponent myImageSpaceMenu={myImageSpaceMenu} setMyImageSpaceMenu={setMyImageSpaceMenu} />
                    </a-entity>
                </> : null}
                {painterLoaded ? <a-entity {...{ [MODELS_SET]: true }}>
                    {painterLoaded ? <ModelLoraConfigurationComponent /> : null}
                    {painterLoaded ? <a-entity
                        ref={loraSelectionPanelRef}
                        id="lora-selection-panel"
                        position={'-2.4 -4.34 .3'}
                        rotation={`0 0 0`}>
                        <frame-infinite-list
                            ref={loralistRef}
                            selectionevent="lora-selection"
                            width={4}
                            itemsize={1}
                            icon="f128"
                            icon-font-size=".14"
                            font-size=".1"
                            icon-font="assets/fonts/ionicons.ttf"
                            itemtemplate={'a-model-item'}
                            columns={3}
                            closeevent={PAINTER_CONSTANTS.CLOSE_LORA_SELECTION}
                            position="0 1 0">
                        </frame-infinite-list>
                    </a-entity> : null}
                    {painterLoaded ? <>
                        <ModelSelectionPanel listModels={listModels} />
                    </> : null}
                    <a-entity position="-3 .3 -1.5">
                        <ModelFilterMenu />
                    </a-entity>
                </a-entity> : null}
                {painterLoaded ? <a-entity  {...{ [PAINTING_SET]: true }}>
                    {painterLoaded ? <a-entity position="-1.5 3.1 -2">
                        <a-entity position="0 0 -.01">
                            <frame-rounded width="2" height=".3" radius={'.06'} color="#000" opacity=".3" />
                        </a-entity>
                        <frame-troika-text anchor={'right'} value="Credits : " />
                        <frame-troika-text anchor={'left'} value={`${context?.user?.[UserProperties.Credits] || 0}`} />
                    </a-entity> : null}
                    <a-entity position="-2 3.4 -2">
                        <RatioMenu />
                    </a-entity>
                    <a-entity position="-.7 3.4 -2">
                        <SchedulerMenu />
                    </a-entity>
                    <a-entity rotation="0 -90 0" position="4 3.75 .5">
                        <TextLayerComponent />
                    </a-entity>
                </a-entity> : null}
                {painterLoaded ? <a-entity>
                    <a-entity rotation="-75 0 0" position="0 5 4">
                        <frame-canvas-image
                            url="assets/images/floor.jpg"
                            draganddropzone=''
                            rotation="-75 180 0"
                            height={9 / 16 * 8}
                            ref={myImageRef}></frame-canvas-image>
                    </a-entity>
                    {painterLoaded ? <ImagePanels myImageSpaceMenu={myImageSpaceMenu} myImages={myImages} /> : null}
                </a-entity> : null}
                <a-entity  {...{ [PAINTING_SET]: true }}>
                    {painterLoaded && renderBusy ? <a-sphere position="4 3.1 -1" radius="0.05" flash-red></a-sphere> : null}
                    {painterLoaded && renderBusy ? <a-text color="#fff000" position="4 3.1 -.8" rotation="0 -90 0" value="Rendering" ></a-text> : null}
                    {painterLoaded && !context.userInfo ? <a-text color="#ffffff" position="4 3.1 -.8" rotation="0 -90 0" value="Login to render" ></a-text> : null}
                    {painterLoaded ? <>
                        <a-entity position="1 3 -2">
                            <a-entity scale=".5 .5 .5" rotation={'35 0 0'} position=".05 .2 0">
                                <frame-container>
                                    <a-prompt-list-component centered prompts={JSON.stringify([])} ref={promptListComponentRef}></a-prompt-list-component>
                                </frame-container>
                            </a-entity>
                            <a-entity scale=".5 .5 .5" rotation={'35 0 0'} position="-.5 .2 0" >
                                <a-text position="-.75 .4 0" value="Prompts" width="2" scale="2 2 2" color="#fff" ></a-text>
                                <frame-gui-icon-label-button
                                    width=".45" height="0.25"
                                    onclick="onPromptPositive"
                                    icon="f204"
                                    icon-font-size=".14"
                                    font-size=".3"
                                    icon-font="assets/fonts/ionicons.ttf"
                                    value="+"
                                    margin="0 0 0.05 0"
                                    active-color="#009688"
                                    background-color={GetPositiveColor()}
                                    hover-color="#00695C"
                                    border-color="#000000"
                                    font-color="#E0F2F1"
                                ></frame-gui-icon-label-button>
                            </a-entity>
                            <a-entity scale=".5 .5 .5" rotation={'35 0 0'} position="-.75 .2 0" >
                                <frame-gui-icon-label-button
                                    width=".45" height="0.25"
                                    onclick="onPromptNegative"
                                    icon="f204"
                                    icon-font-size=".14"
                                    font-size=".3"
                                    icon-font="assets/fonts/ionicons.ttf"
                                    value="-"
                                    margin="0 0 0.05 0"
                                    active-color="#009688"
                                    background-color={GetNegativeColor()}
                                    hover-color="#00695C"
                                    border-color="#000000"
                                    font-color="#E0F2F1"
                                ></frame-gui-icon-label-button>
                            </a-entity>
                        </a-entity>
                    </> : null}
                    {painterLoaded && hasDrawn ? <a-entity position="3 .2 1.5" rotation="-45 -90 0">
                        <frame-gui-icon-label-button
                            width=".65" height="0.25"
                            onclick="onSaveImage"
                            ref={saveImageRef}
                            icon="f40b"
                            icon-font-size=".14"
                            font-size=".1"
                            icon-font="assets/fonts/ionicons.ttf"
                            value="Save"
                            margin="0 0 0.05 0"
                            active-color="#009688"
                            background-color="#004D40"
                            hover-color="#00695C"
                            border-color="#E0F2F1"
                            font-color="#E0F2F1"
                        ></frame-gui-icon-label-button>
                    </a-entity> : null}

                    {painterLoaded && transformModeOn ?
                        <a-entity position="0 1 -1.9">
                            <a-gui-transformer>
                            </a-gui-transformer>
                        </a-entity> : null}
                    {painterLoaded ? <a-entity ref={loraMenuPanelRef} id="lora-panel" position={'-1.4 -4.34 .3'} rotation={`0 75 0`}>
                        <LoraMenuPanel targetLora={targetLora} />
                    </a-entity> : null}
                    {painterLoaded ? <a-entity
                        id="painter_canvas"
                        ref={painterCanvasRef}
                        paint-canvas={`layerhand:right; height:${context.canvasSize.height}; width:${context.canvasSize.width}`}
                        raycaster-listen="pressed: false"></a-entity> : null}
                    {painterLoaded ?
                        <>
                            <a-gui-flex-container
                                flex-direction="column" justify-content="center" align-items="normal" component-padding="0.01"
                                opacity="0.25" width=".5" height=".5" panel-color="#000" panel-rounded="0.15"
                                position="-3 .4 0" rotation="0 50 0"
                            >
                                <a-text position=".15 0 0" value="Add Layer" width="2" scale="1 1 1" color="#fff" ></a-text>
                                <frame-gui-button
                                    width="0.25" height="0.25"
                                    onclick="addLayer"
                                    value="+"
                                    font-color="#ffffff"
                                    margin="0 0.02 0.05 0"
                                >
                                </frame-gui-button>
                            </a-gui-flex-container>
                        </>
                        : null}
                    {painterLoaded ?
                        <a-entity position="3 2 -1.5" >
                            <a-entity rotation="0 -50 0">
                                <frame-container direction='vertical'>
                                    <DrawMenuComponent />
                                    <MaskModeMenuComponent />
                                </frame-container>
                            </a-entity>
                        </a-entity> : null}
                    {painterLoaded ? <a-entity
                        interaction-panel=""
                        position="1 .01 -1"
                    ></a-entity> : null}
                    {painterLoaded ? <>
                        <a-entity position="1.7 .1 -1" rotation={'-70 -90 0'}>
                            <ColorBrightnessComponent />
                        </a-entity>
                    </> : null}
                    {painterLoaded ? <>
                        <a-entity position="2.4 .1 -1" rotation={'-70 -90 0'}>
                            <BrushSizeComponent />
                        </a-entity>
                    </> : null}
                </a-entity>
                {painterLoaded ? <>
                    <a-entity id="mouseCursor" cursor="rayOrigin: mouse" raycaster="objects: [gui-interactable]">
                    </a-entity>
                    <a-entity id="leftHand" laser-controls="hand: left" raycaster="objects: [gui-interactable]"></a-entity>
                    <a-entity id="rightHand" laser-controls="hand: right" raycaster="objects: [gui-interactable]"></a-entity>
                </> : null}
            </a-entity>
            <a-entity id="cameraRig">
                {/* <!-- camera --> */}
                <a-entity id="acamera" camera>
                </a-entity>
                {/* <!-- Hand controls --> */}
                {painterLoaded ? <a-entity id="leftHand"
                    laser-controls="hand: left"
                    event-set__triggerdown="event: triggerdown; target: #left_box; attribute: material.color; value: #ffff00"
                    event-set__triggerup="event: triggerup; target: #left_box;  attribute: material.color; value: #00ff00"

                    event-set__xbuttonup={`event: xbuttonup; target: #paint_ui; component: paint-ui; function_name: onButtonPressed; function_args: [{"key":"x"}]`}
                    event-set__ybuttonup={`event: ybuttonup; target: #paint_ui; component: paint-ui; function_name: onButtonPressed; function_args: [{"key":"y"}]`}

                    event-set__gripdown="event: gripdown; target: #left_box2;  attribute: material.color; value: #ffff00"
                    event-set__gripup="event: gripup; target: #left_box2;  attribute: material.color; value: #00ff00"

                    event-set__triggerdown1="event: triggerdown; target: #painter_canvas; attribute: raycaster-listen.pressed; value: true"
                    event-set__triggerup1="event: triggerup; target: #painter_canvas;  attribute: raycaster-listen.pressed; value: false"

                    event-set__triggerdown2={`event: triggerdown; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "left", "value": "down", "button": "trigger" }]`}
                    event-set__triggerup2={`event: triggerup; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "left", "value": "up", "button": "trigger" }]`}
                    event-set__thumbstickmoved2={`event: thumbstickmoved; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "left", "value": "up", "button": "thumbstickmoved" }]`}
                    event-set__thumbsticktouchstart={`event: thumbsticktouchstart; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "left", "value": "down", "button": "thumbsticktouch" }]`}
                    event-set__thumbsticktouchend={`event: thumbsticktouchend; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "left", "value": "up", "button": "thumbsticktouch" }]`}

                    raycaster="objects: .raycastable;"
                >
                    <a-entity id="paint_ui" brush paint-ui ></a-entity>
                </a-entity> : null}
                {painterLoaded ? <a-entity id="rightHand"
                    laser-controls="hand: right"
                    event-set__xbuttonup={`event: xbuttonup; target: #paint_ui; component: paint-ui; function_name: onFunctionSelection; function_args: [{"key":"up"}]`}
                    event-set__ybuttonup={`event: ybuttonup; target: #paint_ui; component: paint-ui; function_name: onFunctionSelection; function_args: [{"key":"down"}]`}

                    event-set__gripdown={`event: gripdown; target: #paint_ui; component: paint-ui; function_name: onFunctionExecute; function_args: [{"on":true}]`}
                    event-set__gripup={`event: gripup; target: #paint_ui; component: paint-ui; function_name: onFunctionExecute; function_args: [{"on": false}]`}

                    event-set__triggerdown2={`event: triggerdown; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "right", "value": "down", "button": "trigger" }]`}
                    event-set__triggerup2={`event: triggerup; target: #scene; component: interactions; function_name: onButtonInteraction; function_args: [{"hand": "right", "value": "up", "button": "trigger" }]`}
                    raycaster="objects: .raycastable"
                    line="color: #118A7E">
                </a-entity> : null}
            </a-entity>
        </a-scene>
    );

}