import React, { useContext, useEffect, useRef, useState } from 'react';
import { PAINTER_CONSTANTS } from './painter/constants';
import { raiseCustomEvent, uuidv4 } from './painter/util';
import { PainerFabricCanvas } from "./PainterCanvas";
import { APainterContext } from './APainterContext';
export default function PainterCanvasManager() {
    const canvases = useRef([])
    const context = useContext(APainterContext);
    const [update, setUpdate] = useState(Date.now());
    useEffect(() => {
        setUpdate(Date.now());
    }, [context.shouldUpdate])
    useEffect(() => {
        let handle = (evt) => {
            setUpdate(Date.now());
            if (canvases.current.length < 11)
                canvases.current.push(uuidv4());
        }
        document.body.addEventListener(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILD, handle)
        // raiseCustomEvent(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILD, {});
        return () => {
            document.body.removeEventListener(PAINTER_CONSTANTS.PAINTER_CANVAS_BUILD, handle)
        }
    }, [])
    return (
        <>
            {canvases.current.map((canvasId) => {
                return <PainerFabricCanvas
                    id={canvasId}
                    key={canvasId}
                    height={context.canvasSize.height}
                    width={context.canvasSize.width} />
            })}
        </>
    )

}